import { ContactRow } from "services/src/shared/models/Contact";
import { ContactGroupRowForDisplay } from "services/src/shared/models/ContactGroup";
import { getCurEpochMs } from "utils/dateTime";

import { queryFetch } from "@/helpers/fetch";
import { setUpdatesLastFetchedAt } from "@/hooks/useContacts";

export function getGroupsByContactId(groups: ContactGroupRowForDisplay[]) {
  const groupByContactId: { [contactId: string]: ContactGroupRowForDisplay[] } = {};
  for (const group of groups) {
    // probably don't need to be enforced here, but just in case
    const uniqueContactIds: { [id: string]: true } = {};
    if (!group.contactIds) continue;

    for (const contactId of group.contactIds) {
      if (!groupByContactId[contactId]) groupByContactId[contactId] = [];
      if (!uniqueContactIds[contactId]) groupByContactId[contactId].push(group);
      uniqueContactIds[contactId] = true;
    }
  }
  return groupByContactId;
}

export function getIdIndex(list: { id: string }[] | undefined) {
  const index: { [id: string]: number } = {};
  if (!list) return index;
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (item && item.id) {
      index[item.id] = i;
    }
  }
  return index;
}

export async function fetchPaginatedContacts(
  page: number,
  startKey: string,
  callback?: (contacts: ContactRow[]) => Promise<any>,
) {
  const curTimestamp = getCurEpochMs();

  const {
    data: contactUpdates,
    response,
    error,
  } = await queryFetch<ContactRow[]>(`/contacts?page=${page}&startKey=${startKey}`);

  if (contactUpdates && contactUpdates.length > 0) {
    setUpdatesLastFetchedAt(curTimestamp);
    if (callback) await callback(contactUpdates);
  }
  return { startKey: response?.headers?.get("next-start-key"), page: page + 1, error };
}
