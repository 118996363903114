import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";

import {
  selectAuthenticatedSession,
  selectAuthExplictlySignedOut,
} from "../integrations/auth/selectors";
import { signIn, signOut } from "../integrations/auth/slice";
import { getAuth } from "../integrations/firebase";
import { useAppDispatch } from "../integrations/redux/store";

let isTransitioning = false;

const useAuth = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const session = useSelector(selectAuthenticatedSession);
  const explicitlySignedOut = useSelector(selectAuthExplictlySignedOut);

  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);

  const handleStateTransition = useCallback(async () => {
    if (isTransitioning) return;
    isTransitioning = true;
    try {
      if (user && !error) {
        dispatch(signIn(user));
      } else if ((explicitlySignedOut || !user) && !loading && router.route !== "/signin") {
        dispatch(signOut());
        await router.push("/signout");
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }

    isTransitioning = false;
  }, [user, error, explicitlySignedOut, loading, router, dispatch]);

  useEffect(() => {
    handleStateTransition();
  }, [error, explicitlySignedOut, loading, user]);

  useEffect(() => {
    if (session) {
      Sentry.setUser({
        id: session.sub,
        email: session.email || undefined,
      });
    }
  }, [session]);

  return { session, authUser: user, loading, error };
};

export default useAuth;
