import { Listbox, Transition } from "@headlessui/react";
import classNames from "clsx";
import type { FC} from "react";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { CheckIcon } from "../../../core/Icon";

export interface FieldSelectItem {
  value: string;
  displayName: string;
}

type FieldSelectProps = {
  title: string;
  items: FieldSelectItem[];
  initialItemValue?: string;
  onSelectItemValue: (value: string) => void;
  shouldResetSelection?: boolean;
};

const FieldSelect: FC<FieldSelectProps> = ({
  title,
  items,
  initialItemValue,
  onSelectItemValue,
  shouldResetSelection,
}) => {
  const popperDivRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
  });

  const [selectedItem, setSelectedItem] = useState<FieldSelectItem | null>(null);

  useEffect(() => {
    if (items && initialItemValue) {
      setSelectedItem(items.find((item) => item.value === initialItemValue) || null);
    }
  }, [items, initialItemValue]);

  const handleOnChange = (item: FieldSelectItem) => {
    if (!shouldResetSelection) {
      setSelectedItem(item);
    }
    onSelectItemValue(item.value);
  };

  return (
    <Listbox value={selectedItem} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative flex justify-end mr-2 space-y-1">
          <div>
            <Listbox.Button
              ref={setReferenceElement}
              className="relative w-full px-5 py-2 mt-px text-sm font-medium text-color-purple"
            >
              <div className="flex items-center">{title}</div>
            </Listbox.Button>

            {/* Options */}
            <div
              ref={popperDivRef}
              style={{ ...styles.popper, minWidth: referenceElement?.scrollWidth, zIndex: 100 }}
              {...attributes.popper}
            >
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
                beforeEnter={() => setPopperElement(popperDivRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Listbox.Options
                  static
                  className="z-50 w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg bg-primary max-h-56 ring-1 ring-primary focus:outline-none sm:text-sm"
                >
                  {/* Main list of items */}
                  {items.map((item, index) => (
                    <TypeSelectOption item={item} key={index} />
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        </div>
      )}
    </Listbox>
  );
};

const TypeSelectOption: FC<{ item: FieldSelectItem }> = ({ item }) => {
  return (
    <Listbox.Option
      key={item.value}
      className={({ active }) =>
        classNames(
          active ? "text-white bg-purple-600" : "text-zinc-900 dark:text-zinc-400",
          "cursor-pointer select-none relative py-3 pl-3 pr-9"
        )
      }
      value={item}
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center">
            {/* Display name */}
            <span
              className={classNames(
                "ml-2 block truncate",
                selected ? "font-semibold dark:text-zinc-100" : "font-normal"
              )}
            >
              {item.displayName}
            </span>
          </div>

          {/* Checkmark (if selected) */}
          {selected ? (
            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
              <CheckIcon className={active ? "text-white" : "text-purple-600"} aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};

export default FieldSelect;
