import type { FC } from "react";
import { ChangeEvent } from "react";

import { getJobDescriptionList } from "@/helpers/contact";

import { BriefcaseIcon } from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import type { ContactDataFieldProps } from "../types";

const JobsField: FC<ContactDataFieldProps> = ({ contactData, dispatch, isEditing }) => {
  const companyName = contactData?.companyName || "";
  const jobTitle = contactData?.jobTitle || "";
  const departmentName = contactData?.departmentName || "";

  const jobList = getJobDescriptionList(contactData);

  return jobList.filter((str) => str || "").length > 0 || isEditing ? (
    <BaseDetailsField
      label="Jobs"
      isEditing={isEditing}
      icon={<BriefcaseIcon size="lg" className="icon-color-purple" />}
    >
      {!isEditing && (
        <dd>
          <span className="flex py-2 pl-2 mr-2 -my-2 -ml-2 rounded-md group hover:bg-zinc-100 dark:hover:bg-zinc-900 pr-28">
            {jobList.join(" ")}
          </span>
        </dd>
      )}
      {isEditing && (
        <>
          <dd key="company" className="flex flex-row w-full">
            <TypeLabel>Company</TypeLabel>
            <div className="flex flex-1 mr-2">
              <TextInput
                name="companyName"
                placeholder="Name"
                type="text"
                value={companyName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (dispatch)
                    dispatch({
                      type: "companyName",
                      payload: event.target.value,
                    });
                }}
                className="flex-1 mr-2"
              />
              <TextInput
                name="departmentName"
                placeholder="Department"
                type="text"
                value={departmentName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (dispatch)
                    dispatch({
                      type: "departmentName",
                      payload: event.target.value,
                    });
                }}
                className="flex-1"
              />
            </div>
          </dd>
          <dd key="jobTitle" className="flex flex-row w-full">
            <TypeLabel>Job Title</TypeLabel>
            <TextInput
              name="jobTitle"
              placeholder="Job title"
              type="text"
              value={jobTitle}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (dispatch) dispatch({ type: "jobTitle", payload: event.target.value });
              }}
              className="flex-1 mr-2"
            />
          </dd>
        </>
      )}
    </BaseDetailsField>
  ) : null;
};

export default JobsField;
