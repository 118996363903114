import { ContactInteractionRow } from "@shared/models/ContactInteraction";
import classNames from "clsx";
import React, { FC, memo } from "react";
import { getTimeStr } from "utils/dateTime";

import { CalendarDaysIcon, EnvelopeIcon } from "@/components/core/Icon";
import { getFormattedEventTime } from "@/helpers/date";

const ContactInteractionLink: FC<{ interaction: ContactInteractionRow }> = ({ interaction }) => {
  return (
    <a
      key={`${interaction.email}_${interaction.vendorTable_vendorTableId}`}
      href={interaction.url}
      target="_blank"
      className={classNames("inline-block text-primary truncate", {
        "font-bold": interaction.labelIds?.includes("UNREAD"),
      })}
    >
      <div className="flex h-10 w-8 shrink-0 items-center sm:h-12">
        {interaction.vendorTable === "GmailMessage" ? (
          <EnvelopeIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        ) : (
          <CalendarDaysIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
        )}
      </div>
      <div className="flex w-full overflow-hidden">
        <div className="text-sm text-primary flex-auto truncate pr-4">
          {interaction?.summary || ""}
          {interaction.vendorTable === "CalendarEvent" &&
            interaction.startAt &&
            `: ${getFormattedEventTime(interaction.startAt, interaction.endAt)}`}
        </div>
        <div className="text-sm text-secondary whitespace-nowrap flex-shrink-0">
          {getTimeStr(interaction.createdAt)}
        </div>
      </div>
    </a>
  );
};

export default memo(ContactInteractionLink);
