import { useCallback, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export function useKeyboardSelectControl<T extends { id: string }>(items: T[]) {
  const [hoveredOptionIndex, setHoveredOptionIndex] = useState<number | undefined>(undefined);

  const onUpNavigation = useCallback(() => {
    setHoveredOptionIndex((prev) => Math.max((prev || 0) - 1, 0));
  }, [setHoveredOptionIndex]);

  const onDownNavigation = useCallback(() => {
    setHoveredOptionIndex((prev) =>
      Math.min(typeof prev !== "undefined" ? prev + 1 : 0, items.length - 1),
    );
  }, [items.length, setHoveredOptionIndex]);

  useHotkeys("up", onUpNavigation, {
    enableOnFormTags: true,
    enableOnContentEditable: true,
  });

  useHotkeys("down", onDownNavigation, {
    enableOnFormTags: true,
    enableOnContentEditable: true,
  });

  return {
    hoveredOptionIndex,
    setHoveredOptionIndex,
  };
}
