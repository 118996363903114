import type { ContactRow } from "@shared/models/Contact";
import { ContactMetadataValueMap } from "@shared/models/ContactMetadata";
import classNames from "clsx";
import { getSurnameForDisplay } from "core/helpers/contact";
import { Dispatch, FC, ReactElement } from "react";
import React, { useCallback, useEffect, useMemo } from "react";

import CompanyDetailDrawer from "@/components/contacts/details/CompanyDetailDrawer";
import ContactHistoryDrawer from "@/components/contacts/details/history/ContactHistoryDrawer";
import {
  useCompanyDetail,
  useContactFields,
  useContactHistory,
} from "@/components/contacts/details/hooks";
import BaseDetailsField from "@/components/contacts/details/shared/BaseDetailsField";
import type { ContextualMenuFieldValue } from "@/components/contacts/details/shared/ContextualMenuField";
import {
  UpdateContactDataAction,
  UpsertContactMetadataAction,
} from "@/components/contacts/details/types";
import ContactInteraction from "@/components/contacts/interaction";
import Avatar from "@/components/core/Avatar";
import Button, { ButtonVariant } from "@/components/core/Button";
import { SpecialColor, SpecialHoverColor } from "@/components/core/colorVariant";
import { BookArrowRight, SquareInfoIcon } from "@/components/core/Icon";
import HighlightText from "@/components/HighlightText";
import { getJobDescriptionList } from "@/helpers/contact";
import { useContactMetadataFields } from "@/hooks/data/useContactMetadataFields";
import { useLiveContact } from "@/hooks/data/useLiveContacts";

const ContactDetails: FC<{
  contactData: ContactRow;
  isEditing?: boolean;
  dispatch?: Dispatch<UpdateContactDataAction>;
  focusedField?: ContextualMenuFieldValue<any> | undefined;
  onFocusField?: (field: ContextualMenuFieldValue<any>) => void;
  setSearchQuery?: (query: string) => void;
  contactMetadataValueMap?: ContactMetadataValueMap;
  contactMetadataDispatch?: Dispatch<UpsertContactMetadataAction>;
  singleCol?: boolean;
  noCompanyDetail?: boolean;
  noContactHistory?: boolean;
  endNameContent?: ReactElement;
}> = ({
  contactMetadataValueMap,
  contactMetadataDispatch,
  contactData,
  setSearchQuery,
  isEditing,
  dispatch,
  focusedField,
  onFocusField,
  singleCol,
  noCompanyDetail,
  noContactHistory,
  endNameContent,
}) => {
  const { editContact } = useLiveContact(contactData?.id);

  /**
   * Helper function to directly update contact data, mostly used by fields from read mode. For
   * example, notes field or mark / unmark default.
   *
   * @param dataToUpdate Contact data to upate
   */
  const directlyUpdateContactData = useCallback(
    async (dataToUpdate: Partial<ContactRow>) => {
      const updatedContactData: ContactRow = {
        ...contactData,
        ...dataToUpdate,
      };

      try {
        await editContact(updatedContactData);
      } catch (error) {
        console.error("Encountered error directly updating contact data. Error: ", error);
      }
    },
    [contactData, editContact],
  );

  const { fields: Fields } = useContactFields({
    contactData,
    dispatch,
    isEditing: isEditing || false,
    focusedField,
    onFocusField,
    directlyUpdateContactData,
    showNamedEntities: true,
    setSearchQuery,
    contactMetadataDispatch,
    contactMetadataValueMap,
  });

  const contactMetadataFields = useContactMetadataFields({
    contact: contactData,
    isEditing: isEditing || false,
    setSearchQuery,
    contactMetadataDispatch,
    contactMetadataValueMap,
  });

  const { isContactHistoryOpen, setIsContactHistoryOpen, undoVersion, rollbackToVersion } =
    useContactHistory(contactData, dispatch);

  const {
    isContactCompanyDetailOpen,
    setIsCompanyDetailOpen,
    setCompanyDataQuery,
    contactCompanyDataByName,
    contactCompanyDataByEmail,
  } = useCompanyDetail(contactData);

  const jobDescriptions = useMemo(() => getJobDescriptionList(contactData), [contactData]);

  useEffect(() => {
    return () => {
      setCompanyDataQuery({ email: undefined, name: undefined, domain: undefined });
    };
  }, [setCompanyDataQuery]);

  return (
    <>
      <div
        className={classNames(
          "flex flex-col max-h-full",
          isContactCompanyDetailOpen ? "w-1/2" : "w-full",
        )}
      >
        <div className="flex items-center bg-primary z-10 px-4 py-6 sm:px-6 lg:px-8 w-full">
          {isEditing ? (
            <h1 className="text-lg font-semibold truncate text-zinc-900 dark:text-white sm:font-bold sm:text-2xl">
              Edit Contact
            </h1>
          ) : (
            <>
              <Avatar
                firstName={contactData?.givenName}
                lastName={getSurnameForDisplay(contactData?.surname)}
                photos={contactData?.photos}
                className="w-10 h-10 text-md sm:text-2xl sm:w-16 sm:h-16"
              />
              <div className="flex-1 flex flex-col justify-center min-w-0 -mt-1 sm:mt-0 pl-4">
                <h1 className="mb-1 text-lg font-semibold truncate text-zinc-900 dark:text-white sm:font-bold sm:text-2xl">
                  <HighlightText
                    matchPhrase={false}
                    value={`${contactData?.prefix || ""} ${contactData?.givenName || ""} ${
                      contactData?.middleName || ""
                    } ${getSurnameForDisplay(contactData?.surname)} ${contactData?.suffix || ""}`}
                  />
                </h1>
                {jobDescriptions.map((desc, index) => (
                  <h4
                    key={index}
                    className="-mt-0.5 text-xs text-zinc-500 truncate sm:text-base items-center flex"
                  >
                    <HighlightText value={desc} matchPhrase={false} key={index} />

                    {desc === contactData.companyName &&
                      contactCompanyDataByName &&
                      contactCompanyDataByName?.company &&
                      !isContactCompanyDetailOpen &&
                      !noCompanyDetail && (
                        <div className="flex items-center ml-4 mt-1">
                          <Button
                            variant={ButtonVariant.None}
                            noGutter
                            hotkey={["alt+o"]}
                            onClick={() => {
                              setCompanyDataQuery({ name: contactData.companyName });
                              setIsCompanyDetailOpen(true);
                            }}
                            iconOnly
                            icon={
                              <BookArrowRight
                                fixedWidth
                                className={classNames(SpecialColor, SpecialHoverColor, "border-0")}
                              />
                            }
                          />
                        </div>
                      )}
                  </h4>
                ))}
              </div>
            </>
          )}
          {endNameContent}
        </div>

        <div
          className={classNames(
            "flex flex-1 overflow-hidden flex-col space-x-6",
            isEditing ? "space-y-6 divide-primary-y" : "sm:flex-row max-w-7xl",
          )}
        >
          {/* Left column */}

          <div
            className={classNames(
              "px-4 sm:px-6 lg:px-8",
              isContactHistoryOpen || isContactCompanyDetailOpen
                ? "overflow-auto flex-1 pr-4"
                : "overflow-hidden grid grid-cols-1 gap-4 w-full overflow-y-auto",
              !singleCol && "xl:grid-cols-2",
            )}
          >
            <dl
              className={classNames(
                "w-full space-y-6 lg:space-y-8 none-if-empty relative pr-4",
                !singleCol && "xl:overflow-y-auto",
              )}
            >
              {isEditing && Fields.names}
              {isEditing && Fields.jobs}
              {Fields.emails}
              {Fields.phoneNumbers}
              {Fields.birthday}
              {Fields.physicalAddresses}
              {Fields.dates}
              {Fields.relatives}
              {Fields.socialProfiles}
              {Fields.webPages}
              {Fields.imHandles}
              <div className="h-1  w-full" />
            </dl>
            {/* Right column */}
            <dl
              className={classNames(
                "w-full space-y-6 lg:space-y-8 none-if-empty relative pr-4",
                !singleCol && "xl:overflow-y-auto",
              )}
            >
              {Fields.groups}
              {Fields.notes}
              {(Object.keys(contactMetadataFields).length > 0 || isEditing) && (
                <BaseDetailsField
                  label="Metadata"
                  icon={
                    <SquareInfoIcon
                      size="lg"
                      className={classNames("icon-color-purple", isEditing && "mt-6")}
                    />
                  }
                  className={classNames("space-y-4", isEditing ? "mt-3.5" : "")}
                >
                  {contactMetadataFields.industry}
                  {contactMetadataFields.gender}
                </BaseDetailsField>
              )}

              <div className="h-1  w-full" />
            </dl>
          </div>

          <ContactHistoryDrawer
            contact={contactData}
            isDrawerOpen={isContactHistoryOpen && !noContactHistory}
            setIsDrawerOpen={setIsContactHistoryOpen}
            undoVersion={undoVersion}
            rollbackToVersion={rollbackToVersion}
          />
        </div>

        <ContactInteraction contactId={contactData.id} />
      </div>
      {!noCompanyDetail && (
        <CompanyDetailDrawer
          company={contactCompanyDataByEmail?.company || contactCompanyDataByName?.company}
          isLoading={
            typeof contactCompanyDataByEmail === "undefined" ||
            typeof contactCompanyDataByName === "undefined"
          }
        />
      )}
    </>
  );
};

export default ContactDetails;
