import { BlockNoteEditor, PartialBlock } from "@blocknote/core";
import { BlockNoteTipTapEditorOptions } from "@blocknote/core/types/src/editor/BlockNoteTipTapEditor";
import { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import classNames from "clsx";
import dynamic from "next/dynamic";
import { FC, useCallback, useContext, useMemo, useRef, useState } from "react";

import Button, { ButtonVariant } from "@/components/core/Button";
import { EnvelopeBulkIcon, TrashIcon } from "@/components/core/Icon";
import EmailAccountSelection from "@/components/email/EmailAccountSelection";
import { EmailDraftContext } from "@/components/email/EmailDraftProvider";
import EmailDrawerWindowControl, {
  EmailDrawerWindowControlProps,
} from "@/components/email/EmailDrawerWindowControl";
import EmailRecipients from "@/components/email/EmailRecipients";
import EmailSendScheduleControls from "@/components/email/EmailSendScheduleControls";
import EmailTools from "@/components/email/EmailTools";
import { useFlattenedContactRecipientList } from "@/components/email/hooks";
import MailMergePreviewModal from "@/components/email/MailMergePreviewModal";
import SendScheduleBanner from "@/components/email/SendScheduleBanner";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

const EmailEditor = dynamic(() => import("../editor/EmailTemplateEditor"), { ssr: false });

const EmailEditorPanel: FC<
  EmailDrawerWindowControlProps & {
    isFullWindow?: boolean;
    remoteApiWithEmailSend: RemoteApiForDisplay[];
    onSendOneOffEmail: (draft: LocallyPersistedEmailDraft) => Promise<void>;
  }
> = ({
  draft,
  remoteApiWithEmailSend,
  onChangeWindowState,
  isFullWindow = false,
  onSendOneOffEmail,
}) => {
  const editorRef = useRef<BlockNoteEditor<any, any, any>>();

  const [subjectInput, setSubjectInput] = useState<string>(draft.subject || "");

  const [hideSideMenu, setHideSideMenu] = useState(false);
  const [showMailMergePreview, setShowMailMergePreview] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);

  const flattenedRecipientList = useFlattenedContactRecipientList(draft);

  const { editDraft, deleteDraft: doDeleteDraft } = useContext(EmailDraftContext);

  const deleteDraft = useCallback(async () => {
    doDeleteDraft(draft.id);
  }, [doDeleteDraft, draft.id]);

  const updateDraft = useCallback(
    async (update: Partial<Omit<LocallyPersistedEmailDraft, "id">>) => {
      editDraft(draft.id, update as Partial<LocallyPersistedEmailDraft>);
    },
    [draft.id, editDraft],
  );

  const onScheduledAtChange = useCallback(
    async (date: Date | undefined) => {
      return updateDraft({ scheduledAt: date ? date.getTime() : undefined });
    },
    [updateDraft],
  );

  const onContentChange = useCallback<
    NonNullable<BlockNoteTipTapEditorOptions["onUpdate"]>
  >(async () => {
    const body = editorRef.current?.document;
    if (body) await updateDraft({ body: body as PartialBlock[] });
  }, [updateDraft]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const updateDraftSubject = useCallback(async () => {
    editDraft(draft.id, { subject: subjectInput });
  }, [draft.id, editDraft, subjectInput]);

  const sendOneOffEmail = useCallback(async () => {
    return onSendOneOffEmail(draft);
  }, [draft, onSendOneOffEmail]);

  const onHideSideMenu = useCallback(() => {
    setHideSideMenu(true);
  }, []);

  const onShowSideMenu = useCallback(() => {
    setHideSideMenu(false);
  }, []);

  const sendScheduleControls = useMemo(() => {
    return (
      <EmailSendScheduleControls
        showSchedule={showSchedule}
        setShowSchedule={setShowSchedule}
        flattenedRecipientList={flattenedRecipientList}
        onChange={onScheduledAtChange}
        onSendOneOffEmail={sendOneOffEmail}
        scheduledAt={draft.scheduledAt ? new Date(draft.scheduledAt) : undefined}
        remoteApiWithEmailSend={remoteApiWithEmailSend}
      />
    );
  }, [
    draft.scheduledAt,
    flattenedRecipientList,
    onScheduledAtChange,
    remoteApiWithEmailSend,
    sendOneOffEmail,
    showSchedule,
  ]);

  return (
    <div
      key={draft.id}
      id={`emailDraft_${draft.id}`}
      className={classNames(
        "rounded-t-md drop-shadow-xl bg-primary border-t border-l border-r border-color-primary overflow-hidden shrink-0 pointer-events-auto z-40",
        isFullWindow
          ? "flex flex-col fixed top-20 bottom-10 left-0 right-0 z-50 m-auto min-w-[80%] sm:min-w-[60vw] sm:max-w-[1000px] border-b rounded-b-md"
          : "w-[600px]",
      )}
    >
      <div className="bg-secondary w-full rounded-t-md flex px-4 py-1 items-center">
        <div className="flex-1 text-secondary text-sm truncate pr-4">
          {draft.subject || "New Message"}
        </div>
        <EmailDrawerWindowControl draft={draft} onChangeWindowState={onChangeWindowState} />
      </div>

      {draft && flattenedRecipientList && (
        <MailMergePreviewModal
          draft={draft}
          flattenedRecipientList={flattenedRecipientList}
          isOpen={flattenedRecipientList.isMailMerge && showMailMergePreview}
          onClose={() => setShowMailMergePreview(false)}
          sendScheduleControls={sendScheduleControls}
          showSchedule={showSchedule}
        />
      )}

      <div
        className="border-b border-color-primary"
        onMouseEnter={onHideSideMenu}
        onMouseLeave={onShowSideMenu}
      >
        <EmailAccountSelection
          draft={draft}
          updateDraft={updateDraft}
          remoteApiWithEmailSend={remoteApiWithEmailSend}
          selectedRemoteApiId={draft.remoteApiId}
        />

        <EmailRecipients
          draft={draft}
          updateDraft={updateDraft}
          containerHeight={wrapperRef.current?.scrollHeight || 300}
        />

        <div className="w-full border-b border-color-primary" />

        <div className="flex w-full items-center text-secondary">
          <div className="ml-4 text-label text-sm">Subject</div>
          <input
            onBlur={updateDraftSubject}
            className="block flex-1 border-transparent text-primary focus:border-transparent bg-transparent focus:ring-0 sm:text-sm outline-0"
            value={subjectInput}
            onChange={({ target }) => setSubjectInput(target.value)}
          />
        </div>
      </div>

      {!showMailMergePreview && (
        <div
          className={classNames(
            isFullWindow ? "flex flex-1 flex-col" : "flex-1 md:h-[40vh]",
            "overflow-y-auto py-2",
          )}
          ref={wrapperRef}
        >
          <SendScheduleBanner draft={draft} />
          <EmailEditor
            initialContent={draft.body}
            editorRef={editorRef}
            onContentChange={onContentChange}
            hideSideMenu={hideSideMenu}
          />
        </div>
      )}

      <div className="border-t border-color-primary">
        <div className="rounded-md flex-1 p-4 flex shadow space-x-2">
          <div className="flex flex-1 space-x-2">
            {flattenedRecipientList?.isMailMerge ? (
              <Button
                variant={ButtonVariant.Special}
                icon={<EnvelopeBulkIcon />}
                disabled={!flattenedRecipientList.flattenedContactList.length}
                onClick={() => {
                  setShowMailMergePreview(true);
                }}
              >
                Preview Mail Merge
              </Button>
            ) : (
              sendScheduleControls
            )}

            <EmailTools updateDraft={updateDraft} draft={draft} />
          </div>

          {!showSchedule && (
            <Button
              variant={ButtonVariant.Danger}
              icon={<TrashIcon />}
              tooltip="Discard Draft"
              onClick={deleteDraft}
              iconOnly
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailEditorPanel;
