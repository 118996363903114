import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { ContactMetadataRowForDisplay } from "@shared/models/ContactMetadata";
import { IsReadOnly } from "@shared/models/types";
import type { LocallyPersistedContactRow } from "core/helpers/contact";
import {
  contactGroupSearchOptions,
  contactMetadataSearchOptions,
  contactSearchOptions,
  prospectSearchOptions,
} from "core/helpers/contactSearch";
import {
  ContactGroupSearchIndex,
  ContactMetadataSearchIndex,
  ContactSearchIndex,
  SearchableContact,
  SearchableContactGroup,
  SearchableContactMetadata,
} from "core/types/contactSearch";
import { SimpleDocumentSearchResultSetUnit } from "flexsearch";
import debounce from "lodash/debounce";
import PQueue from "p-queue";
import { batchPromiseAllSettled } from "utils/promise";

export const MAX_SEARCH_RESULT_SIZE = 10000;

export let isSearchInUse = false;

export const indexableContactMetadataFields: ContactMetadataRowForDisplay["type"][] = [
  "gender",
  "industry",
];

export let frontendProspectSearch: ContactSearchIndex | undefined;
export let frontendContactSearch: ContactSearchIndex | undefined;
export let frontendContactMetadataSearch: ContactMetadataSearchIndex | undefined;

export let frontendContactGroupSearch: ContactGroupSearchIndex | undefined;

let searchPromiseQueue = new PQueue({ concurrency: 1 });

export const performProspectSearchAsync = async (query: string, options?: any) => {
  isSearchInUse = true;
  const result = await frontendProspectSearch?.searchAsync(query, {
    limit: MAX_SEARCH_RESULT_SIZE,
    ...options,
  });

  const metadataResult = await frontendContactMetadataSearch?.searchAsync(query, {
    ...options,
    limit: MAX_SEARCH_RESULT_SIZE,
  });

  normalizeContactMetadataResults(metadataResult || []);

  isSearchInUse = false;
  return [...(result || []), ...(metadataResult || [])];
};

export const performContactSearchAsync = async (query: string, options?: any) => {
  isSearchInUse = true;
  const result = await frontendContactSearch?.searchAsync(query, {
    limit: MAX_SEARCH_RESULT_SIZE,
    ...options,
  });

  const metadataResult = await frontendContactMetadataSearch?.searchAsync(query, {
    limit: MAX_SEARCH_RESULT_SIZE,
    ...options,
  });

  normalizeContactMetadataResults(metadataResult || []);

  isSearchInUse = false;
  return [...(result || []), ...(metadataResult || [])];
};

export const performContactGroupSearchAsync = async (query: string, options?: any) => {
  return frontendContactGroupSearch?.searchAsync(query, {
    limit: MAX_SEARCH_RESULT_SIZE,
    ...options,
  });
};

export const bulkIndexDbRowsInContactSearch = debounce(bulkIndexContactRowsInSearch, 100, {
  leading: false,
  trailing: true,
});

export function removeContactGroupRowFromSearch(id: string) {
  return searchPromiseQueue.add(async () => frontendContactGroupSearch?.removeAsync(id));
}

export function removeDbRowFromSearch(id: string) {
  return searchPromiseQueue.add(async () =>
    Promise.all([frontendContactSearch?.removeAsync(id), frontendProspectSearch?.removeAsync(id)]),
  );
}

export function removeContactMetadataDbRowFromSearch(contactId_type: string) {
  return searchPromiseQueue.add(async () =>
    frontendContactMetadataSearch?.removeAsync(contactId_type),
  );
}

export function resetFrontendContactSearch() {
  console.log("Resetting frontend contact search...");
  searchPromiseQueue = new PQueue({ concurrency: 1 });
  isSearchInUse = false;
  frontendContactSearch = new window.FlexSearch.Document<SearchableContact>(contactSearchOptions);
}

/**
 * Contact metadata results are keyed by contactId_type, we need to get unique list of contact ids to merge
 * @param result
 */
function normalizeContactMetadataResults(result: SimpleDocumentSearchResultSetUnit[]) {
  for (const [i, r] of result.entries()) {
    const uniqueIds = new Set<string>();
    for (const id of r.result) {
      const [contactId] = String(id).split("_");
      uniqueIds.add(contactId);
    }
    result[i].result = Array.from(uniqueIds);
  }
}

export function bulkIndexContactGroupRowsInSearch(contactGroupRows: ContactGroupRowForDisplay[]) {
  if (!frontendContactGroupSearch) {
    frontendContactGroupSearch = new window.FlexSearch.Document<SearchableContactGroup>(
      contactGroupSearchOptions,
    );
  }

  return searchPromiseQueue.add(async () => {
    await batchPromiseAllSettled(contactGroupRows, async ({ name, id }) => {
      return frontendContactGroupSearch?.update(id, { name, id });
    });
  });
}

export const bulkIndexContactMetadataRowsInSearch = debounce(
  (contactMetadataRows: ContactMetadataRowForDisplay[]) => {
    if (!frontendContactMetadataSearch) {
      frontendContactMetadataSearch = new window.FlexSearch.Document<SearchableContactMetadata>(
        contactMetadataSearchOptions,
      );
    }

    const rows = contactMetadataRows
      .filter((row) => indexableContactMetadataFields.includes(row.type))
      .map(({ values, ...row }) => ({ ...row, [row.type]: values })) as SearchableContactMetadata[];

    return searchPromiseQueue.add(async () => {
      await batchPromiseAllSettled(rows, async (contactMetadata) => {
        return frontendContactMetadataSearch?.update(
          contactMetadata.contactId_type,
          contactMetadata,
        );
      });
    });
  },
  100,
  { leading: false, trailing: true },
);

export function bulkIndexContactRowsInSearch(rows: LocallyPersistedContactRow[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (typeof window === "undefined" || typeof FlexSearch === "undefined") {
    console.info("Not in main browser env, skipping search indexing...");
    return;
  }

  if (!frontendContactSearch) {
    frontendContactSearch = new window.FlexSearch.Document(contactSearchOptions);
  }

  if (!frontendProspectSearch) {
    frontendProspectSearch = new window.FlexSearch.Document(prospectSearchOptions);
  }

  return searchPromiseQueue.add(async () => {
    isSearchInUse = true;
    await batchPromiseAllSettled(
      rows,
      async (row) => {
        if (row.isReadOnly === IsReadOnly.YES) {
          return frontendProspectSearch?.updateAsync(row.id, row);
        }
        return frontendContactSearch?.updateAsync(row.id, row);
      },
      100,
    );

    isSearchInUse = false;
  });
}
