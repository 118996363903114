import { isComboGroup, isIntersectGroup, isSmartGroup } from "@shared/helpers/contactGroup";
import { Contact, ContactRow } from "@shared/models/Contact";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { SortableKeys } from "core/helpers/contact";
import uniqBy from "lodash/uniqBy";
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { HotkeyCallback } from "react-hotkeys-hook/dist/types";
import { useSelector } from "react-redux";
import { VirtuosoHandle } from "react-virtuoso";
import { useDebouncedCallback } from "use-debounce";

import { ContactListProps } from "@/components/contacts/list/types";
import HighlightText from "@/components/HighlightText";
import { SortedContact } from "@/hooks/data/useLiveContactList";
import { useIsFocusIn } from "@/hooks/useIsFocusIn";
import { selectIsSearchOptionOpen } from "@/integrations/app/selectors";
import { selectMultiSelectedContacts } from "@/integrations/contact/selectors";
import contactSlice from "@/integrations/contact/slice";
import { selectMultiSelectedContactGroups } from "@/integrations/contactGroup/selectors";
import contactGroupSlice from "@/integrations/contactGroup/slice";
import { useAppDispatch } from "@/integrations/redux/store";

export function useGroupedListControls<T extends { id: string }>({
  items,
  groupCounts,
  selectedItem,
  onKeySelected,
  onMultiSelect,
  onClearMultiSelected,
  multiSelectedItems,
  enableDirKeys,
}: {
  items: T[];
  groupCounts?: number[];
  selectedItem: T | null | undefined;
  onKeySelected?: (item: T) => void;
  onMultiSelect?: (items: T[]) => void;
  multiSelectedItems?: T[];
  onClearMultiSelected?: () => void;
  enableDirKeys?: boolean;
}) {
  const initLoad = useRef<boolean>(true);

  const defaultSelectedItemIndex = useMemo(() => {
    for (const [i, item] of items.entries()) {
      if (item.id === selectedItem?.id) return i;
    }

    return 0;
  }, [items, selectedItem?.id]);

  const [currentItemIndex, setCurrentItemIndex] = useState<number>(defaultSelectedItemIndex);

  const ref = useRef<VirtuosoHandle>(null);

  const wrapperRef = useRef<HTMLElement>(null);

  const { isBodyFocused, isRefFocused } = useIsFocusIn(wrapperRef);

  const onClickToSelectItem = useCallback(
    (item: T) => {
      if (!onMultiSelect) return;

      let results: T[] = multiSelectedItems || [];
      if (!multiSelectedItems || multiSelectedItems.length === 0) {
        results = [item].filter(Boolean) as T[];
      } else if (results.some(({ id }) => id === item.id)) {
        results = results.filter((o) => o.id !== item.id);
      } else {
        results = [...results, item];
      }
      results = uniqBy(results, "id");

      onMultiSelect(results);
    },
    [multiSelectedItems, onMultiSelect],
  );

  const onClickItem = useCallback(
    (event: MouseEvent<HTMLElement> | KeyboardEvent | Event, item: T, itemIndex?: number) => {
      if ((event as KeyboardEvent).metaKey) {
        window?.getSelection()?.removeAllRanges();
        event.preventDefault();
        event.stopPropagation();

        onClickToSelectItem(item);
      } else if ((event as KeyboardEvent).shiftKey && typeof itemIndex !== "undefined") {
        window?.getSelection()?.removeAllRanges();
        event.preventDefault();
        event.stopPropagation();
        // multi select range
        if (!onMultiSelect) return;

        // Stop event propagation (so that we don't route to contact details page)
        let results: T[] = [];
        // select item index to the current item index
        const startIndex = Math.min(currentItemIndex, itemIndex);
        const endIndex = Math.max(currentItemIndex, itemIndex);

        // select all between start and end index
        for (let i = startIndex; i <= endIndex; i++) {
          results.push(items[i]);
        }

        results = uniqBy(results, "id");
        onMultiSelect(results);
      } else {
        // Reset multi-selection and propagate event
        if (onClearMultiSelected) onClearMultiSelected();
        if (onKeySelected) onKeySelected(item);
        if (typeof itemIndex !== "undefined") {
          setCurrentItemIndex(itemIndex);
        }
      }
    },
    [
      currentItemIndex,
      items,
      onClearMultiSelected,
      onClickToSelectItem,
      onKeySelected,
      onMultiSelect,
    ],
  );

  const onSelectAlphabetKey = useCallback(
    (_alphabet: string, index: number) => {
      if (ref.current && groupCounts) {
        const contactIndex = [...groupCounts].splice(0, index).reduce((prev, cur) => {
          return prev + cur;
        }, 0);
        ref.current.scrollToIndex({
          index: contactIndex,
          align: "start",
          behavior: "auto",
        });
      }
    },
    [groupCounts, ref],
  );

  const handleOnKeySelected = useDebouncedCallback(
    (nextIndex: number) => {
      const item = items[nextIndex];
      if (item && onKeySelected) {
        onKeySelected(item);
      }
    },
    75,
    { leading: false, trailing: true, maxWait: 200 },
  );

  const setCurItemIndex = useCallback(
    (nextIndex: number) => {
      setCurrentItemIndex(nextIndex);
      handleOnKeySelected(nextIndex);
    },
    [handleOnKeySelected],
  );

  // Hot up key navigation
  const onUpNavigation: HotkeyCallback = useCallback(
    (e) => {
      if (currentItemIndex === 0) return; // already at the top, do nothing.

      const prevContactIndex = Math.max(0, currentItemIndex - 1);

      if (typeof prevContactIndex !== "undefined") {
        ref.current?.scrollIntoView({
          index: prevContactIndex,
          behavior: "auto",
          done: () => {
            setCurItemIndex(Number(prevContactIndex));
          },
        });
      }
    },
    [currentItemIndex, setCurItemIndex],
  );

  // Hot down key navigation
  const onDownNavigation: HotkeyCallback = useCallback(
    (e) => {
      const lastContactIndex = items.length - 1;
      if (currentItemIndex === lastContactIndex) return; // already at the bottom, do nothing.

      const nextIndex = Math.min(items.length - 1, currentItemIndex + 1);

      if (typeof nextIndex !== "undefined") {
        ref.current?.scrollIntoView({
          index: nextIndex,
          behavior: "auto",
          done: () => {
            setCurItemIndex(Number(nextIndex));
          },
        });
      }
    },
    [currentItemIndex, items.length, setCurItemIndex],
  );

  const isSearchOptionOpen = useSelector(selectIsSearchOptionOpen);
  const shouldHotkeysBeEnabled = useMemo(
    () =>
      enableDirKeys ||
      ((isBodyFocused || isRefFocused || document.activeElement?.id === "search") &&
        !isSearchOptionOpen),
    [enableDirKeys, isBodyFocused, isRefFocused, isSearchOptionOpen],
  );

  useHotkeys("up", onUpNavigation, {
    enableOnFormTags: true,
    enabled: shouldHotkeysBeEnabled,
    enableOnContentEditable: true,
  });

  useHotkeys("down", onDownNavigation, {
    enableOnFormTags: true,
    enabled: shouldHotkeysBeEnabled,
    enableOnContentEditable: true,
  });

  useEffect(() => {
    if (initLoad.current && selectedItem?.id) {
      initLoad.current = false;
      const index = items.findIndex(({ id }) => selectedItem.id === id);
      setCurItemIndex(index || -1);
      ref.current?.scrollIntoView({
        index,
        behavior: "auto",
      });
    }
  }, [items, selectedItem?.id, setCurItemIndex]);

  return {
    onClickItem,
    onClickToSelectItem,
    ref,
    wrapperRef,
    currentItemIndex,
    setCurrentItemIndex,
    onUpNavigation,
    onDownNavigation,
    onSelectAlphabetKey,
  };
}

export function useContactGroupListControls({
  contactGroups,
  selectedContactGroup,
  onKeySelectContactGroupItem,
}: {
  contactGroups: ContactGroupRowForDisplay[];
  selectedContactGroup?: ContactGroupRowForDisplay | null;
  onKeySelectContactGroupItem?: (contactGroup: ContactGroupRowForDisplay) => void;
}) {
  const dispatch = useAppDispatch();
  const multiSelectedContactGroups = useSelector(selectMultiSelectedContactGroups);

  const onSetMultiSelectedContactGroups = useCallback(
    (contactGroups: ContactGroupRowForDisplay[]) => {
      dispatch(contactGroupSlice.actions.setMultiSelectedContactGroups(contactGroups));
    },
    [],
  );

  const onClearMultiSelectedContactGroups = useCallback(() => {
    dispatch(contactGroupSlice.actions.clearMultiSelectedContactGroups());
  }, []);

  const { flattenedSortedContactGroups, userGroups, smartGroups, groupCounts } = useMemo(() => {
    const userGroups: ContactGroupRowForDisplay[] = [];
    const smartGroups: ContactGroupRowForDisplay[] = [];
    const comboGroups: ContactGroupRowForDisplay[] = [];

    for (const group of contactGroups || []) {
      if (isSmartGroup(group)) {
        smartGroups.push(group);
      } else if (isComboGroup(group) || isIntersectGroup(group)) {
        comboGroups.push(group);
      } else userGroups.push(group);
    }

    return {
      groupCounts: [smartGroups.length, comboGroups.length, userGroups.length],
      userGroups,
      smartGroups,
      comboGroups,
      flattenedSortedContactGroups: [...smartGroups, ...comboGroups, ...userGroups],
    };
  }, [contactGroups]);

  const groupListControls = useGroupedListControls({
    items: flattenedSortedContactGroups,
    groupCounts,
    selectedItem: selectedContactGroup,
    onKeySelected: onKeySelectContactGroupItem,
    onMultiSelect: onSetMultiSelectedContactGroups,
    onClearMultiSelected: onClearMultiSelectedContactGroups,
    multiSelectedItems: multiSelectedContactGroups,
  });

  return {
    ...groupListControls,
    groupCounts,
    flattenedSortedContactGroups,
    labels: ["Smart Groups", "Combo Groups", "Static Groups"],
    multiSelectedContactGroups,
  };
}

export function useContactListControls(
  props: Pick<
    ContactListProps,
    "contacts" | "groupCounts" | "selectedContact" | "onKeySelectContactItem"
  >,
) {
  const { contacts, groupCounts, selectedContact, onKeySelectContactItem } = props;

  const dispatch = useAppDispatch();
  const multiSelectedContacts = useSelector(selectMultiSelectedContacts);

  const onSetMultiSelectedContacts = useCallback(
    (contacts: (Contact | ContactRow | SortedContact)[]) => {
      dispatch(contactSlice.actions.setMultiSelectedContacts({ contacts }));
    },
    [],
  );

  const onClearMultiSelections = useCallback(() => {
    dispatch(contactSlice.actions.clearMultiSelectedContacts());
  }, []);

  const controls = useGroupedListControls({
    items: contacts,
    groupCounts,
    selectedItem: selectedContact,
    onMultiSelect: onSetMultiSelectedContacts,
    onClearMultiSelected: onClearMultiSelections,
    multiSelectedItems: multiSelectedContacts,
    onKeySelected: onKeySelectContactItem,
  });

  return {
    ...controls,
    multiSelectedContacts,
  };
}

export function useContactListItemBody({
  contact,
  sortKey,
}: {
  contact: ContactRow | SortedContact | Contact;
  sortKey: SortableKeys;
}) {
  const showCompanyNameOnly =
    !contact.givenName?.trim() && !contact.surname?.trim() && !!contact.companyName?.trim();

  const contactDescription = useMemo(() => {
    if (
      sortKey === "givenName" ||
      sortKey === "surname" ||
      sortKey === "companyName" ||
      sortKey === "_surnameSort" ||
      sortKey === "_givenNameSort"
    )
      return (
        <>
          <p className="text-sm truncate text-label">
            <HighlightText value={contact.jobTitle} />
            {contact.jobTitle && contact.departmentName && ` - `}
            {contact.departmentName && <HighlightText value={contact.departmentName} />}
          </p>
          {!showCompanyNameOnly && (
            <p className="text-sm truncate text-label">
              <HighlightText value={contact.companyName} />
            </p>
          )}
        </>
      );

    if (sortKey === "nickname")
      return (
        <p className="text-sm truncate text-label">
          <HighlightText value={contact.nickname} />
        </p>
      );

    if (sortKey === "email")
      return (
        <p className="text-sm truncate text-label">
          <HighlightText value={contact.emails?.[0]?.value} />
        </p>
      );
  }, [
    contact.companyName,
    contact.departmentName,
    contact.emails,
    contact.jobTitle,
    contact.nickname,
    showCompanyNameOnly,
    sortKey,
  ]);

  return {
    showCompanyNameOnly,
    contactDescription,
  };
}
