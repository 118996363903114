import { emptyFalseyStrings, removeUndefinedFromObj } from "./object";
import { NoUndefinedField } from "./types";

export function removeUndefinedFromList<T>(list: T) {
  for (const i in list) {
    if (typeof list[i] === "undefined") {
      // @ts-ignore
      list.splice(Number(i), 1);
    } else if (Array.isArray(list[i])) {
      removeUndefinedFromList(list[i]);
    } else if (typeof list[i] === "object") {
      removeUndefinedFromObj(list[i]);
    }
  }
  return list as unknown as NoUndefinedField<T>;
}

export function getRandomNodeFromArray<T>(list: T[]) {
  return list[Math.floor(Math.random() * list.length)];
}

export function getUniqueListBy<T>(list: T[], key: keyof T) {
  const preservedItemsWithoutKey = list.filter((i) => typeof i[key] === "undefined");
  // @ts-ignore
  return [...new Map(list.map((item) => [item[key], item])).values(), ...preservedItemsWithoutKey];
}

export function getUniqueList<T>(list: T[]) {
  return [...new Set(list)];
}

export function uniqueOnlyPredicate<T>(value: T, index: number, list: T[]) {
  return list.indexOf(value) === index;
}

export function chunkList<T>(list: T[], size: number): T[][] {
  return list.reduce((acc: T[][], _: T, i: number) => {
    if (i % size === 0) acc.push(list.slice(i, i + size));
    return acc;
  }, []);
}

export function sortPortionByList(fullList: string[], list: string[]) {
  const index: { [key: string]: number } = {};
  for (let i = 0; i < fullList.length; i++) {
    index[fullList[i]] = i;
  }
  return [...list].sort((a, b) => index[a] - index[b]);
}

export function emptyFalseyStringsOfList(listOfObjs: any[]) {
  let resultList: any[] = [];
  for (const obj of listOfObjs) {
    resultList.push(emptyFalseyStrings(obj));
  }
  return resultList;
}

export function chunkEmailListByStringLength(list: string[], size: number) {
  const result = [];
  let curAcc = "";
  let curIndex = 0;
  // @ts-ignore
  for (const [index, value] of [...list].sort((a, b) => a.length - b.length).entries()) {
    if (curAcc.length + value.length > size) {
      result[curIndex] = curAcc;
      curAcc = value;
      curIndex++;
    } else {
      curAcc += curAcc ? `,${value}` : value;
    }

    if (index === list.length - 1) {
      result[curIndex] = curAcc;
    }
  }

  return result;
}
