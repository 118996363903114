import { Email, IsDefault } from "@shared/models/types";
import { FC, memo, useMemo } from "react";

import { useCompanyDetail } from "@/components/contacts/details/hooks";
import ContextualMenuField, {
  ContextualMenuFieldValue,
  ContextualMenuItem,
} from "@/components/contacts/details/shared/ContextualMenuField";
import { ContactDataFieldProps } from "@/components/contacts/details/types";
import {
  BookArrowRight,
  CopySolidIcon,
  PaperPlaneSolidIcon,
  PenSolidIcon,
  StarIcon,
  StarSolidIcon,
} from "@/components/core/Icon";
import { showToast } from "@/components/core/Toast";
import HighlightText from "@/components/HighlightText";
import { openLink } from "@/helpers/window";
import { useLiveEmailDomainCompany } from "@/hooks/data/useLiveCompany";

const EmailDisplayField: FC<{
  email: Email;
  originalIndex: number;
  onMarkEmailDefaultAtIndex?: (isDefault: boolean, index: number) => void;
  onFocusField?: (field: ContextualMenuFieldValue<any>) => void;
  contextualMenuBlacklist?: ContactDataFieldProps["contextualMenuBlacklist"];
}> = ({
  email,
  originalIndex,
  onMarkEmailDefaultAtIndex,
  onFocusField,
  contextualMenuBlacklist,
}) => {
  const emailContextualItems = useMemo<ContextualMenuItem<Email>[]>(() => {
    const menuItems: (ContextualMenuItem<Email> | null)[] = [
      onMarkEmailDefaultAtIndex
        ? {
            icon: (payload) => (payload.value.isDefault ? StarSolidIcon : StarIcon),
            tooltip: (payload) =>
              payload.value.isDefault ? "Unmark as Default" : "Mark as Default",
            action: (payload) => {
              if (payload.index !== undefined) {
                onMarkEmailDefaultAtIndex(!payload.value.isDefault, payload.index);
              }
            },
            isPinned: (payload) => payload.value.isDefault === IsDefault.YES,
          }
        : null,
      {
        icon: () => PaperPlaneSolidIcon,
        tooltip: () => "Send email",
        action: (payload) => {
          openLink(`mailto:${payload.value.value}`);
        },
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy email to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(payload.value.value);
            showToast({ title: "Email copied to clipboard!" });
          }
        },
      },
      (!contextualMenuBlacklist?.email || !contextualMenuBlacklist?.email?.edit) && onFocusField
        ? {
            icon: () => PenSolidIcon,
            tooltip: () => "Edit email",
            action: (payload) => {
              onFocusField(payload);
            },
          }
        : null,
    ];

    return menuItems.filter(Boolean) as ContextualMenuItem<Email>[];
  }, [contextualMenuBlacklist, onFocusField, onMarkEmailDefaultAtIndex]);

  const domainToCompany = useLiveEmailDomainCompany(email.value);
  const {
    setCompanyDataQuery,
    setIsCompanyDetailOpen,
    isContactCompanyDetailOpen,
    companyDataQuery,
  } = useCompanyDetail();

  const additionalContextualItems = useMemo<ContextualMenuItem<Email> | undefined>(() => {
    if (
      domainToCompany?.company &&
      (companyDataQuery?.email !== email.value || !isContactCompanyDetailOpen)
    ) {
      const menuItem: ContextualMenuItem<Email> = {
        icon: () => BookArrowRight,
        tooltip: () => "Show company data",
        action: (payload) => {
          if (payload.value) {
            setIsCompanyDetailOpen(true);
            setCompanyDataQuery({ email: payload.value.value });
          }
        },
      };

      return menuItem;
    }
  }, [
    companyDataQuery?.email,
    domainToCompany?.company,
    email.value,
    isContactCompanyDetailOpen,
    setCompanyDataQuery,
    setIsCompanyDetailOpen,
  ]);

  return (
    <dd>
      <ContextualMenuField
        items={
          [...emailContextualItems, additionalContextualItems].filter(
            Boolean
          ) as ContextualMenuItem<Email>[]
        }
        actionPayload={{
          fieldName: "emails",
          value: email,
          index: originalIndex,
        }}
      >
        <a
          href={`mailto:${email.value}`}
          target="_blank"
          rel="noreferrer"
          className="text-primary text-underline whitespace-pre"
        >
          <HighlightText value={email.value} />
        </a>
        <div className="uppercase text-label">{email.type}</div>
      </ContextualMenuField>
    </dd>
  );
};

export default memo(EmailDisplayField);
