import classNames from "clsx";
import type { FC } from "react";
import type { RemoteApiForDisplay } from "services/src/shared/models/RemoteApi";
import { IsRemoteApiActive } from "services/src/shared/models/types";

import { AppleIcon, GoogleIcon } from "@/components/core/Icon";
import { PeopleDataLabsLogo } from "@/components/core/Logo";

export type VendorIconProps = {
  integration?: RemoteApiForDisplay;
  vendor?: RemoteApiForDisplay["vendor"] | string;
  className?: string;
};

export const Monochrome: FC<VendorIconProps> = ({ integration, vendor, className }) => {
  const v = vendor || integration?.vendor;
  const cx = integration
    ? classNames(
        {
          "text-purple-700": integration.isActive === IsRemoteApiActive.YES,
          "text-gray-400": integration.isActive === IsRemoteApiActive.NO,
        },
        className
      )
    : undefined;

  if (v === "google") {
    return <GoogleIcon className={cx} size="xl" />;
  }
  if (v === "icloud") {
    return <AppleIcon className={cx} size="xl" />;
  }
  if (v === "peopleDataLabs") {
    return (
      <PeopleDataLabsLogo
        className={cx}
        monochrome={integration?.isActive === IsRemoteApiActive.NO}
        bold
      />
    );
  }
  return null;
};

export const Color: FC<VendorIconProps> = ({ className, vendor, integration }) => {
  const v = vendor || integration?.vendor;

  if (v === "google") return <img className={className} src="/logos/google-logo-icon.svg" alt="" />;
  if (v === "icloud") return <img className={className} src="/logos/icloud-logo-icon.svg" alt="" />;
  if (v === "zapier") return <img className={className} src="/logos/zapier-logo-icon.svg" alt="" />;
  return null;
};
