import type { FC } from "react";
import { useCallback, useState } from "react";
import type { RemoteApiSnapshot } from "services/src/shared/models/RemoteApiSnapshot";

import { getHumanReadableVendorName } from "@/helpers/integration";
import {
  useGetIntegrationsQuery,
  useGetSnapshotsQuery,
  useRestoreSnapshotMutation,
} from "@/integrations/user/api";

import Header from "../../core/Header";
import { showToast, ToastLevel } from "../../core/Toast";
import LoadingSpinner from "../../loading/LoadingSpinner";
import RestoreSnapshotConfirmationAlert from "./RestoreSnapshotConfirmationAlert";
import SnapshotRow from "./SnapshotRow";

const RestoreSection: FC = () => {
  const { data: integrations } = useGetIntegrationsQuery();
  const defaultIntegrationId = integrations?.[0]?.id || "";

  const { data: snapshots } = useGetSnapshotsQuery(
    { integrationId: defaultIntegrationId, limit: 20 },
    {
      skip: !defaultIntegrationId,
    }
  );
  const isLoadingSnapshots = integrations === undefined || snapshots === undefined;

  const [isRestoreAlertOpen, setIsRestoreAlertOpen] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState<RemoteApiSnapshot | null>(null);
  const [restoreSnapshot, { isLoading: isRestoringSnapshot }] = useRestoreSnapshotMutation();

  const onRestoreSnapshot = useCallback(async () => {
    setIsRestoreAlertOpen(false);
    if (selectedSnapshot) {
      const vendorName = getHumanReadableVendorName(selectedSnapshot.vendor);
      try {
        await restoreSnapshot({
          integrationId: selectedSnapshot.remoteApiId,
          createdAt: selectedSnapshot.createdAt,
        });
        showToast({
          title: `Successfully restored ${vendorName} contact data!`,
          level: ToastLevel.Success,
        });
      } catch (error) {
        console.error("Encountered error restoring contact data. Error: ", error);
        showToast({
          title: `Failed to restore ${vendorName} contact data. Please contact support@titledock.com.`,
          level: ToastLevel.Warning,
        });
      }
    }
  }, [selectedSnapshot]);

  return (
    <>
      <div className="container mx-auto my-8">
        {/* Header */}
        <Header
          title="Restore Your Contacts"
          details={`Restore your contacts on iCloud to any state from past backups. Note: This process may take up to 5 minutes.`}
          className="p-container"
        />

        {/* Divider */}
        <div className="divider" />

        {/* Snapshots table */}
        {isLoadingSnapshots || isRestoringSnapshot ? (
          <LoadingSpinner
            loadingText={isLoadingSnapshots ? "Loading backups…" : "Restoring contact data…"}
            className="h-48"
          />
        ) : (
          <ul role="list" className="divide-y divide-zinc-200 dark:divide-zinc-600">
            {snapshots?.map((snapshot, index) => (
              <SnapshotRow
                key={index}
                snapshot={snapshot}
                onRestoreSnapshot={() => {
                  setSelectedSnapshot(snapshot);
                  setIsRestoreAlertOpen(true);
                }}
              />
            ))}
          </ul>
        )}
      </div>
      <RestoreSnapshotConfirmationAlert
        snapshot={selectedSnapshot}
        isRestoreAlertOpen={isRestoreAlertOpen}
        setIsRestoreAlertOpen={setIsRestoreAlertOpen}
        onClickRestore={onRestoreSnapshot}
        onClickCancel={() => setIsRestoreAlertOpen(false)}
      />
    </>
  );
};

export default RestoreSection;
