import type { Email } from "@shared/models/types";
import { IsDefault } from "@shared/models/types";
import classNames from "clsx";
import type { ChangeEvent, FC } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";

import EmailDisplayField from "@/components/contacts/details/fields/components/EmailDisplayField";
import { isEmpty } from "@/helpers/array";

import { EnvelopeIcon } from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import { generateTypeSelectItems } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import MarkDefaultItemButton from "../shared/MarkDefaultItemButton";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeSelect from "../shared/TypeSelect";
import type { ContactDataFieldProps } from "../types";

const EmailsField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
  focusedField,
  onFocusField,
  contextualMenuBlacklist,
}) => {
  const emails = useMemo(() => contactData?.emails || [], [contactData?.emails]);
  const emailsWithIndices: { email: Email; originalIndex: number }[] = useMemo(
    () =>
      [...emails]
        .map((email, index) => ({ email, originalIndex: index }))
        .sort((a, b) => (b.email.isDefault || 0) - (a.email.isDefault || 0)),
    [emails]
  );

  const lastEmailRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedEmailRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastEmailInput = useCallback(() => {
    lastEmailRowInputRef.current?.focus();
  }, [lastEmailRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "emails") {
      focusedEmailRowInputRef.current?.focus();
      focusedEmailRowInputRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [focusedField]);

  // Resolve email types from the default list + types that are in-use
  const emailTypeSelectItems = generateTypeSelectItems(
    "emails",
    emails.map((email) => email.type)
  );

  const updateEmails = (emails: Email[]) => {
    if (dispatch) dispatch({ type: "emails", payload: emails });
  };

  const onAddNewEmail = (type?: Email["type"]) => {
    const emailsToUpdate: Email[] = [
      ...emails,
      { value: "", type: type || emailTypeSelectItems[0]?.value },
    ];
    updateEmails(emailsToUpdate);

    // After a short delay, focus on the last email input (that was just added)
    setTimeout(() => {
      focusOnLastEmailInput();
    }, 100);
  };

  const onUpdateEmail = ({ index, data }: { index: number; data: Partial<Email> }) => {
    const emailsToUpdate: Email[] = [...emails];
    emailsToUpdate[index] = {
      ...emailsToUpdate[index],
      ...data,
    };
    updateEmails(emailsToUpdate);
  };

  const onRemoveEmailAtIndex = (index: number) => {
    const emailsToUpdate: Email[] = [...emails];
    emailsToUpdate.splice(index, 1);
    updateEmails(emailsToUpdate);
  };

  const onMarkEmailDefaultAtIndex = useCallback(
    (isDefault: boolean, index: number) => {
      if (!directlyUpdateContactData) return;
      // If marking an email default, first reset isDefault state for every object in the array
      const emailsToUpdate: Email[] = isDefault
        ? emails.map((o) => ({
            ...o,
            isDefault: IsDefault.NO,
          }))
        : [...emails];
      emailsToUpdate[index] = {
        ...emailsToUpdate[index],
        isDefault: isDefault ? IsDefault.YES : IsDefault.NO,
      };
      if (!isEditing) {
        directlyUpdateContactData({ emails: emailsToUpdate });
      }
      updateEmails(emailsToUpdate);
    },
    [directlyUpdateContactData, emails, isEditing, updateEmails]
  );

  // Do not show for read-only mode if there are no emails
  if (!isEditing && isEmpty(emails)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Emails"
      isEditing={isEditing}
      icon={<EnvelopeIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {emailsWithIndices.map(({ email, originalIndex }, index) => {
            const isLastEmailInput = originalIndex === emails.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "emails" && focusedField?.index === originalIndex;
            const isDefault = email.isDefault === IsDefault.YES;
            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <TypeSelect
                  items={emailTypeSelectItems}
                  initialItemId={email.type}
                  onSelectItem={(item) =>
                    onUpdateEmail({ index: originalIndex, data: { type: item.value } })
                  }
                  createNewItemTitle="Custom"
                />
                <TextInput
                  name="email"
                  type="email"
                  value={email.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onUpdateEmail({ index: originalIndex, data: { value: event.target.value } })
                  }
                  forwardedRef={
                    (isFocusedField && focusedEmailRowInputRef) ||
                    (isLastEmailInput && lastEmailRowInputRef) ||
                    undefined
                  }
                  className="flex-1 mr-2"
                />
                <MarkDefaultItemButton
                  isDefault={isDefault}
                  onClickButton={() => onMarkEmailDefaultAtIndex(!isDefault, originalIndex)}
                  tooltip={isDefault ? "Unmark as Default" : "Mark as Default"}
                  className={classNames(
                    "group-hover:visible",
                    isDefault ? "visible opacity-90" : "invisible opacity-70"
                  )}
                />
                <RemoveDetailsItemButton
                  onClickButton={() => onRemoveEmailAtIndex(originalIndex)}
                  tooltip="Remove Email"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-email" className="flex flex-row w-full">
            <TypeSelect
              items={emailTypeSelectItems}
              onSelectItem={(item) => onAddNewEmail(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewEmail()}
            >
              Add an email
            </button>
          </dd>
        </>
      ) : (
        emailsWithIndices.map(({ email, originalIndex }) => (
          <EmailDisplayField
            key={originalIndex}
            email={email}
            originalIndex={originalIndex}
            onFocusField={onFocusField}
            onMarkEmailDefaultAtIndex={
              directlyUpdateContactData ? onMarkEmailDefaultAtIndex : undefined
            }
            contextualMenuBlacklist={contextualMenuBlacklist}
          />
        ))
      )}
    </BaseDetailsField>
  );
};

export default EmailsField;
