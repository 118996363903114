import { DdbBoolean } from "@shared/models/types";
import { FC, useCallback, useMemo } from "react";

import Button, { ButtonVariant } from "@/components/core/Button";
import {
  MaximizeIcon,
  WindowMaximizeIcon,
  WindowMinimizeIcon,
  XmarkIcon,
} from "@/components/core/Icon";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

export type EmailDrawerWindowControlProps = {
  draft: LocallyPersistedEmailDraft;
  onChangeWindowState: (draftId: string, state: Partial<LocallyPersistedEmailDraft>) => void;
};

const EmailDrawerWindowControl: FC<EmailDrawerWindowControlProps> = ({
  draft,
  onChangeWindowState,
}) => {
  const onClose = useCallback(async () => {
    onChangeWindowState(draft.id, {
      _isOpenedAt: DdbBoolean.NO,
      _isMinimized: DdbBoolean.NO,
      _isFullScreened: DdbBoolean.NO,
    });
  }, [draft.id, onChangeWindowState]);

  const onMinimize = useCallback(async () => {
    onChangeWindowState(draft.id, {
      _isMinimized: DdbBoolean.YES,
      _isFullScreened: DdbBoolean.NO,
    });
  }, [draft.id, onChangeWindowState]);

  const onMaximize = useCallback(async () => {
    onChangeWindowState(draft.id, {
      _isMinimized: DdbBoolean.NO,
      _isFullScreened: DdbBoolean.NO,
    });
  }, [draft.id, onChangeWindowState]);

  const onFullScreen = useCallback(async () => {
    onChangeWindowState(draft.id, {
      _isMinimized: DdbBoolean.NO,
      _isFullScreened: DdbBoolean.YES,
    });
  }, [draft.id, onChangeWindowState]);

  const isMinimized = useMemo(() => draft._isMinimized === DdbBoolean.YES, [draft._isMinimized]);

  const isFullScreened = useMemo(
    () => draft._isFullScreened === DdbBoolean.YES,
    [draft._isFullScreened],
  );

  return (
    <div className="flex h-8 space-x-2 items-center">
      <Button
        variant={ButtonVariant.None}
        icon={isMinimized ? <WindowMaximizeIcon /> : <WindowMinimizeIcon />}
        iconOnly
        rounded={false}
        noGutter
        className="text-secondary px-0 sm:px-0"
        onClick={isMinimized ? onMaximize : onMinimize}
      />
      <Button
        variant={ButtonVariant.None}
        icon={isFullScreened ? <WindowMaximizeIcon /> : <MaximizeIcon />}
        iconOnly
        rounded={false}
        noGutter
        className="text-secondary px-0 sm:px-0"
        onClick={isFullScreened ? onMaximize : onFullScreen}
      />
      <Button
        variant={ButtonVariant.None}
        icon={<XmarkIcon />}
        iconOnly
        rounded={false}
        noGutter
        className="text-secondary px-0 sm:px-0"
        onClick={onClose}
      />
    </div>
  );
};

export default EmailDrawerWindowControl;
