import { FC } from "react";

import Button, { ButtonVariant } from "@/components/core/Button";
import DetailPanelNavLayout from "@/components/DetailPanelNavLayout";

const AccountAddTopNav: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  return (
    <DetailPanelNavLayout
      Left={
        <Button variant={ButtonVariant.Secondary} hotkey={["escape"]} onClick={onCancel}>
          Cancel
        </Button>
      }
    />
  );
};

export default AccountAddTopNav;
