import { createApi } from "@reduxjs/toolkit/query/react";
import { MsaJson } from "services/src/shared/helpers/location";

import { getAuthBaseQuery } from "@/integrations/redux/baseQuery";

export const geoApi = createApi({
  reducerPath: "titledockGeoCdn",
  baseQuery: getAuthBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL,
  }),
  endpoints: (builder) => ({
    getMsaIndex: builder.query<MsaJson, string | void>({
      query: (country = "usa") => `/geojson/msa/${country}/index.json`,
    }),
    getMsaGeoJson: builder.query<
      {
        [stateKey: string]: {
          [name: string]: number;
        };
      },
      {
        country?: "usa";
        id: number;
      }
    >({
      query: ({ country = "usa", id }) => `/geojson/msa/${country}/${id}.geojson`,
    }),
  }),
});

export const { useLazyGetMsaGeoJsonQuery, useGetMsaIndexQuery } = geoApi;
