import { getValueByPointer, Operation } from "fast-json-patch";
import { ContactRow } from "services/src/shared/models/Contact";
import { ContactVersion } from "services/src/shared/models/ContactVersion";

import { CONTACT_FIELD_SORT_VALUE } from "./contact";

export type OperationGroup = {
  fieldName: keyof ContactRow;
  basePath: string;
  operations: (Operation & { value?: any })[];
};

export type ContactVersionOperationGroups = {
  version: ContactVersion;
  groups: OperationGroup[];
};

// Paths that map to arrays in contact model
export const ARRAY_OPERATION_PATHS: (keyof ContactRow)[] = [
  "emails",
  "phoneNumbers",
  "physicalAddresses",
  "imHandles",
  "webPages",
];

// Valid paths when processing operations (ignoring paths like "/id", "/updatedAt", etc.)
export const VALID_OPERATION_PATHS: string[] = [
  "givenName",
  "middleName",
  "surname",
  "suffix",
  "prefix",
  "nickname",
  "birthday",
  "companyName",
  "departmentName",
  "jobTitle",
  "managerName",
  "notes",
  "pictureUrl",
  "emails",
  "imHandles",
  "physicalAddresses",
  "phoneNumbers",
  "webPages",
];

export function isValidOperation(path: string): boolean {
  const pathComponents = path.split("/");
  const basePath = pathComponents[1];
  if (!basePath) {
    return false;
  }

  return VALID_OPERATION_PATHS.includes(basePath);
}

// User-friendly present and past tense verb for each json diff operation
export const OPERATION_ACTION_VERB_MAPPINGS: {
  [key: string]: { present: string; past: string } | undefined;
} = {
  add: {
    present: "Add",
    past: "Added",
  },
  remove: {
    present: "Remove",
    past: "Removed",
  },
  replace: {
    present: "Change",
    past: "Changed",
  },
  move: {
    present: "Change",
    past: "Changed",
  },
  copy: {
    present: "Add",
    past: "Added",
  },
};

/**
 * Given a list of json patch operations, inverse the operations using the following strategy and
 * return the resulting array of the shapes [test, operation] or [operation]
 *
 * Inversion strategy:
 * 1. add: [add new_value] => revert: [test new_value, remove new_value]
 * 2. move: [move old_path to new_path] => revert: [move new_path to old_path]
 * 3. copy: [copy value at from_path to new_path] => revert: [test value at new_path, delete value at new_path]
 * 4. replace: [test previous_value, replace with new_value] => revert: [test new_value, replace with previous_value]
 * 5. remove: [test old_value, remove old_value] => revert: [add previous_value]
 *
 * Inspired by:
 * https://github.com/Starcounter-Jack/JSON-Patch/issues/242
 * https://github.com/benjamine/JsonDiffPatch/issues/1
 *
 * @param operations Operations to inverse
 * @returns Array of inversed operation pairs
 */
export function inverse(operations: Operation[], prevContact?: ContactRow): Operation[][] {
  const result: Operation[][] = [];
  for (let i = 0, len = operations.length; i < len; i += 1) {
    const currentOperation = operations[i];

    // Skip any operations whose paths are not defined in VALID_OPERATION_PATHS array
    if (!isValidOperation(currentOperation.path)) continue;

    if (currentOperation.op === "add") {
      // 1. Revert add operation
      result.push([
        { op: "test", path: currentOperation.path, value: currentOperation.value },
        { op: "remove", path: currentOperation.path },
      ]);
    } else if (currentOperation.op === "move") {
      // 2. Revert move operation
      result.push([{ op: "move", from: currentOperation.path, path: currentOperation.from }]);
    } else if (currentOperation.op === "copy") {
      // 3. Revert copy operation
      const copiedValue = getValueByPointer(prevContact, currentOperation.from);
      if (copiedValue) {
        result.push([
          { op: "test", path: currentOperation.path, value: copiedValue },
          { op: "remove", path: currentOperation.path },
        ]);
      }
    } else if (currentOperation.op === "test") {
      const nextOperation = operations[i + 1];
      if (!nextOperation) break;

      // Test operations should be followed by replace or remove operations
      if (nextOperation.op === "replace") {
        // 4. Revert replace operation
        result.push([
          { op: "test", path: nextOperation.path, value: nextOperation.value },
          { op: "replace", path: currentOperation.path, value: currentOperation.value },
        ]);
      } else if (nextOperation.op === "remove") {
        // 5. Revert remove operation (Fetch removed value from prevContact object)
        const removedValue = getValueByPointer(prevContact, nextOperation.path);
        if (removedValue) {
          result.push([{ op: "add", path: nextOperation.path, value: removedValue }]);
        }
      } else {
        console.warn(
          "Unexpected operation following test operation: ",
          JSON.stringify(nextOperation)
        );
      }

      // Increment cursor as we've processed next operation at this point
      i += 1;
    }
  }

  console.log("PREVIOUS OPERATIONS");
  console.log(JSON.stringify(operations));
  console.log("NEXT OPERATIONS");
  console.log(JSON.stringify(result.flat()));

  // Important: reverse order of operations so that array operations are applied LIFO fashion
  return result.reverse();
}

/**
 * Given an array of operations, create OperationGroup array by merging operations done on same
 * paths. Also sort by order of contact field importance.
 * @param operations Operations to group
 * @returns Array of operation groups
 */
export function createOperationGroups(operations: Operation[]): OperationGroup[] {
  return operations
    .filter((diff) => isValidOperation(diff.path) && diff.op !== "test")
    .reduce((group: OperationGroup[], operation: Operation) => {
      const pathComponents = operation.path.split("/");

      // If operation path maps to a property that is part of an array (e.g. emails),
      // include the array index as well.
      let basePath = pathComponents[1];
      if (
        pathComponents.length >= 3 &&
        ARRAY_OPERATION_PATHS.includes(basePath as keyof ContactRow)
      ) {
        basePath = `${basePath}/${pathComponents[2]}`;
      }

      // Insert or create a new group with operationPath + operations.
      const existingGroup = group.find((o) => o.basePath === basePath);
      if (existingGroup) {
        existingGroup.operations.push(operation);
      } else {
        group.push({
          fieldName: pathComponents[1] as keyof ContactRow,
          basePath,
          operations: [operation],
        });
      }
      return group;
    }, [])
    .sort((a, b) => CONTACT_FIELD_SORT_VALUE[a.fieldName] - CONTACT_FIELD_SORT_VALUE[b.fieldName]);
}
