import { encode, decode } from "@cfworker/base64url";

const NOT_A_SECRET_KEY = "a3rt4507fa917328"; // Ensure this is 16 or 32 bytes long

const textEncoder = new TextEncoder();

async function createHmacSha256(secret: string, data: string): Promise<string> {
  const key = await crypto.subtle.importKey(
    "raw",
    textEncoder.encode(secret),
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"],
  );
  const signature = await crypto.subtle.sign("HMAC", key, textEncoder.encode(data));
  return arrayBufferToBase64(signature);
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const bytes = new Uint8Array(buffer);
  let binary = "";
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

async function getSignatureLength(secret: string): Promise<number> {
  const signature = await createHmacSha256(secret, "");
  return encode(signature).length;
}

export async function obfuscate(input: string, secret = NOT_A_SECRET_KEY): Promise<string> {
  const signature = await createHmacSha256(secret, input);
  const encodedInput: string = encode(input);
  const encodedSignature: string = encode(signature);
  return `${encodedInput}${encodedSignature}`;
}

export async function deobfuscate(
  obfuscated: string,
  secret = NOT_A_SECRET_KEY,
): Promise<string | null> {
  const signatureLength = await getSignatureLength(secret);

  const encodedInput: string = obfuscated.slice(0, -signatureLength);
  const encodedSignature: string = obfuscated.slice(-signatureLength);

  const input: string = decode(encodedInput);
  const signature: string = decode(encodedSignature);

  const expectedSignature = await createHmacSha256(secret, input);

  if (signature !== expectedSignature) {
    return null;
  }
  return input;
}

export function getObfuscateMarkup(val: string): string {
  return `[[tdobfu]]${val}[[/tdobfu]]`;
}

export function getObfuscateMarkupWithUserId(val: string): string {
  return `[[tdobfuserid]]${val}[[/tdobfuserid]]`;
}

export const obfuscateMarkupPattern = /\[\[tdobfu\]\](.*?)\[\[\/tdobfu\]\]/gs;
export const obfuscatedMarkupPatternWithUserId = /\[\[tdobfuserid\]\](.*?)\[\[\/tdobfuserid\]\]/gs;

export function transformObfuscateMarkup(
  body: string,
  tagPattern: RegExp,
  transformer: (val: string) => string,
): string {
  const cache = new Map<string, string>();
  return body.replace(tagPattern, (match, p1) => {
    if (cache.has(p1)) {
      return cache.get(p1)!;
    }
    const result = transformer(p1);
    cache.set(p1, result);
    return result;
  });
}

export async function transformMarkupAsync(
  input: string,
  tagPattern: RegExp,
  transformer: (val: string) => Promise<string>,
) {
  // Find all matches using matchAll
  const matches = [...input.matchAll(tagPattern)];
  const cache = new Map<string, string>();

  // Prepare promises for all transformations
  const transformations = matches.map(async (match) => {
    const fullMatch = match[0];
    const innerText = match[1];

    let transformedText = cache.get(innerText);
    if (!transformedText) {
      transformedText = await transformer(innerText);
      cache.set(innerText, transformedText);
    }
    return { fullMatch, transformedText };
  });

  // Wait for all transformations to complete
  const results = await Promise.all(transformations);

  // Replace each full match with its transformed text
  for (const { fullMatch, transformedText } of results) {
    console.log("Replacing", fullMatch, "with", transformedText);
    input = input.replace(fullMatch, transformedText);
  }

  return input;
}
