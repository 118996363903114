import { ChainableFilter, KeywordFilter } from "core/types/contactSearch";
import { FC, MutableRefObject, useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import SearchFilterCompanyName from "@/components/contacts/search/SearchFilterOptions/SearchFilterCompanyName";
import SearchFilterGroupLocation from "@/components/contacts/search/SearchFilterOptions/SearchFilterGroupLocation";
import SearchFilterSelection from "@/components/contacts/search/SearchFilterOptions/SearchFilterSelection";
import { SelectedSearchFilterState } from "@/components/contacts/search/types";
import { SearchHistoryRow } from "@/database/contactDb";

const SearchSelectFilter: FC<{
  filter: Partial<ChainableFilter> | KeywordFilter;
  pendingFilter: MutableRefObject<Partial<ChainableFilter> | KeywordFilter | undefined>;
  pendingSavedSearch: MutableRefObject<SearchHistoryRow | undefined>;
  setFilter: (value: Partial<ChainableFilter> | KeywordFilter) => void;
  setSelectedFilters: (val: SelectedSearchFilterState) => void;
  savedSearchLimit?: number;
}> = ({
  pendingSavedSearch,
  savedSearchLimit,
  filter,
  setFilter,
  pendingFilter,
  setSelectedFilters,
}) => {
  const onChange = useCallback(
    (updatedFilter?: Partial<ChainableFilter> | undefined) => {
      if (updatedFilter || pendingFilter.current) {
        setFilter({
          ...filter,
          ...(updatedFilter || pendingFilter.current),
        } as ChainableFilter);
        pendingFilter.current = undefined;
      } else if (pendingSavedSearch.current) {
        setSelectedFilters(pendingSavedSearch.current.filters);
        pendingSavedSearch.current = undefined;
      }
    },
    [filter, pendingFilter, pendingSavedSearch, setFilter, setSelectedFilters],
  );

  const onFocusedFilter = useCallback(
    (updatedFilter: Partial<ChainableFilter> | undefined) => {
      if (updatedFilter) {
        pendingFilter.current = {
          ...filter,
          ...updatedFilter,
        } as ChainableFilter;
      } else {
        pendingFilter.current = undefined;
      }
    },
    [filter, pendingFilter],
  );

  const onFocusedSavedSearch = useCallback(
    (savedSearch: SearchHistoryRow | undefined) => {
      pendingFilter.current = undefined;
      if (savedSearch) {
        pendingSavedSearch.current = savedSearch;
      }
    },
    [pendingFilter, pendingSavedSearch],
  );

  useHotkeys(
    "enter",
    () => {
      onChange();
    },
    {
      enableOnFormTags: true,
      enableOnContentEditable: true,
    },
  );

  if (["keyword"].includes(filter.type || "") || ["in:", "co:"].includes(filter.token || "")) {
    return (
      <>
        {filter.type === "keyword" && (
          <SearchFilterSelection
            filter={filter}
            onFocusedFilter={onFocusedFilter}
            onFocusedSavedSearch={onFocusedSavedSearch}
            onSelected={onChange}
            setSelectedFilters={setSelectedFilters}
            savedSearchLimit={savedSearchLimit}
          />
        )}
        {filter.token === "in:" && (
          <SearchFilterGroupLocation
            filter={filter}
            onFocused={onFocusedFilter}
            onSelected={onChange}
          />
        )}
        {filter.token === "co:" && (
          <SearchFilterCompanyName
            filter={filter}
            onSelected={onChange}
            onFocused={onFocusedFilter}
          />
        )}
      </>
    );
  }
  return null;
};

export default SearchSelectFilter;
