import nlp from "compromise";
import { FC, useCallback, useMemo } from "react";
import { getTokenizedStr } from "utils/string";

import { SecondaryBadge, SpecialBadge, SuccessBadge } from "@/components/core/Badge";

const NamedEntities: FC<{
  notes: string;
  setSearchQuery?: (query: string) => void;
}> = ({ notes, setSearchQuery }) => {
  const sanitizeText = useCallback((text: string) => {
    // The regular expression below matches one or more non-alphanumeric characters at the
    // end of a string, but excludes closing brackets of any kind and closing quotes.
    return String(text)
      .replace(/[\.\}\]\)\'\"]+$/, "")
      .replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, "");
  }, []);

  const filterStrList = useCallback((list: string[]) => {
    return list.filter((str, idx, arr) => {
      // Check if the current string contains any other string in the array
      return !arr.some(
        (otherStr, otherIdx) =>
          idx !== otherIdx && getTokenizedStr(str).includes(getTokenizedStr(otherStr)),
      );
    });
  }, []);

  const entities = useMemo(() => {
    const text = nlp(notes);
    const people: Set<string> = new Set(text.people().unique().out("array").map(sanitizeText));
    const orgs: Set<string> = new Set(
      text.organizations().unique().out("array").slice(0, 10).map(sanitizeText),
    );
    const places: Set<string> = new Set(text.places().unique().out("array").map(sanitizeText));

    return {
      people: [...people],
      orgs: [...orgs],
      places: filterStrList([...places]),
    };
  }, [filterStrList, notes, sanitizeText]);

  if (entities.people.length === 0 && entities.orgs.length === 0) return null;

  return (
    <div className="w-full py-2 px-3 bg-gray-200 dark:bg-gray-600">
      <div className="gap-1 flex flex-wrap">
        {entities.people.map((person: string) => (
          <div
            key={person}
            onClick={(e) => {
              e.stopPropagation();
              if (setSearchQuery) setSearchQuery(person);
            }}
          >
            <SuccessBadge className="hover:cursor-pointer">{person}</SuccessBadge>
          </div>
        ))}
        {entities.orgs.map((org: string) => (
          <div
            key={org}
            onClick={(e) => {
              e.stopPropagation();
              if (setSearchQuery) setSearchQuery(org);
            }}
          >
            <SpecialBadge className="hover:cursor-pointer">{org}</SpecialBadge>
          </div>
        ))}
        {entities.places.map((org: string) => (
          <div
            key={org}
            onClick={(e) => {
              e.stopPropagation();
              if (setSearchQuery) setSearchQuery(org);
            }}
          >
            <SecondaryBadge className="hover:cursor-pointer">{org}</SecondaryBadge>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NamedEntities;
