import { Listbox, Transition } from "@headlessui/react";
import classNames from "clsx";
import type { FC, FocusEventHandler, KeyboardEventHandler } from "react";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { isEmpty } from "@/helpers/array";

import type { IconType } from "../../../core/Icon";
import { CheckIcon, ChevronDownIcon } from "../../../core/Icon";

const CREATE_NEW_FORM_SELECT_ITEM_ID = "CREATE_NEW_FORM_SELECT_ITEM_ID";

export interface TypeSelectItem {
  id: string;
  value: string;
  icon?: IconType;
}

type TypeSelectProps = {
  items: TypeSelectItem[];
  initialItemId?: string;
  onSelectItem: (item: TypeSelectItem) => void;
  shouldResetSelection?: boolean;
  createNewItemTitle?: string;
};

const TypeSelect: FC<TypeSelectProps> = ({
  items,
  initialItemId,
  onSelectItem,
  shouldResetSelection,
  createNewItemTitle,
}) => {
  const popperDivRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    strategy: "fixed",
  });

  const [selectedItem, setSelectedItem] = useState<TypeSelectItem | null>(null);
  const [isCreatingNewItem, setIsCreatingNewItem] = useState<boolean>(false);
  const [newItemText, setNewItemText] = useState<string>("");
  const createNewItemInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (items && initialItemId) {
      setSelectedItem(items.find((item) => item.id === initialItemId) || null);
    }
  }, [items, initialItemId]);

  const handleOnChange = (item: TypeSelectItem) => {
    if (item.id === CREATE_NEW_FORM_SELECT_ITEM_ID) {
      setIsCreatingNewItem(true);

      // After a short delay, focus on the new item input ref
      setTimeout(() => {
        createNewItemInputRef.current?.focus();
      }, 100);
      return;
    }

    if (!shouldResetSelection) {
      setSelectedItem(item);
    }
    onSelectItem(item);
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const newItem: TypeSelectItem = { id: newItemText, value: newItemText };
    if (e.key === "Enter" && !!newItemText) {
      if (!shouldResetSelection) {
        setSelectedItem(newItem);
      }
      onSelectItem(newItem);
      setIsCreatingNewItem(false);
      setNewItemText("");
    }
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (!!newItemText) {
      const newItem: TypeSelectItem = { id: newItemText, value: newItemText };
      if (!shouldResetSelection) {
        setSelectedItem(newItem);
      }
      onSelectItem(newItem);
    }
    setIsCreatingNewItem(false);
    setNewItemText("");
  };

  return (
    <Listbox value={selectedItem} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative flex justify-end mr-2 space-y-1 w-28">
          <div>
            {isCreatingNewItem ? (
              <input
                id="create-new-type-select-item"
                type="text"
                value={newItemText}
                ref={createNewItemInputRef}
                onChange={(e) => setNewItemText(e.target.value)}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnBlur}
                className="w-24 py-2 pl-3 text-right rounded-md shadow-sm appearance-none border-primary text-input-primary focus:ring-pink-500 focus:border-pink-500"
              />
            ) : (
              <Listbox.Button
                ref={setReferenceElement}
                className="relative w-full px-3 py-2 mt-px text-xs uppercase rounded-md appearance-none placeholder-zinc-400 h-9 dark:placeholder-zinc-600"
              >
                {selectedItem ? (
                  <div className="flex items-center text-secondary">{selectedItem.value}</div>
                ) : (
                  <div className="flex items-center text-zinc-400 dark:text-zinc-600">Select</div>
                )}
                <span className="absolute inset-y-0 right-0 flex items-center ml-3 pointer-events-none">
                  <ChevronDownIcon size="xs" className="mt-px ml-1 text-zinc-500" />
                </span>
              </Listbox.Button>
            )}

            {/* Options */}
            <div
              ref={popperDivRef}
              style={{ ...styles.popper, minWidth: referenceElement?.scrollWidth, zIndex: 100 }}
              {...attributes.popper}
            >
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
                beforeEnter={() => setPopperElement(popperDivRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Listbox.Options
                  static
                  className="z-50 w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg bg-primary max-h-56 ring-primary focus:outline-none sm:text-sm"
                >
                  <>
                    {/* Main list of items */}
                    {items.map((item, index) => (
                      <TypeSelectOption item={item} key={index} />
                    ))}

                    {/* Create new item (if specified) */}
                    {createNewItemTitle && (
                      <>
                        {!isEmpty(items) && (
                          <div className="w-full my-1 border-t border-zinc-200 dark:border-zinc-600" />
                        )}
                        <TypeSelectOption
                          item={{
                            id: CREATE_NEW_FORM_SELECT_ITEM_ID,
                            value: createNewItemTitle,
                          }}
                          key={CREATE_NEW_FORM_SELECT_ITEM_ID}
                        />
                      </>
                    )}
                  </>
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        </div>
      )}
    </Listbox>
  );
};

const TypeSelectOption: FC<{ item: TypeSelectItem }> = ({ item }) => {
  return (
    <Listbox.Option
      key={item.value}
      className={({ active }) =>
        classNames(
          active ? "text-white bg-purple-600" : "text-zinc-900 dark:text-zinc-400",
          "cursor-pointer select-none relative py-3 pl-3 pr-9"
        )
      }
      value={item}
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center">
            {/* Icon */}
            {item.icon && (
              <div className="w-5 h-5 icon-container">
                <item.icon className={active ? "text-white" : "text-zinc-500"} />
              </div>
            )}

            {/* Display name */}
            <span
              className={classNames(
                "ml-2 block truncate uppercase",
                selected ? "font-semibold dark:text-zinc-100" : "font-normal"
              )}
            >
              {item.value}
            </span>
          </div>

          {/* Checkmark (if selected) */}
          {selected ? (
            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
              <CheckIcon className={active ? "text-white" : "text-purple-600"} aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};

export default TypeSelect;
