import { format, formatDistanceToNowStrict } from "date-fns";
import type { FC } from "react";
import React from "react";
import type { RemoteApiSnapshot } from "services/src/shared/models/RemoteApiSnapshot";

import { getHumanReadableVendorName } from "@/helpers/integration";

import Button, { ButtonVariant } from "../../core/Button";
import { HistorySolidIcon } from "../../core/Icon";

type SnapshotRowProps = {
  snapshot: RemoteApiSnapshot;
  onRestoreSnapshot: (snapshot: RemoteApiSnapshot) => void;
};

const SnapshotRow: FC<SnapshotRowProps> = ({ snapshot, onRestoreSnapshot }) => {
  const vendorName = getHumanReadableVendorName(snapshot.vendor);
  const createdDateString = format(snapshot.createdAt, "MMM do, yyyy 'at' h a");
  const createdDistanceString = formatDistanceToNowStrict(snapshot.createdAt, {
    addSuffix: true,
  });
  return (
    <li className="block hover:bg-zinc-50 dark:hover:bg-zinc-800">
      <div className="flex items-center px-6 py-4">
        {/* Vendor + created at */}
        <div className="flex items-center flex-1 min-w-0">
          <div className="flex-row flex-1 min-w-0">
            <div>
              <p className="text-sm font-medium text-zinc-900 dark:text-zinc-100">
                {vendorName} - <span className="truncate">{createdDateString}</span>
              </p>
              <p className="flex items-center text-sm text-zinc-400">
                <span className="text-xs">{createdDistanceString}</span>
              </p>
            </div>
          </div>
        </div>

        {/* Restore button */}
        <Button
          variant={ButtonVariant.Primary}
          icon={<HistorySolidIcon />}
          onClick={() => onRestoreSnapshot(snapshot)}
        >
          Restore
        </Button>
      </div>
    </li>
  );
};

export default SnapshotRow;
