import type { Contact } from "@shared/models/Contact";
import classNames from "clsx";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import ContactInteractionLink from "@/components/contacts/interaction/ContactInteractionLink";
import Button, { ButtonVariant } from "@/components/core/Button";
import { ChevronLeftIcon } from "@/components/core/Icon";
import { useLiveContactInteraction } from "@/hooks/data/useLiveContactInteraction";
import { selectIsContactInteractionDialogOpen } from "@/integrations/contact/selectors";
import contactSlice from "@/integrations/contact/slice";

const ContactInteraction: FC<{ contactId: Contact["id"] }> = ({ contactId }) => {
  const { interactions, fetchMoreHistorical, fetchMoreRecent } =
    useLiveContactInteraction(contactId);

  const isContactInteractionDialogOpen = useSelector(selectIsContactInteractionDialogOpen);
  const appDispatch = useDispatch();
  const toggleContactInteractionDialog = () => {
    appDispatch(
      contactSlice.actions.setIsContactInteractionDialogOpen(!isContactInteractionDialogOpen)
    );
  };

  const openContactInteractionDialog = useDebouncedCallback(
    () => {
      appDispatch(contactSlice.actions.setIsContactInteractionDialogOpen(true));
    },
    200,
    { leading: false, trailing: true }
  );

  const closeContactInteractionDialog = useDebouncedCallback(
    () => {
      appDispatch(contactSlice.actions.setIsContactInteractionDialogOpen(false));
    },
    200,
    { leading: false, trailing: true }
  );

  return (
    interactions &&
    interactions.length > 0 && (
      <div className="group border-0 relative max-h-[50%] flex flex-col">
        {interactions.length > 2 && (
          <div
            className={classNames(
              "-mt-8 w-full relative flex justify-center text-base font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75",
              isContactInteractionDialogOpen ? "rotate-180" : "rotate-0"
            )}
          >
            <Button
              variant={ButtonVariant.None}
              rounded={false}
              onClick={toggleContactInteractionDialog}
              className="hover:bg-gray-50 dark:hover:bg-zinc-600 text-primary focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50 w-full"
            >
              <ChevronLeftIcon className="rotate-90" />
            </Button>
          </div>
        )}

        <div
          // onMouseEnter={openContactInteractionDialog}
          // onMouseLeave={closeContactInteractionDialog}
          className={classNames(
            "transition-all duration-300 overflow-y-auto z-30 flex-1 bg-blue-50 dark:bg-blue-950 min-h-[6rem] border-t border-0 border-primary px-2 sm:px-6 py-4",
            { "max-h-[10rem]": !isContactInteractionDialogOpen }
          )}
        >
          <h3 className="text-primary text-sm px-4">
            Interactions {interactions.length ? `(${interactions.length})` : null}
          </h3>
          {interactions?.map((interaction) => (
            <div
              key={`${interaction.email}_${interaction.vendorTableId}`}
              className="[&>*]:flex [&>*]:w-full [&>*]:items-center flex rounded-lg px-4 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
            >
              <ContactInteractionLink interaction={interaction} />
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default ContactInteraction;
