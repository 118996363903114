import { useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from "@react-oauth/google";
import { ReactElement } from "react";
import {
  allGoogleScopes,
  getAllScopeList,
  hasSendGmailScope,
} from "services/src/shared/google/authScopes";
import type { RemoteApiForDisplay } from "services/src/shared/models/RemoteApi";
import {
  IsDeleted,
  IsIntegrationEnabled,
  IsRemoteApiActive,
  RemoteApiStatus,
} from "services/src/shared/models/types";
import { getMsFromSec } from "utils/dateTime";

import { DangerBadge, SecondaryBadge, SuccessBadge, WarningBadge } from "@/components/core/Badge";
import { ArrowRotateIcon } from "@/components/core/Icon";
import { showToast, ToastLevel } from "@/components/core/Toast";
import { useCreateIntegrationFromGoogleOauthMutation } from "@/integrations/user/api";

export type IntegrationType =
  | "contacts"
  | "calendars"
  | "emails"
  | "contactEnrichment"
  | "prospectSearch";

export type IntegrationScope = {
  title: string;
  description: string;
  enabled: boolean;
  required: boolean;
};

export type AddIntegrationWorkflowConfig = {
  name: string;
  description: string;
  iconUrl?: string;
  Icon?: ReactElement;
  handler: () => void;
};

function hasScopes(scopes: string[], grantedScopes: string) {
  return scopes.every((scope) => grantedScopes.includes(scope));
}

export function getIntegrationDisplay(remoteApi: RemoteApiForDisplay, type: IntegrationType) {
  if (remoteApi.vendor === "icloud") {
    if (type === "contacts")
      return {
        iconUrl: "/logos/icloud-contacts-logo.svg",
        name: "iCloud Contacts",
      };
  }

  if (remoteApi.vendor === "google") {
    if (type === "contacts")
      return {
        iconUrl: "/logos/google-contacts-logo.svg",
        name: "Google Contacts",
      };
    if (type === "calendars")
      return {
        iconUrl: "/logos/google-calendar-logo.svg",
        name: "Google Calendar",
      };
    if (type === "emails")
      return {
        iconUrl: "/logos/google-gmail-logo.svg",
        name: "Gmail",
      };
  }
}

export function getIntegrationScopes(
  remoteApi: RemoteApiForDisplay,
  type: IntegrationType,
): IntegrationScope[] {
  const { vendor, authScope } = remoteApi;

  if (vendor === "peopleDataLabs") {
    if (type === "contactEnrichment")
      return [
        {
          title: "Contact Enrichment",
          description: "Enrich contacts, and prospects.",
          enabled: true,
          required: true,
        },
        {
          title: "Contact Metadata",
          description: "Unlock additional data fields - gender, interests, skills, etc.",
          enabled: true,
          required: true,
        },
      ];

    if (type === "prospectSearch") {
      return [
        {
          title: "Prospect Search",
          description: "Discover people not in your contacts.",
          enabled: true,
          required: true,
        },
      ];
    }
  }

  if (vendor === "icloud" && type === "contacts") {
    return [
      {
        title: "iCloud Contacts",
        description: "Create, edit, delete contacts and groups",
        enabled: true, // app specific pass gives access to all
        required: true,
      },
    ];
  }

  if (vendor === "google") {
    let grantedScopes = "";
    if (type === "contacts") {
      grantedScopes = authScope || "";
      return [
        {
          title: "Google Contacts",
          description: "Create, edit, delete contacts and groups",
          enabled: hasScopes(allGoogleScopes.contacts.sync, grantedScopes),
          required: true,
        },
        {
          title: "Other Contacts",
          description:
            "Read-only contacts created by Google based on your interactions (Gmail, Google+, etc.)",

          enabled: hasScopes(allGoogleScopes.contacts.readOnly, grantedScopes),
          required: false,
        },
      ];
    }
    if (type === "calendars") {
      grantedScopes = authScope || "";
      return [
        {
          title: "Google Calendar",
          description: "Create, edit, delete calendars and events",
          enabled: hasScopes(allGoogleScopes.calendars, grantedScopes),
          required: true,
        },
      ];
    }
    if (type === "emails") {
      grantedScopes = authScope || "";

      return [
        {
          title: "Metadata",
          description: "Read email metadata such as labels and headers, but not the email content",
          enabled: hasScopes(allGoogleScopes.emails.metadata, grantedScopes),
          required: true,
        },
        {
          title: "Filters & Labels",
          description: "Create, update, delete filters and labels",
          enabled: hasScopes(allGoogleScopes.emails.filtersLabels, grantedScopes),
          required: true,
        },
        {
          title: "Send Email",
          description: "Send emails to your contacts from TitleDock",
          enabled: hasScopes(allGoogleScopes.emails.send, grantedScopes),
          required: false,
        },
      ];
    }
    return [];
  }

  return [];
}

export function getIntegrationTimesForDisplay(
  remoteApi: RemoteApiForDisplay,
  type: IntegrationType,
) {
  if (type === "contacts") {
    return [
      {
        label: "Downstream",
        time: remoteApi.contactFetchLastRunSecAt
          ? getMsFromSec(remoteApi.contactFetchLastRunSecAt)
          : undefined,
      },
      {
        label: "Upstream",
        time: remoteApi.contactUpstreamLastRunSecAt
          ? getMsFromSec(remoteApi.contactUpstreamLastRunSecAt)
          : undefined,
      },
    ];
  }
  if (type === "calendars") {
    return [
      {
        label: "Last synced",
        time: remoteApi.calendarFetchLastRunSecAt
          ? getMsFromSec(remoteApi.calendarFetchLastRunSecAt)
          : undefined,
      },
    ];
  }
  if (type === "emails") {
    return [
      {
        label: "Last synced",
        time: remoteApi.emailFetchLastRunSecAt
          ? getMsFromSec(remoteApi.emailFetchLastRunSecAt)
          : undefined,
      },
    ];
  }
}

export function getRemoteApiContactSyncStatus(
  remoteApi: RemoteApiForDisplay,
  type: IntegrationType,
) {
  if (remoteApi.isDeleted === IsDeleted.YES) {
    return <DangerBadge>Deleting</DangerBadge>;
  }

  if (remoteApi.vendor === "peopleDataLabs") {
    if (
      remoteApi.pdlPersonEnrichRateLimit?.totalLimitRemaining === 0 ||
      remoteApi.pdlPersonSearchRateLimit?.totalLimitRemaining === 0
    ) {
      return <DangerBadge>Credits Exhausted</DangerBadge>;
    }

    if (
      remoteApi.pdlPersonEnrichRateLimit?.rateLimitRemaining?.minute === 0 ||
      remoteApi.pdlPersonSearchRateLimit?.rateLimitRemaining?.minute === 0
    ) {
      return <WarningBadge>Rate Limited</WarningBadge>;
    }
  }

  if (remoteApi.vendor === "google" && !remoteApi.authScope) {
    return <DangerBadge>Permissions Revoked</DangerBadge>;
  }

  if (remoteApi.isActive !== IsRemoteApiActive.YES) {
    return <DangerBadge>Deactivated</DangerBadge>;
  }

  if (!remoteApi.status || remoteApi.status === RemoteApiStatus.INIT) {
    return (
      <SecondaryBadge>
        Initial Download <ArrowRotateIcon className="ml-2 animate-spin" />
      </SecondaryBadge>
    );
  }

  if (type === "contacts") {
    if (
      remoteApi.status === RemoteApiStatus.MATCHING ||
      remoteApi.status === RemoteApiStatus.MATCHED
    ) {
      return <WarningBadge>Matching Contacts</WarningBadge>;
    }

    if (remoteApi.status === RemoteApiStatus.USER_MERGE) {
      return <WarningBadge>Manual Dedupe Required</WarningBadge>;
    }

    if (remoteApi.status === RemoteApiStatus.FINALIZING) {
      return <WarningBadge>Finalizing</WarningBadge>;
    }
  }

  if (
    remoteApi.status == RemoteApiStatus.READY ||
    (remoteApi.vendor === "peopleDataLabs" && remoteApi.auth?.apiKey)
  ) {
    return <SuccessBadge>Operational</SuccessBadge>;
  }
}

export const contactCalendarEmailVendors: RemoteApiForDisplay["vendor"][] = ["icloud", "google"];
export const contactEnrichmentVendors: RemoteApiForDisplay["vendor"][] = ["peopleDataLabs"];
export const videoConferenceVendors: RemoteApiForDisplay["vendor"][] = ["zoom", "google"];

export function isEmailSendIntegration(remoteApi: RemoteApiForDisplay) {
  return (
    remoteApi.vendor === "google" &&
    hasSendGmailScope(remoteApi.authScope || "") &&
    remoteApi.auth.username
  );
}

export function isContactCalendarEmailIntegration(remoteApi: RemoteApiForDisplay) {
  return contactCalendarEmailVendors.includes(remoteApi.vendor);
}

export function isContactEnrichmentIntegration(remoteApi: RemoteApiForDisplay) {
  return contactEnrichmentVendors.includes(remoteApi.vendor);
}

export function isVideoConferenceIntegration(remoteApi: RemoteApiForDisplay) {
  return videoConferenceVendors.includes(remoteApi.vendor);
}

export function isIntegrationActive(remoteApi: RemoteApiForDisplay) {
  const {
    isActive,
    isContactEnabled,
    isCalendarEnabled,
    isEmailEnabled,
    isContactEnrichmentEnabled,
  } = remoteApi;

  if (isContactCalendarEmailIntegration(remoteApi)) {
    return (
      (isContactEnabled === IsIntegrationEnabled.YES ||
        isCalendarEnabled === IsIntegrationEnabled.YES ||
        isEmailEnabled === IsIntegrationEnabled.YES) &&
      isActive === IsRemoteApiActive.YES
    );
  }

  if (isContactEnrichmentIntegration(remoteApi)) {
    return (
      isContactEnrichmentEnabled === IsIntegrationEnabled.YES && isActive === IsRemoteApiActive.YES
    );
  }

  return isActive === IsRemoteApiActive.YES;
}

export function isIntegrationBeingDeleted(remoteApi: RemoteApiForDisplay) {
  return remoteApi.isDeleted === IsDeleted.YES;
}

export function getPdlRateLimitToDisplay(remoteApi: RemoteApiForDisplay, type: IntegrationType) {
  const rateLimit =
    type === "contactEnrichment"
      ? remoteApi.pdlPersonEnrichRateLimit
      : remoteApi.pdlPersonSearchRateLimit;

  if (remoteApi.vendor !== "peopleDataLabs" || !rateLimit) {
    return undefined;
  }

  return [
    {
      label: "Total Credits Remaining",
      value: rateLimit?.totalLimitRemaining,
    },
    {
      label: "Rate Limit Remaining",
      value: rateLimit?.rateLimitRemaining?.minute,
    },
    {
      label: "Lifetime Usage",
      value: rateLimit?.lifetimeUsed,
    },
  ];
}

export function getContactEnrichmentRemoteApi(
  remoteApis: RemoteApiForDisplay[],
  checkQuota = false,
) {
  const result = [];
  for (const remoteApi of remoteApis) {
    if (isContactEnrichmentIntegration(remoteApi) && isIntegrationActive(remoteApi)) {
      if (checkQuota && remoteApi.pdlPersonEnrichRateLimit) {
        if (remoteApi.pdlPersonEnrichRateLimit.totalLimitRemaining === 0) {
          continue;
        }
      }
      result.push(remoteApi);
    }
  }

  return result;
}

export function useAddGoogleAccount({
  onCancel,
  options,
}: {
  onCancel?: () => void;
  options?: UseGoogleLoginOptionsAuthCodeFlow;
}): AddIntegrationWorkflowConfig {
  const [createIntegrationFromGoogleOauth] = useCreateIntegrationFromGoogleOauthMutation();

  return {
    name: "Google",
    description: "Contacts, Gmail, Calendar",
    iconUrl: "/logos/google-logo-icon.svg",
    handler: useGoogleLogin({
      flow: "auth-code",
      scope: getAllScopeList().join(" "),
      onSuccess: async (tokenResponse) => {
        const { dismissToast } = showToast({
          title: "Adding Google account...",
          description: "Please wait a few minutes while we sync your data.",
          level: ToastLevel.Info,
        });
        if (onCancel) onCancel();
        await createIntegrationFromGoogleOauth(tokenResponse.code);
        dismissToast();
        showToast({
          title: "Google account added",
          description: "Please wait a few minutes while we sync your data.",
          level: ToastLevel.Success,
        });
      },
      ...options,
    }),
  };
}
