import { ContactMetadataValueMap, ContactMetadataValues } from "@shared/models/ContactMetadata";
import type { Dispatch } from "react";
import type { ContactRow } from "services/src/shared/models/Contact";

import type { ContactData } from "@/components/contacts/v2/types";

import type { ContextualMenuFieldValue } from "./shared/ContextualMenuField";

export type UpdateContactDataAction = {
  type: keyof ContactRow | "RESET";
  payload: any;
};

export type UpsertContactMetadataAction =
  | {
      contactId?: string;
      type: ContactMetadataValues["type"];
      payload: ContactMetadataValues["values"];
    }
  | {
      contactId?: string;
      type: "RESET";
      payload: ContactMetadataValueMap;
    };

export type FieldKey = keyof ContactRow | string;

export type ContactDataFieldProps = {
  contactData: Partial<ContactData>;
  enrichmentData?: Partial<ContactRow>;
  dispatch?: Dispatch<UpdateContactDataAction>;
  isEditing: boolean;
  directlyUpdateContactData?: (dataToUpdate: Partial<ContactRow>) => Promise<void>;
  focusedField?: ContextualMenuFieldValue<any> | null;
  onFocusField?: (field: ContextualMenuFieldValue<any>) => void;
  fieldKeys?: FieldKey[];
  isNotesDirectlyEditable?: boolean;
  showNamedEntities?: boolean;
  setSearchQuery?: (query: string) => void;
  contextualMenuBlacklist?: {
    email?: {
      [key: string]: true;
    };
  };
  contactMetadataValueMap?: ContactMetadataValueMap;
  contactMetadataDispatch?: Dispatch<UpsertContactMetadataAction>;
};

export enum SocialProfileType {
  Apple = "apple",
  Facebook = "facebook",
  Flickr = "flickr",
  Generic = "-",
  Instagram = "instagram",
  LinkedIn = "linkedin",
  Myspace = "myspace",
  TikTok = "tiktok",
  Twitter = "twitter",
  Wechat = "wechat",
  Weibo = "weibo",
  Youtube = "youtube",
}
