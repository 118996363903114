import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import classNames from "clsx";
import { getFirstLastName } from "core/helpers/contact";
import { searchContactByQueryIndex, searchContactGroupByQuery } from "core/helpers/contactSearch";
import { ContactRecipient, EmailRecipient } from "core/types/userMessaging";
import { useLiveQuery } from "dexie-react-hooks";
import { parseAddressList } from "email-addresses";
import { ChangeEvent, FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { standardizeSeparator } from "utils/string";

import { ContactData } from "@/components/contacts/v2/types";
import { SpecialActiveColor, WarningActiveColor } from "@/components/core/colorVariant";
import { TagSolidIcon } from "@/components/core/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/core/Popover";
import Tag from "@/components/core/Tag";
import EmailRecipientContactGroupOptionModal from "@/components/email/EmailRecipientContactGroupOptionModal";
import EmailRecipientOptions from "@/components/email/EmailRecipientOptions";
import EmailRecipientPopoverContent from "@/components/email/EmailRecipientPopoverContent";
import { getEmailRecipientLabel } from "@/components/email/helpers";
import { getContactDb } from "@/database/contactDb";
import {
  frontendContactGroupSearch,
  frontendContactSearch,
  frontendProspectSearch,
} from "@/database/search";

const EmailRecipientInput: FC<{
  field: "to" | "cc" | "bcc";
  containerHeight: number;
  recipients: EmailRecipient[];
  moveRecipients: (targetField: "to" | "cc" | "bcc", idList: EmailRecipient["id"][]) => void;
  replaceRecipient: (prevId: EmailRecipient["id"], recipient: EmailRecipient) => void;
  setRecipients: (recipients: EmailRecipient[]) => void;
  focusedRecipient: { field: "to" | "cc" | "bcc"; id: EmailRecipient["id"] } | undefined;
  setFocusedRecipient: (id: string | undefined) => void;
}> = ({
  field,
  containerHeight,
  recipients,
  moveRecipients,
  replaceRecipient,
  setRecipients,
  focusedRecipient,
  setFocusedRecipient,
}) => {
  const searchRetryTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (searchRetryTimer.current) {
        clearTimeout(searchRetryTimer.current);
      }
    };
  }, []);

  const [input, setInput] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  const [openContactGroupModal, setOpenContactGroupModal] = useState<string | undefined>();

  const inputRef = useRef<HTMLInputElement>(null);

  const onCloseContactGroupModal = useCallback(() => setOpenContactGroupModal(undefined), []);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  }, []);

  const onBlur = useCallback(async () => {
    const str = input.trim();
    if (!str) return;
    const standardizedInput = standardizeSeparator(str);

    const emails = parseAddressList({ input: standardizedInput, partial: true });

    const newRecipients: ContactRecipient[] = [];

    for (const email of emails || []) {
      if (email.type === "mailbox") {
        newRecipients.push({
          id: email.address,
          name: email.name || "",
          type: "contact",
          email: email.address,
        });
      } else if (email.addresses) {
        for (const address of email.addresses) {
          newRecipients.push({
            id: address.address,
            name: address.name || "",
            type: "contact",
            email: address.address,
          });
        }
      }
    }

    if (newRecipients.length === 0) return;

    // lookup contacts based on email addresses parsed from input

    const contactDb = getContactDb();
    const data = await contactDb?.contacts
      .where("_emailValueList")
      .anyOf(newRecipients.map((r) => r.email))
      .toArray();

    const emailToContactMap: { [email: string]: ContactData } = {};
    for (const contact of data || []) {
      for (const email of contact.emails || []) {
        emailToContactMap[email.value] = contact;
      }
    }

    for (const recipient of newRecipients) {
      if (emailToContactMap[recipient.email]) {
        recipient.contactId = emailToContactMap[recipient.email].id;
        recipient.name = getFirstLastName(emailToContactMap[recipient.email]);
      }
    }

    setRecipients([...recipients, ...newRecipients]);
    setInput("");
  }, [input, recipients, setRecipients]);

  const [searchResults, setSearchResults] = useState<{
    contactIds: string[];
    prospectIds: string[];
    contactGroupIds: string[];
  }>({ contactIds: [], prospectIds: [], contactGroupIds: [] });

  const putSearchResults = useDebouncedCallback(
    async (query: string) => {
      if (searchRetryTimer.current) {
        clearTimeout(searchRetryTimer.current);
      }

      if (!query) {
        setSearchResults({
          contactIds: [],
          prospectIds: [],
          contactGroupIds: [],
        });
        setOpenPopover(false);
        return;
      }

      if (!frontendContactSearch || !frontendContactGroupSearch || !frontendProspectSearch) {
        searchRetryTimer.current = setTimeout(() => putSearchResults(query), 250);
        return;
      }

      const index = ["_fullName", "emails[]:value"];
      const [contactIds, prospectIds, contactGroupIds] = await Promise.all([
        searchContactByQueryIndex(query, frontendContactSearch, index),
        searchContactByQueryIndex(query, frontendProspectSearch, index),
        searchContactGroupByQuery(query, frontendContactGroupSearch),
      ]);

      setSearchResults({
        contactIds,
        prospectIds,
        contactGroupIds,
      });
    },
    100,
    {
      leading: false,
      trailing: true,
    },
  );

  useEffect(() => {
    putSearchResults(input);
  }, [input, putSearchResults]);

  const onRemoveRecipient = useCallback(
    (id: string) => {
      const recipientToKeep = recipients.filter((recipient) => recipient.id !== id);
      setRecipients(recipientToKeep);
    },
    [recipients, setRecipients],
  );

  const onSetSelectedRecipients = useCallback(
    (recipients: EmailRecipient[]) => {
      setRecipients(recipients);
      setInput("");
      setOpenPopover(false);
    },
    [setRecipients],
  );

  const onInputFocus = useCallback(() => {
    setOpenPopover(true);
  }, []);

  const entitiesData = useLiveQuery(async () => {
    // fetch contact data and group data from selected recipients

    const contactIds = new Set<string>();
    const contactGroupIds = new Set<string>();

    for (const recipient of recipients) {
      if (recipient.type === "contactGroup") {
        contactGroupIds.add(recipient.contactGroupId);
      } else if (recipient.contactId) {
        contactIds.add(recipient.contactId);
      }
    }

    const contactsById: { [contactId: string]: ContactData } = {};
    const contactGroupsById: { [contactGroupId: string]: ContactGroupRowForDisplay } = {};

    const frontendDb = getContactDb();
    const [contacts, contactGroups] = await Promise.all([
      frontendDb?.contacts
        .where("id")
        .anyOf([...contactIds])
        .toArray(),
      frontendDb?.contactGroups
        .where("id")
        .anyOf([...contactGroupIds])
        .toArray(),
    ]);

    if (contacts) {
      for (const contact of contacts) {
        if (!contact) continue;
        contactsById[contact.id] = contact;
      }
    }

    if (contactGroups) {
      for (const contactGroup of contactGroups) {
        if (!contactGroup) continue;
        contactGroupsById[contactGroup.id] = contactGroup;
      }
    }

    return {
      contactsById,
      contactGroupsById,
    };
  }, [recipients]);

  return (
    <div className="flex flex-1 flex-wrap gap-1 items-center">
      {recipients?.map((recipient) => {
        const isFocusedRecipient = focusedRecipient?.id === recipient.id;

        const entity =
          recipient.type === "contactGroup"
            ? entitiesData?.contactGroupsById[recipient.contactGroupId]
            : recipient.contactId
              ? entitiesData?.contactsById[recipient.contactId]
              : undefined;

        return (
          <Fragment key={recipient.id}>
            <Popover
              placement="bottom-start"
              open={focusedRecipient?.id === recipient.id}
              onOpenChange={(open) => {
                if (!open) {
                  setFocusedRecipient(undefined);
                }
              }}
            >
              <PopoverTrigger
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setFocusedRecipient(recipient.id);
                  }
                  if (e.key === "Delete" || e.key === "Backspace") {
                    onRemoveRecipient(recipient.id);
                  }
                }}
                asChild
              >
                <div className="inline-flex">
                  <Tag
                    className={classNames(
                      "py-2 h-6 w-full max-w-full overflow-x-auto",
                      isFocusedRecipient &&
                        "ring-1 ring-amber-500 dark:ring-amber-600 font-semibold",
                    )}
                    tagItem={{
                      id: recipient.id,
                      name: isFocusedRecipient
                        ? getEmailRecipientLabel(recipient)
                        : recipient.name || (recipient as ContactRecipient).email || "",
                    }}
                    onRemoveTag={() => {
                      onRemoveRecipient(recipient.id);
                    }}
                    onClickTag={() => {
                      setFocusedRecipient(recipient.id);
                      if (recipient.type === "contactGroup") {
                        setOpenContactGroupModal(recipient.id);
                      }
                    }}
                    Icon={recipient.type === "contactGroup" ? <TagSolidIcon /> : undefined}
                    enableRemoveButton
                    backgroundColor="bg-primary"
                    foregroundColor={
                      recipient.type === "contactGroup" ? SpecialActiveColor : WarningActiveColor
                    }
                  />
                </div>
              </PopoverTrigger>
              <PopoverContent initialFocus={-1} className="shadow z-50 overflow-hidden rounded-md">
                <div>
                  {(recipient.type === "contact" || recipient.type === "prospect") && (
                    <EmailRecipientOptions
                      entity={entity}
                      recipient={recipient}
                      selectedRecipients={recipients}
                      replaceRecipient={replaceRecipient}
                      moveRecipients={moveRecipients}
                      field={field}
                    />
                  )}
                </div>
              </PopoverContent>
            </Popover>

            {recipient.type === "contactGroup" && openContactGroupModal === recipient.id && (
              <EmailRecipientContactGroupOptionModal
                isOpen={openContactGroupModal === recipient.id}
                onClose={onCloseContactGroupModal}
                contactGroupRecipient={recipient}
                entity={entity as ContactGroupRowForDisplay}
                replaceRecipient={replaceRecipient}
              />
            )}
          </Fragment>
        );
      })}

      <Popover
        placement="bottom-start"
        open={openPopover}
        onOpenChange={(open) => {
          if (!open) {
            setOpenPopover(false);
          }
        }}
      >
        <PopoverTrigger asChild>
          <input
            ref={inputRef}
            className={classNames(
              "flex h-6 py-2 pl-0 flex-1 min-w-[50px] border-transparent text-primary focus:border-transparent bg-transparent focus:ring-0 sm:text-sm outline-0",
            )}
            value={input}
            onFocus={onInputFocus}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={(e) => {
              if (e.key === "Backspace" || e.key === "Delete") {
                if (!input && recipients.length > 0) {
                  const lastRecipient = recipients[recipients.length - 1];
                  if (lastRecipient) {
                    if (lastRecipient.id === focusedRecipient?.id) {
                      onRemoveRecipient(lastRecipient.id);
                    } else {
                      setFocusedRecipient(lastRecipient.id);
                    }
                  }
                }
              }
            }}
          />
        </PopoverTrigger>

        <EmailRecipientPopoverContent
          input={input}
          selectedRecipients={recipients}
          searchResults={searchResults}
          containerHeight={containerHeight}
          setOpenPopover={setOpenPopover}
          setSelectedRecipients={onSetSelectedRecipients}
        />
      </Popover>
    </div>
  );
};

export default EmailRecipientInput;
