import { FC, useCallback } from "react";

import AccountAddWithSidebar from "@/components/accounts/AccountAddWithSidebar";
import { AddIntegrationWorkflowConfig } from "@/components/accounts/helpers";
import Alert from "@/components/core/Alert";
import { ButtonVariant } from "@/components/core/Button";
import { EnvelopeIcon } from "@/components/core/Icon";

const AccountAddModal: FC<{
  title?: string;
  description?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  hasRemoteApi: boolean;
  filterPredicate?: (item: AddIntegrationWorkflowConfig) => boolean;
}> = ({ title, isOpen, setIsOpen, description, hasRemoteApi, filterPredicate }) => {
  const onCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Alert
      title={title || "Add Account"}
      description={description}
      isAlertOpen={isOpen}
      variant={ButtonVariant.Special}
      onClose={onCancel}
      AlertIcon={<EnvelopeIcon />}
    >
      <AccountAddWithSidebar
        title="" // title is already displayed in the Alert
        noGutter
        onCancel={onCancel}
        hasRemoteApi={hasRemoteApi}
        filterPredicate={filterPredicate}
      />
    </Alert>
  );
};

export default AccountAddModal;
