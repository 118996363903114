import { createRef, RefObject, useCallback, useRef } from "react";

export const useGetRef = () => {
  const refs = useRef<{ [key: string]: RefObject<HTMLElement> }>({});
  return useCallback(
    (idx: string | number) => {
      const i = String(idx);
      if (!refs.current[i]) {
        refs.current[i] = createRef<HTMLElement>();
      }
      return refs.current[i];
    },
    [refs]
  );
};
