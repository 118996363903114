import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppUIState } from "@/integrations/app/types";

const initialState: AppUIState = {
  isMobileSidebarOpen: false,
  isNotificationPanelOpen: false,
  isSearchOptionOpen: false,
};

const appSlice = createSlice({
  name: "appUi",
  initialState,
  reducers: {
    setIsSearchOptionOpen: (state, action: PayloadAction<boolean>) => {
      state.isSearchOptionOpen = action.payload;
    },
    toggleIsMobileSidebarOpen: (state) => {
      state.isMobileSidebarOpen = !state.isMobileSidebarOpen;
    },
    setIsMobileSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isMobileSidebarOpen = action.payload;
    },
    toggleIsNotificationPanelOpen: (state) => {
      state.isNotificationPanelOpen = !state.isNotificationPanelOpen;
    },
  },
});

export default appSlice;
