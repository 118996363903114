import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import classNames from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { useRouter } from "next/router";
import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import createPersistedState from "use-persisted-state";

import { getContactDb } from "@/database/contactDb";
import { getContactsRoute, GroupsRoute } from "@/helpers/routes";
import { useLiveAllContactGroups } from "@/hooks/data/useLiveContactGroup";
import { selectIsMobileSidebarOpen } from "@/integrations/app/selectors";
import appSlice from "@/integrations/app/slice";
import { useAppDispatch } from "@/integrations/redux/store";
import { useGetIntegrationsQuery } from "@/integrations/user/api";

import { ArrowRightToLineIcon, TimesIcon } from "../core/Icon";
import { SidebarGroupsSection } from "./SidebarGroupsSection";
import SidebarRouteItem from "./SidebarRouteItem";

export const useShrinkSidebar = createPersistedState<{
  shouldAutoShrinkSideBar: boolean;
  isShrunk: boolean;
  userExplicitlyDisabledAutoShrink?: boolean;
  didManuallyToggle: boolean;
}>("shrinkSidebar");

const Sidebar: FC = () => {
  const dispatch = useAppDispatch();

  const setIsMobileSidebarOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(appSlice.actions.setIsMobileSidebarOpen(isOpen));
    },
    [dispatch],
  );

  const isMobileSidebarOpen = useSelector(selectIsMobileSidebarOpen);

  const { contactGroups } = useLiveAllContactGroups({});

  const { data: remoteApiList } = useGetIntegrationsQuery();

  const [shrinkSidebar, setShrinkSidebar] = useShrinkSidebar({
    isShrunk: false,
    shouldAutoShrinkSideBar: false,
    userExplicitlyDisabledAutoShrink: false,
    didManuallyToggle: false,
  });

  const dbCounts = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    const dupeCount = await frontendDb?.duplicates.where("isLowConfMatch").notEqual(1).count();
    const onboardingCount = await frontendDb?.pendingContacts.count();
    return {
      dupeCount,
      onboardingCount,
    };
  });

  const [contactRoute, setContactRoute] = useState(getContactsRoute({}));

  const updateContactRoute = useDebouncedCallback(
    () => {
      const counts = dbCounts || { dupeCount: 0, onboardingCount: 0 };
      setContactRoute(getContactsRoute({ ...counts, remoteApis: remoteApiList }));
    },
    1000,
    { leading: false, trailing: true },
  );

  // unsure if useDebouncedCallback will maintain instance, so don't want to use it as dep
  useEffect(updateContactRoute, [dbCounts, remoteApiList]);

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      setShrinkSidebar((prevState) => {
        if (prevState.userExplicitlyDisabledAutoShrink) {
          return prevState;
        }
        return {
          isShrunk: true,
          shouldAutoShrinkSideBar: true,
          didManuallyToggle: false,
        };
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  const expandShrinkTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (expandShrinkTimer.current) {
        clearTimeout(expandShrinkTimer.current);
      }
    };
  }, []);

  const onShrinkDesktopSidebar = useCallback(() => {
    if (expandShrinkTimer.current) {
      clearTimeout(expandShrinkTimer.current);
      expandShrinkTimer.current = null;
    }
    if (shrinkSidebar.shouldAutoShrinkSideBar) {
      expandShrinkTimer.current = setTimeout(() => {
        setShrinkSidebar((prevState) => ({
          isShrunk: true,
          shouldAutoShrinkSideBar: prevState.shouldAutoShrinkSideBar,
          didManuallyToggle: false,
        }));
      }, 200);
    }
  }, [setShrinkSidebar, shrinkSidebar.shouldAutoShrinkSideBar]);

  const onExpandDesktopSidebar = useCallback(() => {
    if (expandShrinkTimer.current) {
      clearTimeout(expandShrinkTimer.current);
      expandShrinkTimer.current = null;
    }
    if (shrinkSidebar.didManuallyToggle) {
      return setShrinkSidebar((prevState) => ({
        ...prevState,
        didManuallyToggle: false,
      }));
    } else if (shrinkSidebar.shouldAutoShrinkSideBar) {
      expandShrinkTimer.current = setTimeout(() => {
        setShrinkSidebar((prevState) => ({
          isShrunk: false,
          shouldAutoShrinkSideBar: prevState.shouldAutoShrinkSideBar,
          didManuallyToggle: false,
        }));
      }, 400);
    }
  }, [setShrinkSidebar, shrinkSidebar.didManuallyToggle, shrinkSidebar.shouldAutoShrinkSideBar]);

  const onToggleDesktopSidebar = useCallback(() => {
    setShrinkSidebar((prevState) => {
      return {
        isShrunk: !prevState.isShrunk,
        shouldAutoShrinkSideBar: true,
        didManuallyToggle: true,
      };
    });
  }, [setShrinkSidebar]);

  const sidebarContent = useMemo(() => {
    return (
      <div className="relative flex flex-col h-screen py-2">
        {/* Logo */}
        <div
          className={classNames(
            "relative flex items-center h-12 mx-4 cursor-pointer overflow-hidden",
          )}
        >
          <img
            className="absolute h-12 dark:visible object-left-top object-cover"
            src="/titledock-logo-light-text.svg"
            alt="TitleDock light text logo"
          />
          <img
            className="absolute h-12 dark:invisible object-left-top object-cover"
            src="/titledock-logo-dark-text.svg"
            alt="TitleDock dark text logo"
          />
        </div>

        {/* Routes */}
        <nav className="relative flex-1 mt-2 overflow-y-auto" aria-label="Sidebar">
          <div className="px-2 space-y-1">
            {/*<SidebarRouteItem route={DashboardRoute} />*/}
            <SidebarRouteItem route={contactRoute} shrinkSidebar={shrinkSidebar.isShrunk} />
            {/*<SidebarRouteItem route={CalendarRoute} />*/}
            <div className="w-full py-2">
              <div className="w-full h-px bg-zinc-200 dark:bg-zinc-600" />
            </div>
            <SidebarGroupsSection
              shrinkSidebar={shrinkSidebar.isShrunk}
              groups={contactGroups || []}
              route={GroupsRoute}
              actionTitle={contactGroups && contactGroups.length > 0 ? "manage" : ""}
            />
          </div>
        </nav>

        {/* Profile item */}

        {!shrinkSidebar.shouldAutoShrinkSideBar && (
          <button
            type="button"
            className="w-full px-1 hover:bg-zinc-200 dark:hover:bg-zinc-800 text-right text-secondary text-sm shadow-sm z-50 hidden lg:block"
            onClick={onToggleDesktopSidebar}
          >
            {shrinkSidebar.isShrunk ? (
              <ArrowRightToLineIcon />
            ) : (
              <ArrowRightToLineIcon rotation={180} />
            )}
          </button>
        )}
      </div>
    );
  }, [contactRoute, contactGroups, onToggleDesktopSidebar, shrinkSidebar]);

  return (
    <>
      {/* Mobile sidebar */}
      <Transition show={isMobileSidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setIsMobileSidebarOpen}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-opacity-75 bg-zinc-600" />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs bg-secondary focus:outline-none">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    type="button"
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setIsMobileSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <TimesIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>

              {/* Main content */}
              {sidebarContent}
            </div>
          </TransitionChild>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition>

      {/* Desktop sidebar */}
      <div
        className={classNames(
          "hidden lg:flex lg:flex-shrink-0",
          shrinkSidebar.shouldAutoShrinkSideBar && "w-20",
        )}
      >
        <div
          className={classNames(
            "transition-all duration-300 ease-in-out z-40 flex flex-col",
            shrinkSidebar.isShrunk ? "w-20" : "w-60",
          )}
        >
          <div
            className="flex flex-col flex-1 min-h-0 border-r bg-secondary border-color-primary transform-none"
            onMouseEnter={onExpandDesktopSidebar}
            onMouseLeave={onShrinkDesktopSidebar}
          >
            {/* Main content */}
            {sidebarContent}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Sidebar);
