import type { ChangeEvent, FC } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { WebPage } from "services/src/shared/models/types";
import { ensureHttpProtocol } from "utils/string";

import { isEmpty } from "@/helpers/array";
import { getSocialProfileList } from "@/helpers/contact";

import {
  CopySolidIcon,
  ExternalLinkSolidIcon,
  HashtagIcon,
  PenSolidIcon,
} from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import { showToast } from "../../../core/Toast";
import { getSocialProfileService, SOCIAL_PROFILE_ICONS } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import type { ContextualMenuItem } from "../shared/ContextualMenuField";
import ContextualMenuField from "../shared/ContextualMenuField";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import type { ContactDataFieldProps, SocialProfileType } from "../types";

const SocialProfilesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  focusedField,
  onFocusField,
}) => {
  const allWebPages = useMemo(() => contactData?.webPages || [], [contactData?.webPages]);

  const webPagesWithIndices: { webPage: WebPage; originalIndex: number }[] = useMemo(
    () => getSocialProfileList(allWebPages),
    [allWebPages]
  );

  const lastSocialProfileRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedSocialProfileRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastSocialProfileInput = useCallback(() => {
    lastSocialProfileRowInputRef.current?.focus();
  }, [lastSocialProfileRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "socialProfiles") {
      focusedSocialProfileRowInputRef.current?.focus();
      focusedSocialProfileRowInputRef.current?.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [focusedField]);

  const socialProfileContextualItems = useMemo<ContextualMenuItem<WebPage>[]>(() => {
    return [
      {
        icon: () => ExternalLinkSolidIcon,
        tooltip: () => "Open link in a new tab",
        action: (payload) => {
          if (window) {
            window.open(payload.value.value, "_blank");
          }
        },
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy link to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(payload.value.value);
            showToast({ title: "Link copied to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit link",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [contactData]);

  const updateAllWebPages = (webPages: WebPage[]) => {
    if (dispatch) dispatch({ type: "webPages", payload: webPages });
  };

  const onAddNewSocialProfile = () => {
    const webPagesToUpdate: WebPage[] = [
      ...allWebPages,
      { value: "", type: "social", service: "" },
    ];
    updateAllWebPages(webPagesToUpdate);

    // After a short delay, focus on the last web page input (that was just added)
    setTimeout(() => {
      focusOnLastSocialProfileInput();
    }, 100);
  };

  const onUpdateSocialProfile = ({ index, data }: { index: number; data: Partial<WebPage> }) => {
    const webPagesToUpdate: WebPage[] = [...allWebPages];
    webPagesToUpdate[index] = {
      ...webPagesToUpdate[index],
      ...data,
    };
    updateAllWebPages(webPagesToUpdate);
  };

  const onRemoveSocialProfileAtIndex = (index: number) => {
    const webPagesToUpdate: WebPage[] = [...allWebPages];
    webPagesToUpdate.splice(index, 1);
    updateAllWebPages(webPagesToUpdate);
  };

  // Do not show for read-only mode if there are no web pages
  if (!isEditing && isEmpty(webPagesWithIndices)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Social profiles"
      isEditing={isEditing}
      icon={<HashtagIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {webPagesWithIndices.map(({ webPage, originalIndex }, index) => {
            const service = getSocialProfileService(webPage) as SocialProfileType;
            const SocialProfileIcon = SOCIAL_PROFILE_ICONS[service];

            const isLastWebPageInput = index === allWebPages.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "socialProfiles" && focusedField?.index === originalIndex;
            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <div className="flex items-center justify-end px-3 mr-2 -mt-px uppercase rounded-md appearance-none text-secondary w-28 text-zinc-400 dark:text-zinc-600">
                  <SocialProfileIcon size="lg" />
                </div>
                <TextInput
                  name="socialProfile"
                  type="text"
                  placeholder="e.g. https://twitter.com/titledock"
                  value={webPage.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onUpdateSocialProfile({
                      index: originalIndex,
                      data: { value: event.target.value, service },
                    })
                  }
                  forwardedRef={
                    (isFocusedField && focusedSocialProfileRowInputRef) ||
                    (isLastWebPageInput && lastSocialProfileRowInputRef) ||
                    undefined
                  }
                  className="flex-1 mr-2"
                />
                <RemoveDetailsItemButton
                  onClickButton={() => onRemoveSocialProfileAtIndex(originalIndex)}
                  tooltip="Remove Profile"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-web-page" className="flex flex-row w-full">
            <button
              className="px-5 py-2 text-sm font-medium ml-28 text-color-purple"
              onClick={() => onAddNewSocialProfile()}
            >
              Add a social profile
            </button>
          </dd>
        </>
      ) : (
        webPagesWithIndices.map(({ webPage, originalIndex }, index) => {
          const socialProfile = getSocialProfileService(webPage) as SocialProfileType;
          const SocialProfileIcon = SOCIAL_PROFILE_ICONS[socialProfile];

          return (
            <dd key={index}>
              <ContextualMenuField
                items={socialProfileContextualItems}
                actionPayload={{
                  fieldName: "socialProfiles",
                  value: webPage,
                  index: originalIndex,
                }}
              >
                <div className="flex flex-row">
                  <div className="flex items-center justify-center w-6 h-6 mr-2 -ml-1 text-zinc-400 dark:text-zinc-600">
                    <SocialProfileIcon />
                  </div>
                  <a
                    href={ensureHttpProtocol(webPage.value)}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-underline"
                  >
                    {webPage.value}
                  </a>
                </div>
              </ContextualMenuField>
            </dd>
          );
        })
      )}
    </BaseDetailsField>
  );
};

export default SocialProfilesField;
