import * as Sentry from "@sentry/nextjs";
import { getApp, initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth as getFirebaseAuth } from "firebase/auth";

function initApp() {
  const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_API_CONFIG || "{}");
  try {
    return getApp();
  } catch (e) {
    Sentry.captureException(e);
    return initializeApp(firebaseConfig);
  }
}

export function getAuth() {
  const app = initApp();
  const auth = getFirebaseAuth(app);
  auth.setPersistence(browserLocalPersistence);
  return auth;
}
