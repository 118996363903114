import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import classNames from "clsx";
import { ChainableFilter, LocationState } from "core/types/contactSearch";
import { FC, useEffect, useMemo, useState } from "react";
import { GroupedVirtuoso } from "react-virtuoso";

import { useGroupedListControls } from "@/components/contacts/list/hooks";
import { SecondaryBadge } from "@/components/core/Badge";
import { useLiveSearchedContactGroups } from "@/hooks/data/useLiveContactGroup";
import { useLocationsAndContacts } from "@/hooks/useLocations";

import SearchFilterOptionWrapper from "./SearchFilterOptionWrapper";

const SearchFilterGroupLocation: FC<{
  filter: Partial<ChainableFilter>;
  onFocused: (filter: Partial<ChainableFilter> | undefined) => void;
  onSelected: (filter: Partial<ChainableFilter> & { hidePopover?: boolean }) => void;
}> = ({ filter, onFocused, onSelected }) => {
  const { contactGroups, isLoaded: isContactGroupsLoaded } = useLiveSearchedContactGroups({
    query: filter.query || "",
  });

  const { flattenedLocations, isLoaded: isLocationsLoaded } = useLocationsAndContacts(
    filter.query || "",
  );

  const combinedList: (
    | (ContactGroupRowForDisplay & { rowType: "contactGroup" })
    | (LocationState & { rowType: "location" })
  )[] = useMemo(() => {
    const contactGroupRows = (contactGroups || []).map(
      (group) =>
        ({ ...group, rowType: "contactGroup" }) as ContactGroupRowForDisplay & {
          rowType: "contactGroup";
        },
    );

    const locationRows = flattenedLocations.map(
      (loc) => ({ ...loc, rowType: "location" }) as LocationState & { rowType: "location" },
    );

    return [...contactGroupRows, ...locationRows];
  }, [contactGroups, flattenedLocations]);

  const labels = ["Groups", "Locations"];

  const groupCounts = useMemo(
    () => [contactGroups?.length || 0, flattenedLocations?.length || 0],
    [contactGroups?.length, flattenedLocations?.length],
  );

  const [selected, setSelected] = useState<(typeof combinedList)[0] | undefined>(combinedList[0]);

  const { ref, currentItemIndex, onClickItem } = useGroupedListControls({
    items: combinedList,
    groupCounts,
    selectedItem: selected,
    onKeySelected: setSelected,
    enableDirKeys: true,
  });

  useEffect(() => {
    // set selected option to actual filter
    if (selected) {
      if (selected.rowType === "location") {
        onFocused({
          index: undefined,
          query: `${[selected.name, selected.stateKey].join(", ")}`,
          token: "in:",
          type: selected.rowType,
          selected: selected,
        });
      } else if (selected.rowType === "contactGroup") {
        onFocused({
          index: undefined,
          query: selected.name,
          token: "in:",
          type: selected.rowType,
          selected: selected.id,
        });
      }
    } else {
      onFocused(undefined);
    }
  }, [onFocused, selected]);

  useEffect(() => {
    if (contactGroups?.length === 0 && flattenedLocations?.length === 0) {
      onSelected({
        hidePopover: true,
      });
    }
  }, [contactGroups?.length, flattenedLocations?.length, onSelected]);

  return (
    combinedList.length > 0 && (
      <SearchFilterOptionWrapper filter={filter}>
        <GroupedVirtuoso
          ref={ref}
          style={{ minHeight: "300px" }}
          groupCounts={groupCounts}
          groupContent={(index) => {
            const count = groupCounts[index];
            return (
              <div className="flex text-sm px-2 pb-2 bg-primary">
                <span className="items-center inline-flex font-bold">{labels[index]}</span>
                <SecondaryBadge className="ml-auto">{count}</SecondaryBadge>
              </div>
            );
          }}
          itemContent={(index) => {
            const item = combinedList[index];
            const isSelected = index === currentItemIndex;
            const newFilterState: ChainableFilter =
              item?.rowType === "contactGroup"
                ? {
                    index: undefined,
                    query: item.name,
                    token: "in:",
                    type: item.rowType,
                    selected: item.id,
                  }
                : item?.rowType === "location"
                  ? {
                      index: undefined,
                      query: `${[item.name, item.stateKey].join(", ")}`,
                      token: "in:",
                      type: item.rowType,
                      selected: item,
                    }
                  : ({} as ChainableFilter);
            return (
              <div
                className={classNames(
                  "text-sm hover:bg-zinc-200 dark:hover:bg-zinc-800 p-2 rounded-md cursor-pointer",
                  isSelected && "bg-zinc-200 dark:bg-zinc-800",
                )}
                onMouseEnter={() => setSelected(item)}
                onMouseLeave={() => setSelected(undefined)}
                onClick={(e) => {
                  onClickItem(e, item, index);
                  onSelected(newFilterState);
                }}
              >
                {item?.rowType === "contactGroup"
                  ? item?.name || ""
                  : item?.rowType === "location"
                    ? `${[item.name, item.stateKey].filter(Boolean).join(", ")} ${
                        item.type === "msa" ? "(MSA)" : ""
                      }`
                    : ""}
              </div>
            );
          }}
        />
      </SearchFilterOptionWrapper>
    )
  );
};

export default SearchFilterGroupLocation;
