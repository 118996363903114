import * as Sentry from "@sentry/nextjs";
import { FC, PropsWithChildren, useEffect, useRef } from "react";

import { useNewAppVersionToast } from "@/components/core/Toast";
import EmailEditorDrawer from "@/components/email/EmailEditorDrawer";
import { useContactDedupeStore } from "@/hooks/data/useLiveContactDedupe";
import useLiveDatabase from "@/hooks/data/useLiveDatabase";
import useAuth from "@/hooks/useAuth";

import TosConsent from "./TosConsent";
/**
 * This component should only be rendered after auth has been checked.
 * @constructor
 */
const AuthedOnly: FC<PropsWithChildren> = ({ children }) => {
  useLiveDatabase();
  useNewAppVersionToast();
  useContactDedupeStore();

  return (
    <>
      <TosConsent />
      <EmailEditorDrawer />
      {children}
    </>
  );
};

const AuthedOnlyWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { session } = useAuth();

  const sentryUserSession = useRef<typeof session>();

  useEffect(() => {
    if (session && session.sub && sentryUserSession?.current?.sub !== session.sub) {
      Sentry.setUser(
        session
          ? {
              id: session.sub,
              displayName: session.displayName,
              email: session.email || "",
            }
          : {},
      );
      sentryUserSession.current = session;
    }
  }, [session]);

  return session ? <AuthedOnly>{children}</AuthedOnly> : null;
};

export default AuthedOnlyWrapper;
