import classNames from "clsx";
import type { FC, PropsWithChildren } from "react";
import React from "react";

type TypeLabelProps = {
  className?: string;
};

const TypeLabel: FC<PropsWithChildren<TypeLabelProps>> = ({ className, children }) => {
  return (
    <label
      className={classNames(
        "flex justify-end px-3 pt-3 mr-2 -mt-px uppercase rounded-md appearance-none text-secondary w-28",
        className
      )}
    >
      {children}
    </label>
  );
};

export default TypeLabel;
