export const PrimaryFontColor = "text-white dark:text-white";
export const PrimaryColor = `${PrimaryFontColor} bg-purple-700 dark:bg-purple-800 border border-transparent`;
export const PrimaryHoverColor = "hover:bg-purple-600 dark:hover:bg-purple-700";
export const PrimaryActiveColor = "bg-purple-600 dark:bg-purple-700";

export const SecondaryFontColor = "text-zinc-700 dark:text-zinc-300";
export const SecondaryColor = `${SecondaryFontColor} bg-white dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-600`;
export const SecondaryHoverColor = "hover:bg-zinc-100 dark:hover:bg-zinc-700";
export const SecondaryActiveColor = "bg-zinc-100 dark:bg-zinc-700";

export const WarningFontColor = "text-yellow-700 dark:text-yellow-200";
export const WarningColor = `${WarningFontColor} bg-yellow-100 dark:bg-yellow-900 border border-yellow-300 dark:border-yellow-600`;
export const WarningHoverColor = "hover:bg-yellow-200 dark:hover:bg-yellow-800";
export const WarningActiveColor = "bg-yellow-200 dark:bg-yellow-800";

export const SuccessFontColor = "text-green-700 dark:text-green-200";
export const SuccessColor = `${SuccessFontColor} bg-green-100 dark:bg-green-800 border border-green-300 dark:border-green-600`;
export const SuccessHoverColor = "hover:bg-green-200 dark:hover:bg-green-900";
export const SuccessActiveColor = "bg-green-200 dark:bg-green-800";

export const DangerFontColor = "text-red-700 dark:text-red-200";
export const DangerColor = `${DangerFontColor} bg-red-100 dark:bg-red-800 border border-red-300 dark:border-red-600`;
export const DangerHoverColor = "hover:bg-red-200 dark:hover:bg-red-700";

export const DangerActiveColor = "bg-red-200 dark:bg-red-800";

export const SpecialFontColor = "text-blue-700 dark:text-blue-200";
export const SpecialColor = `${SpecialFontColor} bg-blue-100 dark:bg-blue-800 border border-blue-300 dark:border-blue-600`;
export const SpecialHoverColor = "hover:bg-blue-200 dark:hover:bg-blue-900";
export const SpecialActiveColor = "bg-blue-200 dark:bg-blue-800";
