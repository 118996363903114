import { ContactRow } from "@shared/models/Contact";
import { ContactMetadataValueMap } from "@shared/models/ContactMetadata";
import classNames from "clsx";
import { Dispatch, FC, useCallback, useMemo } from "react";
import { pluralize } from "utils/string";

import TypeLabel from "@/components/contacts/details/shared/TypeLabel";
import TypeSelect from "@/components/contacts/details/shared/TypeSelect";
import {
  ContactDataFieldProps,
  UpsertContactMetadataAction,
} from "@/components/contacts/details/types";
import { ContactData } from "@/components/contacts/v2/types";
import { SecondaryBadge } from "@/components/core/Badge";
import HighlightText from "@/components/HighlightText";

const GenderField: FC<{
  contact: ContactData | Partial<ContactRow>;
  isEditing: boolean;
  setSearchQuery?: ContactDataFieldProps["setSearchQuery"];
  contactMetadataValueMap?: ContactMetadataValueMap;
  contactMetadataDispatch?: Dispatch<UpsertContactMetadataAction>;
}> = ({ contactMetadataValueMap, contactMetadataDispatch, contact, isEditing, setSearchQuery }) => {
  const genders = useMemo(() => {
    return contactMetadataValueMap?.["gender"]?.map((value) => ({
      id: String(value).toLowerCase(),
      name: value,
    }));
  }, [contactMetadataValueMap]);

  const selection = ["female", "male", "other"].map((value) => ({
    id: String(value).toLowerCase(),
    value,
  }));

  const onSelectItem = useCallback(
    async (item: { id: string; value: string }) => {
      if (contactMetadataDispatch) {
        contactMetadataDispatch({
          contactId: contact.id,
          type: "gender",
          payload: [item.id],
        });
      }
    },
    [contact.id, contactMetadataDispatch]
  );

  const onClickTag = useCallback(
    (tag: string) => {
      if (setSearchQuery) {
        setSearchQuery(tag);
      }
    },
    [setSearchQuery]
  );

  return (
    (isEditing || (genders?.length || 0) > 0) && (
      <div className={classNames("flex mt-1", isEditing ? "flex-row" : "flex-col")}>
        {isEditing ? (
          <TypeLabel className={classNames({ "-ml-3": isEditing })}>
            {pluralize((genders || []).length, "Gender", "Genders")}
          </TypeLabel>
        ) : (
          <div className="text-secondary uppercase">
            {pluralize((genders || []).length, "Gender", "Genders")}
          </div>
        )}
        <div className="flex gap-x-1">
          {isEditing ? (
            <TypeSelect
              items={selection}
              onSelectItem={onSelectItem}
              initialItemId={(genders || [])[0]?.id}
            />
          ) : (
            genders?.map((gender, i) => (
              <button key={i} onClick={() => onClickTag(gender.name)}>
                <SecondaryBadge className="capitalize">
                  <HighlightText value={gender.name} />
                </SecondaryBadge>
              </button>
            ))
          )}
        </div>
      </div>
    )
  );
};

export default GenderField;
