import classNames from "clsx";
import { ChainableFilter, KeywordFilter } from "core/types/contactSearch";
import { useLiveQuery } from "dexie-react-hooks";
import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from "react";
import { objKeys } from "utils/object";

import { filterOptionLabels, getDefaultFilterState } from "@/components/contacts/search/helpers";
import SearchFilterOptionWrapper from "@/components/contacts/search/SearchFilterOptions/SearchFilterOptionWrapper";
import { SelectedSearchFilterState } from "@/components/contacts/search/types";
import { SecondaryBadge } from "@/components/core/Badge";
import Button, { ButtonVariant } from "@/components/core/Button";
import { TrashIcon } from "@/components/core/Icon";
import { getContactDb, SearchHistoryRow } from "@/database/contactDb";
import { useKeyboardSelectControl } from "@/hooks/useListControls";

const SearchFilterSelection: FC<{
  filter: KeywordFilter & { hidePopover?: boolean };
  onSelected: (filter: Partial<ChainableFilter> & { hidePopover?: boolean }) => void;
  onFocusedFilter: (
    filter: (Partial<ChainableFilter> & { hidePopover?: boolean }) | undefined,
  ) => void;
  onFocusedSavedSearch: (savedSearch: SearchHistoryRow | undefined) => void;
  setSelectedFilters: (val: SelectedSearchFilterState) => void;
  savedSearchLimit?: number;
}> = ({
  savedSearchLimit,
  onSelected,
  onFocusedFilter,
  onFocusedSavedSearch,
  filter,
  setSelectedFilters,
}) => {
  const savedSearchListEl = useRef<HTMLUListElement>(null);

  const items = useMemo(() => {
    return objKeys(filterOptionLabels)
      .map((key) => ({
        id: key,
        label: filterOptionLabels[key],
      }))
      .filter((item) => {
        const query = filter.query?.toLowerCase() || "";
        return item.label.toLowerCase().includes(query) || item.id.includes(query);
      });
  }, [filter?.query]);

  const savedSearches = useLiveQuery(async () => {
    if (savedSearchLimit === 0) return [];
    const frontendDb = getContactDb();
    const list = await frontendDb?.searchHistory.reverse().sortBy("createdAt");
    return list?.slice(0, savedSearchLimit);
  });

  const { hoveredOptionIndex } = useKeyboardSelectControl([...(savedSearches || []), ...items]);

  useEffect(() => {
    if (hoveredOptionIndex !== undefined) {
      // is hover on saved search
      if (hoveredOptionIndex < (savedSearches?.length || 0)) {
        const savedSearch = savedSearches?.[hoveredOptionIndex];
        if (savedSearch) {
          onFocusedSavedSearch(savedSearch);
        }

        return;
      }

      const token = items[hoveredOptionIndex - (savedSearches?.length || 0)]?.id;
      if (token) {
        onFocusedFilter(getDefaultFilterState(token));
      }
    }
  }, [
    hoveredOptionIndex,
    items,
    onFocusedFilter,
    onFocusedSavedSearch,
    savedSearches,
    savedSearches?.length,
    setSelectedFilters,
  ]);

  useEffect(() => {
    if (typeof hoveredOptionIndex === "number" && savedSearchListEl.current) {
      const el = savedSearchListEl.current.querySelector(`#savedSearch-${hoveredOptionIndex}`);
      if (el) {
        el.scrollIntoView({ block: "nearest" });
      }
    }
  }, [hoveredOptionIndex]);

  const removeSavedSearch = useCallback(async (id: string) => {
    const frontendDb = getContactDb();
    return frontendDb?.searchHistory.delete(id);
  }, []);

  return (
    items.length > 0 && (
      <SearchFilterOptionWrapper filter={filter}>
        {savedSearches && savedSearches.length > 0 && (
          <>
            <h4 className="leading-1 px-2 pb-2 text-sm font-bold">
              History{" "}
              {savedSearches.length > 0 && (
                <span className="text-xs font-normal">{`(${savedSearches.length})`}</span>
              )}
            </h4>
            <ul className="max-h-[200px] overflow-y-auto" ref={savedSearchListEl}>
              {savedSearches.map((item, index) => {
                return (
                  <li
                    key={item.id}
                    id={`savedSearch-${index}`}
                    className={classNames(
                      "flex hover:bg-zinc-200 dark:hover:bg-zinc-800 p-2 rounded-md cursor-pointer space-x-2",
                      index === hoveredOptionIndex && "bg-zinc-200 dark:bg-zinc-800",
                    )}
                  >
                    <div
                      className="text-sm items-center flex flex-1 overflow-hidden"
                      onClick={() => {
                        setSelectedFilters(item.filters);
                      }}
                    >
                      {item.filters &&
                        item.filters.map((filter, index) => {
                          let label = filter.query;

                          if (filter.type === "location") {
                            label = filter.selected?.name;
                          }

                          if (!label) return null;

                          return (
                            <Fragment key={index}>
                              {filter.token && (
                                <span className="font-bold pr-1">{filter.token}</span>
                              )}
                              <span className="truncate mr-1">{label}</span>
                            </Fragment>
                          );
                        })}
                    </div>
                    <Button
                      variant={ButtonVariant.None}
                      noGutter
                      iconOnly
                      className="hover:bg-secondary p-1"
                      icon={<TrashIcon />}
                      onClick={() => removeSavedSearch(item.id)}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        )}

        <h4 className="leading-1 px-2 pb-2 mt-2 text-sm font-bold">Filter options</h4>
        <ul>
          {items.map((item, index) => {
            const combinedIndex =
              savedSearches && savedSearches.length ? index + savedSearches.length : index;
            return (
              <li
                onClick={() => {
                  const newFilter = getDefaultFilterState(item.id);
                  onFocusedFilter(newFilter);
                  onSelected(newFilter);
                }}
                key={item.id}
                className={classNames(
                  "hover:bg-zinc-200 dark:hover:bg-zinc-800 p-2 rounded-md cursor-pointer",
                  combinedIndex === hoveredOptionIndex && "bg-zinc-200 dark:bg-zinc-800",
                )}
              >
                <div className="grid grid-cols-4">
                  <div className="col-span-1">
                    <SecondaryBadge className="font-bold mr-2">{item.id}</SecondaryBadge>
                  </div>
                  <div className="col-span-3">
                    <span className="text-sm">{item.label}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </SearchFilterOptionWrapper>
    )
  );
};

export default SearchFilterSelection;
