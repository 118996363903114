import { Transition, TransitionChild } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, PropsWithChildren } from "react";

const DetailedSlideOverPanel: FC<
  PropsWithChildren<{ show: boolean; onToggle: (val: boolean) => void; title?: string }>
> = ({ show, onToggle, children, title }) => {
  return (
    <Transition show={show} as={Fragment} appear>
      <div className="absolute right-0 bottom-0 top-0 h-full w-full sm:max-w-lg">
        <TransitionChild
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="h-full p-4 bg-secondary overflow-y-auto">
            <div className="flex flex-row">
              {title && <div className="font-semibold leading-6 text-primary text-lg">{title}</div>}
              <div className="flex flex-grow items-center justify-end">
                <button className="pb-6" onClick={() => onToggle(false)}>
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>

            {children}
          </div>
        </TransitionChild>
      </div>
    </Transition>
  );
};

export default DetailedSlideOverPanel;
