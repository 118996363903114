import classNames from "clsx";
import {
  ButtonHTMLAttributes,
  cloneElement,
  FC,
  forwardRef,
  isValidElement,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
} from "react";
import { useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { twMerge } from "tailwind-merge";
import { capitalizeFirstLetter } from "utils/string";

import {
  DangerActiveColor,
  DangerColor,
  DangerHoverColor,
  PrimaryActiveColor,
  PrimaryColor,
  PrimaryHoverColor,
  SecondaryActiveColor,
  SecondaryColor,
  SecondaryHoverColor,
  SpecialActiveColor,
  SpecialColor,
  SpecialHoverColor,
  SuccessActiveColor,
  SuccessColor,
  SuccessHoverColor,
  WarningActiveColor,
  WarningColor,
  WarningHoverColor,
} from "@/components/core/colorVariant";
import { KeyboardIcon } from "@/components/core/Icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/core/Tooltip";
import { isMac } from "@/helpers/device";

export enum ButtonVariant {
  None,
  Primary,
  Secondary,
  Success,
  Warning,
  Danger,
  Special,
}

export type ButtonProps = PropsWithChildren<
  {
    full?: boolean;
    small?: boolean;
    left?: boolean;
    rounded?: boolean;
    noGutter?: boolean;
    variant?: ButtonVariant;
    endIcon?: ReactElement<HTMLElement>;
    icon?: ReactElement<HTMLElement>;
    className?: string;
    iconOnly?: boolean;
    innerRef?: MutableRefObject<HTMLElement | HTMLButtonElement | null>;
    hotkey?: string[];
    isActive?: boolean;
    wide?: boolean;
    hotkeyHandler?: (e: KeyboardEvent) => void | boolean;
    hotkeyOnFormTags?: boolean;
    tooltip?: string;
  } & ButtonHTMLAttributes<HTMLButtonElement>
>;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ButtonComponent: FC<ButtonProps> = forwardRef<any, ButtonProps>((props, ref) => {
  const {
    type = "button",
    endIcon,
    icon,
    children,
    hotkey,
    hotkeyHandler,
    onClick,
    iconOnly,
    full,
    small,
    rounded,
    variant,
    className,
    isActive,
    wide,
    noGutter,
    tooltip,
    innerRef,
    hotkeyOnFormTags,
    ...rest
  } = props;

  const { buttonClassNames } = useMemo(() => {
    return getButtonClassname(props);
  }, [props]);

  useHotkeys(
    hotkey || [],
    (e) => {
      if (hotkeyHandler) hotkeyHandler(e);
      else if (onClick) onClick(e as any);
    },
    {
      enableOnFormTags: hotkeyOnFormTags,
      enableOnContentEditable: hotkeyOnFormTags,
    },
  );

  return (
    <button ref={ref} type={type} onClick={onClick} className={buttonClassNames} {...rest}>
      {icon && isValidElement(icon) && (
        <div
          className={classNames("flex-shrink w-5 h-5 flex items-center", {
            "mr-2": !iconOnly,
          })}
          aria-hidden="true"
        >
          {cloneElement(icon, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            width: "100%",
            height: "100%",
          })}
        </div>
      )}
      <>{children}</>
      {endIcon && isValidElement(endIcon) && (
        <div
          className={classNames("flex-shrink w-5 h-5 flex items-center", {
            "ml-2": !iconOnly,
          })}
          aria-hidden="true"
        >
          {cloneElement(endIcon, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            width: "100%",
            height: "100%",
          })}
        </div>
      )}
    </button>
  );
});

ButtonComponent.displayName = "ButtonComponent";

const Button = forwardRef<any, ButtonProps>(function (props, ref) {
  const { hotkey, tooltip, ...rest } = props;

  return hotkey || tooltip ? (
    <Tooltip>
      <TooltipTrigger asChild>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ButtonComponent {...props} ref={ref} />
      </TooltipTrigger>
      <TooltipContent>
        <div className="px-1 rounded text-sm border bg-secondary text-secondary whitespace-nowrap items-center">
          {tooltip && <span className="inline-flex">{tooltip}</span>}
          {hotkey && (
            <>
              <KeyboardIcon className="mr-1.5 inline-flex" size="sm" />
              <span className="inline-flex">
                {hotkey
                  ?.map((keyStr: string) => {
                    const keys = keyStr.split("+");

                    return keys
                      .map((key) => {
                        let k = key.toLowerCase().trim();
                        if (k === "alt") k = isMac() ? "Opt" : "Alt";
                        if (k === "delete") k = "del";
                        return capitalizeFirstLetter(k);
                      })
                      .join(" + ");
                  })
                  .join(", ")}
              </span>
            </>
          )}
        </div>
      </TooltipContent>
    </Tooltip>
  ) : (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ButtonComponent {...props} ref={ref} />
    </>
  );
});
Button.displayName = "Button";

export default Button;

export function getButtonClassname(props: ButtonProps) {
  let colorClassNames = "";

  const {
    full = false,
    small = false,
    rounded = true,
    variant = ButtonVariant.Primary,
    wide = false,
    className,
    left,
    noGutter,
  } = props;

  switch (variant) {
    case ButtonVariant.Primary:
      colorClassNames = classNames(PrimaryColor, "shadow-sm", {
        [PrimaryHoverColor]: !props.disabled,
        [PrimaryActiveColor]: !props.disabled && props.isActive,
      });
      break;
    case ButtonVariant.Secondary:
      colorClassNames = classNames(SecondaryColor, "shadow-sm", {
        [SecondaryHoverColor]: !props.disabled,
        [SecondaryActiveColor]: !props.disabled && props.isActive,
      });
      break;
    case ButtonVariant.Success:
      colorClassNames = classNames(SuccessColor, "shadow-sm", {
        [SuccessHoverColor]: !props.disabled,
        [SuccessActiveColor]: !props.disabled && props.isActive,
      });

      break;
    case ButtonVariant.Warning:
      colorClassNames = classNames(WarningColor, "shadow-sm", {
        [WarningHoverColor]: !props.disabled,
        [WarningActiveColor]: !props.disabled && props.isActive,
      });

      break;
    case ButtonVariant.Danger:
      colorClassNames = classNames(DangerColor, "shadow-sm", {
        [DangerHoverColor]: !props.disabled,
        [DangerActiveColor]: !props.disabled && props.isActive,
      });
      break;

    case ButtonVariant.Special:
      colorClassNames = classNames(SpecialColor, "shadow-sm", {
        [SpecialHoverColor]: !props.disabled,
        [SpecialActiveColor]: !props.disabled && props.isActive,
      });
      break;

    default:
      colorClassNames = "border-0 filter-none";
  }

  const sizeClassNames = small
    ? classNames("text-xs", { rounded, "px-2 py-1": !noGutter })
    : classNames("text-sm", { "rounded-md": rounded, "px-2.5 py-1.5 sm:px-4 sm:py-2": !noGutter });

  return {
    buttonClassNames: twMerge(
      classNames(
        full ? "flex w-full" : "inline-flex",
        colorClassNames,
        sizeClassNames,
        "font-medium relative items-center disabled:opacity-50 disabled:cursor-default truncate",
        !left && "justify-center",
      ),
      className,
    ),
    colorClassNames,
    sizeClassNames,
  };
}
