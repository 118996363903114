import { FC } from "react";

const ContactDuplicateBadge: FC<{ dupeCount?: number }> = ({ dupeCount }) => {
  return dupeCount ? (
    <div className="inline-flex items-center px-1.5 py-0.5 rounded-full leading-4 bg-red-500 text-white text-xs">
      {dupeCount}
    </div>
  ) : null;
};

export default ContactDuplicateBadge;
