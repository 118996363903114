import { removeUndefinedFromList } from "./array";
import { NoUndefinedField } from "./types";
import deepEqual from "fast-deep-equal";

export function objKeys<T extends {}>(obj: T) {
  return Object.keys(obj) as Array<keyof typeof obj>;
}

export function removeUndefinedFromObj<T>(obj: T) {
  for (const key in obj) {
    if (typeof obj[key] === "undefined") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeUndefinedFromObj(obj[key]);
    } else if (Array.isArray(obj[key])) {
      removeUndefinedFromList(obj[key]);
    }
  }
  return obj as unknown as NoUndefinedField<T>;
}

export function emptyFalseyStrings<T>(obj: T) {
  const result = { ...obj };
  for (const key in result) {
    if (!result[key] || String(result[key]) === "null") {
      // @ts-ignore
      result[key] = "";
    }
    if (typeof result[key] === "object") {
      emptyFalseyStrings(result[key]);
    }
  }

  return result;
}

export function pickByKeys<T>(obj: T, keys: (keyof T)[]) {
  const result: Partial<T> = {};

  for (const key of keys) {
    result[key] = obj[key];
  }

  return result;
}

export function getCircularReplacer() {
  const seen = new WeakSet();
  return (key: any, value: object | null) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
}

export function isDeepEqual(a: any, b: any) {
  try {
    return deepEqual(a, b);
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function isEmpty(value: any): boolean {
  return (
    typeof value === "undefined" ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}
