import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/solid";
import * as Sentry from "@sentry/nextjs";
import React, { FC, useMemo, useState } from "react";

import AccountAddListItem from "@/components/accounts/AccountAddListItem";
import AccountAddTopNav from "@/components/accounts/AccountAddTopNav";
import { AddIntegrationWorkflowConfig, useAddGoogleAccount } from "@/components/accounts/helpers";
import PdlApiKeyModal from "@/components/accounts/PdlApiKeyModal";
import { PeopleDataLabsLogo } from "@/components/core/Logo";
import { showToast, ToastLevel } from "@/components/core/Toast";
import DetailedSlideOverPanel from "@/components/DetailedSlideOverPanel";
import DetailPanelLayout from "@/components/DetailPanelLayout";

import SyncICloudModal from "./IcloudSignInModal";

const googleTroubleshooting = [
  {
    question: `Google blocked access?`,
    answer: (
      <div className="text-sm space-y-4 py-6">
        <p>
          Occasionally, company settings prevent the addition of third-party apps without explicitly
          allowing them.
        </p>
        <img src="/troubleshooting/google/app-blocked.png" alt="app blocked" />

        <p>In this case, all you need to do is to add TitleDock in your list of trusted apps.</p>

        <ol className="list-decimal list-inside	space-y-4">
          <li>
            <a
              href="https://admin.google.com/ac/owl/list?tab=configuredApps"
              target="_blank"
              className="font-semibold text-blue-600"
            >
              Click here
            </a>{" "}
            to open Google Admin console for your organization.
          </li>
          <li>
            Once signed-in, click on <strong>Configure new app</strong> and select{" "}
            <strong>OAuth App Name Or Client ID</strong>
            <img src="/troubleshooting/google/add-oauth-app-owl.png" />
          </li>
          <li>
            Use the following Client ID for our app: <br />
            <strong className="break-all">
              710530278879-aeqbbpcqthgtcbigvdr8ei8fi4n42g6d.apps.googleusercontent.com{" "}
            </strong>{" "}
            <br />
            and click on <strong>Search</strong>.
            <img src="/troubleshooting/google/add-oauth-client-id-search.png" />
          </li>
          <li>
            Select the option <strong>Trusted: app can request access to all Google data</strong>{" "}
            and click on <strong>Continue</strong>.
            <img src="/troubleshooting/google/add-oauth-trusted-all.png" />
          </li>

          <li>
            Confirm you selections and click on <strong>Finish</strong>. Try to add your Google
            account again.
          </li>
        </ol>

        <p></p>
      </div>
    ),
  },
  // More questions...
];

const AccountAddWithSidebar: FC<{
  title?: string;
  noGutter?: boolean;
  onCancel: () => void;
  hasRemoteApi: boolean;
  filterPredicate?: (item: AddIntegrationWorkflowConfig) => boolean;
}> = ({ noGutter, title = "Add New Account", onCancel, hasRemoteApi, filterPredicate }) => {
  const [showIcloudSignin, setShowIcloudSignin] = useState(false);
  const [showGoogleHelp, setShowGoogleHelp] = useState(false);
  const [showPdlModal, setShowPdlModal] = useState(false);

  const addGoogleAccount = useAddGoogleAccount({
    onCancel,
    options: {
      onError: (error) => {
        setShowGoogleHelp(true);
        showToast({
          title: "Error adding Google account",
          description: "Please try again later.",
          level: ToastLevel.Danger,
        });
      },
      onNonOAuthError: (error) => {
        Sentry.captureException(error);
        setShowGoogleHelp(true);
      },
    },
  });

  const syncWorkflows: AddIntegrationWorkflowConfig[] = useMemo(() => {
    const allAccounts = [
      {
        name: "iCloud",
        description: "Contacts",
        iconUrl: "/logos/icloud-logo-icon.svg",
        handler: () => {
          setShowIcloudSignin(true);
        },
      },
      addGoogleAccount,
      {
        name: "People Data Labs",
        description: "Contact Enrichment",
        Icon: <PeopleDataLabsLogo />,
        handler: () => {
          setShowPdlModal(true);
        },
      },
      // {
      //   name: "Zapier",
      //   description: "Connect with 4000+ apps",
      //   iconUrl: "/logos/zapier-logo-icon.svg",
      // },
    ];

    if (filterPredicate) {
      return allAccounts.filter(filterPredicate);
    }
    return allAccounts;
  }, [addGoogleAccount, filterPredicate]);

  return (
    <DetailPanelLayout
      Nav={hasRemoteApi && <AccountAddTopNav onCancel={onCancel} />}
      Detail={
        <>
          <div className={noGutter ? "" : "px-4 sm:px-6 lg:px-8 py-6"}>
            {title && (
              <h2 className="text-lg font-semibold text-primary sm:font-bold sm:text-2xl">
                {title}
              </h2>
            )}
            <ul role="list" className="flex gap-4 mt-4">
              {syncWorkflows.map((workflow) => (
                <AccountAddListItem {...workflow} />
              ))}
            </ul>
          </div>

          <SyncICloudModal
            isModalOpen={showIcloudSignin}
            setIsClosed={setShowIcloudSignin}
            onSuccess={() => {
              // should test connectivity here if case user didn't use app spec pass
              setShowIcloudSignin(false);
              showToast({
                title: "iCloud account added",
                description: "Please wait a few minutes while we sync your data.",
                level: ToastLevel.Success,
              });
              onCancel();
            }}
          />
          <PdlApiKeyModal
            isModalOpen={showPdlModal}
            setIsClosed={setShowPdlModal}
            onSuccess={() => {
              setShowPdlModal(false);
              showToast({
                title: "People Data Labs API Key added",
                level: ToastLevel.Success,
              });
              onCancel();
            }}
          />
        </>
      }
      Side={
        <DetailedSlideOverPanel show={showGoogleHelp} onToggle={setShowGoogleHelp}>
          {googleTroubleshooting.map((faq) => (
            <Disclosure as="div" key={faq.question} defaultOpen>
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left">
                      <span className="text-base font-semibold leading-7">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2">
                    {faq.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </DetailedSlideOverPanel>
      }
    />
  );
};

export default AccountAddWithSidebar;
