import { ChangeEventHandler, Dispatch, FC, Fragment, SetStateAction, useCallback } from "react";

export type TimeInputValue = {
  hr: string;
  min: string;
  sec?: string;
  ampm: "AM" | "PM";
};

const limits = {
  hr: [1, 12],
  min: [0, 59],
  sec: [0, 59],
};

export const defaultTimeInput: TimeInputValue = {
  hr: "12",
  min: "00",
  sec: "00",
  ampm: "AM",
};

const TimeInput: FC<{
  value: TimeInputValue | undefined;
  onChange: Dispatch<SetStateAction<TimeInputValue | undefined>>;
  hideSeconds?: boolean;
}> = ({ value, onChange, hideSeconds = true }) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = useCallback(
    (e) => {
      const { dataset, value } = e.currentTarget;

      if (value.length > 2) return;

      const key = dataset.timeInput as keyof TimeInputValue;

      onChange((prev) => {
        if (!prev) {
          return {
            ...defaultTimeInput,
            [key]: value,
          };
        }

        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [onChange],
  );

  const onInputBlur: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { dataset, value } = e.currentTarget;
      const key = dataset.timeInput as keyof TimeInputValue;

      const valueNum = +value;

      if (key !== "ampm") {
        // if user enters double digits within the 24hr format, set the value to 12hr format equivalent
        if (key === "hr") {
          // set 0 to 12
          if (valueNum === 0) {
            return onChange((prev) => {
              if (!prev) {
                return {
                  ...defaultTimeInput,
                  hr: "12",
                  ampm: "AM",
                };
              }

              return {
                ...prev,
                hr: "12",
                ampm: "AM",
              };
            });
          }

          if (value.length === 2 && valueNum > 12 && valueNum < 24) {
            const hr = String(valueNum - 12).padStart(2, "0");
            return onChange((prev) => {
              if (!prev) {
                return {
                  ...defaultTimeInput,
                  hr,
                  ampm: "PM",
                };
              }

              return {
                ...prev,
                hr,
                ampm: "PM",
              };
            });
          }
        }

        // enforce limits
        const [min, max] = limits[key];

        if (valueNum < min) {
          onChange((prev) => {
            const val = String(min).padStart(2, "0");
            if (!prev) {
              return {
                ...defaultTimeInput,
                [key]: val,
              };
            }

            return {
              ...prev,
              [key]: val,
            };
          });
        } else if (valueNum > max) {
          onChange((prev) => {
            const val = String(max).padStart(2, "0");
            if (!prev) {
              return {
                ...defaultTimeInput,
                [key]: val,
              };
            }

            return {
              ...prev,
              [key]: val,
            };
          });
        } else if (value.length === 1) {
          onChange((prev) => {
            const val = value.padStart(2, "0");
            if (!prev) {
              return {
                ...defaultTimeInput,
                [key]: val,
              };
            }

            return {
              ...prev,
              [key]: val,
            };
          });
        }
      }
    },
    [onChange],
  );

  return (
    <div className="group flex items-center">
      {["hr", "min", "sec"].map((key, i) => {
        if (hideSeconds && key === "sec") return null;
        return (
          <Fragment key={key}>
            {i > 0 && <span className="flex font-bold px-2">:</span>}
            <input
              type="text"
              data-time-input={key}
              value={value ? value?.[key as keyof TimeInputValue] : ""}
              onChange={onInputChange}
              size={2}
              onBlur={onInputBlur}
              className="flex bg-transparent border border-color-primary text-sm rounded-md px-1 py-0.5 text-center"
            />
          </Fragment>
        );
      })}
      <select
        name="ampm"
        className="block w-full rounded-md border border-color-primary text-primary bg-transparent text-sm py-0.5 ml-2"
        value={value?.ampm}
        data-time-input="ampm"
        onChange={onInputChange}
      >
        <option>AM</option>
        <option>PM</option>
      </select>
    </div>
  );
};

export default TimeInput;
