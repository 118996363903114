import type { ImHandle } from "@shared/models/types";
import { IsDefault } from "@shared/models/types";
import classNames from "clsx";
import type { ChangeEvent, FC } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { ensureHttpProtocol, isUrl } from "utils/string";

import { isEmpty } from "@/helpers/array";
import { getSanitizedImHandleValue } from "@/helpers/contact";

import {
  CommentsAltIcon,
  CopySolidIcon,
  PenSolidIcon,
  StarIcon,
  StarSolidIcon,
} from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import { showToast } from "../../../core/Toast";
import HighlightText from "../../../HighlightText";
import { generateTypeSelectItems } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import type { ContextualMenuItem } from "../shared/ContextualMenuField";
import ContextualMenuField from "../shared/ContextualMenuField";
import MarkDefaultItemButton from "../shared/MarkDefaultItemButton";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeSelect from "../shared/TypeSelect";
import type { ContactDataFieldProps } from "../types";

const ImHandlesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
  focusedField,
  onFocusField,
}) => {
  const imHandles = useMemo(() => contactData?.imHandles || [], [contactData?.imHandles]);
  const imHandlesWithIndices: { imHandle: ImHandle; originalIndex: number }[] = useMemo(
    () =>
      [...imHandles]
        .map((imHandle, index) => ({ imHandle, originalIndex: index }))
        .sort((a, b) => (b.imHandle.isDefault || 0) - (a.imHandle.isDefault || 0)),
    [imHandles]
  );

  const lastImHandleRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedImHandleRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastImHandleInput = useCallback(() => {
    lastImHandleRowInputRef.current?.focus();
  }, [lastImHandleRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "imHandles") {
      focusedImHandleRowInputRef.current?.focus();
      focusedImHandleRowInputRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [focusedField]);

  // Resolve im handle services from the default list + services that are in-use
  const imServiceSelectItems = generateTypeSelectItems(
    "imHandles",
    imHandles.map((imHandle) => imHandle.service)
  );

  const updateImHandles = useCallback(
    (imHandles: ImHandle[]) => {
      if (dispatch) dispatch({ type: "imHandles", payload: imHandles });
    },
    [dispatch]
  );

  const onAddNewImHandle = (service?: ImHandle["service"]) => {
    const imHandlesToUpdate: ImHandle[] = [
      ...imHandles,
      { value: "", service: service || imServiceSelectItems[0]?.value },
    ];
    updateImHandles(imHandlesToUpdate);

    // After a short delay, focus on the last im handle input (that was just added)
    setTimeout(() => {
      focusOnLastImHandleInput();
    }, 100);
  };

  const onUpdateImHandle = ({ index, data }: { index: number; data: Partial<ImHandle> }) => {
    const imHandlesToUpdate: ImHandle[] = [...imHandles];
    imHandlesToUpdate[index] = {
      ...imHandlesToUpdate[index],
      ...data,
    };
    updateImHandles(imHandlesToUpdate);
  };

  const onRemoveImHandleAtIndex = (index: number) => {
    const imHandlesToUpdate: ImHandle[] = [...imHandles];
    imHandlesToUpdate.splice(index, 1);
    updateImHandles(imHandlesToUpdate);
  };

  const onMarkImHandleDefaultAtIndex = useCallback(
    (isDefault: boolean, index: number) => {
      // If marking a handle default, first reset isDefault state for every object in the array
      const imHandlesToUpdate: ImHandle[] = isDefault
        ? imHandles.map((o) => ({
            ...o,
            isDefault: IsDefault.NO,
          }))
        : [...imHandles];

      imHandlesToUpdate[index] = {
        ...imHandlesToUpdate[index],
        isDefault: isDefault ? IsDefault.YES : IsDefault.NO,
      };
      if (!isEditing) {
        directlyUpdateContactData?.({ imHandles: imHandlesToUpdate });
      }
      updateImHandles(imHandlesToUpdate);
    },
    [directlyUpdateContactData, imHandles, isEditing, updateImHandles]
  );

  const imHandleContextualItems = useMemo<ContextualMenuItem<ImHandle>[]>(() => {
    return [
      {
        icon: (payload) => (payload.value.isDefault ? StarSolidIcon : StarIcon),
        tooltip: (payload) => (payload.value.isDefault ? "Unmark as Default" : "Mark as Default"),
        action: (payload) => {
          if (payload.index !== undefined) {
            onMarkImHandleDefaultAtIndex(!payload.value.isDefault, payload.index);
          }
        },
        isPinned: (payload) => payload.value.isDefault === IsDefault.YES,
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy handle to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(getSanitizedImHandleValue(payload.value));
            showToast({ title: "Copied handle to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit handle",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [onFocusField, onMarkImHandleDefaultAtIndex]);

  // Do not show for read-only mode if there are no im handles
  if (!isEditing && isEmpty(imHandles)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Instant messaging handles"
      isEditing={isEditing}
      icon={<CommentsAltIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {imHandlesWithIndices.map(({ imHandle, originalIndex }, index) => {
            const isLastImHandleInput = index === imHandles.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "imHandles" && focusedField?.index === originalIndex;
            const isDefault = imHandle.isDefault === IsDefault.YES;
            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <TypeSelect
                  items={imServiceSelectItems}
                  initialItemId={imHandle.service}
                  onSelectItem={(item) =>
                    onUpdateImHandle({ index: originalIndex, data: { service: item.value } })
                  }
                  createNewItemTitle="Custom"
                />
                <TextInput
                  name="imHandle"
                  type="text"
                  value={getSanitizedImHandleValue(imHandle)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const xmppValue = event.target.value && `xmpp:${event.target.value}`;
                    onUpdateImHandle({ index: originalIndex, data: { value: xmppValue } });
                  }}
                  forwardedRef={
                    (isFocusedField && focusedImHandleRowInputRef) ||
                    (isLastImHandleInput && lastImHandleRowInputRef) ||
                    undefined
                  }
                  className="flex-1 mr-2"
                />
                <MarkDefaultItemButton
                  isDefault={isDefault}
                  onClickButton={() => onMarkImHandleDefaultAtIndex(!isDefault, originalIndex)}
                  tooltip={isDefault ? "Unmark as Default" : "Mark as Default"}
                  className={classNames(
                    "group-hover:visible",
                    isDefault ? "visible opacity-90" : "invisible opacity-70"
                  )}
                />
                <RemoveDetailsItemButton
                  onClickButton={() => onRemoveImHandleAtIndex(originalIndex)}
                  tooltip="Remove Handle"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-im-handle" className="flex flex-row w-full">
            <TypeSelect
              items={imServiceSelectItems}
              onSelectItem={(item) => onAddNewImHandle(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewImHandle()}
            >
              Add instant messenger
            </button>
          </dd>
        </>
      ) : (
        imHandlesWithIndices.map(({ imHandle, originalIndex }, index) => (
          <dd key={index}>
            <ContextualMenuField
              items={imHandleContextualItems}
              actionPayload={{
                fieldName: "imHandles",
                value: imHandle,
                index: originalIndex,
              }}
            >
              <div className="text-primary text-underline">
                {isUrl(imHandle.value) ? (
                  <a
                    href={ensureHttpProtocol(imHandle.value)}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-underline"
                  >
                    <HighlightText value={imHandle.value} />
                  </a>
                ) : (
                  <HighlightText value={getSanitizedImHandleValue(imHandle)} />
                )}
              </div>
              <div className="uppercase text-label">{imHandle.service}</div>
            </ContextualMenuField>
          </dd>
        ))
      )}
    </BaseDetailsField>
  );
};

export default ImHandlesField;
