import * as Sentry from "@sentry/nextjs";
import { DdbBoolean } from "@shared/models/types";
import { JSONContent } from "@tiptap/core";
import { EmailSendMessagePayload } from "core/services/UserMessaging/types";
import { EmailRecipient, UserMessageEmailVendor, UserMessageType } from "core/types/userMessaging";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { getMs } from "utils/dateTime";

import { isEmailSendIntegration } from "@/components/accounts/helpers";
import { ModalError } from "@/components/AppError";
import Button, { ButtonVariant } from "@/components/core/Button";
import { useCountdownToast } from "@/components/core/Toast";
import { EmailDraftContext } from "@/components/email/EmailDraftProvider";
import EmailDrawerWindowControl from "@/components/email/EmailDrawerWindowControl";
import EmailEditorPanel from "@/components/email/EmailEditorPanel";
import {
  getFromLineFromEmailDraft,
  getRfc5322FromEmailRecipient,
} from "@/components/email/helpers";
import Portal from "@/components/Portal";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";
import { queryFetch } from "@/helpers/fetch";
import TipTap2Email from "@/helpers/TipTap2Email";
import { useGetIntegrationsQuery } from "@/integrations/user/api";

const EmailEditorDrawer = () => {
  const { data: remoteApiList, isLoading } = useGetIntegrationsQuery(undefined, {
    pollingInterval: getMs("1 minute"),
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const { editDraft, drafts } = useContext(EmailDraftContext);

  const [errorBoundaryKey, setErrorBoundaryKey] = useState(Date.now());

  const remoteApiWithEmailSend = useMemo(() => {
    if (!remoteApiList || isLoading) {
      return [];
    }
    return remoteApiList?.filter(isEmailSendIntegration);
  }, [isLoading, remoteApiList]);

  const portalRef = useRef<Element>();

  const { showToast, dismissToast } = useCountdownToast({
    durationSec: 10,
  });

  const onSendEmail = useCallback(
    async (draft: LocallyPersistedEmailDraft) => {
      let payload: EmailSendMessagePayload | undefined = undefined;

      editDraft(draft.id, { _isOpenedAt: DdbBoolean.NO });

      const doSend = async () => {
        dismissToast(draft.id);
        if (payload) {
          console.log("payload", payload);
          // call schedule endpoint instead of send
          await queryFetch(`/me/messages/${draft.id}/schedule`, "POST", payload);
        }
        // todo if payload is undefined, show error toast
      };

      showToast({
        id: draft.id,
        processCallback: async () => {
          const trackingId = draft.isTracked === DdbBoolean.YES ? draft.id : undefined;
          const renderer = new TipTap2Email({ content: draft.body as JSONContent[], trackingId });
          const body = await renderer.renderAsync();

          const vendor = remoteApiWithEmailSend.find((api) => api.id === draft.remoteApiId)?.vendor;
          if (vendor === UserMessageEmailVendor.GOOGLE) {
            payload = {
              to: getRfc5322FromEmailRecipient(draft.to),
              cc: getRfc5322FromEmailRecipient(draft.cc),
              bcc: getRfc5322FromEmailRecipient(draft.bcc),
              scheduledAt: draft.scheduledAt,
              subject: draft.subject,
              from: getFromLineFromEmailDraft(draft),
              remoteApiId: draft.remoteApiId!,
              id: draft.id,
              type: UserMessageType.EMAIL,
              vendor: UserMessageEmailVendor.GOOGLE,
              body,
            };
          }
        },
        actionCallback: async () => {
          await doSend();
          // todo if payload is undefined, show error toast
        },
        Content: (
          <div className="flex items-center space-x-4">
            <div className="text-primary text-sm flex-1">
              {(payload as EmailSendMessagePayload | undefined)?.scheduledAt
                ? `${draft.subject || "Message"} scheduled`
                : `${draft.subject || "Message"} sent`}
            </div>
            <Button
              variant={ButtonVariant.Secondary}
              onClick={() => {
                dismissToast(draft.id);
                editDraft(draft.id, { _isOpenedAt: Date.now() });
              }}
            >
              Undo
            </Button>
          </div>
        ),
      });
    },
    [dismissToast, editDraft, remoteApiWithEmailSend, showToast],
  );

  const onSendMailMerge = useCallback(
    async (draft: LocallyPersistedEmailDraft, flattenedEmailRecipients: EmailRecipient[]) => {},
    [],
  );

  return (
    <>
      {(drafts?.length || 0) > 0 && (
        <Sentry.ErrorBoundary
          fallback={
            <ModalError
              onClose={() => setErrorBoundaryKey(Date.now())}
              title="Email composer"
              description="An error occurred while processing your action and has been reported to our team. Please try again."
            />
          }
        >
          <Portal
            className="absolute bottom-0 right-0 max-w-full pointer-events-none"
            getRef={(ref) => (portalRef.current = ref)}
          >
            <div className="flex flex-row-reverse overflow-x-auto w-full px-4 items-end gap-3 float-left">
              {drafts?.map((draft) => {
                const renderKey = `${draft.id}-${errorBoundaryKey}`;
                if (draft._isMinimized === DdbBoolean.YES) {
                  return (
                    <div
                      key={renderKey}
                      className="bg-secondary w-80 rounded-t-md flex px-4 py-1 items-center border border-color-primary shadow-xl cursor-pointer shrink-0 grow-0 bg-opacity-85 pointer-events-auto z-40"
                      onClick={() => {
                        editDraft(draft.id, {
                          _isMinimized: DdbBoolean.NO,
                        });
                      }}
                    >
                      <div className="flex-1 text-secondary text-sm truncate pr-4">
                        {draft.subject || "New Message"}
                      </div>
                      <EmailDrawerWindowControl draft={draft} onChangeWindowState={editDraft} />
                    </div>
                  );
                }

                if (draft._isOpenedAt > 0) {
                  return (
                    <EmailEditorPanel
                      key={renderKey}
                      draft={draft}
                      isFullWindow={draft._isFullScreened === DdbBoolean.YES}
                      onChangeWindowState={editDraft}
                      remoteApiWithEmailSend={remoteApiWithEmailSend}
                      onSendOneOffEmail={onSendEmail}
                    />
                  );
                }

                return null; // isOpenedAt is 0, means user has closed the draft editor
              })}
            </div>
          </Portal>
        </Sentry.ErrorBoundary>
      )}
    </>
  );
};

export default EmailEditorDrawer;
