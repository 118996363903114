import type { Contact, ContactRow } from "@shared/models/Contact";
import { DdbBoolean, IsDeleted, IsDoNotSync, IsReadOnly } from "@shared/models/types";
import { LocallyPersistedContactRow, SortableKeys } from "core/helpers/contact";
import { SearchableContact } from "core/types/contactSearch";
import { useLiveQuery } from "dexie-react-hooks";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getCurEpochMs } from "utils/dateTime";

import type { ContactData } from "@/components/contacts/v2/types";
import { getContactDb, SnapshotMetadata } from "@/database/contactDb";
import { frontendContactSearch, frontendProspectSearch } from "@/database/search";
import { getMappedSortKey } from "@/hooks/data/useLiveContacts";

export type ContactsContextProps = {
  contacts: ContactData[];
  snapshotMetadata: SnapshotMetadata | undefined;
  isLoaded: boolean;
  idToContact: { [id: string]: ContactData };
  lastOverrideAt: number;
  contactOverrides: { [id: string]: Partial<ContactData> };
  handleContactOverride: (id: string, contact: Partial<ContactData>) => Promise<void>;
  setContactOverrides: Dispatch<
    SetStateAction<{ [p: string]: Partial<ContactData> | ContactData }>
  >;
  editContact: (contact: Partial<ContactData> & { id: ContactRow["id"] }) => Promise<void>;
  deleteContact: (contact: ContactData) => Promise<void>;
  createContact: (contact: Contact) => Promise<void>;
};
export const ContactsContext = createContext<ContactsContextProps>({
  isLoaded: false,
  contacts: [] as ContactData[],
  idToContact: {},
  lastOverrideAt: 0,
  contactOverrides: {},
  snapshotMetadata: undefined,
} as ContactsContextProps);

const ContactsProvider: FC<
  PropsWithChildren<{
    isReadOnly?: IsReadOnly;
    isDeleted?: IsDeleted;
    sortKey?: SortableKeys;
    sortDirection?: "asc" | "desc";
  }>
> = ({
  children,
  isReadOnly = IsReadOnly.NO,
  isDeleted = IsDeleted.NO,
  sortKey = "_surnameSort",
  sortDirection = "asc",
}) => {
  const [contactOverrides, setContactOverrides] = useState<{
    [id: string]: Partial<ContactData> | ContactData;
  }>({});

  const lastOverrideAt = useRef<number>(0);

  const snapshotMetadata = useLiveQuery(async () => {
    const contactDb = getContactDb();
    return isReadOnly === IsReadOnly.YES
      ? contactDb?.snapshotMetadata.get("prospect")
      : contactDb?.snapshotMetadata.get("contact");
  }, [isReadOnly]);

  const liveContacts: ContactData[] | undefined = useLiveQuery(async () => {
    const contactDb = getContactDb();
    return sortDirection === "asc"
      ? contactDb?.contacts
          .where("[isDeleted+isReadOnly]")
          .equals([isDeleted, isReadOnly])
          .sortBy(getMappedSortKey(sortKey))
      : contactDb?.contacts
          .where("[isDeleted+isReadOnly]")
          .equals([isDeleted, isReadOnly])
          .reverse()
          .sortBy(getMappedSortKey(sortKey));
  }, [isReadOnly, isDeleted, sortKey, sortDirection]);

  const isLoaded = useMemo(() => {
    return (
      (liveContacts?.length || 0) > 0 ||
      (Boolean(liveContacts) && snapshotMetadata?.isLoading === false)
    );
  }, [liveContacts, snapshotMetadata?.isLoading]);

  const idToContact = useMemo(() => {
    console.log("building contact id to index dictionary");

    const index: { [id: string]: ContactData } = {};
    for (const contact of liveContacts || []) {
      index[contact.id] = contact;
    }

    return index;
  }, [liveContacts]);

  useEffect(() => {
    if (Object.keys(contactOverrides).length === 0) return;

    const overrideIdsUsed = new Set<string>();
    for (let contact of liveContacts || []) {
      if (contactOverrides[contact.id]) {
        contact = { ...contact, ...contactOverrides[contact.id] };
        overrideIdsUsed.add(contact.id);
      }
    }

    for (const contactId in contactOverrides) {
      if (!idToContact[contactId]) {
        idToContact[contactId] = { ...idToContact[contactId], ...contactOverrides[contactId] };
        liveContacts?.push(idToContact[contactId]);
        overrideIdsUsed.add(contactId);
      }
    }

    if (overrideIdsUsed.size > 0) {
      setContactOverrides((prev) => {
        const newState = { ...prev };
        for (const id in prev) {
          if (overrideIdsUsed.has(id)) {
            delete newState[id];
          }
        }
        return newState;
      });
    }
  }, [contactOverrides, idToContact, liveContacts]);

  const handleContactOverride = useCallback(
    async (id: string, contact: Partial<ContactData>) => {
      const searchableContact = idToContact[id] ? { ...idToContact[id], ...contact } : contact;

      if (isReadOnly === IsReadOnly.NO) {
        await frontendContactSearch?.updateAsync(id, searchableContact as SearchableContact);
      } else {
        await frontendProspectSearch?.updateAsync(id, searchableContact as SearchableContact);
      }

      setContactOverrides((prev) => {
        return {
          ...prev,
          [id]: contact,
        };
      });

      lastOverrideAt.current = getCurEpochMs();
    },
    [idToContact, isReadOnly],
  );

  const editContact = useCallback(
    async (contact: Partial<ContactData> & { id: ContactRow["id"] }) => {
      const { id, ...updated } = contact;

      await handleContactOverride(id, contact);

      const contactDb = getContactDb();

      return contactDb?.transaction(
        "rw",
        contactDb.contacts,
        contactDb.updateOps,
        contactDb.snapshotMetadata,
        async () => {
          await Promise.all([
            contactDb.contacts.update(id, updated),
            contactDb.updateOps.put({
              id,
              type: "contact",
              data: contact,
              _opCreatedAt: getCurEpochMs(),
              _opRetryCount: 0,
              _opIsDone: DdbBoolean.NO,
              _opIsInProgress: DdbBoolean.NO,
            }),
            contactDb.snapshotMetadata.update("contact", { lastOpAt: getCurEpochMs() }),
          ]);
        },
      );
    },
    [],
  );

  const deleteContact = useCallback(async (contact: ContactData) => {
    const contactDb = getContactDb();

    const { id } = contact;
    return contactDb?.transaction(
      "rw",
      contactDb.contacts,
      contactDb.deleteOps,
      contactDb.snapshotMetadata,
      async () => {
        await Promise.all([
          contactDb.contacts.delete(id),
          contactDb.deleteOps.put({
            id,
            type: "contact",
            data: contact,
            _opCreatedAt: getCurEpochMs(),
            _opRetryCount: 0,
            _opIsDone: DdbBoolean.NO,
            _opIsInProgress: DdbBoolean.NO,
          }),
          contactDb.snapshotMetadata.update("contact", { lastOpAt: getCurEpochMs() }),
        ]);
      },
    );
  }, []);

  const createContact = useCallback(async (contact: Contact) => {
    const { id } = contact;

    setContactOverrides((prev) => {
      return {
        ...prev,
        [id]: contact,
      };
    });

    const contactDb = getContactDb();

    const row = {
      ...contact,
      isDoNotSync: IsDoNotSync.NO,
      source: "user",
      isDeleted: IsDeleted.NO,
      isReadOnly: IsReadOnly.NO,
    } as LocallyPersistedContactRow;

    return contactDb?.transaction(
      "rw",
      contactDb.contacts,
      contactDb.createOps,
      contactDb.snapshotMetadata,
      async () => {
        await Promise.all([
          contactDb.contacts.put(row),
          contactDb.createOps.put({
            id,
            type: "contact",
            data: contact,
            _opCreatedAt: getCurEpochMs(),
            _opRetryCount: 0,
            _opIsDone: DdbBoolean.NO,
            _opIsInProgress: DdbBoolean.NO,
          }),
          contactDb.snapshotMetadata.update("contact", { lastOpAt: getCurEpochMs() }),
        ]);
      },
    );
  }, []);

  return (
    <ContactsContext.Provider
      value={{
        contacts: liveContacts || [],
        snapshotMetadata,
        isLoaded,
        idToContact: idToContact || {},
        contactOverrides,
        lastOverrideAt: lastOverrideAt.current,
        setContactOverrides,
        handleContactOverride,
        editContact,
        deleteContact,
        createContact,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export default ContactsProvider;
