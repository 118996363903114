import React, { FC } from "react";

import { AddIntegrationWorkflowConfig } from "@/components/accounts/helpers";

const AccountAddListItem: FC<AddIntegrationWorkflowConfig> = ({
  name,
  handler,
  description,
  Icon,
  iconUrl,
}) => {
  return (
    <li key={name} className="relative w-44">
      <button
        key={name}
        className="px-5 py-6 bg-primary w-full h-full border border-indigo-700 rounded flex flex-col items-center text-sm text-primary hover:shadow-lg"
        onClick={handler}
      >
        {iconUrl && <img className="mb-3 h-12 w-12" alt="" src={iconUrl} />}
        {Icon && <div className="mb-3 w-12">{Icon}</div>}
        <span className="flex items-center text-sm"> {name}</span>
        <span className="flex items-center text-xs"> {description}</span>
      </button>
    </li>
  );
};

export default AccountAddListItem;
