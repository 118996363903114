import classNames from "clsx";
import { FC, ReactElement } from "react";

const DetailPanelNavLayout: FC<{
  Left?: ReactElement;
  LeftMost?: ReactElement;
  Right?: ReactElement;
  className?: string;
  classNameLeft?: string;
  classNameRight?: string;
}> = ({ Left, LeftMost, Right, className, classNameRight, classNameLeft }) => {
  return (
    <div className="relative flex flex-1 h-16">
      {LeftMost}
      <div className="px-4 sm:px-6 lg:px-8 items-center flex flex-1 overflow-x-auto">
        <div className={classNames("flex justify-between flex-1", className)}>
          <div className={classNames("inline-flex rounded-md sm:space-x-6", classNameLeft)}>
            {Left}
          </div>
          <div className={classNames("inline-flex flex-1 justify-end", classNameRight)}>
            {Right}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPanelNavLayout;
