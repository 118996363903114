import { casual as dateParser, ParsedResult } from "chrono-node";
import { isValid, parse } from "date-fns";
import { ArbitraryDate } from "services/src/shared/models/types";
import { getMonthName } from "utils/dateTime";

import { MONTHS, OMIT_YEAR } from "../constants";

/**
 * Pretty print date format used by arbitrary date / birthday fields.
 * @param date Date in yyyy-MM-dd format
 * @returns Pretty printed string
 */
export function prettyPrintArbitraryDateString(date?: string): string {
  if (!date) return "";

  const [year, month, day] = date.split("-");

  return `${MONTHS[Number(month) - 1]} ${Number(day)}${
    year && String(year) !== `${OMIT_YEAR}` ? `, ${year}` : ""
  }`;
}

/**
 * Check if passed in date is a valid JS Date object
 * @param date Potential date object
 * @returns Boolean whether or not passed date is a valid Date object
 */
export function isValidDateInstance(date: any) {
  return date instanceof Date && !isNaN(date as any);
}

/**
 * Use date-fns isValid to see if date string is valid.
 * @param date Date in yyyy-MM-dd format
 * @returns Whether or not date is valid
 */
export function isValidDateString(date?: string): boolean {
  if (!date) return false;

  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  return isValid(parsedDate);
}

export function parseArbitraryDate(dateStr: ArbitraryDate["value"] | null) {
  if (!dateStr) return null;
  const [year, month, day] = dateStr.split("-");

  return {
    month,
    day,
    year: String(year) !== String(OMIT_YEAR) ? year : undefined,
  };
}

export function parseDateFromText(value: string) {
  if (!value) return [];
  const custom = dateParser.clone();
  custom.parsers.push({
    pattern: () => {
      return /([0-9]*)[^0-9]*([0-9]*)[^0-9]*([0-9]*)/gim;
    },
    extract: (context, match) => {
      const [, num1, num2, num3] = match;

      const result = [num1, num2, num3].filter(Boolean);
      const likelyYear = result.filter((num) => num.length > 2);
      const year =
        result.length > 2
          ? [...result].sort((a, b) =>
              likelyYear.length > 0 ? b.length - a.length : b.localeCompare(a)
            )[0]
          : undefined;

      return context.createParsingComponents(
        year
          ? {
              month: Number(num1),
              day: Number(num2),
              year: Number(year),
            }
          : { month: Number(num1), day: Number(num2) }
      );
    },
  });

  const firstPass = custom.parse(value);

  return firstPass.concat(custom.parse(value + " ")); // workaround to get a result without year
}

export function getDateStringFromParseResult(result: ParsedResult) {
  if (!result) return { displayValue: "", value: "" };
  const hasYear = result.start.isCertain("year");
  const monthDayDisplay = `${getMonthName({
    date: result.start.date(),
  })} ${result.start.get("day")}`;
  return {
    displayValue: hasYear ? `${monthDayDisplay} ${result.start.get("year")}` : monthDayDisplay,
    value: `${hasYear ? result.start.get("year") : OMIT_YEAR}-${result.start.get(
      "month"
    )}-${result.start.get("day")}`,
  };
}

export function getFormattedEventTime(startTimeStamp: number, endTimeStamp?: number): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const userLocale = typeof window.navigator !== "undefined" ? navigator.language : "en-US";

  const startDate = new Date(startTimeStamp);
  const endDate = endTimeStamp !== undefined ? new Date(endTimeStamp) : null;

  const dateFormatter = new Intl.DateTimeFormat(userLocale, options);
  const timeFormatter = new Intl.DateTimeFormat(userLocale, timeOptions);

  let formattedString = dateFormatter.format(startDate) + ", " + timeFormatter.format(startDate);

  if (endDate) {
    // Check if the start and end dates are the same (ignoring time)
    if (startDate.toDateString() === endDate.toDateString()) {
      formattedString += ` - ${timeFormatter.format(endDate)}`;
    } else {
      formattedString += ` - ${dateFormatter.format(endDate)}, ${timeFormatter.format(endDate)}`;
    }
  }

  return formattedString;
}
