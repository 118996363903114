import { AppState } from "../redux/store";
export const selectSelectedContactId = (state: AppState) => state.contact.selectedContactId;
export const selectMultiSelectedContacts = (state: AppState) => state.contact.multiSelectedContacts;

export const selectIsMultiSelectAddToGroupDialogOpen = (state: AppState) =>
  state.contact.isMultiSelectAddToGroupDialogOpen;

export const selectIsDeleteContactsAlertOpen = (state: AppState) =>
  state.contact.isDeleteContactsAlertOpen;
export const selectSelectedGroups = (state: AppState) => state.contact.selectedGroups;

export const selectContactDrawer = (state: AppState) => state.contact.contactDrawer;

export const selectCompanyDataQuery = (state: AppState) => state.contact.companyDataQuery;

export const selectIsContactInteractionDialogOpen = (state: AppState) =>
  state.contact.isContactInteractionDialogOpen;
