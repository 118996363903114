import type { ContactRow } from "@shared/models/Contact";
import type { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { IsReadOnly } from "@shared/models/types";
import type { LocallyPersistedContactRow, SortableKeys } from "core/helpers/contact";
import { getNameSortKeys } from "core/helpers/contact";
import { PendingContactToMerge } from "core/helpers/contactMatching";
import { searchContactBySearchFilters } from "core/helpers/contactSearch";
import { ChainableFilter, ContactSearchIndex } from "core/types/contactSearch";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import ContactList from "@/components/contacts/list";
import { SelectedSearchFilterState } from "@/components/contacts/search/types";
import { ContactData } from "@/components/contacts/v2/types";
import { ALL_ALPHABET_KEYS } from "@/constants";
import {
  frontendContactMetadataSearch,
  frontendContactSearch,
  frontendProspectSearch,
} from "@/database/search";
import { useLiveAllContactGroups } from "@/hooks/data/useLiveContactGroup";
import { useAlphabetList, useLiveSearchedContacts } from "@/hooks/data/useLiveContacts";
import { useContactMsaIndex } from "@/hooks/useLocations";
import { ContactSearchQueryContext } from "@/hooks/useSearch";
import { getIdIndex } from "@/integrations/contact/helpers";
import { selectSelectedGroups } from "@/integrations/contact/selectors";

export const useLiveContactListSearchWithFilters = ({
  isReadOnly = IsReadOnly.NO,
  idToContact,
  allContacts,
  selectedContactGroups,
  contactSearchIndex,
  lastUpdatedAt,
}: {
  isReadOnly?: IsReadOnly;
  idToContact: { [p: string]: ContactData };
  allContacts: ContactData[];
  selectedContactGroups?: ContactGroupRowForDisplay[];
  contactSearchIndex?: ContactSearchIndex;
  lastUpdatedAt?: number;
}) => {
  const retryTimer = useRef<NodeJS.Timeout>();

  const lastSearchedAt = useRef<number>(0);

  const { contactGroups } = useLiveAllContactGroups({});
  const msaIndex = useContactMsaIndex();
  const [selectedFilters, setSelectedFilters] = useState<SelectedSearchFilterState>([
    {
      type: "keyword",
      query: "",
    },
  ]);

  const [filteredContactIds, setFilteredContactIds] = useState<string[]>([]);

  const keywordFilter = useMemo(
    () => selectedFilters.find((filter) => filter.type === "keyword"),
    [selectedFilters],
  );

  const runSearch = useDebouncedCallback(
    async () => {
      if (!contactSearchIndex) {
        contactSearchIndex =
          isReadOnly === IsReadOnly.YES ? frontendProspectSearch : frontendContactSearch;
      }

      if (!contactSearchIndex) {
        if (retryTimer.current) {
          clearTimeout(retryTimer.current);
        }
        retryTimer.current = setTimeout(runSearch, 250);
        return;
      }

      const selectedContactGroupAsFilters: ChainableFilter[] =
        selectedContactGroups?.map((group) => ({
          type: "contactGroup",
          selected: group?.id,
          token: "in:",
          query: group?.name,
        })) || [];

      const filters = [
        keywordFilter,
        ...selectedContactGroupAsFilters,
        ...selectedFilters.filter((f) => f.type !== "keyword"),
      ];

      const contactIds = await searchContactBySearchFilters({
        msaIndex,
        op: "and",
        filters,
        contactSearchIndex,
        contactMetadataSearchIndex: frontendContactMetadataSearch,
        contactGroups,
      });

      const validContactIds = contactIds.filter((id) => idToContact[id]); // filter out orphaned contact ids from groups

      setFilteredContactIds(validContactIds);
      lastSearchedAt.current = Date.now();
    },
    100,
    {
      leading: false,
      trailing: true,
    },
  );

  const isShowingAllContacts = useMemo(() => {
    return selectedFilters.every((filter) => !filter.query) && !selectedContactGroups?.length;
  }, [selectedContactGroups?.length, selectedFilters]);

  useEffect(() => {
    if (
      !isShowingAllContacts ||
      (!isShowingAllContacts && lastUpdatedAt && lastUpdatedAt > lastSearchedAt.current)
    ) {
      runSearch();
    }
  }, [
    runSearch,
    selectedFilters,
    allContacts,
    selectedContactGroups,
    isShowingAllContacts,
    lastUpdatedAt,
  ]);

  const filteredContacts = useMemo(() => {
    if (!isShowingAllContacts) {
      const contacts: ContactData[] = [];

      for (const contactId of filteredContactIds) {
        contacts.push(idToContact[contactId]);
      }

      return contacts.sort((a, b) => {
        return ((a as LocallyPersistedContactRow)._surnameSort || "").localeCompare(
          (b as LocallyPersistedContactRow)._surnameSort || "",
        );
      });
    }

    return allContacts;
  }, [isShowingAllContacts, allContacts, filteredContactIds, idToContact]);

  const filteredIdToContactIndex = useMemo(() => {
    return getIdIndex(filteredContacts);
  }, [filteredContacts]);

  const setQuery = useCallback((query: string) => {
    setSelectedFilters([
      {
        type: "keyword",
        query,
      },
    ]);
  }, []);

  return {
    query: keywordFilter?.query || "",
    setQuery,
    selectedFilters,
    setSelectedFilters,
    filteredContacts,
    filteredIdToContactIndex,
    isShowingAllContacts,
  };
};

export const useLiveContactListProps = ({
  initQuery,
  sortKey,
  allContacts,
  selectedContactGroups,
  idToContact,
  isReadOnly = IsReadOnly.NO,
}: {
  isReadOnly?: IsReadOnly;
  initQuery: string;
  sortKey?: SortableKeys;
  allContacts: ContactData[];
  selectedContactGroups: ContactGroupRowForDisplay[];
  idToContact: { [p: string]: ContactData };
}) => {
  const [query, setQuery] = useState<string | undefined>(initQuery);

  const { contacts: searchedContacts } = useLiveSearchedContacts({
    query,
    sortKey,
    isReadOnly,
    idToContact,
  });

  const isShowingAllContacts = typeof searchedContacts === "undefined";

  const filteredContacts = useFilteredContactsFromSelectedGroups(
    isShowingAllContacts ? allContacts : searchedContacts,
    selectedContactGroups,
  );

  const filteredIdToContactIndex = useMemo(() => {
    return getIdIndex(filteredContacts);
  }, [filteredContacts]);

  return {
    setQuery,
    query,
    filteredContacts,
    filteredIdToContactIndex,
    isShowingAllContacts,
  };
};

/**
 * @deprecated
 * @param initQuery
 * @param selectedContact
 * @param sortKey
 * @param allContacts
 * @param isAllContactsLoaded
 * @param onClickContactItem
 * @param onKeySelectContactItem
 */
const useLiveContactList = ({
  initQuery = "",
  selectedContact,
  sortKey,
  allContacts,
  isAllContactsLoaded,
  onKeySelectContactItem,
}: {
  initQuery: string;
  sortKey: SortableKeys;
  selectedContact?: ContactRow | null;
  allContacts: ContactRow[];
  isAllContactsLoaded: boolean;
  onKeySelectContactItem?: (contact: ContactData) => void;
}) => {
  const [query, setQuery] = useState<string | undefined>(initQuery);

  const { contacts: searchedContacts } = useLiveSearchedContacts({
    query,
    sortKey,
    isReadOnly: IsReadOnly.NO,
  });

  const selectedGroups = useSelector(selectSelectedGroups);

  const contacts = useFilteredContactsFromSelectedGroups(
    typeof searchedContacts !== "undefined" ? searchedContacts : allContacts,
    selectedGroups,
  );
  const sortedContactsByLetter = useAlphabetList(contacts, sortKey);

  const SearchedContactList = useMemo(() => {
    console.log("refresh search contact list...");
    return (
      <ContactSearchQueryContext.Provider value={String(query)}>
        <ContactList
          isLoaded={isAllContactsLoaded}
          contacts={contacts}
          sortKey={sortKey}
          selectedContact={selectedContact}
          onKeySelectContactItem={onKeySelectContactItem}
          showContactsCount
        />
      </ContactSearchQueryContext.Provider>
    );
  }, [contacts, isAllContactsLoaded, onKeySelectContactItem, query, selectedContact, sortKey]);

  return {
    ContactList: SearchedContactList,
    sortedContactsByLetter,
    contacts,
    query,
    setQuery,
  };
};

export default useLiveContactList;

export function useFilteredContactsFromSelectedGroups(
  contacts: ContactData[],
  selectedGroups: ContactGroupRowForDisplay[],
) {
  const { contactGroups: groups } = useLiveAllContactGroups({});
  const latestGroups = useMemo(
    () =>
      selectedGroups
        .map((selectedGroup) => groups?.find((group) => group.id === selectedGroup.id))
        .filter(Boolean) as ContactGroupRowForDisplay[],
    [groups, selectedGroups],
  );

  return useMemo(() => {
    if (latestGroups.length > 0 && contacts) {
      return contacts?.filter((contact) => {
        for (const group of latestGroups) {
          if (group.contactIds?.includes(contact.id) === true) {
            return true;
          }
        }
        return false;
      });
    }
    return contacts;
  }, [contacts, latestGroups]);
}

export type SortedContact =
  | LocallyPersistedContactRow
  | ((ContactRow | PendingContactToMerge) & ReturnType<typeof getNameSortKeys>);

export function useSortedContactsGroupedByLetter(
  sortedContacts: SortedContact[] | undefined,
  sortKey: SortableKeys = "_surnameSort",
) {
  const sortedContactsByLetter = useMemo(() => {
    const contactsByLetter: {
      [letter: string]: SortedContact[];
    } = {};

    if (sortedContacts) {
      ALL_ALPHABET_KEYS.forEach((letter) => {
        contactsByLetter[letter] = [];
      });
      for (const contact of sortedContacts) {
        if (!contact) {
          continue;
        }

        const keyVal = contact?.[sortKey];
        const letter = String(keyVal || "")
          .charAt(0)
          .toUpperCase();
        if (!contactsByLetter[letter]) {
          contactsByLetter["#"].push(contact);
        } else contactsByLetter[letter].push(contact);
      }

      // Sort the last bucket
      const lastBucket = contactsByLetter["#"];
      if (lastBucket.length > 0) {
        contactsByLetter["#"] = lastBucket.sort((a, b) => {
          return String(a[sortKey] || "").localeCompare(b[sortKey] ? String(b[sortKey]) : "") || 0;
        });
      }

      // Remove empty buckets
      ALL_ALPHABET_KEYS.forEach((letter) => {
        if (!contactsByLetter[letter] || contactsByLetter[letter].length === 0)
          delete contactsByLetter[letter];
      });
    }

    return contactsByLetter;
  }, [sortedContacts, sortKey]);

  const sortedAlphabetKeys = useMemo(() => {
    return ALL_ALPHABET_KEYS.filter((letter) => sortedContactsByLetter[letter]);
  }, [sortedContactsByLetter]);

  const alphabetListAndCount = useMemo(() => {
    const alphabetKeyCounts: number[] = sortedAlphabetKeys.map((letter) => {
      return sortedContactsByLetter[letter].length;
    });
    return { alphabetKeys: sortedAlphabetKeys, alphabetKeyCounts };
  }, [sortedAlphabetKeys, sortedContactsByLetter]);

  const flattenedSortedContacts = useMemo(() => {
    const sortedContacts: SortedContact[] = [];
    for (const letter of sortedAlphabetKeys) {
      sortedContacts.push(...sortedContactsByLetter[letter]);
    }
    return sortedContacts;
  }, [sortedAlphabetKeys, sortedContactsByLetter]);

  return { sortedContactsByLetter, flattenedSortedContacts, ...alphabetListAndCount };
}
