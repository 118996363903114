import { getFullName, getSurnameForDisplay } from "core/helpers/contact";
import type { ChangeEvent, FC } from "react";
import { useCallback, useState } from "react";

import { isEmpty } from "@/helpers/array";

import { UserAltIcon } from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import BaseDetailsField from "../shared/BaseDetailsField";
import type { FieldSelectItem } from "../shared/FieldSelect";
import FieldSelect from "../shared/FieldSelect";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeLabel from "../shared/TypeLabel";
import type { ContactDataFieldProps } from "../types";

const NamesField: FC<ContactDataFieldProps> = ({ contactData, dispatch, isEditing }) => {
  // Required fields
  const givenName = contactData?.givenName || "";
  const middleName = contactData?.middleName || "";
  const surname = getSurnameForDisplay(contactData?.surname);

  // Optional fields
  const prefix = contactData?.prefix || "";
  const suffix = contactData?.suffix || "";
  const nickname = contactData?.nickname || "";

  const [showPrefixField, setShowPrefixField] = useState(!!prefix);
  const [showSuffixField, setShowSuffixField] = useState(!!suffix);
  const [showNicknameField, setShowNicknameField] = useState(!!nickname);

  // Assemble select items from missing optional fields
  const nameTypeSelectItems: FieldSelectItem[] = [];
  if (!showPrefixField) {
    nameTypeSelectItems.push({ value: "prefix", displayName: "Prefix" });
  }
  if (!showSuffixField) {
    nameTypeSelectItems.push({ value: "suffix", displayName: "Suffix" });
  }
  if (!showNicknameField) {
    nameTypeSelectItems.push({ value: "nickname", displayName: "Nickname" });
  }

  const onClickAddMoreFields = useCallback((value: string) => {
    if (value === "prefix") {
      setShowPrefixField(true);
    } else if (value === "suffix") {
      setShowSuffixField(true);
    } else if (value === "nickname") {
      setShowNicknameField(true);
    }
  }, []);

  const onRemovePrefixField = useCallback(() => {
    if (dispatch) dispatch({ type: "prefix", payload: undefined });
    setShowPrefixField(false);
  }, [dispatch]);

  const onRemoveSuffixField = useCallback(() => {
    if (dispatch) dispatch({ type: "suffix", payload: undefined });
    setShowSuffixField(false);
  }, [dispatch]);

  const onRemoveNicknameField = useCallback(() => {
    if (dispatch) dispatch({ type: "nickname", payload: undefined });
    setShowNicknameField(false);
  }, [dispatch]);

  return (
    <BaseDetailsField
      label="Names"
      isEditing={isEditing}
      icon={<UserAltIcon size="lg" className="icon-color-purple" />}
    >
      {!isEditing && (
        <dd>
          <span className="flex py-2 pl-2 mr-2 -my-2 -ml-2 rounded-md group hover:bg-zinc-100 dark:hover:bg-zinc-900 pr-28">
            {getFullName(contactData)}
          </span>
        </dd>
      )}
      {isEditing && (
        <>
          {/* Prefix */}
          {showPrefixField && (
            <dd key="prefix" className="flex flex-row w-full">
              <TypeLabel>Prefix</TypeLabel>
              <TextInput
                name="prefix"
                placeholder="Prefix"
                type="text"
                value={prefix}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (dispatch) dispatch({ type: "prefix", payload: event.target.value });
                }}
                className="flex-1 mr-2"
              />
              <RemoveDetailsItemButton
                onClickButton={onRemovePrefixField}
                tooltip="Remove Prefix"
              />
            </dd>
          )}

          {/* Name */}
          <dd key="givenName" className="flex flex-row w-full">
            <TypeLabel>Name</TypeLabel>
            <TextInput
              name="givenName"
              placeholder="First"
              type="text"
              value={givenName}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (dispatch) dispatch({ type: "givenName", payload: event.target.value });
              }}
              className="flex-1 mr-2"
            />
            <TextInput
              name="middleName"
              placeholder="Middle"
              type="text"
              value={middleName}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (dispatch) dispatch({ type: "middleName", payload: event.target.value });
              }}
              className="flex-1 mr-2"
            />
            <TextInput
              name="surname"
              placeholder="Last"
              type="text"
              value={surname}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (dispatch) dispatch({ type: "surname", payload: event.target.value });
              }}
              className="flex-1 mr-2"
            />
          </dd>

          {/* Suffix */}
          {showSuffixField && (
            <dd key="suffix" className="flex flex-row w-full">
              <TypeLabel>Suffix</TypeLabel>
              <TextInput
                name="suffix"
                placeholder="Suffix"
                type="text"
                value={suffix}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (dispatch) dispatch({ type: "suffix", payload: event.target.value });
                }}
                className="flex-1 mr-2"
              />
              <RemoveDetailsItemButton
                onClickButton={onRemoveSuffixField}
                tooltip="Remove Suffix"
              />
            </dd>
          )}

          {/* Nickname */}
          {showNicknameField && (
            <dd key="nickname" className="flex flex-row w-full">
              <TypeLabel>Nickname</TypeLabel>
              <TextInput
                name="nickname"
                placeholder="Nickname"
                type="text"
                value={nickname}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (dispatch) dispatch({ type: "nickname", payload: event.target.value });
                }}
                className="flex-1 mr-2"
              />
              <RemoveDetailsItemButton
                onClickButton={onRemoveNicknameField}
                tooltip="Remove Nickname"
              />
            </dd>
          )}

          {/* Add name field */}
          {!isEmpty(nameTypeSelectItems) && (
            <dd key="add-more-fields" className="flex flex-row w-full pl-28">
              <FieldSelect
                title="Add more fields..."
                items={nameTypeSelectItems}
                onSelectItemValue={onClickAddMoreFields}
                shouldResetSelection
              />
            </dd>
          )}
        </>
      )}
    </BaseDetailsField>
  );
};

export default NamesField;
