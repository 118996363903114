import * as parseAddress from "parse-address";
import { PhysicalAddress } from "services/src/shared/models/types";
import uuid from "utils/uuid";

export function parseAddressString(addressString: string, type?: string): PhysicalAddress {
  const parsed = parseAddress.parseLocation(addressString);
  return {
    id: uuid(),
    type,
    street:
      [parsed.number, parsed.prefix, parsed.street, parsed.type].filter(Boolean).join(" ") || "",
    line2: [parsed.sec_unit_type, parsed.sec_unit_num].filter(Boolean).join(" ") || "",
    city: parsed.city,
    state: parsed.state,
    postalCode: parsed.zip,
  };
}

export function formatAddressToString(address: PhysicalAddress, ignoreCountry = false): string {
  const components: (string | undefined)[] = [
    address?.street || "",
    ...(address.line2 || "").split("\\n"),
    [address.city, address.state, address.postalCode].filter(Boolean).join(", "),
  ];
  if (!ignoreCountry) {
    components.push(address.country);
  }
  return components.filter(Boolean).join(", ");
}

export function getGoogleMapsLink(address: PhysicalAddress): string {
  const spaced = [address.street, address.line2].join("%20");
  const commaDelimited = [address.city, address.postalCode, address.state, address.country].join(
    "%2C"
  );

  return `https://maps.google.com/?q=${spaced}%2C${commaDelimited}`;
}
