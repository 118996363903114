import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EmailUIState } from "./types";

const initialState: EmailUIState = {
  isEmailEditorOpen: false,
};

const emailSlice = createSlice({
  name: "emailUi",
  initialState,
  reducers: {
    setIsEmailEditorOpen: (state, action: PayloadAction<boolean>) => {
      state.isEmailEditorOpen = action.payload;
    },
  },
});

export default emailSlice;
