import createPersistedState from "use-persisted-state";

import LocalStorage from "@/helpers/localStorage";
import { DelegatedUser } from "@/integrations/user/types";

const useDelegatedUserState = createPersistedState<DelegatedUser | null>("delegatedUser");

export function useDelegatedUser() {
  const [delegatedUser, setDelegatedUser] = useDelegatedUserState();

  return {
    delegatedUser: delegatedUser,
    setDelegatedUser,
  };
}

export function getDelegatedUser() {
  const value = LocalStorage.getItem("delegatedUser");
  if (value) {
    return JSON.parse(value) as DelegatedUser;
  }
  return null;
}
