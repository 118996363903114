import { useMergeRefs } from "@floating-ui/react";
import Datepicker from "@frankleng/react-tailwindcss-datepicker";
import { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import classNames from "clsx";
import { FlattenedContactRecipientList } from "core/types/userMessaging";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { DangerBadge } from "@/components/core/Badge";
import Button, { ButtonVariant } from "@/components/core/Button";
import {
  PrimaryColor,
  PrimaryHoverColor,
  SecondaryColor,
  SecondaryHoverColor,
  SpecialColor,
  SpecialHoverColor,
} from "@/components/core/colorVariant";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarClockIcon,
  PaperPlaneTopIcon,
} from "@/components/core/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/core/Popover";
import TimeInput, { TimeInputValue } from "@/components/core/TimeInput";

const EmailSendScheduleControls: FC<{
  showSchedule: boolean;
  setShowSchedule: Dispatch<SetStateAction<boolean>>;
  flattenedRecipientList?: FlattenedContactRecipientList;
  onChange: (date: Date | undefined) => void;
  onSendOneOffEmail: () => Promise<void>;
  scheduledAt: Date | undefined;
  className?: string;
  remoteApiWithEmailSend: RemoteApiForDisplay[];
}> = ({
  showSchedule,
  setShowSchedule,
  flattenedRecipientList,
  className,
  onChange,
  onSendOneOffEmail,
  scheduledAt,
  remoteApiWithEmailSend,
}) => {
  const getDefaultTime = useCallback<(dateObj?: Date) => TimeInputValue>((dateObj) => {
    let date = dateObj;
    if (!date) {
      date = new Date();
      date.setHours(date.getHours() + 1);
    }

    const hr = date.getHours();
    return {
      hr: (hr > 12 ? hr - 12 : hr).toString().padStart(2, "0"),
      min: date.getMinutes().toString().padStart(2, "0"),
      ampm: hr >= 12 ? "PM" : "AM",
    };
  }, []);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(scheduledAt || new Date());
  const [selectedTime, setSelectedTime] = useState<TimeInputValue | undefined>(
    getDefaultTime(scheduledAt),
  );

  const [isPast, setIsPast] = useState(false);

  useLayoutEffect(() => {
    if (showSchedule) {
      setSelectedDate((prev) => {
        if (!prev) {
          return new Date();
        }
        return prev;
      });
    }
  }, [showSchedule]);

  const onDateReset = useCallback(() => {
    setSelectedDate(undefined);
    setSelectedTime(getDefaultTime());
  }, [getDefaultTime]);

  const minDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }, []);

  const scheduledDateTime = useMemo(() => {
    if (selectedDate && selectedTime) {
      let hr = +selectedTime.hr;
      // Handle edge cases for AM/PM conversion
      if (selectedTime.ampm === "AM") {
        if (hr === 12) {
          hr = 0; // 12 AM should be 0 hours
        }
      } else if (selectedTime.ampm === "PM") {
        if (hr !== 12) {
          hr = hr + 12; // PM hours except 12 PM should add 12
        }
      }

      const date = new Date(selectedDate);
      date.setHours(hr);
      date.setMinutes(+selectedTime.min);
      date.setSeconds(0);

      return date;
    }
    return undefined;
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    const isPast = scheduledDateTime ? scheduledDateTime.getTime() < Date.now() : false;
    setIsPast((prev) => (isPast !== prev ? isPast : prev));
  }, [scheduledDateTime]);

  const onSaveSchedule = useCallback(() => {
    if (scheduledDateTime) {
      if (isPast) {
        onChange(undefined);
      } else onChange(scheduledDateTime);
    } else {
      onChange(undefined);
    }
    setShowSchedule(false);
  }, [isPast, onChange, scheduledDateTime, setShowSchedule]);

  const isControlsDisabled = useMemo(() => {
    return remoteApiWithEmailSend.length === 0 || flattenedRecipientList?.flattenedContactList
      ? flattenedRecipientList?.flattenedContactList.length === 0
      : false;
  }, [flattenedRecipientList?.flattenedContactList, remoteApiWithEmailSend.length]);

  const onClickSend = useCallback(async () => {
    if (isControlsDisabled || !flattenedRecipientList) return;
    if (flattenedRecipientList.isMailMerge) {
      // send mail merge
    } else {
      await onSendOneOffEmail();
    }
  }, [flattenedRecipientList, isControlsDisabled, onSendOneOffEmail]);

  const triggerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const ref = useMergeRefs<HTMLDivElement>([triggerRef, contentRef]);

  return (
    <div className={classNames(className, "flex-nowrap flex-1")}>
      <Popover
        placement="top-start"
        open={showSchedule}
        onOpenChange={(open) => {
          if (!open) {
            setShowSchedule(false);
            onDateReset();
          }
        }}
      >
        <PopoverContent className="z-50" ref={ref}>
          <Datepicker
            show
            value={selectedDate}
            options={{
              autoHide: false,
              datepickerClassNames: "static flex-col pt-0 mb-2",
              minDate,
              theme: {
                background:
                  "bg-zinc-100 dark:bg-zinc-900 border border-color-primary border-t-0 rounded-t-none p-4 pt-0",
                todayBtn: classNames(SpecialColor, SpecialHoverColor),
                clearBtn: classNames(SecondaryColor, SecondaryHoverColor),
                icons: classNames("bg-zinc-100 dark:bg-zinc-900", SecondaryHoverColor),
                text: "text-secondary hover:bg-blue-800",
                disabledText: "opacity-20",
                input: "",
                inputIcon: "",
                selected: classNames(
                  "text-primary border border-color-primary",
                  PrimaryColor,
                  PrimaryHoverColor,
                ),
              },
              icons: {
                prev: () => <ArrowLeftIcon size="sm" />,
                next: () => <ArrowRightIcon size="sm" />,
              },
            }}
            setShow={setShowSchedule}
            onChange={setSelectedDate}
            onClear={onDateReset}
          >
            <div
              className="flex-1 space-y-3 items-center w-full flex flex-col bg-secondary px-4 py-2 text-secondary text-sm rounded-md rounded-b-none border border-color-primary"
              ref={contentRef}
            >
              <h2 className="leading-4">Select Time and Date</h2>
              <TimeInput value={selectedTime} onChange={setSelectedTime} />
              {isPast && <DangerBadge>Time is in the past</DangerBadge>}
            </div>
          </Datepicker>
        </PopoverContent>
        <PopoverTrigger asChild>
          <div className="flex flex-nowrap shrink-0 items-center space-x-4 flex-1" ref={triggerRef}>
            {!showSchedule && (
              <div>
                <Button
                  variant={ButtonVariant.Primary}
                  rounded={false}
                  className="rounded-l-md"
                  icon={<PaperPlaneTopIcon size="lg" />}
                  disabled={isControlsDisabled}
                  onClick={onClickSend}
                >
                  {flattenedRecipientList?.flattenedContactList &&
                  flattenedRecipientList?.flattenedContactList.length > 1 &&
                  flattenedRecipientList.isMailMerge
                    ? `${scheduledAt ? "Schedule" : "Send"} All (${flattenedRecipientList.flattenedContactList.length})`
                    : `${scheduledAt ? "Schedule" : "Send"}`}
                </Button>
                <Button
                  onClick={() => setShowSchedule((prev) => !prev)}
                  variant={ButtonVariant.Secondary}
                  className={showSchedule ? "rounded-md" : "rounded-r-md"}
                  rounded={false}
                  icon={<CalendarClockIcon size="lg" />}
                  tooltip="Schedule"
                  iconOnly
                  disabled={isControlsDisabled}
                />
              </div>
            )}

            {showSchedule && !isControlsDisabled && (
              <>
                <div className="flex flex-nowrap space-x-4 flex-1">
                  <Button
                    variant={ButtonVariant.Secondary}
                    onClick={() => {
                      onDateReset();
                      setShowSchedule(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={scheduledDateTime ? ButtonVariant.Success : ButtonVariant.Warning}
                    onClick={onSaveSchedule}
                  >
                    {scheduledDateTime ? "Save Schedule" : "Remove Schedule"}
                  </Button>
                </div>
              </>
            )}
          </div>
        </PopoverTrigger>
      </Popover>
    </div>
  );
};

export default EmailSendScheduleControls;
