import Header from "@/components/core/Header";
import useCollaboratorInvite from "@/components/tools/collaborate/useCollaboratorInvite";
import { useGetInvitesQuery, useGetUserQuery } from "@/integrations/user/api";

import CollaboratorUserTable from "./CollaboratorUserTable";

export default function Collaborate() {
  const { data: userProfileData } = useGetUserQuery();
  const userDelegations = userProfileData?.userDelegations || []; // shared with me
  const collaborators = userProfileData?.collaborators || []; // invited by me, and accepted
  const { data: pendingCollaborators } = useGetInvitesQuery();

  const { InviteModal, InviteButton } = useCollaboratorInvite({
    defaultOpen: userDelegations.length === 0,
  });

  return (
    <div className="w-full">
      <div className="container mx-auto my-8">
        <Header
          title="Collaborators"
          details="Invite others to manage your contacts."
          className="p-container"
        >
          {InviteButton}
        </Header>

        <div className="flex flex-col">
          <div className="inline-block min-w-full align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              {(collaborators.length > 0 || (pendingCollaborators || []).length > 0) && (
                <CollaboratorUserTable
                  userDelegations={collaborators}
                  invites={pendingCollaborators}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {userDelegations.length > 0 && (
        <div className="container mx-auto mt-8 overflow-y-auto">
          <Header
            title="Shared with me"
            // details="Invite others to manage your contacts."
            className="p-container"
          />
          <div className="flex flex-col">
            <div className="inline-block min-w-full align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <CollaboratorUserTable userDelegations={userDelegations} />
              </div>
            </div>
          </div>
        </div>
      )}
      {InviteModal}
    </div>
  );
}
