import classNames from "clsx";
import type { ChangeEventHandler, FC, FocusEventHandler, MutableRefObject } from "react";
import TextareaAutosize from "react-textarea-autosize";

type TextAreaProps = {
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  prefix?: string | JSX.Element;
  autoComplete?: string;
  singleColumn?: boolean;
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
  className?: string;
  forwardedRef?: MutableRefObject<HTMLTextAreaElement | null>;
  autoFocus?: boolean;
};

const TextArea: FC<TextAreaProps> = ({
  name,
  id,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  prefix,
  autoComplete,
  singleColumn = true,
  minRows = 2,
  maxRows,
  disabled = false,
  className,
  forwardedRef,
  autoFocus,
}) => {
  return (
    <div
      className={classNames(
        "relative space-y-1",
        singleColumn ? "" : "sm:grid sm:grid-cols-3 sm:gap-4",
        className
      )}
    >
      {/* Optional label */}
      {label && (
        <label
          htmlFor={name}
          aria-label={label}
          className={classNames(
            "block text-sm font-medium",
            singleColumn
              ? "text-zinc-500 dark:text-zinc-400"
              : "pt-0 sm:pt-3 text-zinc-800 dark:text-zinc-200"
          )}
        >
          {label}
        </label>
      )}

      {/* Main text area element */}
      <div className={singleColumn ? "relative" : "relative sm:col-span-2 pt-0 mt-0"}>
        {prefix && (
          <div className="absolute left-0 flex items-center h-full pl-3 pointer-events-none">
            <span className="text-zinc-400 text-md dark:text-zinc-600">{prefix}</span>
          </div>
        )}

        <TextareaAutosize
          autoFocus={autoFocus}
          id={id || name}
          minRows={minRows}
          maxRows={maxRows}
          autoComplete={autoComplete}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
          ref={forwardedRef}
          value={value}
          onChange={onChange}
          className={classNames(
            `whitespace-pre-line appearance-none text-input-primary resize-none block w-full py-2 border-primary
            rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500`,
            prefix ? "pl-10" : "pl-3"
          )}
        />
      </div>
    </div>
  );
};

export default TextArea;
