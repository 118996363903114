import type { FC } from "react";
import { useMemo } from "react";

import { prettyPrintArbitraryDateString } from "@/helpers/date";

import { BirthdayCakeIcon } from "../../../core/Icon";
import SimpleDateInput from "../../../core/SimpleDateInput";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import type { ContactDataFieldProps } from "../types";

const BirthdayField: FC<ContactDataFieldProps> = ({
  contactMetadataDispatch,
  contactMetadataValueMap,
  contactData,
  dispatch,
  isEditing,
}) => {
  const birthday = useMemo(() => contactData?.birthday || "", [contactData?.birthday]);
  if (!isEditing && !birthday) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Birthday"
      isEditing={isEditing}
      icon={<BirthdayCakeIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <dd key="birthday" className="flex flex-row min-w-0">
          <TypeLabel>Birthday</TypeLabel>
          <div className="flex-1">
            <SimpleDateInput
              name="birthday"
              initialDateString={prettyPrintArbitraryDateString(birthday)}
              onChangeDateString={(dateString) => {
                if (dispatch) dispatch({ type: "birthday", payload: dateString });
              }}
              contactMetadataDispatch={contactMetadataDispatch}
              contactMetadataValueMap={contactMetadataValueMap}
            />
          </div>
        </dd>
      ) : (
        <dd className="text-primary">{prettyPrintArbitraryDateString(birthday)}</dd>
      )}
    </BaseDetailsField>
  );
};

export default BirthdayField;
