import {
  ContactGroup,
  ContactGroupRow,
  ContactGroupRowForDisplay,
} from "@shared/models/ContactGroup";
import { IsAutoFilling } from "@shared/models/types";

export function isSmartGroup(
  contactGroup: ContactGroup | ContactGroupRow | ContactGroupRowForDisplay | undefined
) {
  if (!contactGroup) return false;
  return Boolean(contactGroup?.isAutoFilling === IsAutoFilling.YES);
}

export function isComboGroup(
  contactGroup: ContactGroup | ContactGroupRow | ContactGroupRowForDisplay | undefined
) {
  if (!contactGroup) return false;
  return Object.keys(contactGroup?.subGroupIds || {}).length > 0;
}

export function isIntersectGroup(
  contactGroup: ContactGroup | ContactGroupRow | ContactGroupRowForDisplay | undefined
) {
  if (!contactGroup) return false;
  return Object.keys(contactGroup?.intersectGroupIds || {}).length > 0;
}

export function isAnyComboGroup(
  contactGroup: ContactGroup | ContactGroupRow | ContactGroupRowForDisplay | undefined
) {
  return isComboGroup(contactGroup) || isIntersectGroup(contactGroup);
}

export function getContactGroupContactIdsAsComboIntersect(
  contactGroups: ContactGroupRowForDisplay[]
) {
  const uniqueContactIds = new Set<string>();
  const contactIdCount: { [contactId: string]: number } = {};

  for (const group of contactGroups) {
    for (const contactId of group.contactIds) {
      uniqueContactIds.add(contactId);
      if (typeof contactIdCount[contactId] === "undefined") {
        contactIdCount[contactId] = 1;
      } else {
        contactIdCount[contactId]++;
      }
    }
  }

  const intersectContactIds: string[] = [];
  for (const contactId in contactIdCount) {
    if (contactIdCount[contactId] === contactGroups.length) {
      intersectContactIds.push(contactId);
    }
  }

  return {
    subGroupContactIds: Array.from(uniqueContactIds),
    intersectGroupContactIds: intersectContactIds,
  };
}

/**
 * Get the contact ids for a contact group, including subgroups and intersect groups
 * @param contactGroup
 * @param allContactGroups
 */
export function getFilledContactGroup(
  contactGroup: ContactGroupRowForDisplay,
  allContactGroups: ContactGroupRowForDisplay[]
) {
  const contactIds = new Set(contactGroup.contactIds);

  const orphanedSubGroupIds = new Set<string>();
  const orphanedIntersectGroupIds = new Set<string>();

  const contactGroupById: { [id: string]: ContactGroupRowForDisplay } = {};
  for (const group of allContactGroups) {
    contactGroupById[group.id] = group;
  }

  for (const subGroupId in contactGroup.subGroupIds) {
    const subGroup = contactGroupById[subGroupId];
    if (!subGroup) {
      orphanedSubGroupIds.add(subGroupId);
      continue;
    }

    for (const contactId of subGroup.contactIds) {
      contactIds.add(contactId);
    }
  }

  const contactIdCountForIntersect: { [contactId: string]: number } = {};
  for (const intersectGroupId in contactGroup.intersectGroupIds) {
    const intersectGroup = contactGroupById[intersectGroupId];

    if (!intersectGroup) {
      orphanedIntersectGroupIds.add(intersectGroupId);
      continue;
    }

    for (const contactId of intersectGroup.contactIds) {
      if (!contactIdCountForIntersect[contactId]) {
        contactIdCountForIntersect[contactId] = 1;
      } else {
        contactIdCountForIntersect[contactId]++;
      }
    }
  }

  for (const contactId in contactIdCountForIntersect) {
    if (
      contactIdCountForIntersect[contactId] ===
      Object.keys(contactGroup.intersectGroupIds || {}).length
    ) {
      contactIds.add(contactId);
    }
  }

  return {
    contactGroup: {
      ...contactGroup,
      contactIds: [...contactIds],
    },
    orphanedSubGroupIds,
    orphanedIntersectGroupIds,
  };
}
