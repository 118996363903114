import { getEntityId } from "@shared/helpers/contact";
import { IsDeleted } from "@shared/models/types";
import classNames from "clsx";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import type { ContactGroupRowForDisplay } from "services/src/shared/models/ContactGroup";
import uuid from "utils/uuid";

import { isEmpty } from "@/helpers/array";
import { getContactsRoute } from "@/helpers/routes";
import { useLiveAllContactGroups } from "@/hooks/data/useLiveContactGroup";
import contactSlice from "@/integrations/contact/slice";
import { useAppDispatch } from "@/integrations/redux/store";

import { TagIcon } from "../../../core/Icon";
import type { TagItem } from "../../../core/Tag";
import TagsInput from "../../../core/TagsInput";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import type { ContactDataFieldProps } from "../types";

type GroupsFieldProps = {
  createGroup: (group: Partial<ContactGroupRowForDisplay>) => void;
  addContactToGroup: (contactId: string, group: ContactGroupRowForDisplay) => void;
  removeContactFromGroup: (contactId: string, group: ContactGroupRowForDisplay) => void;
};

const GroupsField: FC<ContactDataFieldProps & GroupsFieldProps> = ({
  contactData,
  isEditing,
  createGroup,
  addContactToGroup,
  removeContactFromGroup,
}) => {
  const { contactGroups: groups } = useLiveAllContactGroups({
    doNotReturnComboGroupContactIds: true,
  });

  const dispatch = useAppDispatch();
  const router = useRouter();
  const currentPathName = router?.pathname;

  const contactId = contactData?.id || "";

  const [tagItems, suggestions] = useMemo<[TagItem[], TagItem[]]>(() => {
    if (!contactId || isEmpty(groups)) {
      return [[], []];
    }

    // Create tags list from groups that contain this contact ID
    const tagItems: TagItem[] = groups
      .filter((group) => (group.contactIds || []).includes(contactId))
      .map((group) => ({ id: group.id, name: group.name }));

    // Create suggestions list from groups that don't contain this contact ID
    const suggestions: TagItem[] = groups
      .filter((group) => !(group.contactIds || []).includes(contactId))
      .map((group) => ({ id: group.id, name: group.name }));

    return [tagItems, suggestions];
  }, [contactId, groups]);

  const onClickTag = useCallback(
    (tagItem: TagItem) => {
      const tagGroup = groups.find((o) => o.id === tagItem.id);
      if (tagGroup) {
        dispatch(contactSlice.actions.setSelectedGroups({ groups: [tagGroup] }));

        // If we are not currently showing /contacts route, navigate to it
        if (getContactsRoute({}).isCurrent?.(currentPathName) !== true) {
          router.push("/contacts", undefined, { shallow: true });
        }
      }
    },
    [currentPathName, dispatch, groups, router]
  );

  const onAddTag = useCallback(
    (tagId: string) => {
      const matchedSuggestion = groups.find((group) => group.id === tagId);
      if (matchedSuggestion) {
        addContactToGroup(contactId, matchedSuggestion);
      }
    },
    [addContactToGroup, contactId, groups]
  );

  const onCreateTag = useCallback(
    (name: string) => {
      const existingGroup = groups?.find((o) => o.name.toLowerCase() === name.toLowerCase());
      if (existingGroup) {
        // This group already exists, add contactId to it instead of creating a new group
        onAddTag(existingGroup.id);
        return;
      }

      createGroup({
        id: getEntityId({ id: uuid(), isContactGroup: true, remoteApiId: "" }),
        name,
        contactIds: [contactId],
        isDeleted: IsDeleted.NO,
      });
    },
    [contactId, createGroup, groups, onAddTag]
  );

  const onRemoveTag = useCallback(
    (tagId: string) => {
      const groupToRemove = groups.find((group) => group.id === tagId);
      if (groupToRemove) {
        removeContactFromGroup(contactId, groupToRemove);
      }
    },
    [contactId, groups, removeContactFromGroup]
  );

  // Don't render if empty contactId
  if (!contactId) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Groups"
      icon={<TagIcon size="lg" className={classNames("icon-color-purple")} />}
    >
      <div className="flex flex-row">
        {isEditing && <TypeLabel className="-ml-3">Groups</TypeLabel>}
        <TagsInput
          tagItems={tagItems}
          suggestions={suggestions}
          onClickTag={onClickTag}
          onCreateTag={onCreateTag}
          onAddTag={onAddTag}
          onRemoveTag={onRemoveTag}
          placeholder="Add a new group"
        />
      </div>
    </BaseDetailsField>
  );
};

export default GroupsField;
