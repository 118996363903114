import { Transition } from "@headlessui/react";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import type { ContactVersion } from "services/src/shared/models/ContactVersion";

import ContactVersionRow from "@/components/contacts/details/history/ContactVersionRow";
import { ContactData } from "@/components/contacts/v2/types";
import { ClockRotateLeftIcon } from "@/components/core/Icon";
import { isEmpty } from "@/helpers/array";
import type { ContactVersionOperationGroups } from "@/helpers/operation";
import { createOperationGroups } from "@/helpers/operation";
import { useGetContactChangelogQuery } from "@/integrations/contact/api";

import RestoreContactConfirmationAlert from "./RestoreContactConfirmationAlert";
import UndoChangesConfirmationAlert from "./UndoChangesConfirmationAlert";

type ContactHistoryDrawerProps = {
  contact: ContactData;
  undoVersion?: (version: ContactVersion) => any;
  rollbackToVersion?: (version: ContactVersion) => any;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (open: boolean) => void;
};

const ContactHistoryDrawer: FC<ContactHistoryDrawerProps> = ({
  contact,
  undoVersion,
  rollbackToVersion,
  isDrawerOpen,
}) => {
  // API
  const { data: versions } = useGetContactChangelogQuery(contact.id, { skip: !isDrawerOpen });

  // For each version, calculate operation groups to show
  const versionOperationGroupsList = useMemo(() => {
    if (versions && isDrawerOpen) {
      const result: ContactVersionOperationGroups[] = [];
      const sortedArray = [...versions];
      sortedArray.sort((obj1, obj2) => obj2.createdAt - obj1.createdAt);
      sortedArray?.forEach((version) => {
        const groups = createOperationGroups(version.contactDiff || []);
        if (!isEmpty(groups) || version.isNew) {
          result.push({ version, groups });
        }
      });
      return result;
    } else {
      return [];
    }
  }, [isDrawerOpen, versions]);

  const [selectedVersionOperationGroups, setSelectedVersionOperationGroups] =
    useState<ContactVersionOperationGroups | null>(null);
  const [isUndoAlertOpen, setIsUndoAlertOpen] = useState(false);
  const [isRestoreAlertOpen, setIsRestoreAlertOpen] = useState(false);

  const onClickUndo = useCallback(() => {
    if (selectedVersionOperationGroups && undoVersion) {
      undoVersion(selectedVersionOperationGroups.version);
    }
  }, [selectedVersionOperationGroups, undoVersion]);

  const onClickRestore = useCallback(() => {
    if (selectedVersionOperationGroups && rollbackToVersion) {
      rollbackToVersion(selectedVersionOperationGroups.version);
    }
  }, [selectedVersionOperationGroups, rollbackToVersion]);

  return (
    <Transition
      as="div"
      show={isDrawerOpen}
      className="w-1/2 overflow-y-auto"
      enter="transform transition ease-linear duration-250"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-linear duration-250"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex items-center px-4 sm:px-6">
          <ClockRotateLeftIcon className="w-6 h-6 icon-color-purple" />
          <h3 className="pl-3 text-lg font-semibold text-secondary">Contact History</h3>
        </div>

        {/* Main content */}
        {typeof versions === "undefined" ? (
          <span className="p-4 sm:p-6">Loading…</span>
        ) : (
          <div className="flex flex-col flex-1">
            <ul role="list" className="px-4 py-6 sm:px-6">
              {versionOperationGroupsList.map((versionOperationGroups, index) => (
                <ContactVersionRow
                  key={index}
                  versionOperationGroups={versionOperationGroups}
                  showSpine={index !== versionOperationGroupsList.length - 1}
                  showUndoOption={!versionOperationGroups.version.isNew}
                  onClickUndo={() => {
                    setSelectedVersionOperationGroups(versionOperationGroups);
                    setIsUndoAlertOpen(true);
                  }}
                  showRestoreOption={index !== 0}
                  onClickRestore={() => {
                    setSelectedVersionOperationGroups(versionOperationGroups);
                    setIsRestoreAlertOpen(true);
                  }}
                />
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Undo confirmation alert */}
      <UndoChangesConfirmationAlert
        versionOperationGroups={selectedVersionOperationGroups}
        isUndoAlertOpen={isUndoAlertOpen}
        setIsUndoAlertOpen={setIsUndoAlertOpen}
        onClickUndo={onClickUndo}
        onClickCancel={() => setIsUndoAlertOpen(false)}
      />

      {/* Restore confirmation alert */}
      <RestoreContactConfirmationAlert
        currentContact={contact}
        versionOperationGroups={selectedVersionOperationGroups}
        isRestoreAlertOpen={isRestoreAlertOpen}
        setIsRestoreAlertOpen={setIsRestoreAlertOpen}
        onClickRestore={onClickRestore}
        onClickCancel={() => setIsUndoAlertOpen(false)}
      />
    </Transition>
  );
};

export default ContactHistoryDrawer;
