import highlightWords from "highlight-words";
import Linkify from "linkify-react";
import type { FC, PropsWithChildren } from "react";
import { Fragment, useContext, useMemo } from "react";
import { escapeRegExp, removeSpecialChars } from "utils/string";

import { ContactSearchQueryContext } from "../hooks/useSearch";

const LinkParser: FC<PropsWithChildren<{ shouldParseLinks?: boolean }>> = ({
  shouldParseLinks,
  children,
}) => {
  return shouldParseLinks ? (
    <Linkify options={{ className: "text-blue-600 text-underline" }}>{children}</Linkify>
  ) : (
    <>{children}</>
  );
};

const HighlightChunks: FC<{
  value: string | undefined | null;
  highlight?: string;
  needle?: string;
  matchPhrase?: boolean;
  matchWithoutSpecialChar?: boolean;
}> = ({ value, highlight, matchPhrase = false, matchWithoutSpecialChar, needle }) => {
  const contextSearchQuery = useContext(ContactSearchQueryContext);
  const searchQuery = highlight?.trim() || contextSearchQuery.trim();
  const escapedSearchQuery = useMemo(() => {
    const result = escapeRegExp(searchQuery);
    if (matchWithoutSpecialChar) return removeSpecialChars(result);
    return result;
  }, [matchWithoutSpecialChar, searchQuery]);

  const highlights = useMemo(() => {
    if (needle && highlight && needle === highlight) {
      return [<mark>{value}</mark>];
    }

    const chunks = highlightWords({
      text: value || "",
      query: escapedSearchQuery,
      matchExactly: matchPhrase,
    });

    const marks = [];

    for (const [i, chunk] of chunks.entries()) {
      const { text, match, key } = chunk;
      let shouldMark = match || needle === text || escapedSearchQuery === text;
      if (matchWithoutSpecialChar && !shouldMark) {
        const textWithoutSpecialChar = removeSpecialChars(text);
        shouldMark =
          (escapedSearchQuery && escapedSearchQuery === textWithoutSpecialChar) ||
          needle === textWithoutSpecialChar;
      }

      if (shouldMark) {
        marks.push(<mark>{text}</mark>);

        const next = chunks[i + 1];
        if (next?.text && next.text.startsWith(" ")) {
          marks.push(<>&nbsp;</>);
        }
      } else {
        marks.push(text.startsWith(" ") ? text.slice(1) : text);
      }
    }

    return marks;
  }, [escapedSearchQuery, highlight, matchPhrase, matchWithoutSpecialChar, needle, value]);

  return highlights.map((highlight, i) => <Fragment key={i}>{highlight}</Fragment>);
};

export default HighlightChunks;
