import {
  ContactMetadataRow,
  ContactMetadataRowForDisplay,
  ContactMetadataValueMap,
} from "@shared/models/ContactMetadata";
import { DdbBoolean, IsReadOnly } from "@shared/models/types";
import { getCombinedSearchResult } from "core/helpers/contactSearch";
import { useLiveQuery } from "dexie-react-hooks";
import debounce from "lodash/debounce";
import { Dispatch, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { getCurEpochMs, getMs } from "utils/dateTime";

import GenderField from "@/components/contacts/details/fields/components/GenderField";
import IndustryField from "@/components/contacts/details/fields/components/IndustryField";
import {
  ContactDataFieldProps,
  UpsertContactMetadataAction,
} from "@/components/contacts/details/types";
import type { ContactData, RelatedContacts } from "@/components/contacts/v2/types";
import { ContactEmailMetadataRow, getContactDb, LocalDbSyncOp } from "@/database/contactDb";
import { getIndexableString } from "@/database/helpers";
import { frontendContactSearch } from "@/database/search";
import { queryFetch } from "@/helpers/fetch";

export async function getEmailMetadata(emails: string[] | undefined) {
  const frontendDb = getContactDb();
  if (!emails) return [];

  const emailMetadata = await frontendDb?.contactEmailMetadata
    .where("email")
    .anyOf(emails)
    .toArray();

  const emailsToFetch: string[] = [];

  for (const email of emails) {
    const metadata = emailMetadata?.find((metadata) => metadata.email === email);
    if (!metadata) {
      emailsToFetch.push(email);
    }
  }

  if (emailsToFetch.length > 0) {
    const results = await queryFetch("/contact/email/metadata", "POST", emailsToFetch);
    const metadata = (results.data as ContactEmailMetadataRow[]) || [];
    frontendDb?.contactEmailMetadata.bulkPut(metadata);

    return [...(emailMetadata || []), ...metadata];
  }

  return emailMetadata;
}

export function useLiveRelatedContacts(contact: ContactData | undefined) {
  const [relatedContacts, setRelatedContacts] = useState<{
    [contactId: string]: RelatedContacts;
  }>({});

  const emails = useMemo(
    () => contact?.emails?.map(({ value }) => value),
    [contact?.emails, contact?.id],
  );

  const getRelatedContactsByEmail = useCallback(async () => {
    const emailMetadata = await getEmailMetadata(emails);
    const nonDisposableEmails = emailMetadata?.filter((metadata) => !metadata.isDisposable) || [];

    const relatedContactIds: Set<string> = new Set();

    await Promise.allSettled(
      nonDisposableEmails.map(async (metadata) => {
        const contacts = await frontendContactSearch?.searchAsync(metadata.domain, {
          limit: 20000,
          index: ["emails[]:value"],
        });

        if (contacts) {
          const contactIds = getCombinedSearchResult([contacts]);
          for (const contactId in contactIds) {
            relatedContactIds.add(contactId);
          }
        }
      }),
    );

    const frontendDb = getContactDb();
    return frontendDb?.contacts
      .where("id")
      .anyOf([...relatedContactIds])
      .toArray();
  }, [emails]);

  const getRelatedContactsByName = useCallback(async () => {
    if (!contact?.givenName && !contact?.surname) return;

    const frontendDb = getContactDb();

    return frontendDb?.contacts
      .where("[_givenName+_surname]")
      .equals([
        getIndexableString(contact?.givenName || ""),
        getIndexableString(contact?.surname || ""),
      ])
      .toArray();
  }, [contact?.givenName, contact?.surname]);

  const getRelatedContactsByCompanyName = useCallback(async () => {
    if (!contact?.companyName) return;

    const contacts = await frontendContactSearch?.searchAsync(contact?.companyName, {
      limit: 20000,
      index: ["companyName"],
    });

    if (!contacts) return;

    const frontendDb = getContactDb();
    return frontendDb?.contacts.bulkGet(Object.keys(getCombinedSearchResult([contacts])));
  }, [contact?.companyName]);

  const setAllRelatedContacts = useCallback(async () => {
    const [byEmail, byName, byCompanyName] = await Promise.all([
      getRelatedContactsByEmail(),
      getRelatedContactsByName(),
      getRelatedContactsByCompanyName(),
    ]);

    const email: { [id: string]: ContactData } = {};
    const name: { [id: string]: ContactData } = {};
    const companyName: { [id: string]: ContactData } = {};

    for (const c of byName || []) {
      if (c.id === contact?.id || c.isReadOnly === IsReadOnly.YES) continue;
      name[c.id] = c;
    }

    for (const c of byEmail || []) {
      if (c.id === contact?.id || c.isReadOnly === IsReadOnly.YES) continue;
      if (!name[c.id]) email[c.id] = c;
    }

    for (const c of byCompanyName || []) {
      if (!c || c?.id === contact?.id || c?.isReadOnly === IsReadOnly.YES) continue;
      if (!name[c?.id || ""] && !email[c?.id || ""]) companyName[c?.id || ""] = c;
    }

    if (
      contact?.id &&
      (Object.keys(email).length > 0 ||
        Object.keys(name).length > 0 ||
        Object.keys(companyName).length > 0)
    ) {
      setRelatedContacts((prevState) => {
        return {
          ...(prevState || {}),
          [contact?.id]: {
            email,
            name,
            companyName,
          },
        };
      });
    } else {
      setRelatedContacts({});
    }
  }, [
    contact?.id,
    getRelatedContactsByCompanyName,
    getRelatedContactsByEmail,
    getRelatedContactsByName,
  ]);

  useEffect(() => {
    setAllRelatedContacts();
  }, [contact?.id, setAllRelatedContacts]);

  return relatedContacts[contact?.id || ""] || undefined;
}

export const loadPartialContactMetadataUpdate = debounce(
  async (updatedAt?: number) => {
    const frontendDb = getContactDb();
    if (!updatedAt) {
      const snapshotMetadata = await frontendDb?.snapshotMetadata.get("contactMetadata");
      const { snapshotCreatedAt, updateFetchedAt } = snapshotMetadata || {};
      updatedAt = Math.max(snapshotCreatedAt || 0, updateFetchedAt || 0);
      if (updatedAt === 0) return;
    }

    const curTimestamp = getCurEpochMs() - getMs("30s");
    const result = await queryFetch<{
      updated: ContactMetadataRowForDisplay[];
      deleted: ContactMetadataRowForDisplay[];
    }>(`/contacts/metadata?updatedAt=${updatedAt}`);

    await frontendDb?.transaction(
      "rw",
      frontendDb.contactMetadata,
      frontendDb.snapshotMetadata,
      async () => {
        const { updated, deleted } = result.data || {};

        console.log("contactMetadata updated", updated);
        console.log("contactMetadata deleted", deleted);

        if (updated && updated.length > 0) {
          await frontendDb.contactMetadata.bulkPut(updated);
        }

        if (deleted && deleted.length > 0) {
          await frontendDb.contactMetadata.bulkDelete(
            deleted.map((c) => `${c.contactId}_${c.type}`),
          );
        }

        return frontendDb.snapshotMetadata.update("contactMetadata", {
          updateFetchedAt: curTimestamp,
        });
      },
    );
  },
  getMs("2s"),
  {
    leading: false,
    trailing: true,
  },
);

export function useContactMetadata(contactId?: string) {
  const contactMetadata = useLiveQuery(async () => {
    if (!contactId) return [];
    const frontendDb = getContactDb();
    return frontendDb?.contactMetadata.where("contactId").equals(contactId).toArray();
  }, [contactId]);

  const contactMetadataValueMap = useMemo(() => {
    const result: ContactMetadataValueMap = {};
    for (const metadata of contactMetadata || []) {
      result[metadata.type as keyof ContactMetadataValueMap] = metadata.values as any;
    }
    return result;
  }, [contactMetadata]);

  const editContactMetadata = useCallback(
    async (
      contactId: string,
      type: ContactMetadataRow["type"],
      updated: Partial<ContactMetadataRow>,
    ) => {
      const frontendDb = getContactDb();

      return frontendDb?.transaction(
        "rw",
        frontendDb.contactMetadata,
        frontendDb.updateOps,
        async () => {
          await frontendDb.contactMetadata.update(`${contactId}_${type}`, updated);
          await frontendDb.updateOps.put({
            id: `${contactId}_${type}`,
            type: "contactMetadata",
            data: {
              ...updated,
              contactId,
              type,
            } as Partial<ContactMetadataRow>,
            _opCreatedAt: getCurEpochMs(),
            _opRetryCount: 0,
            _opIsDone: DdbBoolean.NO,
            _opIsInProgress: DdbBoolean.NO,
          });
        },
      );
    },
    [],
  );

  const createContactMetadata = useCallback(async (row: ContactMetadataRow) => {
    const frontendDb = getContactDb();
    return frontendDb?.transaction(
      "rw",
      frontendDb.contactMetadata,
      frontendDb.createOps,
      async () => {
        await frontendDb.contactMetadata.put(row);
        await frontendDb.createOps.put({
          id: `${row.contactId}_${row.type}`,
          type: "contactMetadata",
          data: {
            ...row,
          },
          _opCreatedAt: getCurEpochMs(),
          _opRetryCount: 0,
          _opIsDone: DdbBoolean.NO,
          _opIsInProgress: DdbBoolean.NO,
        });
      },
    );
  }, []);

  const bulkUpsertContactMetadata = useCallback(async (rows: Partial<ContactMetadataRow>[]) => {
    const frontendDb = getContactDb();
    return frontendDb?.transaction(
      "rw",
      frontendDb.contactMetadata,
      frontendDb.createOps,
      async () => {
        const createOps: LocalDbSyncOp[] = rows.map((row) => {
          return {
            id: `${row.contactId}_${row.type}`,
            type: "contactMetadata",
            data: {
              ...row,
            },
            _opCreatedAt: getCurEpochMs(),
            _opRetryCount: 0,
            _opIsDone: DdbBoolean.NO,
            _opIsInProgress: DdbBoolean.NO,
          };
        });

        await frontendDb.contactMetadata.bulkPut(rows as ContactMetadataRow[]);
        await frontendDb.createOps.bulkPut(createOps);
      },
    );
  }, []);

  const purgeContactMetadata = useCallback(
    async (contactId: string, type: Required<ContactMetadataRow["type"]>) => {
      const frontendDb = getContactDb();
      return frontendDb?.transaction(
        "rw",
        frontendDb.contactMetadata,
        frontendDb.deleteOps,
        async () => {
          await frontendDb.contactMetadata.delete(`${contactId}_${type}`);
          await frontendDb.deleteOps.put({
            id: `${contactId}_${type}`,
            type: "contactMetadata",
            data: {
              contactId,
              type,
            } as Partial<ContactMetadataRow>,
            _opCreatedAt: getCurEpochMs(),
            _opRetryCount: 0,
            _opIsDone: DdbBoolean.NO,
            _opIsInProgress: DdbBoolean.NO,
          });
        },
      );
    },
    [],
  );

  const bulkPurgeContactMetadata = useCallback(
    async (rows: { contactId: string; type: string }[]) => {
      const frontendDb = getContactDb();
      return frontendDb?.transaction(
        "rw",
        frontendDb.contactMetadata,
        frontendDb.deleteOps,
        async () => {
          await frontendDb?.contactMetadata.bulkDelete(
            rows.map((row) => {
              return `${row.contactId}_${row.type}`;
            }),
          );

          await frontendDb.deleteOps.bulkPut(
            rows.map(({ contactId, type }) => {
              return {
                id: `${contactId}_${type}`,
                type: "contactMetadata",
                data: {
                  contactId,
                  type,
                } as Partial<ContactMetadataRow>,
                _opCreatedAt: getCurEpochMs(),
                _opRetryCount: 0,
                _opIsDone: DdbBoolean.NO,
                _opIsInProgress: DdbBoolean.NO,
              };
            }),
          );
        },
      );
    },
    [],
  );

  return {
    contactMetadata,
    contactMetadataValueMap,
    editContactMetadata,
    createContactMetadata,
    bulkUpsertContactMetadata,
    purgeContactMetadata,
    bulkPurgeContactMetadata,
  };
}

const userFacingMetadataFields = ["industry", "gender"];
export function useContactMetadataFields({
  contact,
  isEditing,
  setSearchQuery,
  contactMetadataValueMap,
  contactMetadataDispatch,
}: {
  contact: ContactData;
  isEditing: boolean;
  setSearchQuery: ContactDataFieldProps["setSearchQuery"];
  contactMetadataValueMap?: ContactMetadataValueMap;
  contactMetadataDispatch?: Dispatch<UpsertContactMetadataAction>;
}) {
  return useMemo(() => {
    const contactMetadataIndex: { [type: string]: ReactElement } = {};

    const types = isEditing ? userFacingMetadataFields : Object.keys(contactMetadataValueMap || {});

    for (const type of types) {
      switch (type) {
        case "industry":
          contactMetadataIndex[type] = (
            <IndustryField
              contactMetadataValueMap={contactMetadataValueMap}
              isEditing={isEditing}
              setSearchQuery={setSearchQuery}
              contactMetadataDispatch={contactMetadataDispatch}
            />
          );
          break;
        case "gender":
          contactMetadataIndex[type] = (
            <GenderField
              contact={contact}
              contactMetadataValueMap={contactMetadataValueMap}
              isEditing={isEditing}
              setSearchQuery={setSearchQuery}
              contactMetadataDispatch={contactMetadataDispatch}
            />
          );
      }
    }

    return contactMetadataIndex;
  }, [contact, contactMetadataDispatch, contactMetadataValueMap, isEditing, setSearchQuery]);
}
