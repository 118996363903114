import classNames from "clsx";
import type { LocallyPersistedContactRow } from "core/helpers/contact";
import { FC, RefObject } from "react";

import ContactListItem from "@/components/contacts/list/ContactListItem";
import { useContactListControls } from "@/components/contacts/list/hooks";
import { ContactListProps } from "@/components/contacts/list/types";
import GroupedVirtualizedList from "@/components/GroupedVirtualizedList";
import { useSortedContactsGroupedByLetter } from "@/hooks/data/useLiveContactList";

import AlphabetList from "./AlphabetList";

const ContactList: FC<ContactListProps> = ({
  isLoaded,
  contacts,
  sortKey,
  selectedContact,
  showContactsCount = false,
  onKeySelectContactItem,
  className,
  noTopBorder,
}) => {
  const { alphabetKeys, alphabetKeyCounts, flattenedSortedContacts } =
    useSortedContactsGroupedByLetter(contacts as LocallyPersistedContactRow[], sortKey);

  const {
    wrapperRef,
    ref,
    multiSelectedContacts,
    onClickItem,
    onClickToSelectItem,
    currentItemIndex,
    onSelectAlphabetKey,
  } = useContactListControls({
    contacts: flattenedSortedContacts,
    selectedContact,
    onKeySelectContactItem,
    groupCounts: alphabetKeyCounts,
  });

  return (
    <div
      className={classNames(
        "relative flex flex-1 min-h-0 overflow-x-hidden overflow-y-auto h-full",
        className,
      )}
      ref={wrapperRef as RefObject<HTMLDivElement>}
    >
      {/* Main contact list */}
      <nav id="contacts" className="w-full h-full" aria-label="Contacts">
        <GroupedVirtualizedList
          noTopBorder={noTopBorder}
          showGroupCount
          refHandle={ref}
          items={flattenedSortedContacts}
          selectedItem={selectedContact}
          labelCounts={alphabetKeyCounts}
          labels={alphabetKeys}
          isLoaded={isLoaded}
          showItemCount={showContactsCount}
          itemContent={(index) => {
            const contact = flattenedSortedContacts[index];
            return (
              <ContactListItem
                contact={contact}
                sortKey={sortKey}
                isSelected={
                  multiSelectedContacts && multiSelectedContacts.length > 0
                    ? multiSelectedContacts.some((c) => c.id === contact.id)
                    : index === currentItemIndex
                }
                onClickItem={(event) => {
                  if (onClickItem) onClickItem(event, contact, index);
                }}
                onClickToSelectItem={() => {
                  onClickToSelectItem(contact);
                }}
                isMultiSelected={multiSelectedContacts.some((c) => c.id === contact.id)}
              />
            );
          }}
        />
      </nav>

      {/* Alphabet list overlay */}
      <div className="h-full relative z-10 px-1 pt-16 pb-20 -ml-8 overflow-y-auto">
        {alphabetKeys?.length > 1 && (
          <AlphabetList alphabetKeys={alphabetKeys} onSelectAlphabetKey={onSelectAlphabetKey} />
        )}
      </div>
    </div>
  );
};

export default ContactList;
