import * as Sentry from "@sentry/nextjs";
import { DdbBoolean, IsDeleted } from "@shared/models/types";
import { LocallyPersistedContactRow } from "core/helpers/contact";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { getCurEpochMs, getMs } from "utils/dateTime";

import { getContactDb, LocalDbSyncOp } from "@/database/contactDb";
import { removeSupplementalFields } from "@/database/helpers";
import {
  bulkIndexContactGroupRowsInSearch,
  bulkIndexContactMetadataRowsInSearch,
  bulkIndexDbRowsInContactSearch,
  indexableContactMetadataFields,
  resetFrontendContactSearch,
} from "@/database/search";
import { queryFetch } from "@/helpers/fetch";
import { loadPartialContactMetadataUpdate } from "@/hooks/data/useContactMetadataFields";
import { loadPartialContactGroupUpdate } from "@/hooks/data/useLiveContactGroup";
import { getDelegatedUser, useDelegatedUser } from "@/hooks/useDelegatedUser";
import { getAuth } from "@/integrations/firebase";
import TitledockWebSocket from "@/integrations/titledockWS";

export async function setOpIsInProgress({
  updated,
  deleted,
  created,
}: {
  created?: LocalDbSyncOp[];
  updated?: LocalDbSyncOp[];
  deleted?: LocalDbSyncOp[];
}) {
  const frontendDb = getContactDb();
  return frontendDb?.transaction(
    "rw",
    frontendDb.createOps,
    frontendDb.updateOps,
    frontendDb.deleteOps,
    async () => {
      if (created) {
        await Promise.all(
          created.map(({ id }) =>
            frontendDb.createOps.update(id, {
              _opIsInProgress: DdbBoolean.YES,
            }),
          ),
        );
      }

      if (updated) {
        await Promise.all(
          updated.map(({ id }) =>
            frontendDb.updateOps.update(id, { _opIsInProgress: DdbBoolean.YES }),
          ),
        );
      }

      if (deleted) {
        await Promise.all(
          deleted.map(({ id }) =>
            frontendDb.deleteOps.update(id, { _opIsInProgress: DdbBoolean.YES }),
          ),
        );
      }
    },
  );
}

async function unsetInProgressOps() {
  const frontendDb = getContactDb();
  if (!frontendDb) return;
  if (!window.navigator.onLine)
    return frontendDb.transaction(
      "rw",
      frontendDb.createOps,
      frontendDb.updateOps,
      frontendDb.deleteOps,
      async () => {
        return Promise.all([
          frontendDb.createOps.where({ _opIsInProgress: DdbBoolean.YES }).modify({
            _opIsInProgress: DdbBoolean.NO,
          }),
          frontendDb.updateOps.where({ _opIsInProgress: DdbBoolean.YES }).modify({
            _opIsInProgress: DdbBoolean.NO,
          }),
          frontendDb.deleteOps.where({ _opIsInProgress: DdbBoolean.YES }).modify({
            _opIsInProgress: DdbBoolean.NO,
          }),
        ]);
      },
    );
}

async function updateDedupeOps(
  dedupeOps: {
    key: string;
    type: "created" | "deleted" | "updated";
    changes: Partial<LocalDbSyncOp>;
  }[],
) {
  const frontendDb = getContactDb();
  if (!frontendDb) return;
  return frontendDb.transaction(
    "rw",
    frontendDb.createOps,
    frontendDb.deleteOps,
    frontendDb.updateOps,
    async () => {
      await Promise.all(
        dedupeOps
          .filter(({ type }) => type === "created")
          .map(({ key, changes }) => frontendDb.createOps.update(key, changes)),
      );

      await Promise.all(
        dedupeOps
          .filter(({ type }) => type === "deleted")
          .map(({ key, changes }) => frontendDb.deleteOps.update(key, changes)),
      );

      await Promise.all(
        dedupeOps
          .filter(({ type }) => type === "updated")
          .map(({ key, changes }) => frontendDb.updateOps.update(key, changes)),
      );
    },
  );
}

async function resetDataTables() {
  const frontendDb = getContactDb();
  return Promise.allSettled([
    frontendDb?.contacts.clear(),
    frontendDb?.contactGroups.clear(),
    frontendDb?.contactInteraction.clear(),
    frontendDb?.pendingContacts.clear(),
    frontendDb?.contactMetadata.clear(),
    frontendDb?.snapshotMetadata.clear(),
    frontendDb?.duplicates.clear(),
    frontendDb?.msaIdIndex.clear(),
    frontendDb?.msaCityIndex.clear(),
  ]);
}

export const opHandlers: {
  [type in LocalDbSyncOp["type"]]?: { [op: string]: (op: LocalDbSyncOp) => Promise<any> };
} = {
  contact: {
    create: async (op: LocalDbSyncOp) => {
      if (op.type !== "contact") return;
      const frontendDb = getContactDb();
      await queryFetch(`/contacts`, "POST", removeSupplementalFields(op.data));
      await frontendDb?.createOps.delete(op.id); // delete for now, if we need to display list of queued ops we can update _isOpDone to 1
    },
    update: async (op: LocalDbSyncOp) => {
      if (op.type !== "contact") return;
      const frontendDb = getContactDb();
      await queryFetch(`/contacts/${op.id}`, "POST", removeSupplementalFields(op.data));
      await frontendDb?.updateOps.delete(op.id);
    },
    purge: async (op: LocalDbSyncOp) => {
      if (op.type !== "contact") return;
      const frontendDb = getContactDb();
      await queryFetch(`/contacts/${op.id}`, "DELETE");
      await frontendDb?.deleteOps.delete(op.id);
    },
  },
  contactMetadata: {
    upsert: async (op: LocalDbSyncOp) => {
      if (op.type !== "contactMetadata") return;
      const frontendDb = getContactDb();
      await queryFetch(`/contacts/${op.data.contactId}/metadata`, "POST", op.data);
      await frontendDb?.createOps.delete(op.id); // delete for now, if we need to display list of queued ops we can update _isOpDone to 1
    },
    purge: async (op: LocalDbSyncOp) => {
      if (op.type !== "contactMetadata") return;
      const frontendDb = getContactDb();
      await queryFetch(`/contacts/${op.data.contactId}/${op.data.type}`, "DELETE");
      await frontendDb?.deleteOps.delete(op.id);
    },
  },
  contactGroup: {
    create: async (op: LocalDbSyncOp) => {
      if (op.type !== "contactGroup") return;
      const frontendDb = getContactDb();
      await queryFetch(`/contacts/groups`, "POST", op.data);
      await frontendDb?.createOps.delete(op.id); // delete for now, if we need to display list of queued ops we can update _isOpDone to 1
    },
    update: async (op: LocalDbSyncOp) => {
      if (op.type !== "contactGroup") return;
      const frontendDb = getContactDb();

      await queryFetch(`/contacts/groups/${op.id}`, "POST", op.data);
      await frontendDb?.updateOps.delete(op.id);
    },
    purge: async (op: LocalDbSyncOp) => {
      if (op.type !== "contactGroup") return;
      const frontendDb = getContactDb();
      await queryFetch(`/contacts/groups/${op.id}`, "DELETE");
      await frontendDb?.deleteOps.delete(op.id);
    },
  },
};

function useSyncOps() {
  const updatedOps = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return frontendDb?.updateOps
      .where("[_opIsDone+_opIsInProgress]")
      .equals([DdbBoolean.NO, DdbBoolean.NO])
      .toArray();
  });

  const deletedOps = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return frontendDb?.deleteOps
      .where("[_opIsDone+_opIsInProgress]")
      .equals([DdbBoolean.NO, DdbBoolean.NO])
      .toArray();
  });

  const createdOps = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return frontendDb?.createOps
      .where("[_opIsDone+_opIsInProgress]")
      .equals([DdbBoolean.NO, DdbBoolean.NO])
      .toArray();
  });

  const handleOps = useCallback(async () => {
    if (!window.navigator.onLine) return;

    // mark as in progress so next run doesn't queue up the same ops
    await setOpIsInProgress({
      created: createdOps,
      updated: updatedOps,
      deleted: deletedOps,
    });

    const failedOps: {
      created: LocalDbSyncOp[];
      updated: LocalDbSyncOp[];
      deleted: LocalDbSyncOp[];
    } = {
      created: [],
      updated: [],
      deleted: [],
    };

    // call td api to process
    for (const created of createdOps || []) {
      if (!window.navigator.onLine) {
        await unsetInProgressOps();
        return;
      }
      try {
        const handler = opHandlers[created.type]?.create || opHandlers[created.type]?.upsert;
        if (handler) {
          await handler(created);
        }
      } catch (e) {
        Sentry.captureException(e);
        failedOps.created.push(created);
      }
    }

    for (const updated of updatedOps || []) {
      if (!window.navigator.onLine) {
        await unsetInProgressOps();
        return;
      }

      try {
        const handler = opHandlers[updated.type]?.update || opHandlers[updated.type]?.upsert;
        if (handler) {
          await handler(updated);
        }
      } catch (e) {
        Sentry.captureException(e);
        failedOps.updated.push(updated);
      }
    }

    for (const deleted of deletedOps || []) {
      if (!window.navigator.onLine) {
        await unsetInProgressOps();
        return;
      }

      try {
        const handler = opHandlers[deleted.type]?.purge;
        if (handler) {
          await handler(deleted);
        }
      } catch (e) {
        Sentry.captureException(e);
        failedOps.deleted.push(deleted);
      }
    }

    const failedOpsToUpdate: {
      key: string;
      type: keyof typeof failedOps;
      changes: Partial<LocalDbSyncOp>;
    }[] = [];
    for (const type in failedOps) {
      for (const failedOp of failedOps[type as keyof typeof failedOps]) {
        failedOpsToUpdate.push({
          key: failedOp.id,
          type: type as keyof typeof failedOps,
          changes: {
            _opIsInProgress: DdbBoolean.NO,
            _opIsDone: DdbBoolean.NO,
            _opRetryCount: failedOp._opRetryCount + 1,
          },
        });
      }
    }
    await updateDedupeOps(failedOpsToUpdate);
  }, [createdOps, deletedOps, updatedOps]);

  useEffect(() => {
    handleOps();
  }, [handleOps]);
}

function useContactDb() {
  const contactSnapshotMeta = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return frontendDb?.snapshotMetadata.get("contact");
  });

  const bulkIndexContactRows = useCallback(async (contactIds?: string[]) => {
    const frontendDb = getContactDb();

    if (contactIds) {
      const contacts = await frontendDb?.contacts.bulkGet(contactIds);
      if (contacts && contacts.length > 0) {
        await bulkIndexDbRowsInContactSearch(contacts as LocallyPersistedContactRow[]);
      }
      return;
    }

    // if search is not initialized, likely page was refreshed and search instance was lost
    // need to recreate search instance and reindex all contacts
    const contacts = await frontendDb?.contacts.where("isDeleted").equals(IsDeleted.NO).toArray();
    if (contacts && contacts.length > 0) {
      await bulkIndexDbRowsInContactSearch(contacts as LocallyPersistedContactRow[]);
    }
  }, []);

  const bulkIndexContactMetadata = useCallback(async () => {
    const frontendDb = getContactDb();

    const contactMetadataRows = await frontendDb?.contactMetadata
      .where("type")
      .anyOfIgnoreCase(indexableContactMetadataFields)
      .toArray();

    if (contactMetadataRows && contactMetadataRows.length > 0) {
      await bulkIndexContactMetadataRowsInSearch(contactMetadataRows);
    }
  }, []);

  const contactSnapshotWorker = useRef<Worker | null>(null);
  const contactMetadataSnapshotWorker = useRef<Worker | null>(null);

  useLayoutEffect(() => {
    contactSnapshotWorker.current = new Worker(
      new URL("../../workers/contactSnapshot.worker.ts", import.meta.url),
    );

    contactSnapshotWorker.current.onmessage = async (
      event: MessageEvent<{
        type: "contactFieldsSnapshot" | "contactNotesSnapshot" | "prospectSnapshot";
        contactIds: string[];
      }>,
    ) => {
      await bulkIndexContactRows(event.data.contactIds);
    };

    contactMetadataSnapshotWorker.current = new Worker(
      new URL("../../workers/contactMetadataSnapshot.worker.ts", import.meta.url),
    );

    contactMetadataSnapshotWorker.current.onmessage = async (
      event: MessageEvent<{
        contactMetadata: {
          success: boolean;
          error: Error | undefined;
        };
      }>,
    ) => {
      if (event.data.contactMetadata.success) {
        await bulkIndexContactMetadata();
      } else {
        console.error("Error updating contact metadata snapshot", event.data);
      }
    };

    return () => {
      contactSnapshotWorker.current?.terminate();
      contactMetadataSnapshotWorker.current?.terminate();
    };
  }, []);

  const loadContactAndProspectSnapshots = useDebouncedCallback(
    async () => {
      const auth = getAuth();
      if (!auth) return;

      const delegatedUser = getDelegatedUser();
      const authToken = await auth.currentUser?.getIdToken();
      if (authToken)
        contactSnapshotWorker.current?.postMessage({
          authToken,
          baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
          cdnBaseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL,
          delegatedUserId: delegatedUser?.delegatedUserId,
        });
    },
    getMs("5s"),
    {
      leading: false,
      trailing: true,
    },
  );

  const loadContactMetadataSnapshots = useDebouncedCallback(
    async () => {
      const auth = getAuth();
      if (!auth) return;

      const delegatedUser = getDelegatedUser();
      const authToken = await auth.currentUser?.getIdToken();
      if (authToken)
        contactMetadataSnapshotWorker.current?.postMessage({
          authToken,
          baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
          cdnBaseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL,
          delegatedUserId: delegatedUser?.delegatedUserId,
        });
    },
    getMs("5s"),
    {
      leading: false,
      trailing: true,
    },
  );

  const loadPartialContactUpdate = useDebouncedCallback(
    async () => {
      const auth = getAuth();
      if (!auth) return;

      const delegatedUser = getDelegatedUser();
      const authToken = await auth.currentUser?.getIdToken();
      if (authToken)
        contactSnapshotWorker.current?.postMessage({
          authToken,
          baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
          cdnBaseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL,
          delegatedUserId: delegatedUser?.delegatedUserId,
          isPartialUpdate: true,
        });
    },
    getMs("3s"),
    {
      leading: false,
      trailing: true,
    },
  );

  const loadAllContactSnapshots = useCallback(async () => {
    const frontendDb = getContactDb();

    // if search is not initialized, likely page was refreshed and search instance was lost
    // need to recreate search instance and reindex all contacts
    await bulkIndexContactRows();
    await bulkIndexContactMetadata();

    const contactSnapshotParams = await frontendDb?.snapshotMetadata.get("contact");
    const contactMetadataSnapshotParams = await frontendDb?.snapshotMetadata.get("contactMetadata");

    try {
      if (
        !contactSnapshotParams ||
        !contactSnapshotParams.snapshotCreatedAt ||
        Number(contactSnapshotParams.updateFetchedAt) <= Date.now() - getMs("4hr") ||
        Number(contactSnapshotParams.snapshotCreatedAt) <= Date.now() - getMs("4hr")
      ) {
        console.log("initial snapshot loading...");
        return Promise.all([loadContactAndProspectSnapshots(), loadContactMetadataSnapshots()]);
      } else if (
        !contactMetadataSnapshotParams ||
        !contactMetadataSnapshotParams.snapshotCreatedAt
      ) {
        return loadContactMetadataSnapshots();
      } else {
        await loadPartialContactUpdate();
        await loadPartialContactMetadataUpdate();
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }, [
    bulkIndexContactMetadata,
    bulkIndexContactRows,
    loadContactMetadataSnapshots,
    loadContactAndProspectSnapshots,
    loadPartialContactUpdate,
  ]);

  return {
    contactSnapshotMeta,
    bulkIndexContactRows,
    bulkIndexContactMetadata,
    loadContactAndProspectSnapshots,
    loadContactMetadataSnapshots,
    loadPartialContactUpdate,
    loadAllContactSnapshots,
  };
}

function useContactGroupDb() {
  const contactGroupWorker = useRef<Worker | null>(null);

  const bulkIndexContactGroups = useCallback(async () => {
    const frontendDb = getContactDb();

    const contactGroupRows = await frontendDb?.contactGroups.toArray();

    if (contactGroupRows && contactGroupRows.length > 0) {
      await bulkIndexContactGroupRowsInSearch(contactGroupRows);
    }
  }, []);

  useEffect(() => {
    contactGroupWorker.current = new Worker(
      new URL("../../workers/contactGroup.worker.ts", import.meta.url),
    );

    contactGroupWorker.current.onmessage = async (
      event: MessageEvent<{
        contactGroups: {
          success: boolean;
          error: Error | undefined;
        };
      }>,
    ) => {
      if (event.data.contactGroups.success) {
        await bulkIndexContactGroups();
        console.log("contactGroup snapshot success");
      } else {
        console.error("Error updating contactGroup snapshot", event.data);
      }
    };

    return () => {
      contactGroupWorker.current?.terminate();
    };
  }, []);

  const loadContactGroups = useDebouncedCallback(
    async () => {
      const auth = getAuth();
      if (!auth) return;

      const delegatedUser = getDelegatedUser();
      const authToken = await auth.currentUser?.getIdToken();
      if (authToken)
        contactGroupWorker.current?.postMessage({
          authToken,
          baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
          cdnBaseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL,
          delegatedUserId: delegatedUser?.delegatedUserId,
        });
    },
    getMs("5s"),
    {
      leading: true,
      trailing: true,
    },
  );

  return {
    loadContactGroups,
  };
}

export default function useLiveDatabase() {
  const greedyFetchedAt = useRef<number>(0);

  const { delegatedUser } = useDelegatedUser();
  const delegatedUserId = delegatedUser?.delegatedUserId || "";
  const delegatedUserIdLoading = useRef<string | null>(null);
  const websocket = useRef<TitledockWebSocket>(
    new TitledockWebSocket(process.env.NEXT_PUBLIC_WS_URL),
  );

  // sync changes to local db back to backend db
  useSyncOps();

  const { contactSnapshotMeta, loadPartialContactUpdate, loadAllContactSnapshots } = useContactDb();

  const { loadContactGroups } = useContactGroupDb();

  const shouldReloadDueToDelegatedUserChange = useMemo(() => {
    const isContactDataCurrent =
      contactSnapshotMeta &&
      (delegatedUserId || contactSnapshotMeta?.delegatedUserId) &&
      delegatedUserId !== contactSnapshotMeta?.delegatedUserId;

    return delegatedUserId !== delegatedUserIdLoading.current && isContactDataCurrent;
  }, [contactSnapshotMeta, delegatedUserId]);

  const registerWsHandlers = useCallback(() => {
    TitledockWebSocket.subscribe("/contacts", async (_e, body) => {
      if (body.entity === "/contacts") {
        loadPartialContactUpdate();
      }
    });
    TitledockWebSocket.subscribe("/contacts/deleted", async (_e, body) => {
      if (body.entity === "/contacts/purged" || body.entity === "/contacts/deleted") {
        loadPartialContactUpdate();
      }
    });

    TitledockWebSocket.subscribe("/contacts/metadata", async (_e, body) => {
      if (body.entity === "/contacts/metadata") {
        loadPartialContactMetadataUpdate();
      }
    });

    TitledockWebSocket.subscribe("/contacts/groups", async (_e, body) => {
      if (body.entity === "/contacts/groups") {
        loadPartialContactGroupUpdate();
      }
    });
  }, [loadPartialContactUpdate]);

  const loadAllSnapshots = useCallback(async () => {
    greedyFetchedAt.current = getCurEpochMs();
    TitledockWebSocket.shutdown();
    websocket.current = new TitledockWebSocket(process.env.NEXT_PUBLIC_WS_URL);
    registerWsHandlers();
    await Promise.allSettled([loadAllContactSnapshots(), loadContactGroups()]);
  }, [loadAllContactSnapshots, loadContactGroups, registerWsHandlers]);

  const loadOnWindowFocus = useCallback(() => {
    // reset ws
    TitledockWebSocket.shutdown();
    websocket.current = new TitledockWebSocket(process.env.NEXT_PUBLIC_WS_URL);
    registerWsHandlers();

    const fetchedAtDate = new Date(greedyFetchedAt.current);
    const curDate = new Date();

    if (
      curDate.getDay() !== fetchedAtDate.getDay() ||
      curDate.getMonth() !== fetchedAtDate.getMonth() ||
      getCurEpochMs() - greedyFetchedAt.current > getMs("1hr")
    ) {
      console.info("window focused, loading partial snapshot update...");
      loadPartialContactGroupUpdate();
      loadPartialContactMetadataUpdate();
      loadPartialContactUpdate();
      greedyFetchedAt.current = getCurEpochMs();
    }
  }, [loadPartialContactUpdate, registerWsHandlers]);

  const onlineHandler = useCallback(() => {
    loadAllSnapshots();
  }, [loadAllSnapshots]);

  useEffect(() => {
    // initial handlers
    loadAllSnapshots();

    window.removeEventListener("offline", unsetInProgressOps);
    window.addEventListener("offline", unsetInProgressOps);
    window.removeEventListener("online", onlineHandler);
    window.addEventListener("online", onlineHandler);
    window.addEventListener("focus", loadOnWindowFocus);
    return () => {
      window.removeEventListener("offline", unsetInProgressOps);
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("focus", loadOnWindowFocus);
      TitledockWebSocket.shutdown();
    };
  }, []);

  useEffect(() => {
    // when delegated user changes, we need to reload all data
    if (shouldReloadDueToDelegatedUserChange) {
      console.log("delegated user changed, loading contact snapshot...");
      delegatedUserIdLoading.current = delegatedUserId;
      Sentry.setContext("delegatedUser", {
        delegatedUserId,
      });
      resetFrontendContactSearch();
      resetDataTables()
        .then(loadAllSnapshots)
        .finally(() => {
          delegatedUserIdLoading.current = null;
        });
    }
  }, [delegatedUserId, loadAllSnapshots, shouldReloadDueToDelegatedUserChange]);
}
