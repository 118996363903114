import HighlightText from "@/components/HighlightText";
import type { WebPage } from "@shared/models/types";
import { IsDefault } from "@shared/models/types";
import classNames from "clsx";
import isEmpty from "lodash/isEmpty";
import type { ChangeEvent, FC } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { ensureHttpProtocol } from "utils/string";

import {
  CopySolidIcon,
  ExternalLinkSolidIcon,
  GlobeIcon,
  PenSolidIcon,
  StarIcon,
  StarSolidIcon,
} from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import { showToast } from "../../../core/Toast";
import { generateTypeSelectItems, getSocialProfileService } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import type { ContextualMenuItem } from "../shared/ContextualMenuField";
import ContextualMenuField from "../shared/ContextualMenuField";
import MarkDefaultItemButton from "../shared/MarkDefaultItemButton";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeSelect from "../shared/TypeSelect";
import type { ContactDataFieldProps } from "../types";

const WebPagesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
  focusedField,
  onFocusField,
}) => {
  const allWebPages = useMemo(() => contactData?.webPages || [], [contactData?.webPages]);
  const webPagesWithIndices: { webPage: WebPage; originalIndex: number }[] = useMemo(
    () =>
      [...allWebPages]
        .map((webPage, index) => ({ webPage, originalIndex: index }))
        .filter((o) => getSocialProfileService(o.webPage) === null)
        .sort((a, b) => (b.webPage.isDefault || 0) - (a.webPage.isDefault || 0)),
    [allWebPages]
  );

  const lastWebPageRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedWebPageRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastWebPageInput = useCallback(() => {
    lastWebPageRowInputRef.current?.focus();
  }, [lastWebPageRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "webPages") {
      focusedWebPageRowInputRef.current?.focus();
      focusedWebPageRowInputRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [focusedField]);

  // Resolve web page types from the default list + types that are in-use
  const webPageTypeSelectItems = generateTypeSelectItems(
    "webPages",
    allWebPages.map((webPage) => webPage.type).filter((type) => type !== "social")
  );

  const updateAllWebPages = (webPages: WebPage[]) => {
    if (dispatch) dispatch({ type: "webPages", payload: webPages });
  };

  const onAddNewWebPage = (type?: WebPage["type"]) => {
    const webPagesToUpdate: WebPage[] = [
      ...allWebPages,
      { value: "", type: type || webPageTypeSelectItems[0]?.value },
    ];
    updateAllWebPages(webPagesToUpdate);

    // After a short delay, focus on the last web page input (that was just added)
    setTimeout(() => {
      focusOnLastWebPageInput();
    }, 100);
  };

  const onUpdateWebPage = ({ index, data }: { index: number; data: Partial<WebPage> }) => {
    const webPagesToUpdate: WebPage[] = [...allWebPages];
    webPagesToUpdate[index] = {
      ...webPagesToUpdate[index],
      ...data,
    };
    updateAllWebPages(webPagesToUpdate);
  };

  const onRemoveWebPageAtIndex = (index: number) => {
    const webPagesToUpdate: WebPage[] = [...allWebPages];
    webPagesToUpdate.splice(index, 1);
    updateAllWebPages(webPagesToUpdate);
  };

  const onMarkWebPageDefaultAtIndex = useCallback(
    (isDefault: boolean, index: number) => {
      // If marking a web page default, first reset isDefault state for every object in the array
      const webPagesToUpdate: WebPage[] = isDefault
        ? allWebPages.map((o) => ({
            ...o,
            isDefault: IsDefault.NO,
          }))
        : [...allWebPages];

      webPagesToUpdate[index] = {
        ...webPagesToUpdate[index],
        isDefault: isDefault ? IsDefault.YES : IsDefault.NO,
      };
      if (!isEditing) {
        directlyUpdateContactData?.({ webPages: webPagesToUpdate });
      }
      updateAllWebPages(webPagesToUpdate);
    },
    [allWebPages, directlyUpdateContactData, isEditing, updateAllWebPages]
  );

  const webPageContextualItems = useMemo<ContextualMenuItem<WebPage>[]>(() => {
    return [
      {
        icon: (payload) => (payload.value.isDefault ? StarSolidIcon : StarIcon),
        tooltip: (payload) => (payload.value.isDefault ? "Unmark as Default" : "Mark as Default"),
        action: (payload) => {
          if (payload.index !== undefined) {
            onMarkWebPageDefaultAtIndex(!payload.value.isDefault, payload.index);
          }
        },
        isPinned: (payload) => payload.value.isDefault === IsDefault.YES,
      },
      {
        icon: () => ExternalLinkSolidIcon,
        tooltip: () => "Open website in a new tab",
        action: (payload) => {
          if (window) {
            window.open(payload.value.value, "_blank");
          }
        },
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy website to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(payload.value.value);
            showToast({ title: "Website copied to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit website",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [onFocusField, onMarkWebPageDefaultAtIndex]);

  // Do not show for read-only mode if there are no web pages
  if (!isEditing && isEmpty(webPagesWithIndices)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Web pages"
      isEditing={isEditing}
      icon={<GlobeIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {webPagesWithIndices.map(({ webPage, originalIndex }, index) => {
            const isLastWebPageInput = index === allWebPages.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "webPages" && focusedField?.index === originalIndex;
            const isDefault = webPage.isDefault === IsDefault.YES;
            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <TypeSelect
                  items={webPageTypeSelectItems}
                  initialItemId={webPage.type}
                  onSelectItem={(item) =>
                    onUpdateWebPage({ index: originalIndex, data: { type: item.value } })
                  }
                  createNewItemTitle="Custom"
                />
                <TextInput
                  name="webPage"
                  type="text"
                  value={webPage.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onUpdateWebPage({ index: originalIndex, data: { value: event.target.value } })
                  }
                  forwardedRef={
                    (isFocusedField && focusedWebPageRowInputRef) ||
                    (isLastWebPageInput && lastWebPageRowInputRef) ||
                    undefined
                  }
                  className="flex-1 mr-2"
                />
                <MarkDefaultItemButton
                  isDefault={isDefault}
                  onClickButton={() => onMarkWebPageDefaultAtIndex(!isDefault, originalIndex)}
                  tooltip={isDefault ? "Unmark as Default" : "Mark as Default"}
                  className={classNames(
                    "group-hover:visible",
                    isDefault ? "visible opacity-90" : "invisible opacity-70"
                  )}
                />
                <RemoveDetailsItemButton
                  onClickButton={() => onRemoveWebPageAtIndex(originalIndex)}
                  tooltip="Remove Web Page"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-web-page" className="flex flex-row w-full">
            <TypeSelect
              items={webPageTypeSelectItems}
              onSelectItem={(item) => onAddNewWebPage(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewWebPage()}
            >
              Add a url
            </button>
          </dd>
        </>
      ) : (
        webPagesWithIndices.map(({ webPage, originalIndex }, index) => (
          <dd key={index}>
            <ContextualMenuField
              items={webPageContextualItems}
              actionPayload={{
                fieldName: "webPages",
                value: webPage,
                index: originalIndex,
              }}
            >
              <a
                href={ensureHttpProtocol(webPage.value)}
                target="_blank"
                rel="noreferrer"
                className="text-primary text-underline"
              >
                <HighlightText value={webPage.value} />
              </a>
              <div className="uppercase text-label">{webPage.type}</div>
            </ContextualMenuField>
          </dd>
        ))
      )}
    </BaseDetailsField>
  );
};

export default WebPagesField;
