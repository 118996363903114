import { PostContactsMergeResolutionPayload } from "@http/post-contacts-merge/types";
import type { Contact } from "@shared/models/Contact";
import { getContactDedupeRowId } from "core/helpers/contactMatching";
import { id } from "date-fns/locale";
import uuid from "utils/uuid";

export function getContactIdsToDelete(
  sourceContacts: Contact[],
  mergedContact: Contact,
  excludedContactIds: Contact["id"][]
) {
  return sourceContacts
    .filter(
      ({ id }) =>
        id !== mergedContact.id && !excludedContactIds.includes(id) && !id?.startsWith("enrichRow_")
    )
    .map(({ id }) => id);
}
