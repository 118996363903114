import { EMPTY_PLACEHOLDER_FOR_SORT } from "@shared/models/constants";
import type { Contact, ContactRow, ContactSource } from "@shared/models/Contact";
import type { ContactGroup, ContactGroupRow } from "@shared/models/ContactGroup";
import { ContactMetadataRow, ContactMetadataRowForDisplay } from "@shared/models/ContactMetadata";
import type { ContactUpstream } from "@shared/models/ContactUpstream";
import type { RemoteApi } from "@shared/models/RemoteApi";
import { ArbitraryDate, IsDefault, Photo, PhysicalAddress } from "@shared/models/types";
import { mapValues } from "lodash";
import { parseLocation } from "parse-address";
import sortAny from "sort-any";
import { getFormattedDateStr } from "utils/dateTime";
import { isDeepEqual, isEmpty } from "utils/object";
import { removeAllWhitespace, removeDoubleSpaces, removeSpecialChars } from "utils/string";

export function getNormalizedUsStreet(usStreet: string | undefined) {
  if (!usStreet) return "";
  const { street, suffix, number, prefix, type, sec_unit_type, sec_unit_num } =
    parseLocation(usStreet) || {};
  return [number, prefix, street, type, suffix, sec_unit_type, sec_unit_num]
    .filter(Boolean)
    .join(" ")
    .trim();
}

export function pickContactFieldsToDiff(
  row: Partial<ContactRow | Contact>,
  ignoreFields: string[] = [], // nodes within contact field
  ignoreKeys: string[] = [] // contact root
) {
  const {
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday,
    companyName,
    departmentName,
    jobTitle,
    managerName,
    notes,
    emails,
    relatives,
    dates,
    photos,
    imHandles,
    physicalAddresses,
    phoneNumbers,
    webPages,
  } = JSON.parse(JSON.stringify(row));
  const rowToDiff = {
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday: birthday ? getFormattedDateStr(birthday) : "",
    companyName: removeAllWhitespace(companyName),
    departmentName: removeAllWhitespace(departmentName),
    jobTitle: removeAllWhitespace(jobTitle),
    managerName: removeAllWhitespace(managerName),
    notes: removeAllWhitespace(removeSpecialChars(notes)),
    emails,
    relatives,
    dates: dates?.map((date: ArbitraryDate) => {
      return {
        ...date,
        value: date.value ? getFormattedDateStr(date.value) : "",
      };
    }),
    photos: photos?.map((photo: Photo) => {
      return { id: photo?.id };
    }),
    imHandles,
    physicalAddresses: physicalAddresses?.map((address: PhysicalAddress) => {
      /**
       * always strip optional address fields that are populated by TitleDock for diffing
       * since these will never be present on remotes
       */
      const { placeKey, lat, long, country, id, ...physicalAddressRow } = address || {};

      if (physicalAddressRow.street) {
        physicalAddressRow.street = getNormalizedUsStreet(physicalAddressRow.street);
      }

      return physicalAddressRow;
    }),
    phoneNumbers,
    webPages,
  };

  for (const key in rowToDiff) {
    if (ignoreKeys.includes(key)) delete rowToDiff[key as keyof typeof rowToDiff];
    const valA = rowToDiff[key as keyof typeof rowToDiff];
    if (isEmpty(rowToDiff[key as keyof typeof rowToDiff]))
      delete rowToDiff[key as keyof typeof rowToDiff];
    else if (Array.isArray(valA)) {
      for (const [i, val] of valA.entries()) {
        if (val?.isDefault === IsDefault.NO) {
          delete valA[i].isDefault;
        }
        if (
          typeof val === "object" &&
          !Array.isArray(val) &&
          // @ts-ignore
          val?.type === "other"
        ) {
          // @ts-ignore
          delete valA[i]?.type;
        }
        if (
          typeof val === "object" &&
          !Array.isArray(val) &&
          // @ts-ignore
          val?.label === "other"
        ) {
          // @ts-ignore
          delete valA[i]?.label;
        }
        for (const k in val) {
          if (ignoreFields.includes(k)) {
            delete val[k as keyof typeof val];
            continue;
          }
          if (isEmpty(val[k as keyof typeof val])) delete val[k as keyof typeof val];
          else if (typeof val[k as keyof typeof val] === "string") {
            val[k] = removeDoubleSpaces(val[k]);
          }
        }
      }
    }
  }

  for (const key in rowToDiff) {
    const val = rowToDiff[key as keyof typeof rowToDiff];
    rowToDiff[key as keyof typeof rowToDiff] = sortDeep(val);
  }

  return rowToDiff;
}

export function sortDeep(obj: any): any {
  if (!Array.isArray(obj)) {
    if (typeof obj !== "object" || obj === null || obj instanceof Date) {
      return obj;
    }
    return mapValues(obj, sortDeep);
  }
  return sortAny(obj.map(sortDeep));
}

export function areContactsEqual(
  contacts: Partial<ContactRow | Contact>[],
  ignoreFields: string[] = ["isDefault", "type", "label", "service"],
  ignoreKeys: (keyof ContactRow | keyof Contact)[] = []
) {
  const [firstContact, ...restContacts] = contacts || [];
  const firstRow = pickContactFieldsToDiff(firstContact, ignoreFields, [...ignoreKeys, "photos"]);
  for (const contact of restContacts) {
    const currentRow = pickContactFieldsToDiff(contact, ignoreFields, [...ignoreKeys, "photos"]);
    if (!isDeepEqual(firstRow, currentRow)) {
      return false;
    }
  }

  return true;
}

export function pickContactGroupFields(row: Partial<ContactGroup | ContactGroupRow> = {}) {
  const { contactIds, name, userId, id, autoFillQuery } = row;
  return { contactIds, name, userId, id, autoFillQuery };
}

export function pickContactGroupFieldsToDiffOrMerge(row: Partial<ContactGroup | ContactGroupRow>) {
  const { contactIds, name } = row;
  return { contactIds, name };
}

export function isEmptyContact(contact: Partial<Contact | ContactRow>) {
  const fields = pickContactFieldsToDiff(contact);

  const keys = Object.keys(fields);

  if (keys.length === 0) return true;

  return keys.every((key) => {
    const val = fields[key as keyof typeof fields];
    return !val || val === EMPTY_PLACEHOLDER_FOR_SORT;
  });
}

export function areContactGroupsEqual(
  rowA: Partial<ContactGroup | ContactGroupRow>,
  rowB: Partial<ContactGroup | ContactGroupRow>
) {
  const a = pickContactGroupFieldsToDiffOrMerge(rowA);
  const b = pickContactGroupFieldsToDiffOrMerge(rowB);

  return isDeepEqual(a, b);
}

// helper for Saved Search to split and generate the row params
export function generateSavedSearchParams(query: string) {
  const attrs = query.split(",");
  const savedSearchParams = {};
  for (const item of attrs) {
    const newArr = item.split("=");
    Object.assign(savedSearchParams, { [newArr[0].trim()]: newArr[1].trim() });
  }
  return savedSearchParams;
}

export function getVendorFromVendorTable(
  vendorTable: ContactUpstream["vendorTable"]
): RemoteApi["vendor"] | undefined {
  if (vendorTable === "IcloudContact") return "icloud";
  if (vendorTable === "GoogleContact") return "google";
}

export function getVendorTableFromVendor(
  vendor: RemoteApi["vendor"]
): ContactUpstream["vendorTable"] | undefined {
  if (vendor === "google") return "GoogleContact";
  if (vendor === "icloud") return "IcloudContact";
}

export function getVendorTableFromSource(
  source: ContactSource
): ContactUpstream["vendorTable"] | undefined {
  if (source === "GoogleContact") return "GoogleContact";
  if (source === "IcloudContact") return "IcloudContact";
}

export function getContactSourceFromVendor(vendor: RemoteApi["vendor"]): ContactSource | undefined {
  if (vendor === "google") return "GoogleContact";
  if (vendor === "icloud") return "IcloudContact";
}

export function getContactMetadataRowForDisplay(
  row: ContactMetadataRow
): ContactMetadataRowForDisplay {
  // clean the rows a bit to make it smaller
  const { values, userId_contactId, ...rest } = row;
  if (row.type === "enrichment") {
    return rest as ContactMetadataRowForDisplay;
  }
  return {
    ...rest,
    values,
  } as ContactMetadataRowForDisplay;
}
