// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getAuthBaseQuery } from "../redux/baseQuery";

export const appApi = createApi({
  reducerPath: "titledockApi",
  baseQuery: getAuthBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL }),
  tagTypes: [
    "CONTACTS",
    "CONTACT_SNAPSHOT",
    "PINNED_CONTACT_IDS",
    "CHANGELOG",
    "GROUPS",
    "CONFLICTS",
    "USER",
    "INTEGRATIONS",
    "SNAPSHOTS",
    "ALL_SNAPSHOTS",
    "USER_DELEGATIONS",
    "USER_NOTIFICATIONS",
    "USER_EVENTS",
    "INVITES",
    "ENRICHMENT",
    "ENRICHMENT_PREVIEW",
    "CONTACT_INTERACTIONS",
  ],
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getAppInfo: builder.query<{ WEB_APP_VERSION: string }, void>({
      query: () => "/app",
    }),
  }),
});

export const appNoCacheApi = createApi({
  reducerPath: "titledockNoCacheApi",
  baseQuery: getAuthBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL }),
  tagTypes: ["CONTACTS_UPDATED"],
  endpoints() {
    return {};
  },
  serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName, // temp fix, pending https://github.com/reduxjs/redux-toolkit/issues/1668
});

export const appNoAuthNoCacheApi = createApi({
  reducerPath: "titledockNoAuthNoCacheApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_PUB_BASE_URL }),
  endpoints() {
    return {};
  },
});

export const { useGetAppInfoQuery } = appApi;
