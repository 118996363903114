import { DdbBoolean } from "@shared/models/types";
import classNames from "clsx";
import { LocallyPersistedContactRow } from "core/helpers/contact";
import type { FC } from "react";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import NamedEntities from "@/components/contacts/details/NamedEntities";

import { PenIcon } from "../../../core/Icon";
import TextArea from "../../../core/TextArea";
import { showToast, ToastLevel } from "../../../core/Toast";
import HighlightText from "../../../HighlightText";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import type { ContactDataFieldProps } from "../types";

export const NotesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
  isNotesDirectlyEditable,
  showNamedEntities = false,
  setSearchQuery,
}) => {
  const notes = useMemo(() => contactData?.notes || "", [contactData?.notes]);

  const [cachedNotes, setCachedNotes] = useState<string>(notes);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setCachedNotes(notes);
  }, [notes]);

  const setEditable = useCallback(() => {
    if (isNotesDirectlyEditable || isEditing) setIsEditable(true);
  }, [isNotesDirectlyEditable, isEditing]);
  const setNotEditable = useCallback(() => setIsEditable(false), []);

  const isNotesLoading =
    (contactData as LocallyPersistedContactRow)._isWaitingNotes === DdbBoolean.YES;

  if (!isNotesDirectlyEditable && !notes && !isEditing) return null;
  return (
    <BaseDetailsField
      label="Notes"
      isEditing={isEditing}
      icon={<PenIcon size="lg" className="icon-color-purple" />}
      className="text-zinc-900"
    >
      <div className="flex flex-row mt-1">
        {isEditing && <TypeLabel>Notes</TypeLabel>}
        <dd className={classNames("flex-1", isEditing ? "mr-2" : "max-w-prose")}>
          {isEditable ? (
            <TextArea
              autoFocus
              name="notes"
              minRows={10}
              value={cachedNotes}
              placeholder="Add notes"
              onChange={(event) => {
                setCachedNotes(event.target.value);
              }}
              onBlur={() => {
                setNotEditable();
                if (cachedNotes !== notes) {
                  if (dispatch) dispatch({ type: "notes", payload: cachedNotes });

                  directlyUpdateContactData?.({ notes: cachedNotes });
                  showToast({
                    title: `Changes to ${contactData.givenName} ${contactData.surname} has been saved.`,
                    level: ToastLevel.Success,
                  });
                }
              }}
            />
          ) : (
            <div
              id={`notes-${contactData.id}`}
              className="rounded-md shadow-inner border-primary text-input-primary break-smart min-h-[218px] flex flex-col"
              onClick={isNotesLoading ? undefined : setEditable}
            >
              {showNamedEntities && (
                <NamedEntities notes={cachedNotes} setSearchQuery={setSearchQuery} />
              )}
              <div className="py-2 px-3 flex-1">
                {cachedNotes ? (
                  cachedNotes.split("\n").map((v, i) => {
                    return (
                      <Fragment key={v + i}>
                        <HighlightText value={v} />
                        <br />
                      </Fragment>
                    );
                  })
                ) : isNotesLoading ? (
                  <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-6">
                      <div className="h-2 bg-secondary rounded" />
                      <div className="h-2 bg-secondary rounded" />
                      <div className="h-2 bg-secondary rounded" />
                      <div className="h-2 bg-secondary rounded" />
                      <div className="h-2 bg-secondary rounded" />
                    </div>
                  </div>
                ) : (
                  <div className="p-px text-zinc-400 dark:text-zinc-600 min-h-[218px]">
                    Add notes
                  </div>
                )}
              </div>
            </div>
          )}
        </dd>
      </div>
    </BaseDetailsField>
  );
};

export default NotesField;
