import { Transition } from "@headlessui/react";
import {
  ContactMetadataValueLeapYearPref,
  ContactMetadataValueMap,
} from "@shared/models/ContactMetadata";
import { parse as chronoParse, parseDate } from "chrono-node";
import { format } from "date-fns";
import {
  ChangeEvent,
  ClipboardEventHandler,
  Dispatch,
  FC,
  Fragment,
  KeyboardEventHandler,
  MutableRefObject,
  useCallback,
  useMemo,
} from "react";
import { useEffect, useState } from "react";
import { isLeapDayFromDate } from "utils/dateTime";

import { UpsertContactMetadataAction } from "@/components/contacts/details/types";

import { OMIT_YEAR } from "../../constants";
import { isValidDateInstance } from "../../helpers/date";
import { extractNumbers } from "../../helpers/string";
import TextInput from "./TextInput";

type SimpleDateInputProps = {
  name: string;
  id?: string;
  label?: string;
  initialDateString?: string;
  onChangeDateString?: (dateString: string) => void;
  singleColumn?: boolean;
  disabled?: boolean;
  className?: string;
  forwardedRef?: MutableRefObject<HTMLInputElement | null>;
  contactMetadataValueMap?: ContactMetadataValueMap;
  contactMetadataDispatch?: Dispatch<UpsertContactMetadataAction>;
  disableLeapYearPref?: true;
};

const calculateDateStrings = (
  dateString: string
): { parsedDate: Date | undefined; displayString: string; serverString: string } => {
  if (!dateString) return { parsedDate: undefined, displayString: "", serverString: "" };
  let parsedDate: Date;
  const numberComponents = extractNumbers(dateString);
  if (!numberComponents) {
    // parse free form text
    parsedDate = parseDate(dateString);
  } else {
    const components = dateString.split(/[,\-.\s]/);
    let year: number | undefined = OMIT_YEAR;
    const dateComponents: number[] = [];

    for (const i in components) {
      const num = parseInt(components[i], 10);

      if (!num) {
        dateComponents.push(num);
        continue;
      }

      if (num > 1000) {
        year = num;
        continue;
      }
      if (components.length > 2) {
        if (num > 31 && (Number(i) === 0 || Number(i) + 1 === components.length)) {
          year = num;
          continue;
        }
        if (Number(i) + 1 === components.length) {
          year = num;
          continue;
        }
      }

      dateComponents.push(num);
    }

    const [parsedResult] = chronoParse(dateComponents.join("-"), new Date());

    if (parsedResult) {
      const processedString = [
        parsedResult.start.get("month"),
        parsedResult.start.get("day"),
        year,
      ].join("-");

      parsedDate = parseDate(processedString);
    } else {
      const [m, d] = components;
      parsedDate = parseDate([m, d, year].join("-"));
    }
  }

  // If parsed date is valid, re-format the date to server format and return, omitting OMIT_YEAR for
  // display value in the process.
  if (isValidDateInstance(parsedDate)) {
    const serverDateString = format(parsedDate, "yyyy-MM-dd");
    const displayDateString = format(
      parsedDate,
      parsedDate.getFullYear() === OMIT_YEAR ? "MMMM d" : "MMMM d, yyyy"
    );

    return { displayString: displayDateString, serverString: serverDateString, parsedDate };
  } else {
    console.warn("Invalid date instance trying to parse: ", dateString);
    return { displayString: dateString, serverString: "", parsedDate };
  }
};

const SimpleDateInput: FC<SimpleDateInputProps> = ({
  name,
  id,
  label,
  initialDateString,
  onChangeDateString,
  singleColumn = true,
  disabled = false,
  className,
  forwardedRef,
  disableLeapYearPref,
  contactMetadataDispatch,
  contactMetadataValueMap,
}) => {
  // Parse server string
  const { displayString: initialDisplayString, parsedDate: initialParsedDate } = useMemo(
    () => calculateDateStrings(initialDateString || ""),
    [initialDateString]
  );
  const [inputString, setInputString] = useState<string>(initialDisplayString);

  const setLeapYearPref = useCallback(
    (value: ContactMetadataValueLeapYearPref["values"]) => {
      if (contactMetadataDispatch)
        contactMetadataDispatch({
          type: "leapYearPref",
          payload: value,
        });
    },
    [contactMetadataDispatch]
  );

  const leapYearPref = contactMetadataValueMap?.leapYearPref;

  const [showLeapYearPref, setShowLeapYearPref] = useState(
    Boolean(initialParsedDate && isLeapDayFromDate(initialParsedDate))
  );

  const handleOnBlur = useCallback(() => {
    const { displayString, serverString, parsedDate } = calculateDateStrings(inputString);
    if (!disableLeapYearPref) {
      if (isLeapDayFromDate(parsedDate)) {
        setShowLeapYearPref(true);
      } else {
        setShowLeapYearPref(false);
      }
    }
    setInputString(displayString);
    onChangeDateString?.(serverString);
  }, [disableLeapYearPref, inputString, onChangeDateString]);

  const handleOnPaste: ClipboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.preventDefault();
      const textData = event.clipboardData.getData("Text");
      const { displayString, serverString, parsedDate } = calculateDateStrings(textData);
      if (!disableLeapYearPref) {
        if (isLeapDayFromDate(parsedDate)) {
          setShowLeapYearPref(true);
        } else {
          setShowLeapYearPref(false);
        }
      }

      setInputString(displayString);
      onChangeDateString?.(serverString);
    },
    [disableLeapYearPref, onChangeDateString]
  );

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.key === "Enter") {
      (event.target as any).blur();
    }
  }, []);

  useEffect(() => {
    if (initialDateString)
      setInputString((prevState) => {
        if (initialDateString !== prevState) {
          return initialDateString;
        }
        return prevState;
      });
  }, [initialDateString]);

  return (
    <>
      <TextInput
        name={name}
        id={id}
        label={label}
        singleColumn={singleColumn}
        disabled={disabled}
        className={className}
        forwardedRef={forwardedRef}
        value={inputString}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setInputString(e.target.value)}
        onPaste={handleOnPaste}
        onKeyDown={handleOnKeyDown}
        onBlur={handleOnBlur}
      />
      <LeapYearPrefSelect
        leapYearPref={leapYearPref}
        setLeapYearPref={setLeapYearPref}
        showLeapYearPref={showLeapYearPref}
      />
    </>
  );
};

export default SimpleDateInput;

export const LeapYearPrefSelect: FC<{
  leapYearPref: number | undefined;
  setLeapYearPref: (val: number) => void;
  showLeapYearPref: boolean;
}> = ({ leapYearPref, setLeapYearPref, showLeapYearPref }) => {
  return (
    <Transition
      show={showLeapYearPref}
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="border border-color-primary p-3 mt-2 rounded-md bg-blue-50 dark:bg-zinc-900">
        <label htmlFor="leapYearPref" className="block text-sm font-medium text-secondary">
          Leap day preference:
          <br />
          <span className="text-label inline-block">
            Select Feb 28 or Mar 1 to substitute during non-leap year.
          </span>
        </label>
        <select
          id="leapYearPref"
          name="leapYearPref"
          value={leapYearPref}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 bg-secondary text-secondary ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setLeapYearPref(parseInt(e.target.value, 10))
          }
        >
          <option value="28">February 28</option>
          <option value="1">March 1</option>
        </select>
      </div>
    </Transition>
  );
};
