import RestoreSection from "@/components/settings/restore";
import Collaborate from "@/components/tools/collaborate";

export const ToolsTabs = [
  {
    name: "collaborate",
    href: "collaborate",
    title: "Collaborate",
    component: Collaborate,
    props: {},
    hidden: false,
  },
  {
    name: "restore",
    href: "restore",
    component: RestoreSection,
    title: "Restore",
    props: {},
    hidden: false,
  },
];

export const defaultToolRoute = ToolsTabs[0].href;
