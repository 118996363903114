import classNames from "clsx";
import { SortableKeys } from "core/helpers/contact";
import { ContactRecipient, ContactRecipientPreview } from "core/types/userMessaging";
import { type MouseEventHandler, type ReactElement, FC, PropsWithChildren, useMemo } from "react";

import Avatar from "@/components/core/Avatar";
import HighlightText from "@/components/HighlightText";

const EmailOnlyRecipientListItem: FC<
  PropsWithChildren<{
    recipient: ContactRecipient | ContactRecipientPreview;
    noGutter?: boolean;
    isSelected: boolean;
    className?: string;
    isMultiSelected?: boolean;
    onClickItem?: MouseEventHandler<HTMLElement>;
    onClickToSelectItem?: (item: ContactRecipient) => void;
    sortKey: SortableKeys;
    endIcon?: ReactElement;
    endContent?: ReactElement;
  }>
> = ({
  endIcon,
  endContent,
  children,
  recipient,
  noGutter,
  isSelected,
  className,
  onClickItem,
  onClickToSelectItem,
  isMultiSelected,
  sortKey,
}) => {
  const [givenName, surname] = useMemo(() => {
    return recipient.name.split(" ");
  }, [recipient.name]);

  return (
    <div
      className={classNames(
        "relative flex items-center border-l-4 space-x-3 focus-within:ring-purple-200 dark:focus-within:ring-purple-800",
        !noGutter && "pl-5 pr-7 py-5",
        isSelected
          ? "bg-blue-50 dark:bg-zinc-800 hover:bg-blue-50 dark:hover:bg-zinc-800 border-blue-400 dark:border-blue-800"
          : "hover:bg-zinc-50 dark:hover:bg-zinc-900 border-transparent",
        className,
      )}
      onClick={onClickItem}
    >
      <div className="flex-shrink-0 transition-all delay-200">
        <Avatar
          className="text-lg h-12 w-12"
          firstName={givenName}
          lastName={surname}
          onClickToSelectItem={
            onClickToSelectItem ? () => onClickToSelectItem(recipient) : undefined
          }
          isMultiSelected={isMultiSelected}
        />
      </div>
      <div className="flex-1 min-w-0 truncate text-left">
        <a className="focus:outline-none m-0">
          <p className="text-primary m-0 truncate">
            <span
              className={classNames(
                (sortKey === "givenName" || sortKey === "_givenNameSort") && "font-bold",
              )}
            >
              <HighlightText value={givenName} matchPhrase={false} />
            </span>{" "}
            <span
              className={classNames(
                (sortKey === "surname" || sortKey === "_surnameSort") && "font-bold",
              )}
            >
              <HighlightText value={surname} matchPhrase={false} />
            </span>
          </p>
          <span className="text-secondary">
            <HighlightText value={recipient.email || ""} />
          </span>
        </a>
        {children}
      </div>
      <div className="text-gray-300">{endIcon && endIcon}</div>
      {endContent}
    </div>
  );
};

export default EmailOnlyRecipientListItem;
