import classNames from "clsx";
import { ChainableFilter } from "core/types/contactSearch";
import { useLiveQuery } from "dexie-react-hooks";
import { FC, useEffect, useMemo, useState } from "react";
import { GroupedVirtuoso } from "react-virtuoso";

import { useGroupedListControls } from "@/components/contacts/list/hooks";
import { SearchFilterOptionProps } from "@/components/contacts/search/types";
import { SecondaryBadge } from "@/components/core/Badge";
import { getContactDb } from "@/database/contactDb";

import SearchFilterOptionWrapper from "./SearchFilterOptionWrapper";

const SearchFilterCompanyName: FC<SearchFilterOptionProps> = ({
  filter,
  onSelected,
  onFocused,
}) => {
  const contactsWithCompanies = useLiveQuery(async () => {
    const frontendDb = getContactDb();

    if (filter.query) {
      return frontendDb?.contacts
        .where("_companyNameSort")
        .startsWith(filter.query.toLowerCase())
        .sortBy("_companyNameSort");
    }
    return frontendDb?.contacts.where("_companyNameSort").notEqual("").sortBy("_companyNameSort");
  }, [filter.query]);

  const companies = useMemo(() => {
    const dedupeSet: Set<string> = new Set();
    const result: { id: string }[] = [];

    for (const contact of contactsWithCompanies || []) {
      const companyNameKey = contact.companyName?.toLowerCase().trim();
      if (companyNameKey && !dedupeSet.has(companyNameKey)) {
        dedupeSet.add(companyNameKey);
        result.push({ id: contact.companyName?.trim() || "" });
      }
    }

    return result;
  }, [contactsWithCompanies]);

  const [selected, setSelected] = useState<{ id: string } | undefined>(companies[0]);

  const { ref, currentItemIndex, onClickItem } = useGroupedListControls({
    items: companies || [],
    groupCounts: [companies?.length || 0],
    selectedItem: selected,
    onKeySelected: setSelected,
    enableDirKeys: true,
  });

  useEffect(() => {
    if (selected) {
      onFocused({
        index: "companyName",
        query: selected.id,
        token: "co:",
        type: "search",
        selected: selected.id,
      });
    } else {
      onFocused(undefined);
    }
  }, [onFocused, selected]);

  useEffect(() => {
    if (contactsWithCompanies?.length === 0) {
      onSelected({
        hidePopover: true,
      });
    }
  }, [contactsWithCompanies?.length, onSelected]);

  return (
    companies.length > 0 && (
      <SearchFilterOptionWrapper filter={filter}>
        <GroupedVirtuoso
          ref={ref}
          style={{ minHeight: "300px" }}
          groupCounts={[companies?.length || 0]}
          groupContent={() => (
            <div className="flex text-sm px-2 pb-2 bg-primary">
              <span className="items-center inline-flex font-bold">Companies</span>
              <SecondaryBadge className="ml-auto">{companies.length || 0}</SecondaryBadge>
            </div>
          )}
          itemContent={(index) => {
            const item = companies[index];
            const isSelected = index === currentItemIndex;
            const newFilterState: ChainableFilter = {
              index: "companyName",
              query: item.id,
              token: "co:",
              type: "search",
              selected: item.id,
            };
            return (
              <div
                className={classNames(
                  "text-sm hover:bg-zinc-200 dark:hover:bg-zinc-800 p-2 rounded-md cursor-pointer",
                  isSelected && "bg-zinc-200 dark:bg-zinc-800",
                )}
                onMouseEnter={() => setSelected(item)}
                onMouseLeave={() => setSelected(undefined)}
                onClick={(e) => {
                  onClickItem(e, item, index);
                  onSelected(newFilterState);
                }}
              >
                {item.id}
              </div>
            );
          }}
        />
      </SearchFilterOptionWrapper>
    )
  );
};

export default SearchFilterCompanyName;
