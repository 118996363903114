import { Contact, ContactRow } from "@shared/models/Contact";

export function getIndexableString(value?: string): string {
  return (value || "").toLowerCase().trim();
}

export function removeSupplementalFields(contact: any) {
  const result = {} as ContactRow | Contact;
  for (const key in contact) {
    if (!key.startsWith("_")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[key] = contact[key];
    }
  }

  return result;
}
