import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { FC, PropsWithChildren, ReactElement, useState } from "react";
import { Fragment } from "react";

import Alert from "@/components/core/Alert";
import Button, { ButtonVariant } from "@/components/core/Button";

const AppError: FC<
  PropsWithChildren<{
    title: string | JSX.Element;
    footer: string | JSX.Element;
  }>
> = ({ title, footer, children }) => {
  return (
    <Transition show as={Fragment}>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-secondary text-secondary rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full">
                    <img src="/titledock-logo.svg" className="h-16 w-16" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-secondary">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">{children}</div>
                  </div>
                </div>
                <div className="pt-6 sm:flex sm:flex-row-reverse">{footer}</div>
              </Dialog.Panel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default AppError;

export const FatalError = () => (
  <AppError
    title="App Error"
    footer={
      <Button variant={ButtonVariant.Primary} onClick={() => window.location.reload()}>
        Reload
      </Button>
    }
  >
    <p>
      We apologize for the inconvenience. The error has been reported to our team. Please reload the
      app.
    </p>
  </AppError>
);

export const ModalError: FC<{
  onClose: () => void;
  title: string;
  description?: ReactElement | string;
}> = ({ onClose, title, description }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  return (
    <Alert
      variant={ButtonVariant.Danger}
      className="border border-color-primary"
      isAlertOpen={isAlertOpen}
      onClose={setIsAlertOpen}
      cancelHandler={onClose}
      title={title}
      cancelButtonTitle="Close"
      noBackdrop
    >
      <p className="text-secondary text-sm">
        {description
          ? description
          : "We apologize for the inconvenience. The error has been reported to our team."}
      </p>
    </Alert>
  );
};
