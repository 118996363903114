import Button, { ButtonVariant } from "@/components/core/Button";
import { ArrowUpRightFromSquareIcon } from "@/components/core/Icon";
import { PeopleDataLabsLogo } from "@/components/core/Logo";
import Modal from "@/components/core/Modal";
import { FormTextInput } from "@/components/core/TextInput";
import { useCreateIntegrationMutation } from "@/integrations/user/api";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  IsDeleted,
  IsIntegrationEnabled,
  IsRemoteApiActive,
  RemoteApiStatus,
} from "@shared/models/types";
import { Form, Formik } from "formik";
import type { Dispatch, FC, SetStateAction } from "react";
import { useRef } from "react";
import { getCurEpochMs } from "utils/dateTime";
import * as yup from "yup";

const PdlApiKeyModal: FC<{
  isModalOpen: boolean;
  setIsClosed: Dispatch<SetStateAction<boolean>>;
  apiKey?: string | undefined;
  onSuccess?: () => void;
}> = ({ isModalOpen, setIsClosed, apiKey = "", onSuccess }) => {
  const apiKeyInputRef = useRef<HTMLInputElement | null>(null);
  const [createIntegration] = useCreateIntegrationMutation();

  return (
    <Modal
      initialFocusRef={apiKeyInputRef}
      isModalOpen={isModalOpen}
      setIsClosed={setIsClosed}
      className="mx-auto w-full sm:max-w-lg"
    >
      <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setIsClosed(false)}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col p-12 w-full items-center justify-center ">
          <PeopleDataLabsLogo className="w-12 mb-3" />
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100 mb-3"
          >
            People Data Labs
          </Dialog.Title>
          <div className="mb-5"></div>
          <Formik
            initialValues={{ apiKey }}
            validationSchema={yup.object()}
            onSubmit={async (values, actions) => {
              if (values?.apiKey) {
                const { setSubmitting } = actions;
                await createIntegration({
                  id: "peopleDataLabs",
                  auth: {
                    apiKey: values.apiKey,
                  },
                  status: RemoteApiStatus.READY,
                  vendor: "peopleDataLabs",
                  isActive: IsRemoteApiActive.YES,
                  isDeleted: IsDeleted.NO,
                  createdAt: getCurEpochMs(),
                  updatedAt: getCurEpochMs(),
                  isContactEnabled: IsIntegrationEnabled.NO,
                  isCalendarEnabled: IsIntegrationEnabled.NO,
                  isEmailEnabled: IsIntegrationEnabled.NO,
                  isContactEnrichmentEnabled: IsIntegrationEnabled.YES,
                  isProspectSearchEnabled: IsIntegrationEnabled.YES,
                  isVideoConferencingEnabled: IsIntegrationEnabled.NO,
                });
                setSubmitting(false);
                setIsClosed(false);
                if (onSuccess) {
                  onSuccess();
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className="text-left w-full">
                <FormTextInput
                  className="mb-5"
                  name="apiKey"
                  label="API Key"
                  autoComplete="off"
                  forwardedRef={apiKeyInputRef}
                  required
                />
                <div className="text-center grid grid-cols-2 gap-x-4 ">
                  <a href="https://dashboard.peopledatalabs.com" target="_blank" rel="noreferrer">
                    <Button
                      full
                      variant={ButtonVariant.Secondary}
                      endIcon={<ArrowUpRightFromSquareIcon />}
                    >
                      PDL Dashboard
                    </Button>
                  </a>
                  <Button full disabled={isSubmitting} className="mx-auto" type="submit">
                    Save Account
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
export default PdlApiKeyModal;
