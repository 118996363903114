import * as Sentry from "@sentry/nextjs";
import type { Contact, ContactRow, ContactScalarType } from "@shared/models/Contact";
import { IsDeleted, IsReadOnly } from "@shared/models/types";
import { SortableKeys } from "core/helpers/contact";
import {
  getCombinedSearchResult,
  getUniqueSearchResultIds,
  getValuesInQuotes,
  isLikelyPhoneNumber,
  stripCountryCode,
} from "core/helpers/contactSearch";
import { IndexableType } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import { SimpleDocumentSearchResultSetUnit } from "flexsearch";
import { ChangeEventHandler, useCallback, useContext, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { getNumsFromString } from "utils/string";

import { ContactsContext } from "@/components/contacts/ContactsProvider";
import { ContactData } from "@/components/contacts/v2/types";
import { getContactDb, USE_DB_TO_SEARCH } from "@/database/contactDb";
import { getIndexableString } from "@/database/helpers";
import { performContactSearchAsync, performProspectSearchAsync } from "@/database/search";

export function getMappedSortKey(sortKey?: SortableKeys) {
  if (!sortKey) return "_surnameSort";
  if (sortKey === "surname") return "_surnameSort";
  if (sortKey === "givenName") return "_givenNameSort";
  return sortKey;
}

export function useLiveAllContacts() {
  const { idToContact, isLoaded, contacts } = useContext(ContactsContext);

  return {
    contacts,
    idToContact,
    isLoaded,
  };
}

export function useLiveContactByIdList(idList: string[], sortKey: SortableKeys = "_surnameSort") {
  const contacts = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return frontendDb?.contacts.where("id").anyOf(idList).sortBy(getMappedSortKey(sortKey));
  }, [idList]);

  return { contacts: contacts || [], isLoaded: typeof contacts !== "undefined" };
}

export function useQueryState(initialQuery = "") {
  const [query, setQuery] = useState(initialQuery);

  const queryOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
    setQuery(target.value);
  }, []);

  return { query, setQuery, queryOnChange };
}

export function getPkeysToSearch(allMatches: IndexableType[][]) {
  const stringSets = allMatches.map((arr) => new Set(arr));
  const intersectionCount: { [key: string]: number } = {};

  for (const stringSet of stringSets) {
    for (const str of stringSet) {
      if (typeof intersectionCount[String(str)] === "undefined") {
        intersectionCount[String(str)] = 1;
      } else {
        intersectionCount[String(str)]++;
      }
    }
  }

  let intersectedMatches: string[] = [];
  let highestMatchCount = 0;
  for (const key in intersectionCount) {
    if (intersectionCount[key] > highestMatchCount) {
      highestMatchCount = intersectionCount[key];
      intersectedMatches = [key];
    } else if (intersectionCount[key] === highestMatchCount) {
      intersectedMatches.push(key);
    }
  }

  return intersectedMatches.length > 0 ? intersectedMatches : Object.keys(intersectionCount);
}

export function useLiveSearchedContacts({
  query = "",
  sortKey,
  isReadOnly = IsReadOnly.NO,
  idToContact,
}: {
  query?: string;
  sortKey?: SortableKeys;
  isDeleted?: IsDeleted;
  isReadOnly?: IsReadOnly;
  idToContact?: { [id: string]: ContactData };
}) {
  const [debouncedQuery] = useDebounce(query, 70, {
    leading: false,
    trailing: true,
  });

  const snapshotMeta = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return frontendDb?.snapshotMetadata.get("contact");
  });

  const contacts = useLiveQuery(async () => {
    const frontendDb = getContactDb();

    const startTime = Date.now();
    if (!query || !debouncedQuery) return undefined;

    try {
      const { inQuotes, rest } = getValuesInQuotes(debouncedQuery);
      const queryFragments = [...inQuotes, ...rest];
      let matchedPkeys: string[];

      // use db to search if enabled
      if (USE_DB_TO_SEARCH) {
        const contactFieldMatches = queryFragments.map((word) =>
          frontendDb?.contacts.where("_searchableWords").startsWithIgnoreCase(word).primaryKeys(),
        );

        const noteMatches = queryFragments.map((word) =>
          frontendDb?.contacts.where("_searchableNotes").equalsIgnoreCase(word).primaryKeys(),
        );

        const allMatches = await Promise.all([
          ...contactFieldMatches,
          ...noteMatches,
          ...[
            isLikelyPhoneNumber(debouncedQuery)
              ? frontendDb?.contacts
                  .where("_phoneNumberValueList")
                  .startsWithIgnoreCase(getNumsFromString(getIndexableString(debouncedQuery)))
                  .primaryKeys()
              : undefined,
          ].filter(Boolean),
        ]);

        matchedPkeys = getPkeysToSearch(allMatches as IndexableType[][]);
      } else {
        const performSearchAsync =
          isReadOnly === IsReadOnly.YES ? performProspectSearchAsync : performContactSearchAsync;
        const [phraseResult, phoneNumberResult, tokenResultsToCombine] = await Promise.all([
          performSearchAsync(String(debouncedQuery)),
          isLikelyPhoneNumber(debouncedQuery)
            ? performSearchAsync(
                stripCountryCode(getNumsFromString(getIndexableString(debouncedQuery))),
                {
                  index: ["_phoneNumberValueList[]"],
                },
              )
            : undefined,
          Promise.all(
            queryFragments.map((token) => {
              return performSearchAsync(token);
            }),
          ),
        ]);

        const tokenResultsCombined = getCombinedSearchResult(
          tokenResultsToCombine?.filter(Boolean) as SimpleDocumentSearchResultSetUnit[][],
        );

        let allResults = { ...getUniqueSearchResultIds(phraseResult), ...tokenResultsCombined };

        if (phoneNumberResult) {
          allResults = { ...allResults, ...getUniqueSearchResultIds(phoneNumberResult) };
        }

        matchedPkeys = Object.keys(allResults);
      }

      console.log(`search "${debouncedQuery}" took`, Date.now() - startTime, "ms");

      if (idToContact) {
        const result = [];
        for (const id of matchedPkeys) {
          const contact = idToContact[id];
          if (contact) {
            result.push(contact);
          }
        }

        result.sort((a, b) => {
          const keyToSort = getMappedSortKey(sortKey);
          const valueA = a[keyToSort as keyof typeof a] || "#";
          const valueB = b[keyToSort as keyof typeof b] || "#";
          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          } else {
            return 0;
          }
        });
        return result;
      }

      return await frontendDb?.contacts
        .where("id")
        .anyOf(matchedPkeys)
        .sortBy(getMappedSortKey(sortKey));
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
  }, [
    debouncedQuery,
    sortKey,
    snapshotMeta?.snapshotCreatedAt,
    snapshotMeta?.updateFetchedAt,
    snapshotMeta?.lastOpAt,
    snapshotMeta?.isLoading,
  ]);

  return {
    contacts: debouncedQuery && typeof contacts !== "undefined" ? contacts : undefined,
  };
}

export function useLiveContact(id?: string) {
  const { idToContact, editContact, isLoaded, createContact, deleteContact } =
    useContext(ContactsContext);

  const contact = useMemo(() => {
    if (!id) return undefined;
    return idToContact[id];
  }, [id, idToContact]);

  const result: {
    contact: ContactData;
    editContact: (contact: Partial<ContactData> & { id: ContactRow["id"] }) => Promise<void>;
    createContact: (contact: Contact) => Promise<void>;
    deleteContact: (contact: ContactData) => Promise<void>;
    isLoaded: boolean;
  } = {
    contact: contact || ({} as ContactData),
    isLoaded,
    editContact,
    createContact,
    deleteContact,
  };

  return result;
}

/*
  @deprecated
 */
export function useAlphabetList(contacts: ContactData[], sortKey: SortableKeys = "surname") {
  return useMemo(() => {
    const contactsByLetter: { [letter: string]: ContactData[] } = {};
    for (const contact of contacts) {
      const keyVal = contact[sortKey as keyof ContactScalarType] || "";
      const letter = keyVal.charAt(0).toUpperCase();
      if (!contactsByLetter[letter]) contactsByLetter[letter] = [];
      contactsByLetter[letter].push(contact);
    }
    return contactsByLetter;
  }, [contacts, sortKey]);
}
