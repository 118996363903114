import { Transition } from "@headlessui/react";
import { RocketLaunchIcon } from "@heroicons/react/20/solid";
import { FC, PropsWithChildren, ReactElement, useRef } from "react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import type { Toast } from "react-hot-toast";
import ReactHotToast, { CheckmarkIcon } from "react-hot-toast";
import { getMs } from "utils/dateTime";

import Button, { ButtonVariant } from "@/components/core/Button";
import { useGetAppInfoQuery } from "@/integrations/app/api";

import {
  CheckCircleSolidIcon,
  ExclamationCircleSolidIcon,
  InfoCircleSolidIcon,
  TimesCircleSolidIcon,
  TimesIcon,
} from "./Icon";

const ToastTemplate: FC<
  PropsWithChildren<{
    toast: Toast;
    iconClassName?: string;
    toastIcon?: ReactElement;
    hideClose?: boolean;
  }>
> = ({ toast, iconClassName, toastIcon, children, hideClose }) => {
  return (
    <Transition
      show={toast.visible}
      as={Fragment}
      enter="transition ease-out duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="w-full max-w-md overflow-hidden rounded-lg shadow-lg pointer-events-auto bg-zinc-100 dark:bg-zinc-800 ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            {toastIcon && (
              <div className={iconClassName} aria-hidden="true">
                {toastIcon}
              </div>
            )}

            <div className="ml-3 w-0 flex-1 pt-0.5">{children}</div>
            {!hideClose && (
              <div className="flex flex-shrink-0 ml-4">
                <button
                  className="inline-flex rounded-md bg-zinc-100 text-zinc-400 dark:bg-zinc-800 hover:text-zinc-500 dark:text-zinc-500 dark:hover:text-zinc-400 focus:outline-none"
                  onClick={() => ReactHotToast.dismiss(toast.id)}
                >
                  <span className="sr-only">Close</span>
                  <TimesIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export enum ToastLevel {
  Info,
  Success,
  Warning,
  Danger,
}

export type ToastProps = {
  title: string;
  description?: string;
  level?: ToastLevel;
  duration?: number;
};

export const showToast = (props: PropsWithChildren<ToastProps>) => {
  const { title, description, level = ToastLevel.Success, duration } = props;

  let toastIcon: ReactElement;
  let toastIconColor: string;

  switch (level) {
    case ToastLevel.Info:
      toastIcon = <InfoCircleSolidIcon />;
      toastIconColor = "text-zinc-400";
      break;
    case ToastLevel.Success:
      toastIcon = <CheckCircleSolidIcon />;
      toastIconColor = "text-green-400";
      break;
    case ToastLevel.Warning:
      toastIcon = <ExclamationCircleSolidIcon />;
      toastIconColor = "text-yellow-400";
      break;
    case ToastLevel.Danger:
      toastIcon = <TimesCircleSolidIcon />;
      toastIconColor = "text-red-400";
      break;
  }

  const toastId = ReactHotToast.custom(
    (t) => (
      <ToastTemplate toast={t} iconClassName={toastIconColor} toastIcon={toastIcon}>
        <p className="text-sm font-medium text-primary">{title}</p>
        <p className="mt-1 text-sm text-secondary">{description}</p>
        {props.children}
      </ToastTemplate>
    ),
    {
      position: "bottom-right",
      duration: duration || level === ToastLevel.Danger ? 10000 : 5000,
    },
  );

  return {
    toastId,
    dismissToast: () => ReactHotToast.dismiss(toastId),
  };
};

export function useNewAppVersionToast() {
  const { data: appInfo } = useGetAppInfoQuery(undefined, {
    refetchOnFocus: true,
    pollingInterval: getMs("12 hr"),
  });

  const toastId = React.useRef<string | null>(null);
  const dismissToast = useCallback(() => {
    if (toastId.current) {
      ReactHotToast.dismiss(toastId.current);
    }
  }, []);

  const showToast = useCallback(({ duration = Infinity }: { duration?: number }) => {
    toastId.current = ReactHotToast.custom(
      (t) => (
        <ToastTemplate
          toast={t}
          toastIcon={<RocketLaunchIcon className="w-6 h-6 dark:text-white" />}
          iconClassName="px-2 pt-1"
        >
          <div className="space-y-2">
            <div>
              <p className="text-sm font-medium text-primary">
                A new version of TitleDock is available.
              </p>
              <p className="mt-1 text-sm text-secondary">Reload the page now to update</p>
              <div className="mt-6">
                <Button variant={ButtonVariant.Primary} onClick={() => window.location.reload()}>
                  Reload
                </Button>
              </div>
            </div>
          </div>
        </ToastTemplate>
      ),
      { duration, position: "bottom-right", id: "new-app-version" },
    );
  }, []);

  useEffect(() => {
    if (
      appInfo?.WEB_APP_VERSION &&
      process.env.WEB_APP_VERSION &&
      appInfo.WEB_APP_VERSION.localeCompare(process.env.WEB_APP_VERSION, undefined, {
        numeric: true,
        sensitivity: "base",
      }) > 0
    ) {
      showToast({ duration: getMs("3 min") });
    }
  }, [appInfo?.WEB_APP_VERSION, showToast]);

  return { showToast, dismissToast };
}

export function useProgressToast(name: string, id: string, Icon: ReactElement) {
  const toastId = React.useRef<string | null>(null);

  const [error, setError] = useState("");

  const dismissToast = useCallback(() => {
    if (toastId.current) {
      ReactHotToast.dismiss(toastId.current);
    }
  }, []);

  const showToast = useCallback(
    ({
      progress,
      body,
      duration = Infinity,
    }: {
      progress: number;
      body?: string | ReactElement;
      duration?: number;
    }) => {
      toastId.current = ReactHotToast.custom(
        (t) => (
          <ToastTemplate toast={t} toastIcon={Icon} iconClassName="px-2 pt-1">
            <div className="text-xs text-primary mb-1">{Math.round(progress)}%</div>
            <div className="space-y-2">
              <div className="overflow-hidden rounded-full bg-gray-200">
                <div
                  className="h-2 rounded-full bg-indigo-600"
                  style={{ width: `${Math.min(Math.round(progress), 100)}%` }}
                />
              </div>

              <div>
                <p className="text-sm font-medium text-primary">{name}</p>

                <p className="mt-1 text-sm text-secondary">{body}</p>
                {error && <p className="mt-1 text-sm text-red-400">{error}</p>}
              </div>
            </div>
          </ToastTemplate>
        ),
        { duration, position: "bottom-right", id },
      );
    },
    [Icon, error, name, id],
  );

  const finishToast = useCallback(
    ({ body }: { body?: string | ReactElement }) => {
      ReactHotToast.custom(
        (t) => (
          <ToastTemplate toast={t} toastIcon={<CheckmarkIcon />} iconClassName="px-2 pt-1">
            <div className="text-xs text-primary mb-1">100%</div>
            <div className="space-y-2">
              <div className="overflow-hidden rounded-full bg-gray-200">
                <div className="h-2 rounded-full bg-indigo-600" style={{ width: `100%` }} />
              </div>
              <div>
                <p className="text-sm font-medium text-primary">{name}</p>
                <p className="mt-1 text-sm text-secondary">{body}</p>
                {error && <p className="mt-1 text-sm text-red-400">{error}</p>}
              </div>
            </div>
          </ToastTemplate>
        ),
        {
          duration: 7000,
          position: "bottom-right",
          id,
        },
      );
    },
    [error, name, id],
  );

  return {
    toastId: toastId.current,
    dismissToast,
    showToast,
    finishToast,
    setError,
  };
}
export function useCountdownToast({
  Icon,
  durationSec,
}: {
  Icon?: ReactElement;
  durationSec: number;
}) {
  const countdownById = useRef<{ [id: string]: number }>({});
  const timerById = useRef<{ [id: string]: NodeJS.Timeout }>({});
  const [error, setError] = useState("");

  useEffect(() => {
    const allIds = Object.keys(countdownById);
    for (const id of allIds) {
      const countdown = countdownById.current[id as keyof typeof countdownById];
      if (countdown <= 0) {
        clearInterval(timerById.current[id]);
      }
    }
  }, [durationSec]);

  const dismissToast = useCallback((id: string) => {
    if (timerById.current[id]) {
      clearInterval(timerById.current[id]);
    }

    const { [id]: _, ...rest } = countdownById.current;
    countdownById.current = rest;

    ReactHotToast.dismiss(id);
  }, []);

  const renderToast = useCallback(
    (id: string, countdown: number, Content: ReactElement) => {
      ReactHotToast.custom(
        (t) => (
          <ToastTemplate toast={t} toastIcon={Icon} iconClassName="px-2 pt-1">
            {Content}
            {error && <p className="mt-1 text-sm text-red-400">{error}</p>}
          </ToastTemplate>
        ),
        { duration: countdown === 0 ? 1000 : Infinity, position: "bottom-right", id },
      );
    },
    [Icon, error],
  );

  const showToast = useCallback(
    ({
      id,
      actionCallback,
      processCallback,
      Content,
    }: {
      id: string;
      actionCallback?: () => void;
      processCallback?: () => Promise<void>;
      Content: ReactElement;
    }) => {
      renderToast(id, durationSec, Content);
      countdownById.current[id] = durationSec;
      if (timerById.current[id]) {
        clearInterval(timerById.current[id]);
      }
      const promise = processCallback ? processCallback() : Promise.resolve();
      timerById.current[id] = setInterval(() => {
        const val = countdownById.current[id] - 1;
        if (val <= 0 && actionCallback) {
          promise.then(actionCallback);
        }
        renderToast(id, val, Content);
        countdownById.current[id] = val;
      }, 1000);
    },
    [renderToast, durationSec],
  );

  return {
    dismissToast,
    showToast,
    setError,
  };
}
