import { isSmartGroup } from "@shared/helpers/contactGroup";
import type { MyContactSharedPaths } from "@shared/models/AuthoritativeContactMapping";
import { EMPTY_PLACEHOLDER_FOR_SORT } from "@shared/models/constants";
import type {
  Contact,
  ContactDbRowFields,
  ContactListType,
  ContactRow,
  ContactScalarType,
  ContactSource,
} from "@shared/models/Contact";
import type { ContactGroup } from "@shared/models/ContactGroup";
import type { ContactGroupVersion } from "@shared/models/ContactGroupVersion";
import type { ContactVersion } from "@shared/models/ContactVersion";
import type { PendingContactRow } from "@shared/models/PendingContact";
import type { RemoteApi } from "@shared/models/RemoteApi";
import {
  ArbitraryDate,
  Email,
  ImHandle,
  IsDeleted,
  IsDoNotSync,
  IsReadOnly,
  PhoneNumber,
  Photo,
  PhysicalAddress,
  Relative,
  WebPage,
} from "@shared/models/types";
import type { User } from "@shared/models/User";
import type { UserDelegation } from "@shared/models/UserDelegation";
import { getUnmergeableFieldsFromMergeableContacts } from "core/helpers/contactMatching";
import type { SearchableContact } from "core/types/contactSearch";
import { isValid, parse } from "date-fns";
import isEmpty from "lodash/isEmpty";
import partition from "lodash/partition";
import { getCurEpochMs } from "utils/dateTime";
import { objKeys, removeUndefinedFromObj } from "utils/object";
import { getMergedTextsByLineBreak } from "utils/string";
import uuid from "utils/uuid";

/**
 * Use date-fns isValid to see if date string is valid.
 * @param date Date in yyyy-MM-dd format
 * @returns Whether or not date is valid
 */
export function isValidDateString(date?: string): boolean {
  if (!date) return false;

  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  return isValid(parsedDate);
}

/*
 * todo:  sanitizeContactData below is a copy of
 *  web/helpers/contact.ts:sanitizeContactData, so
 *  we need to bundle this out in the future
 *
 */
export function sanitizeContactData<T extends ContactRow | Partial<ContactRow>>(
  contactData: T
): { validContact: T; invalidContact?: T } {
  const [validEmails, invalidEmails] = partition(
    contactData?.emails,
    (email) => !!email.value?.trim()
  );
  const [validPhoneNumbers, invalidPhoneNumbers] = partition(
    contactData?.phoneNumbers,
    (phoneNumber) => !!phoneNumber.value?.trim()
  );
  const [validImHandles, invalidImHandles] = partition(
    contactData?.imHandles,
    (imHandle) => !!imHandle.value?.trim()
  );
  const [validWebPages, invalidWebPages] = partition(
    contactData?.webPages,
    (webPage) => !!webPage.value?.trim()
  );
  const [validPhysicalAddresses, invalidPhysicalAddresses] = partition(
    contactData?.physicalAddresses,
    (physicalAddress) =>
      !!physicalAddress.street?.trim() ||
      !!physicalAddress.line2?.trim() ||
      !!physicalAddress.city?.trim() ||
      !!physicalAddress.postalCode?.trim() ||
      !!physicalAddress.state?.trim() ||
      !!physicalAddress.country?.trim()
  );
  const [validDates, invalidDates] = partition(contactData?.dates, (date) =>
    isValidDateString(date.value)
  );
  const [validRelatives, invalidRelatives] = partition(
    contactData?.relatives,
    (relative) => !!relative.value?.trim()
  );

  const validContact = {
    ...contactData,
    emails: validEmails,
    phoneNumbers: validPhoneNumbers,
    imHandles: validImHandles,
    webPages: validWebPages,
    physicalAddresses: validPhysicalAddresses,
    dates: validDates,
    relatives: validRelatives,
  };
  const invalidContact: ContactRow | Partial<ContactRow> = {
    emails: invalidEmails,
    phoneNumbers: invalidPhoneNumbers,
    imHandles: invalidImHandles,
    webPages: invalidWebPages,
    physicalAddresses: invalidPhysicalAddresses,
    dates: invalidDates,
    relatives: invalidRelatives,
  };
  const hasInvalidValues =
    !isEmpty(invalidEmails) ||
    !isEmpty(invalidPhoneNumbers) ||
    !isEmpty(invalidImHandles) ||
    !isEmpty(invalidWebPages) ||
    !isEmpty(invalidPhysicalAddresses) ||
    !isEmpty(invalidDates) ||
    !isEmpty(invalidRelatives);

  return {
    validContact: validContact,
    invalidContact: hasInvalidValues && (invalidContact as any),
  };
}

export function getUserImportLastUpdate(userId: User["id"]): {
  lastUpdatedBy: ContactRow["lastUpdatedBy"];
  lastUpdatedByUserId: ContactRow["lastUpdatedByUserId"];
  updatedAt: number;
} {
  return {
    lastUpdatedBy: "user-import",
    lastUpdatedByUserId: userId,
    updatedAt: getCurEpochMs(),
  };
}

export function getUserLastUpdate(userId: User["id"]): {
  lastUpdatedByUserId: ContactRow["lastUpdatedByUserId"];
  updatedAt: number;
} {
  return {
    lastUpdatedByUserId: userId,
    updatedAt: getCurEpochMs(),
  };
}

export function getUserConfResolutionLastUpdate(
  delegatedUserId?: UserDelegation["delegatedUserId"]
) {
  return "user-conf-res" + (delegatedUserId ? `_${delegatedUserId}` : "");
}

export function isForcedUpstream({
  entity,
  type,
}:
  | { entity: ContactGroupVersion; type: "contactGroupVersion" }
  | { entity: ContactGroup; type: "contactGroup" }
  | {
      entity: ContactVersion;
      type: "contactVersion";
    }): boolean {
  if (type === "contactGroupVersion") {
    const { curContactGroup } = entity;
    // auto rotated date groups will always override remote
    return isSmartGroup(curContactGroup);
  }

  if (type === "contactGroup") {
    return isSmartGroup(entity);
  }

  return Boolean(entity.lastUpdatedBy && entity.lastUpdatedBy.startsWith("user-conf-res"));
}

export function getEntityId({
  id,
  remoteApiId,
  isContactGroup,
  isProspect,
}:
  | {
      id: string;
      remoteApiId: RemoteApi["id"];
      isContactGroup: boolean;
      isProspect?: undefined;
    }
  | {
      id: string;
      remoteApiId?: undefined;
      isContactGroup?: false;
      isProspect: true;
    }
  | {
      id: string;
      remoteApiId?: undefined;
      isContactGroup: boolean;
      isProspect?: undefined;
    }): Contact["id"] | ContactGroup["id"] {
  const idFragments = isProspect ? [id, "prospect"] : [id, remoteApiId];
  const uid = uuid(idFragments.join("_"));
  if (isContactGroup) return `${uid}_group`;
  return uid;
}

export function isGroupEntityId(id: string) {
  return id.endsWith("_group");
}

export function pickDbRowFields(row: Partial<ContactRow>): ContactDbRowFields {
  const isDoNotSync = row.isDoNotSync === IsDoNotSync.YES ? IsDoNotSync.YES : IsDoNotSync.NO;
  const isReadOnly = row.isReadOnly === IsReadOnly.YES ? IsReadOnly.YES : IsReadOnly.NO;
  const isDeleted = row.isDeleted === IsDeleted.YES ? IsDeleted.YES : IsDeleted.NO;

  const userId_isDeleted = row.userId_isDeleted || `${row.userId}_${isDeleted}`;

  const userId_isReadOnly = row.userId_isReadOnly || `${row.userId}_${isReadOnly}`;
  const userId_isDoNotSync = row.userId_isDoNotSync || `${row.userId}_${isDoNotSync}`;

  const {
    lastUpdatedBy = "",
    updatedAt = 0,
    createdAt = 0,
    sourceUpdatedAt = 0,
    sourceRemoteApiId,
    lastUpdatedByUserId,
  } = row;

  return {
    userId_isDoNotSync,
    isDeleted,
    userId_isDeleted,
    lastUpdatedBy,
    updatedAt,
    createdAt,
    sourceUpdatedAt,
    sourceRemoteApiId,
    lastUpdatedByUserId,
    isDoNotSync,
    isReadOnly,
    userId_isReadOnly,
  };
}

export function pickContactFields(row: Partial<ContactRow> = {}) {
  const {
    userId,
    id,
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday,
    companyName,
    departmentName,
    jobPositions,
    jobTitle,
    managerName,
    notes,
    pictureUrl,
    emails,
    relatives,
    dates,
    imHandles,
    physicalAddresses,
    phoneNumbers,
    webPages,
    source,
    reminderFrequency,
  } = row;
  return {
    userId,
    id,
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday,
    companyName,
    departmentName,
    jobPositions,
    jobTitle,
    managerName,
    notes,
    pictureUrl,
    emails,
    relatives,
    dates,
    imHandles,
    physicalAddresses,
    phoneNumbers,
    webPages,
    source,
    reminderFrequency,
  };
}

export function pickContactFieldsToMerge(
  row: Partial<ContactRow | Contact>,
  ignoreFields: string[] = [], // nodes within contact field
  ignoreKeys: string[] = [] // contact root
) {
  const {
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday,
    companyName,
    departmentName,
    jobTitle,
    managerName,
    notes,
    emails,
    relatives,
    dates,
    // photos,
    imHandles,
    physicalAddresses,
    phoneNumbers,
    webPages,
  } = row;

  return {
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday,
    companyName,
    departmentName,
    jobTitle,
    managerName,
    notes,
    emails,
    relatives,
    dates,
    // photos,
    imHandles,
    physicalAddresses,
    phoneNumbers,
    webPages,
  };
}

export function jsonContactToContactRow(
  contactJson: any[],
  userId: string,
  isDoNotSync: IsDoNotSync = IsDoNotSync.NO
) {
  // sanitize json object and convert to ContactRow[]
  const contactRows: ContactRow[] = [];
  const updatedAt = getCurEpochMs();

  contactJson.forEach((contactRow) => {
    const { validContact: sanitizedContact } = sanitizeContactData(contactRow);
    const newContact = {
      ...sanitizedContact,
      id: getEntityId({ id: uuid(), isContactGroup: false, remoteApiId: "" }),
      userId,
      source: "user",
      isDeleted: IsDeleted.NO,
      isDoNotSync,
      updatedAt,
      userId_isDoNotSync: `${userId}_${
        isDoNotSync === IsDoNotSync.YES ? IsDoNotSync.YES : IsDoNotSync.NO
      }`,
      userId_isDeleted: `${userId}_${IsDeleted.NO}`,
      userId_isReadOnly: `${userId}_${IsDeleted.NO}`,
    } as ContactRow;
    contactRows.push(newContact);
  });

  return contactRows;
}

function removePlaceKeyInfo(contact: ContactRow) {
  const physicalAddresses = [];
  for (const addr of contact.physicalAddresses || []) {
    const { placeKey, lat, long, ...rest } = addr;
    physicalAddresses.push(rest);
  }
  return physicalAddresses;
}

export function getContactBodyForReconciliation(contact: ContactRow) {
  // this method is mainly used by the reconciliation report, while reusing,
  // please be cautious with any changes you make here.

  const { surname, givenName, middleName, suffix, prefix } = contact;
  const { companyName, departmentName } = contact;
  const { phoneNumbers } = contact;
  const { webPages } = contact;
  const physicalAddresses = removePlaceKeyInfo(contact);
  const { emails, photos, imHandles, dates, relatives } = contact;
  return removeUndefinedFromObj<Omit<Contact, "userId" | "id" | "updatedAt">>({
    birthday: contact.birthday || undefined,
    companyName: companyName || undefined,
    departmentName: departmentName || undefined,
    emails: emails && emails.length > 0 ? emails : undefined,
    givenName: givenName || undefined,
    jobTitle: contact.jobTitle || undefined,
    middleName: middleName || undefined,
    nickname: contact.nickname || undefined,
    notes: contact.notes || undefined,
    imHandles: imHandles && imHandles.length > 0 ? imHandles : undefined,
    photos: photos || undefined,
    dates: dates && dates.length > 0 ? dates : undefined,
    relatives: relatives && relatives.length > 0 ? relatives : undefined,
    prefix: prefix || undefined,
    suffix: suffix || undefined,
    surname: surname ? surname.replace(EMPTY_PLACEHOLDER_FOR_SORT, "") : "",
    phoneNumbers: phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers : undefined,
    webPages: webPages && webPages.length > 0 ? webPages : undefined,
    physicalAddresses:
      physicalAddresses && physicalAddresses.length > 0 ? physicalAddresses : undefined,
  });
}

export const unmergeableFields: Partial<keyof ContactScalarType>[] = [
  "prefix",
  "givenName",
  "middleName",
  "nickname",
  "surname",
  "suffix",
  "managerName",
  "companyName",
  "departmentName",
  "jobTitle",
  "birthday",
];

export function getUnmergeableFields(contact: Partial<ContactRow | PendingContactRow>) {
  const result: Partial<Record<(typeof unmergeableFields)[number], string | undefined>> = {};

  for (const field of unmergeableFields) {
    switch (field) {
      case "surname":
        result[field] = (contact?.["surname"] || "").replace(EMPTY_PLACEHOLDER_FOR_SORT, "");
        break;
      default:
        result[field] = contact?.[field];
    }
  }

  return result;
}

export function getMergeableFields(contact: Partial<ContactRow | PendingContactRow>) {
  const {
    emails,
    phoneNumbers,
    photos,
    physicalAddresses,
    dates,
    webPages,
    relatives,
    imHandles,
    jobPositions,
    notes,
  } = contact;

  return {
    emails,
    phoneNumbers,
    photos,
    physicalAddresses,
    dates,
    webPages,
    relatives,
    imHandles,
    jobPositions,
    notes,
  };
}

/**
 * Get a list mergeable fields names from a list of contacts
 * @param contacts
 */
export function getAllMergeableFields(contacts: Partial<ContactRow | PendingContactRow>[]) {
  const fields: { [field: string]: true } = {};
  for (const contact of contacts) {
    for (const field in getMergeableFields(contact)) {
      const val = contact[field as keyof typeof contact];
      if (!val) continue;
      if (Array.isArray(val) && val?.length === 0) continue;

      if (!fields[field]) fields[field] = true;
    }
  }
  return objKeys(fields) as (keyof ReturnType<typeof getMergeableFields>)[];
}

export function getUnmergeableToken(
  contact: Partial<ContactRow | PendingContactRow | SearchableContact>
) {
  const unmergeables = getUnmergeableFields(contact);

  const valueList: string[] = [];
  for (const key in unmergeables) {
    const val = unmergeables[key as keyof typeof unmergeables];
    if (val) valueList.push(val.trim().toLowerCase());
  }

  return valueList.join();
}

function getPhysicalAddressLookupToken(address: PhysicalAddress) {
  return [
    address?.street?.toLowerCase()?.trim() || "",
    address?.line2?.toLowerCase()?.trim() || "",
    address?.city?.toLowerCase()?.trim() || "",
    address?.state?.toLowerCase()?.trim() || "",
  ].join();
}

export function getMergedContact(
  contact: Partial<ContactRow>,
  pendingContact: Partial<PendingContactRow>
) {
  const allMergeableFields = getAllMergeableFields([contact, pendingContact]);

  const mergedContact: Partial<ContactRow | PendingContactRow> =
    getUnmergeableFieldsFromMergeableContacts([contact, pendingContact]);

  // merge rest of fields
  for (const fieldKey of allMergeableFields) {
    switch (fieldKey) {
      case "physicalAddresses": {
        const contactValueList = contact[fieldKey];
        const pendingContactValueList = pendingContact[fieldKey];
        if (!contactValueList && !pendingContactValueList) continue;

        const contactValueIndex: {
          [value: string]: PhysicalAddress;
        } = {};
        for (const item of contactValueList || []) {
          const lookupKey = getPhysicalAddressLookupToken(item);
          if (lookupKey) contactValueIndex[lookupKey] = item;
        }

        for (const item of pendingContactValueList || []) {
          const lookupKey = getPhysicalAddressLookupToken(item);

          if (!lookupKey) continue;
          if (contactValueIndex[lookupKey]) {
            for (const key in item) {
              const k = key as keyof typeof item;
              if (typeof contactValueIndex[lookupKey][k] === "undefined") {
                // get other fields from pending > TD if TD field is not defined.
                // @ts-ignore
                contactValueIndex[lookupKey][k] = item[k];
              }
            }
          } else {
            contactValueIndex[lookupKey] = item;
          }
        }

        break;
      }
      case "emails":
      case "phoneNumbers":
      case "photos":
      case "webPages":
      case "imHandles":
      case "dates":
      case "relatives": {
        const contactValueList = contact[fieldKey];
        const pendingContactValueList = pendingContact[fieldKey];

        // both empty, skip altogether
        if (!contactValueList && !pendingContactValueList) continue;

        // create index based on values from TD contact side
        const contactValueIndex: {
          [value: string]:
            | Email
            | Photo
            | Relative
            | ImHandle
            | PhoneNumber
            | WebPage
            | ArbitraryDate;
        } = {};
        for (const item of contactValueList || []) {
          const value = (item.value || "").toLowerCase().trim();
          if (value) contactValueIndex[value] = item;
        }

        for (const item of pendingContactValueList || []) {
          const value = (item.value || "").toLowerCase().trim();
          if (!value) continue;
          if (contactValueIndex[value]) {
            for (const key in item) {
              const k = key as keyof typeof item;
              if (!item[k]) continue;
              if (typeof contactValueIndex[value][k] === "undefined") {
                // get other fields from pending > TD if TD field is not defined.
                // @ts-ignore
                contactValueIndex[value][k] = item[k];
              }
            }
          } else {
            contactValueIndex[value] = item;
          }
        }

        // @ts-ignore
        mergedContact[fieldKey] = objKeys(contactValueIndex).map((key) => contactValueIndex[key]);
      }
    }
  }

  return mergedContact;
}

export function getMergedContactFromMergeableContacts(
  contacts: (Contact | ContactRow)[],
  mainContact?: Contact | ContactRow,
  preserveMainContactValues = false
) {
  const mergedContact: Partial<ContactRow | PendingContactRow> =
    getUnmergeableFieldsFromMergeableContacts(
      preserveMainContactValues && mainContact
        ? [mainContact, ...contacts.filter((c) => c?.id !== mainContact.id)]
        : contacts,
      preserveMainContactValues
    );

  const allMergeableFields = getAllMergeableFields(contacts);

  for (const field of allMergeableFields) {
    switch (field) {
      case "physicalAddresses": {
        const valueList = contacts.map((contact) => contact[field] || []);
        const contactValueIndex: {
          [value: string]: PhysicalAddress;
        } = {};
        for (const value of valueList) {
          for (const item of value) {
            const lookupKey = getPhysicalAddressLookupToken(item);
            if (lookupKey) contactValueIndex[lookupKey] = item;
          }
        }

        mergedContact[field] = [];

        // use main contact to preserve order
        if (mainContact) {
          const mainContactValueList = mainContact[field] || [];
          for (const item of mainContactValueList) {
            const lookupKey = getPhysicalAddressLookupToken(item);
            mergedContact[field]?.push(contactValueIndex[lookupKey]);
            delete contactValueIndex[lookupKey];
          }
        }

        for (const key in contactValueIndex) {
          mergedContact[field]?.push(contactValueIndex[key]);
        }

        break;
      }
      case "notes": {
        const sortedContacts = contacts.sort((a, b) => {
          return (a?.updatedAt || 0) - (b?.updatedAt || 0);
        });
        const notes = sortedContacts.map(({ notes }) => notes || "");
        mergedContact[field] = getMergedTextsByLineBreak(notes);
        break;
      }
      case "emails":
      case "phoneNumbers":
      case "photos":
      case "webPages":
      case "imHandles":
      case "dates":
      case "relatives": {
        const valueList = contacts.map((contact) => contact[field] || []);

        // create index based on values from TD contact side
        const contactValueIndex: {
          [value: string]: {
            item: Email | Photo | Relative | ImHandle | PhoneNumber | WebPage | ArbitraryDate;
            length: number;
          };
        } = {};
        for (const value of valueList) {
          for (const item of value) {
            const value = (item.value || "").toLowerCase().trim();
            const curLength = Object.keys(item).length;
            if (value && curLength > (contactValueIndex[value]?.length || 0))
              contactValueIndex[value] = { item, length: curLength };
          }
        }

        mergedContact[field] = [];

        // use main contact to preserve order
        if (mainContact) {
          if (!mergedContact[field]) mergedContact[field] = [];
          for (const item of mainContact[field] || []) {
            const value = (item.value || "").toLowerCase().trim();
            if (!value) continue;
            if (contactValueIndex[value] && contactValueIndex[value].item) {
              // @ts-ignore
              mergedContact[field]?.push(contactValueIndex[value].item);
              delete contactValueIndex[value];
            }
          }
        }

        for (const key in contactValueIndex) {
          if (contactValueIndex[key] && contactValueIndex[key].item) {
            // @ts-ignore
            mergedContact[field]?.push(contactValueIndex[key].item);
          }
        }
      }
    }
  }

  return mergedContact;
}

export function isContactScalarField(fieldKey: string) {
  const scalars: { [key in keyof ContactScalarType]: true } = {
    givenName: true,
    middleName: true,
    surname: true,
    suffix: true,
    prefix: true,
    nickname: true,
    birthday: true,
    notes: true,
    companyName: true,
    departmentName: true,
    jobTitle: true,
    managerName: true,
  };
  return Boolean(scalars[fieldKey as keyof ContactScalarType]);
}

export function isContactListField(fieldKey: string) {
  const listFields: { [key in keyof ContactListType]: true } = {
    emails: true,
    relatives: true,
    dates: true,
    photos: true,
    imHandles: true,
    physicalAddresses: true,
    phoneNumbers: true,
    webPages: true,
    jobPositions: true,
  };

  return Boolean(listFields[fieldKey as keyof ContactListType]);
}

export function pickMyContactFields(contact: ContactRow, sharedPaths: MyContactSharedPaths) {
  const contactToShare: Omit<Contact, "userId" | "id"> = {
    surname: contact.surname,
    updatedAt: getCurEpochMs(),
  };

  for (let path in sharedPaths) {
    const pathValue = sharedPaths[path];

    if (path.startsWith("/")) {
      // remove first slash
      path = path.slice(1);
    }

    // if path value is true/false, then we will either skip or include entire field
    if (typeof pathValue === "boolean" || isContactScalarField(path)) {
      // @ts-ignore
      contactToShare[path as keyof ContactRow] = contact[path as keyof ContactRow];
      continue;
    }

    // not boolean, so pick individual values in field
    for (const value in pathValue) {
      if (!value) continue;
      if (isContactListField(path)) {
        const k = path as keyof ContactListType;
        switch (k) {
          case "emails":
          case "phoneNumbers":
          case "webPages":
          case "imHandles":
          case "relatives":
          case "dates": {
            const field = contact[k];
            for (const item of field || []) {
              if (item.value === value) {
                if (!contactToShare[k]) contactToShare[k] = [];
                contactToShare[k]!.push(item);
              }
            }
            break;
          }

          // todo - physicalAddress doesn't always have id
          case "physicalAddresses":
          case "photos": {
            const field = contact[k];
            for (const item of field || []) {
              if (item.id && item.id === value) {
                if (!contactToShare[k]) contactToShare[k] = [];
                // @ts-ignore
                contactToShare[k]!.push(item);
              }
            }
          }
        }
      }
    }
  }

  return contactToShare;
}

export function getPartialDbRowFields({
  userId,
  isDeleted,
  isDoNotSync,
  isReadOnly,
  lastUpdatedBy,
  lastUpdatedByUserId,
  updatedAt,
  sourceUpdatedAt,
  sourceRemoteApiId,
  ...rest
}: {
  userId: User["id"];
} & Partial<ContactDbRowFields>): Partial<ContactDbRowFields> {
  const isDeletedVal = isDeleted === IsDeleted.YES ? IsDeleted.YES : IsDeleted.NO;
  const isDoNotSyncVal = isDoNotSync === IsDoNotSync.YES ? IsDoNotSync.YES : IsDoNotSync.NO;
  const isReadOnlyVal = isReadOnly === IsReadOnly.YES ? IsReadOnly.YES : IsReadOnly.NO;

  return removeUndefinedFromObj({
    etag: uuid(),
    updatedAt,
    sourceRemoteApiId,
    sourceUpdatedAt,
    isDeleted: isDeletedVal,
    isDoNotSync: isDoNotSyncVal,
    isReadOnly: isReadOnlyVal,
    userId_isDeleted: `${userId}_${isDeletedVal}`,
    userId_isDoNotSync: `${userId}_${isDoNotSyncVal}`,
    userId_isReadOnly: `${userId}_${isReadOnlyVal}`,
    lastUpdatedBy,
    lastUpdatedByUserId,
    ...rest,
  });
}

export function getAllDbRowFields({
  userId,
  isDeleted,
  isDoNotSync = IsDoNotSync.NO,
  isReadOnly = IsReadOnly.NO,
  lastUpdatedBy,
  lastUpdatedByUserId,
  updatedAt,
  sourceUpdatedAt,
  sourceRemoteApiId,
}: {
  userId: User["id"];
} & ContactDbRowFields): ContactDbRowFields {
  return getPartialDbRowFields({
    userId,
    isDoNotSync,
    isReadOnly,
    isDeleted,
    lastUpdatedBy,
    lastUpdatedByUserId,
    updatedAt,
    sourceUpdatedAt,
    sourceRemoteApiId,
  }) as ContactDbRowFields;
}

export function getContactForDisplayFromDbRow(row: ContactRow | null | undefined): ContactRow {
  if (!row) return {} as ContactRow;
  return {
    ...row,
    surname:
      row.surname === EMPTY_PLACEHOLDER_FOR_SORT
        ? row.surname.replace(EMPTY_PLACEHOLDER_FOR_SORT, "")
        : row.surname,
  } as ContactRow;
}

export function getRequiredDbRowFromContactForUpdate(
  row: Partial<Contact>,
  source?: ContactRow["source"]
): ContactRow {
  const updatedAt = getCurEpochMs();
  const sourceUpdatedAt = updatedAt; // once user updates contact, sourceUpdatedAt = updatedAt
  const updatedRow = { ...row } as ContactRow;

  if (!row.surname) {
    updatedRow.surname = EMPTY_PLACEHOLDER_FOR_SORT;
  }

  if (source) updatedRow.source = source;

  return { ...updatedRow, updatedAt, sourceUpdatedAt };
}

export function getContactRowFromContact({
  contact,
  userId,
  userIdToQuery,
  userLastUpdate,
  lastUpdatedBy,
  source,
}: {
  contact: Contact | (Omit<Contact, "id"> & { id?: string });
  userId: string;
  userIdToQuery?: string;
  userLastUpdate?: ReturnType<typeof getUserLastUpdate>;
  lastUpdatedBy: ContactRow["lastUpdatedBy"];
  source?: ContactSource | undefined;
}): ContactRow {
  const { id: userSpecifiedId, ...body } = contact;

  const contactFields = pickContactFields({
    ...getRequiredDbRowFromContactForUpdate(body, source),
  }) as ContactRow;

  const contactRow = {
    ...contactFields,
    ...getPartialDbRowFields({
      ...(userLastUpdate || getUserLastUpdate(userId)),
      userId: userIdToQuery || userId,
      isDeleted: IsDeleted.NO,
      updatedAt: getCurEpochMs(),
    }),
    lastUpdatedBy,
    userId: userIdToQuery || userId,
    id: userSpecifiedId || getEntityId({ id: uuid(), isContactGroup: false, remoteApiId: "" }),
  };

  if (source) {
    contactRow.source = source;
  }

  return contactRow;
}
