import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import classNames from "clsx";
import { getFirstLastName } from "core/helpers/contact";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import AccountAddModal from "@/components/accounts/AccountAddModal";
import Button, { ButtonVariant } from "@/components/core/Button";
import { SecondaryHoverColor } from "@/components/core/colorVariant";
import { PlusIcon } from "@/components/core/Icon";
import { Monochrome as MonochromeVendorIcon } from "@/components/core/VendorIcon";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";
import { useGetUserQuery } from "@/integrations/user/api";

const EmailAccountSelection: FC<{
  draft: LocallyPersistedEmailDraft;
  remoteApiWithEmailSend: RemoteApiForDisplay[];
  selectedRemoteApiId: string | undefined;
  updateDraft: (update: Partial<Omit<LocallyPersistedEmailDraft, "id">>) => Promise<void>;
}> = ({ remoteApiWithEmailSend, selectedRemoteApiId, updateDraft }) => {
  const { data: userProfilePayload } = useGetUserQuery();

  const defaultSelectedRemoteApi = useMemo(() => {
    if (selectedRemoteApiId)
      return remoteApiWithEmailSend.find(({ id }) => id === selectedRemoteApiId);
    return remoteApiWithEmailSend[0];
  }, [remoteApiWithEmailSend, selectedRemoteApiId]);

  const [selected, setSelected] = useState<RemoteApiForDisplay | undefined>(
    defaultSelectedRemoteApi,
  );

  const getSenderName = useCallback(
    (remoteApi: RemoteApiForDisplay) => {
      return (
        remoteApi.displayName ||
        userProfilePayload?.user.displayName ||
        getFirstLastName(userProfilePayload?.user || {})
      );
    },
    [userProfilePayload?.user],
  );

  useEffect(() => {
    if (!selected && remoteApiWithEmailSend.length > 0 && !selectedRemoteApiId) {
      const defaultSelected = remoteApiWithEmailSend[0];
      setSelected(defaultSelected);
      updateDraft({
        remoteApiId: defaultSelected.id,
        from: { email: defaultSelected.auth.username || "", name: getSenderName(defaultSelected) },
      });
      return;
    }

    if (selected?.id !== selectedRemoteApiId) {
      if (selected) {
        const name = getSenderName(selected);
        updateDraft({
          remoteApiId: selected.id,
          from: { email: selected.auth.username || "", name },
        });
      } else {
        updateDraft({ remoteApiId: undefined, from: undefined });
      }
    }
  }, [
    getSenderName,
    remoteApiWithEmailSend,
    selected,
    selectedRemoteApiId,
    updateDraft,
    userProfilePayload?.user,
  ]);

  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);

  return (
    <div className="flex items-center w-full pt-2">
      <div className="flex items-center text-secondary">
        <div className="ml-4 text-label text-sm pr-4 text-right">From</div>
      </div>

      <div className="flex flex-1 items-center">
        {remoteApiWithEmailSend.length === 0 && (
          <Button
            variant={ButtonVariant.Secondary}
            noGutter
            className="py-1 px-3"
            icon={<PlusIcon />}
            onClick={() => setIsAddAccountModalOpen(true)}
          >
            Add Email Account
          </Button>
        )}

        {selected && (
          <div className="bg-primary py-1.5 pr-10 text-left text-primary text-sm">
            {selected?.auth.username}
          </div>
        )}

        {remoteApiWithEmailSend.length > 1 && (
          <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
              <div className="relative flex-1">
                <ListboxButton className="relative w-full cursor-pointer rounded-md bg-primary py-1.5 pl-3 pr-10 text-left text-primary text-sm">
                  <span className="flex items-center">
                    <span className="block truncate">{selected?.auth?.username || ""}</span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions className="absolute z-10 mt-1 w-full max-h-56 overflow-auto rounded-md bg-primary py-1 shadow-lg text-sm">
                    {remoteApiWithEmailSend.map((remoteApi) => (
                      <ListboxOption
                        key={remoteApi.id}
                        className={({ focus }) =>
                          classNames(
                            focus ? SecondaryHoverColor : "",
                            "relative cursor-default select-none py-2 pl-3 pr-9 text-secondary text-sm",
                          )
                        }
                        value={remoteApi}
                      >
                        {({ selected, focus }) => (
                          <>
                            <div className="flex items-center">
                              <MonochromeVendorIcon
                                integration={remoteApi}
                                className="h-5 w-5 flex-shrink-0"
                              />
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-3 block truncate",
                                )}
                              >
                                {remoteApi.auth.username || "asdfasfasdf"}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  focus ? SecondaryHoverColor : "text-secondary",
                                  "absolute inset-y-0 right-0 flex items-center pr-4 text-sm",
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Transition>
              </div>
            )}
          </Listbox>
        )}
      </div>
      <AccountAddModal
        title="Add Email Account"
        description="Only Google accounts are supported at this time. More providers coming soon."
        hasRemoteApi={false}
        isOpen={isAddAccountModalOpen}
        setIsOpen={setIsAddAccountModalOpen}
        filterPredicate={(item) => item.name === "Google"}
      />
    </div>
  );
};

export default EmailAccountSelection;
