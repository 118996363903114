import { RadioGroup } from "@headlessui/react";
import { ArrowLeftCircleIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { IsDefault } from "@shared/models/types";
import classNames from "clsx";
import { LocallyPersistedContactRow } from "core/helpers/contact";
import { ContactGroupRecipient } from "core/types/userMessaging";
import { useLiveQuery } from "dexie-react-hooks";
import { FC, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CheckmarkIcon } from "react-hot-toast";
import { capitalizeFirstLetter, pluralize } from "utils/string";

import AlphabetList from "@/components/contacts/list/AlphabetList";
import ContactListItem from "@/components/contacts/list/ContactListItem";
import { useGroupedListControls } from "@/components/contacts/list/hooks";
import SearchInputWithChainableFilter from "@/components/contacts/search/SearchInputWithChainableFilter";
import ContactDetails from "@/components/contacts/v2/ContactDetails";
import { ContactData } from "@/components/contacts/v2/types";
import Alert from "@/components/core/Alert";
import { DangerBadge } from "@/components/core/Badge";
import Button, { ButtonVariant } from "@/components/core/Button";
import { WarningColor } from "@/components/core/colorVariant";
import { EnvelopesIcon, ExternalLinkSolidIcon, TagIcon } from "@/components/core/Icon";
import {
  getEmailType,
  getRfc5322FromContact,
  getSendToEmailFromContact,
} from "@/components/email/helpers";
import { useJumpToContactInList } from "@/components/email/hooks";
import GroupedVirtualizedList from "@/components/GroupedVirtualizedList";
import LoadingSpinner from "@/components/loading/LoadingSpinner";
import { getContactDb } from "@/database/contactDb";
import {
  SortedContact,
  useLiveContactListSearchWithFilters,
  useSortedContactsGroupedByLetter,
} from "@/hooks/data/useLiveContactList";
import { getIdIndex } from "@/integrations/contact/helpers";

type EmailTypeSelectionId = "defaultEmail" | "lastSent" | "emailType";

const EmailRecipientContactGroupOptionModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  contactGroupRecipient: ContactGroupRecipient;
  entity: ContactGroupRowForDisplay | undefined;
  replaceRecipient: (id: string, recipient: ContactGroupRecipient) => void;
}> = ({ contactGroupRecipient, entity, replaceRecipient, isOpen, onClose }) => {
  const data = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    const allContacts = await frontendDb?.contacts.bulkGet(entity?.contactIds || []);

    let numDefaultEmails: number = 0;
    let numSingleEmail = 0;
    const emailTypes = new Set<string>();

    const validContacts = [];

    for (const contact of allContacts || []) {
      if (!contact || !contact.emails) continue;

      validContacts.push(contact);

      if (contact.emails.length === 1) {
        numSingleEmail++;
      }

      if (contact.emails.some((e) => e.isDefault === IsDefault.YES)) {
        numDefaultEmails++;
      }

      for (const email of contact.emails) {
        const val = email.type?.toLowerCase().trim();
        if (!val || val === "internet") continue;
        emailTypes.add(val);
      }
    }

    return {
      contacts: validContacts,
      numDefaultEmails,
      numSingleEmail,
      emailTypes: Array.from(emailTypes),
    };
  }, [entity]);

  const contactIdIndex = useMemo(() => {
    return getIdIndex(data?.contacts || []);
  }, [data?.contacts]);

  const idToContact = useMemo(() => {
    const idToC: Record<string, ContactData> = {};
    for (const contact of data?.contacts || []) {
      if (!contact) continue;
      idToC[contact.id] = contact;
    }
    return idToC;
  }, [data?.contacts]);

  const selectedContactGroups = useMemo(() => {
    return entity ? [entity] : undefined;
  }, [entity]);

  const [contactIdToOpen, setContactIdToOpen] = useState<string | undefined>();
  const [selectedContact, setSelectedContact] = useState<{ id: string } | undefined>(undefined);
  const [multiSelectedContacts, setMultiSelectedContacts] = useState<{ id: string }[]>([]);
  const [emailTypeToSelect, setEmailTypeToSelect] = useState<EmailTypeSelectionId>("defaultEmail");
  const [recipientState, setRecipientState] = useState<{
    userExcludedContactIdIndex: { [excludedContactId: string]: true };
    contactIdEmail:
      | {
          [contactId: string]: string;
        }
      | undefined;
  }>({
    userExcludedContactIdIndex: Object.fromEntries(
      contactGroupRecipient.excludedContactIds?.map((id) => [id, true]) || [],
    ),
    contactIdEmail: contactGroupRecipient.emailAddresses,
  });
  const [showMultiEmailContactsOnly, setShowMultiEmailContactOnly] = useState(false);

  const contactToOpen = useMemo(() => {
    if (!contactIdToOpen) return;
    return data?.contacts?.[contactIdIndex[contactIdToOpen]];
  }, [contactIdIndex, contactIdToOpen, data?.contacts]);

  const setUserExcludedContactIndex = useCallback(
    (
      state:
        | (typeof recipientState)["userExcludedContactIdIndex"]
        | ((
            prevState: (typeof recipientState)["userExcludedContactIdIndex"],
          ) => (typeof recipientState)["userExcludedContactIdIndex"]),
    ) => {
      setRecipientState((prev) => ({
        ...prev,
        userExcludedContactIdIndex:
          typeof state === "function" ? state(prev.userExcludedContactIdIndex) : state,
      }));
    },
    [],
  );

  const setContactIdEmail = useCallback(
    (
      state:
        | (typeof recipientState)["contactIdEmail"]
        | ((
            prevState: (typeof recipientState)["contactIdEmail"],
          ) => (typeof recipientState)["contactIdEmail"]),
    ) => {
      setRecipientState((prev) => ({
        ...prev,
        contactIdEmail: typeof state === "function" ? state(prev.contactIdEmail) : state,
      }));
    },
    [],
  );

  const selectAllInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (selectAllInputRef.current) {
      selectAllInputRef.current.indeterminate =
        Object.keys(recipientState.userExcludedContactIdIndex || {}).length > 0 &&
        Object.keys(recipientState.userExcludedContactIdIndex || {}).length <
          (data?.contacts?.length || 0);
    }
  }, [data?.contacts?.length, recipientState.userExcludedContactIdIndex]);

  const contacts = useMemo(() => {
    if (showMultiEmailContactsOnly) {
      return data?.contacts.filter((contact) => contact && (contact?.emails?.length || 0) > 1);
    }
    return data?.contacts || [];
  }, [data?.contacts, showMultiEmailContactsOnly]);

  const { filteredContacts, setSelectedFilters, selectedFilters } =
    useLiveContactListSearchWithFilters({
      selectedContactGroups,
      idToContact,
      allContacts: (contacts as LocallyPersistedContactRow[]) || [],
    });

  const { alphabetKeys, alphabetKeyCounts, flattenedSortedContacts } =
    useSortedContactsGroupedByLetter(
      showMultiEmailContactsOnly
        ? ((contacts || []) as LocallyPersistedContactRow[])
        : (filteredContacts as LocallyPersistedContactRow[]),
    );

  useEffect(() => {
    if (!data?.contacts || recipientState.userExcludedContactIdIndex) return;
    const state: { [excludedContactId: string]: true } = {};
    for (const contact of data.contacts) {
      if (contact && (contact?.emails?.length || 0) === 0) {
        state[contact.id] = true;
      }
    }
    setUserExcludedContactIndex(state);
  }, [data?.contacts, recipientState.userExcludedContactIdIndex, setUserExcludedContactIndex]);

  // default selections
  useEffect(() => {
    if (!data?.contacts || recipientState.contactIdEmail) return;
    let state: { [contactId: string]: string } | undefined = undefined;
    for (const contact of data.contacts) {
      if (contact && contact?.emails && contact.emails.length > 0) {
        const email = getSendToEmailFromContact(contact);
        if (!email || !email.value) continue;

        if (!state) state = {};
        state[contact.id] = getRfc5322FromContact(contact, email.value);
      }
    }
    setContactIdEmail(state);
  }, [data?.contacts, recipientState.contactIdEmail, setContactIdEmail]);

  const multiEmailContacts = useMemo(() => {
    return contacts?.filter(
      (contact) =>
        recipientState.userExcludedContactIdIndex &&
        !recipientState.userExcludedContactIdIndex[contact.id] &&
        (contact?.emails?.length || 0) > 1,
    );
  }, [contacts, recipientState.userExcludedContactIdIndex]);

  const clearMultiSelectedContacts = useCallback(() => {
    setMultiSelectedContacts([]);
  }, []);

  const { wrapperRef, ref, onClickItem, currentItemIndex, onSelectAlphabetKey } =
    useGroupedListControls({
      items: flattenedSortedContacts.map((contact) => ({ id: contact.id })),
      groupCounts: alphabetKeyCounts,
      selectedItem: selectedContact,
      onKeySelected: setSelectedContact,
      onMultiSelect: setMultiSelectedContacts,
      onClearMultiSelected: clearMultiSelectedContacts,
      multiSelectedItems: multiSelectedContacts,
    });

  const jumpToPredicate = useCallback(
    (contact: SortedContact) => (contact?.emails?.length || 0) > 1,
    [],
  );
  const { setVisibleRange, scrollToPrev, scrollToNext } = useJumpToContactInList(
    flattenedSortedContacts,
    jumpToPredicate,
    ref,
  );

  const onSaveRecipient = useCallback(() => {
    if (!recipientState.contactIdEmail) return;

    // remove contact ids that have been excluded by the user
    const contactIdToEmail: { [contactId: string]: string } = {};

    for (const contactId in recipientState.contactIdEmail) {
      if (recipientState.userExcludedContactIdIndex?.[contactId]) continue;
      contactIdToEmail[contactId] = recipientState.contactIdEmail[contactId];
    }

    const updatedRecipient: ContactGroupRecipient = {
      ...contactGroupRecipient,
      emailAddresses: contactIdToEmail,
      excludedContactIds: Object.keys(recipientState.userExcludedContactIdIndex || {}),
    };

    replaceRecipient(contactGroupRecipient.id, updatedRecipient);
    onClose();
  }, [
    contactGroupRecipient,
    onClose,
    recipientState.contactIdEmail,
    recipientState.userExcludedContactIdIndex,
    replaceRecipient,
  ]);

  const emailSelectionMethods: {
    id: EmailTypeSelectionId;
    title: string;
    description: ReactNode;
    summary: ReactNode;
  }[] = useMemo(
    () => [
      {
        id: "defaultEmail",
        title: "Default or first",
        description: "Use default, use the first email as fallback",
        summary: null,
      },
      // {
      //   id: "lastSent",
      //   title: "Recently used",
      //   description: "Use the most recently used email",
      //   summary: "",
      // },
      {
        id: "emailType",
        title: "By Type",
        description: "Bulk select emails by type",
        summary: data?.emailTypes && (
          <div className="flex items-center">
            <label
              htmlFor="location"
              className="inline text-sm font-medium leading-6 text-secondary"
            >
              Type
            </label>
            <select
              id="emailType"
              className="block w-full rounded-md border-0 ring-1 ring-inset ring-primary pl-3 ml-4 text-sm text-secondary bg-secondary focus:ring-2 focus:ring-indigo-600"
              onChange={(event) => {
                const value = event.target.value;

                const updatedContactIdEmail: { [contactId: string]: string } = {};
                for (const contact of multiEmailContacts || []) {
                  const email = contact.emails?.find((e) => e.type?.trim().toLowerCase() === value);
                  if (email?.value) {
                    updatedContactIdEmail[contact.id] = getRfc5322FromContact(contact, email.value);
                  }
                }
                setContactIdEmail((prev) => {
                  return {
                    ...prev,
                    ...updatedContactIdEmail,
                  };
                });
              }}
            >
              <option value={undefined}>Select email type...</option>
              {data.emailTypes.map((type) => (
                <option key={type} value={type}>
                  {capitalizeFirstLetter(type)}
                </option>
              ))}
            </select>
          </div>
        ),
      },
    ],
    [data?.emailTypes, multiEmailContacts, setContactIdEmail],
  );

  const isLoaded = useMemo(() => data && entity, [data, entity]);

  return (
    <Alert
      isAlertOpen={isOpen}
      variant={ButtonVariant.Success}
      onClose={onClose}
      title={entity?.name ? `Pick contacts in ${entity?.name}` : "Pick contacts"}
      backdropZIndex="z-40"
      width="max-w-3xl"
      className="border border-color-primary bg-primary overflow-hidden ring-1 ring-primary"
      description="Default emails are automatically selected for contacts with multiple emails."
      AlertIcon={<TagIcon size="lg" />}
      cancelButtonTitle="Close"
      actionButtonTitle="Save"
      actionHandler={isLoaded ? onSaveRecipient : undefined}
    >
      {isLoaded ? (
        <div className="relative text-primary space-y-4">
          {multiEmailContacts && multiEmailContacts.length > 0 && (
            <div className={classNames("w-full items-center rounded-md p-4", WarningColor)}>
              <div className="flex gap-x-4">
                <div className="flex-none">
                  <span className="sr-only">Contact with multiple emails</span>
                  <EnvelopesIcon size="lg" />
                </div>

                <div className="text-sm">
                  <div className="flex items-center flex-col sm:flex-row">
                    <div className="flex-1">
                      <span className="font-semibold">{multiEmailContacts.length}</span>{" "}
                      {pluralize(multiEmailContacts.length, "contact", "contacts")} with multiple
                      emails
                    </div>
                    <div className="flex gap-2">
                      <span>Jump to contact:</span>
                      <Button
                        noGutter
                        variant={ButtonVariant.Secondary}
                        className="px-2"
                        iconOnly
                        icon={<ChevronDownIcon />}
                        onClick={scrollToNext}
                      />
                      <Button
                        noGutter
                        variant={ButtonVariant.Secondary}
                        className="px-2"
                        iconOnly
                        icon={<ChevronUpIcon />}
                        onClick={scrollToPrev}
                      />
                    </div>
                  </div>

                  <div className="flex my-2 pr-1 gap-x-2 items-center">
                    <input
                      id="showMultipleOnly"
                      type="checkbox"
                      className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-600"
                      checked={showMultiEmailContactsOnly}
                      onChange={({ target }) => setShowMultiEmailContactOnly(target.checked)}
                    />{" "}
                    <label htmlFor="showMultipleOnly" className="text-secondary">
                      Show only contacts with multiple emails
                    </label>
                  </div>

                  <RadioGroup
                    value={emailTypeToSelect}
                    onChange={setEmailTypeToSelect}
                    className="mt-4"
                  >
                    <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4">
                      {emailSelectionMethods.map((typeSelection) => {
                        return (
                          <RadioGroup.Option
                            key={typeSelection.id}
                            value={typeSelection.id}
                            className={({ active }) =>
                              classNames(
                                "relative flex cursor-pointer rounded-md p-4 focus:outline-none",
                                WarningColor,
                              )
                            }
                          >
                            {({ focus, checked }) => {
                              return (
                                <>
                                  <span
                                    className="flex flex-1 flex-col
                                "
                                  >
                                    <RadioGroup.Label
                                      as="span"
                                      className="block text-sm font-medium text-primary"
                                    >
                                      {typeSelection.title}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="span"
                                      className="mt-1 flex items-center text-sm text-secondary"
                                    >
                                      {typeSelection.description}
                                    </RadioGroup.Description>
                                    {checked && typeSelection.summary && (
                                      <RadioGroup.Description
                                        as="span"
                                        className="mt-6 text-sm font-medium text-secondary"
                                      >
                                        {typeSelection.summary}
                                      </RadioGroup.Description>
                                    )}
                                  </span>

                                  {checked && <CheckmarkIcon />}

                                  <span
                                    className={classNames(
                                      focus ? "border" : "border-2",
                                      checked ? "border-yellow-600" : "border-transparent",
                                      "pointer-events-none absolute -inset-px rounded-lg",
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              );
                            }}
                          </RadioGroup.Option>
                        );
                      })}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          )}
          {!contactToOpen && (
            <div className="flex items-center h-12 pl-4 border border-color-primary flex-1 rounded-md">
              <SearchInputWithChainableFilter
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                hideIcon
                savedSearchLimit={0}
                placeholder={`Search contacts in "${entity?.name}"`}
              />
            </div>
          )}
          <div
            className="relative flex flex-1 max-h-[50vh] overflow-hidden border border-color-primary rounded-md"
            ref={wrapperRef as RefObject<HTMLDivElement>}
            style={{ height: "100vh" }}
          >
            {contactToOpen && (
              <ContactDetails
                contactData={contactToOpen}
                singleCol
                noCompanyDetail
                noContactHistory
                endNameContent={
                  <Button
                    variant={ButtonVariant.Secondary}
                    icon={<ArrowLeftCircleIcon />}
                    onClick={() => setContactIdToOpen(undefined)}
                  >
                    Back
                  </Button>
                }
              />
            )}

            <nav
              id="contacts"
              className={classNames("flex-1 relative", contactToOpen && "hidden")}
              aria-label="Contacts"
            >
              <div className="flex items-center absolute right-0 top-0 bg-secondary z-20 mr-4 mt-1.5">
                <span className="pl-2 border-l border-color-primary text-xs mr-2">
                  {(data?.contacts?.filter(Boolean).length || 0) -
                    Object.keys(recipientState.userExcludedContactIdIndex || {}).length || 0}{" "}
                  / {data?.contacts?.length || 0}
                </span>

                <input
                  type="checkbox"
                  ref={selectAllInputRef}
                  className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-600"
                  checked={
                    Object.keys(recipientState.userExcludedContactIdIndex || {}).length === 0
                  }
                  onChange={(event) => {
                    setUserExcludedContactIndex(() => {
                      if (event.target.checked) {
                        // exclude none
                        return {};
                      } else {
                        const state: { [excludedContactId: string]: true } = {};
                        for (const contact of (showMultiEmailContactsOnly
                          ? contacts
                          : filteredContacts) || []) {
                          if (contact) state[contact.id] = true;
                        }
                        return state;
                      }
                    });
                  }}
                />
              </div>

              <GroupedVirtualizedList
                onRangeChanged={setVisibleRange}
                overscan={0}
                noTopBorder
                refHandle={ref}
                items={flattenedSortedContacts}
                labelCounts={alphabetKeyCounts}
                labels={alphabetKeys}
                selectedItem={selectedContact}
                isLoaded={typeof data?.contacts !== "undefined"}
                itemContent={(index) => {
                  const contact = flattenedSortedContacts[index];
                  return (
                    <ContactListItem
                      key={contact.id}
                      noGutter
                      className={classNames(
                        "group py-2 px-4 relative bg-opacity-50",
                        (contact?.emails?.length || 0) > 1 &&
                          !recipientState.userExcludedContactIdIndex?.[contact.id] &&
                          "border-l-4 border-yellow-200 dark:border-yellow-600",
                      )}
                      contact={contact}
                      sortKey="_surnameSort"
                      isSelected={
                        multiSelectedContacts && multiSelectedContacts.length > 0
                          ? multiSelectedContacts.some((c) => c.id === contact.id)
                          : index === currentItemIndex
                      }
                      onClickItem={(event) => {
                        if (onClickItem) onClickItem(event, contact, index);
                      }}
                      endContent={
                        <>
                          <Button
                            noGutter
                            variant={ButtonVariant.Secondary}
                            className="transition-all delay-75 group-hover:w-auto group-hover:px-2 group-hover:opacity-100 w-0 px-0 opacity-0 py-1"
                            icon={<ExternalLinkSolidIcon />}
                            onClick={() => {
                              setContactIdToOpen(contact.id);
                            }}
                          >
                            Open
                          </Button>

                          <input
                            type="checkbox"
                            checked={
                              recipientState.userExcludedContactIdIndex &&
                              !recipientState.userExcludedContactIdIndex[contact.id]
                            }
                            className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-600"
                            onChange={(event) => {
                              setUserExcludedContactIndex((prev) => {
                                const newState = { ...prev };
                                if (event.target.checked) {
                                  delete newState[contact.id];
                                } else {
                                  newState[contact.id] = true;
                                }
                                return newState;
                              });
                            }}
                          />
                        </>
                      }
                    >
                      <div className="flex flex-col">
                        {(contact?.emails?.length || 0) === 0 ? (
                          <DangerBadge>No email</DangerBadge>
                        ) : contact?.emails?.length === 1 ? (
                          <div
                            key={contact.emails[0].value}
                            className="text-xs text-secondary space-x-2"
                          >
                            <span className="text-sm ">{contact.emails[0].value}</span>
                            {getEmailType(contact.emails[0]) && (
                              <span className="capitalize text-label">
                                ({getEmailType(contact.emails[0])})
                              </span>
                            )}
                          </div>
                        ) : (
                          contact?.emails?.map((email, index) => {
                            const id = email.value + index;
                            const value = getRfc5322FromContact(contact, email.value);
                            const checked =
                              recipientState.contactIdEmail &&
                              recipientState.contactIdEmail[contact.id] === value;

                            const emailType = getEmailType(email);
                            const isDefault = email.isDefault === IsDefault.YES;
                            return (
                              <div key={value} className="relative flex items-start">
                                <div className="flex h-6 items-center pl-2">
                                  <input
                                    id={id}
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    checked={checked}
                                    onChange={() => {
                                      setContactIdEmail((prev) => {
                                        return {
                                          ...prev,
                                          [contact.id]: value,
                                        };
                                      });
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                  <label
                                    htmlFor={id}
                                    className="font-medium text-primary space-x-2"
                                  >
                                    <span className="text-sm">{email.value}</span>
                                    {emailType && (
                                      <span className="text-label">
                                        ({capitalizeFirstLetter(emailType)})
                                      </span>
                                    )}
                                  </label>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </ContactListItem>
                  );
                }}
              />
            </nav>
            <div className="fixed z-50 px-2 -ml-11 overflow-y-auto">
              {alphabetKeys?.length > 1 && (
                <AlphabetList
                  alphabetKeys={alphabetKeys}
                  onSelectAlphabetKey={onSelectAlphabetKey}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full my-4">
          <LoadingSpinner loadingText="Loading..." />
        </div>
      )}
    </Alert>
  );
};

export default EmailRecipientContactGroupOptionModal;
