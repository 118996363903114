import axios from "axios";

import { getDelegatedUser } from "@/hooks/useDelegatedUser";
import { getAuth } from "@/integrations/firebase";

export async function queryFetch<T, B = void>(
  uri: string,
  method: "GET" | "POST" | "DELETE" = "GET",
  body?: B,
): Promise<{ data?: T; error?: Error; response?: Response }> {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  const headers: { [x: string]: string } = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const delegatedUser = getDelegatedUser();
  if (delegatedUser && delegatedUser.delegatedUserId) {
    headers["delegated-user-id"] = delegatedUser.delegatedUserId;
  }

  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${uri}`, {
      headers,
      method,
      body: body ? JSON.stringify(body) : undefined,
    });

    if (response.status < 300) {
      try {
        const data = (await response.json()) as T;
        return { response, data };
      } catch (error) {
        return { response, error: error as Error };
      }
    }
  } catch (error) {
    console.error(error);
    return { error: error as Error };
  }

  return {};
}

export async function uploadWithProgress<T>({
  url,
  data,
  method = "PUT",
  includeAuth = true,
  headers = {},
  onProgress,
}: {
  url: string;
  data: T;
  method?: "POST" | "PUT";
  includeAuth?: boolean;
  headers?: { [x: string]: string };
  onProgress: (percentInt: number) => void;
}) {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (includeAuth) {
    headers.Authorization = `Bearer ${token}`;
    const delegatedUser = getDelegatedUser();
    if (delegatedUser && delegatedUser.delegatedUserId) {
      headers["delegated-user-id"] = delegatedUser.delegatedUserId;
    }
  }

  return axios(url, {
    method,
    data,
    headers: { ...headers },
    onUploadProgress: ({ progress }) => {
      if (!progress) return onProgress(0);
      const percentCompleted = parseInt((progress * 100).toFixed(2), 10);
      onProgress(percentCompleted);
    },
  });
}
