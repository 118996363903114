import { getCityStateLookupKey } from "@shared/helpers/location";
import { EMPTY_PLACEHOLDER_FOR_SORT } from "@shared/models/constants";
import { ContactRow } from "@shared/models/Contact";
import { DdbBoolean } from "@shared/models/types";
import { getNumsFromString, startsWithNonLetterOrIsEmpty } from "utils/string";
import type { PendingContactToMerge } from "./contactMatching";

export function getIndexableString(value?: string): string {
  return (value === EMPTY_PLACEHOLDER_FOR_SORT ? "" : value || "").toLowerCase().trim();
}

export function getSurnameForDisplay(surname: string | undefined) {
  return (surname || "").replace(EMPTY_PLACEHOLDER_FOR_SORT, "");
}

export function getFirstLastName(contact: { givenName?: string; surname?: string }) {
  return [contact.givenName, getSurnameForDisplay(contact?.surname)]
    .map((str) => str?.trim())
    .filter(Boolean)
    .join(" ");
}

export function getFullName(contact: Partial<ContactRow>) {
  if (!contact) return "";
  const fullName = [
    contact?.prefix,
    contact?.givenName,
    contact?.middleName,
    getSurnameForDisplay(contact?.surname),
    contact?.suffix,
  ]
    .filter(Boolean)
    .join(" ");

  return fullName.trim();
}

export function getNameSortKeys(contact: ContactRow | PendingContactToMerge) {
  const fallbackSort =
    `${contact.emails && getIndexableString(contact?.emails[0]?.value || "")}${
      contact.phoneNumbers && getIndexableString(contact?.phoneNumbers[0]?.value || "")
    }` || "";

  const surname = getSurnameForDisplay(contact.surname);

  return {
    _fullName: getFullName(contact),
    _surnameSort: `${getIndexableString(surname) || ""}${getIndexableString(
      contact.givenName || "",
    )}${fallbackSort}`,
    _givenNameSort: `${getIndexableString(contact.givenName) || ""}${getIndexableString(
      contact.surname || "",
    )}${fallbackSort}`,
  };
}

export function getSingleInitial(s = ""): string {
  if (s.length > 0) {
    const firstChar = s.charAt(0);
    if (firstChar.toLowerCase() >= "a" && firstChar.toLowerCase() <= "z") {
      return firstChar;
    }
  }
  return "";
}

export type LocallyPersistedContactRow = ContactRow & {
  _surnameSort: string;
  _givenNameSort: string;
  _companyNameSort: string;
  _fullName: string;
  _givenName: string;
  _surname: string;
  _emailValueList?: string[];
  _phoneNumberValueList?: string[];
  // _imHandleValueList?: string[];
  // _webPageValueList?: string[];
  // _webPageServiceList?: string[];
  // _physicalAddressStreetList?: string[];
  // _physicalAddressLine2List?: string[];
  // _physicalAddressCityList?: string[];
  // _physicalAddressStateList?: string[];
  // _physicalAddressPostalCodeList?: string[];
  // _physicalAddressCountryList?: string[];
  // _relativeValueList?: string[];
  // _relativeLabelList?: string[];
  _isWaitingNotes?: DdbBoolean;
  _cityStateLookupKeyList?: string[];
};

export function getLocallyPersistedContactRow(
  sourceRow: ContactRow & { _isWaitingNotes?: DdbBoolean },
) {
  const fallbackSort =
    `${sourceRow.emails ? getIndexableString(sourceRow?.emails[0]?.value || "") : ""}${
      sourceRow.phoneNumbers ? getIndexableString(sourceRow?.phoneNumbers[0]?.value || "") : ""
    }` || "";
  const surname = getSurnameForDisplay(sourceRow.surname);
  const surnameToken = getIndexableString(surname || "");

  const row: LocallyPersistedContactRow = {
    ...sourceRow,
    givenName: sourceRow.givenName || "",
    surname,
    middleName: sourceRow.middleName || "",
    _fullName: [sourceRow.givenName, sourceRow.surname].filter(Boolean).join(" ") || "",
    _givenName: getIndexableString(sourceRow.givenName),
    _surname: getIndexableString(sourceRow.surname),
    _surnameSort: `${
      startsWithNonLetterOrIsEmpty(surnameToken) ? "~" : surnameToken
    }${getIndexableString(sourceRow.givenName || "")}${fallbackSort}`,
    _givenNameSort: `${getIndexableString(sourceRow.givenName) || ""}${getIndexableString(
      sourceRow.surname || "",
    )}${fallbackSort}`,
    _companyNameSort: getIndexableString(sourceRow.companyName || ""),
    _emailValueList: sourceRow.emails?.map(({ value }) => getIndexableString(value)),
    _phoneNumberValueList: sourceRow.phoneNumbers?.map(
      ({ value }) => getNumsFromString(getIndexableString(value)) || getIndexableString(value),
    ),
    _cityStateLookupKeyList:
      sourceRow.physicalAddresses
        ?.filter(({ city, state }) => city?.trim() && state?.trim())
        .map(({ city, state }) => getCityStateLookupKey(city || "", state || "")) || [],
  };

  return row;
}

export type SortableKeys =
  | "surname"
  | "givenName"
  | "nickname"
  | "companyName"
  | "_surnameSort"
  | "_givenNameSort";

export function getSortedContacts(contacts: ContactRow[], sortKey: SortableKeys) {
  return contacts.sort((a: ContactRow, b: ContactRow) => {
    const contactA = a as ContactRow;
    const contactB = b as ContactRow;

    if (sortKey === "surname") {
      const keyA = `${contactA.surname}${contactA.givenName}`.toLowerCase();
      const keyB = `${contactB.surname}${contactB.givenName}`.toLowerCase();
      return keyA.localeCompare(keyB);
    }

    if (sortKey === "givenName" || sortKey === "nickname" || sortKey === "companyName") {
      return contactA[sortKey]?.localeCompare(contactB[sortKey] || "") || 0;
    }
    return 0;
  });
}
