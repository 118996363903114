import type { FC } from "react";

type AlphabetListProps = {
  alphabetKeys: string[];
  onSelectAlphabetKey: (alphabet: string, index: number) => void;
};

const AlphabetList: FC<AlphabetListProps> = ({ alphabetKeys, onSelectAlphabetKey }) => {
  return (
    <ul role="list" className="bg-blue-50 rounded bg-opacity-60 dark:bg-opacity-10">
      {alphabetKeys.map((alphabet, index) => (
        <li key={alphabet} className="hover:my-1">
          <div className="transform hover:scale-150">
            <span
              className="relative z-50 flex items-center justify-center w-5 h-5 rounded-full cursor-pointer hover:bg-zinc-200 dark:hover:bg-zinc-800 text-secondary"
              onClick={() => onSelectAlphabetKey(alphabet, index)}
            >
              {alphabet}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default AlphabetList;
