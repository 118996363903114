import { EmailRecipient } from "core/types/userMessaging";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import Button, { ButtonVariant } from "@/components/core/Button";
import EmailRecipientInput from "@/components/email/EmailRecipientInput";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

export type DraftRecipientState = {
  to: LocallyPersistedEmailDraft["to"];
  cc: LocallyPersistedEmailDraft["cc"];
  bcc: LocallyPersistedEmailDraft["bcc"];
};

const EmailRecipients: FC<{
  containerHeight: number;
  draft: LocallyPersistedEmailDraft;
  updateDraft: (update: Partial<LocallyPersistedEmailDraft>) => void;
}> = ({ containerHeight, updateDraft, draft }) => {
  const [ccBccVisibility, setCcBccVisibility] = useState({
    cc: (draft?.cc?.length || 0) > 0,
    bcc: (draft?.bcc?.length || 0) > 0,
  });

  const recipients = useMemo(() => {
    return {
      to: draft.to,
      cc: draft.cc,
      bcc: draft.bcc,
    };
  }, [draft.bcc, draft.cc, draft.to]);

  const setRecipients = useCallback(
    (recipients: DraftRecipientState) => {
      updateDraft({
        ...recipients,
      });
    },
    [updateDraft],
  );

  const [focusedRecipient, setFocusedRecipient] = useState<
    | {
        field: "to" | "cc" | "bcc";
        id: EmailRecipient["id"];
      }
    | undefined
  >();

  const getMoveRecipientHandler = useCallback(
    (fromField: "to" | "cc" | "bcc") =>
      (targetField: "to" | "cc" | "bcc", idList: EmailRecipient["id"][]) => {
        // insert to the target field
        const recipientsToMove: EmailRecipient[] = [];
        const from: EmailRecipient[] = [];
        for (const r of recipients[fromField]) {
          if (idList.includes(r.id)) {
            // pick out recipient we need to move
            recipientsToMove.push(r);
            continue;
          }

          // save rest back to same field
          from.push(r);
        }

        if (recipientsToMove.length === 0) {
          return recipients;
        }

        const target = [
          ...recipients[targetField].filter((r) => !idList.includes(r.id)),
          ...recipientsToMove,
        ];

        setRecipients({
          ...recipients,
          [fromField]: from,
          [targetField]: target,
        });

        setFocusedRecipient(undefined);
      },
    [recipients, setRecipients],
  );

  const getReplaceRecipientEmailHandler = useCallback(
    (fromField: "to" | "cc" | "bcc") =>
      (prevId: EmailRecipient["id"], recipient: EmailRecipient) => {
        const selected: EmailRecipient[] = [];

        for (const r of recipients[fromField]) {
          if (r.id === prevId) {
            selected.push(recipient);
            continue;
          }

          selected.push(r);
        }

        setRecipients({
          ...recipients,
          [fromField]: selected,
        });

        setFocusedRecipient({
          field: fromField,
          id: recipient.id,
        });
      },
    [recipients, setRecipients],
  );

  useEffect(() => {
    setCcBccVisibility({
      cc: recipients.cc.length > 0,
      bcc: recipients.bcc.length > 0,
    });
  }, [recipients.bcc, recipients.cc, recipients.to, updateDraft]);

  const maxPopoverHeight = useMemo(() => {
    return containerHeight * 0.75;
  }, [containerHeight]);

  return (
    <div className="flex flex-col w-full py-2 space-y-2">
      <div className="flex w-full items-center text-secondary">
        <div className="text-label text-sm px-4 w-16">To</div>
        <EmailRecipientInput
          field="to"
          containerHeight={maxPopoverHeight}
          recipients={recipients.to}
          moveRecipients={getMoveRecipientHandler("to")}
          replaceRecipient={getReplaceRecipientEmailHandler("to")}
          setRecipients={(to) => {
            setRecipients({
              ...recipients,
              to,
            });
          }}
          focusedRecipient={focusedRecipient?.field === "to" ? focusedRecipient : undefined}
          setFocusedRecipient={(id: string | undefined) => {
            setFocusedRecipient((prev) => {
              if (!id || prev?.id === id) {
                return undefined;
              }
              return {
                field: "to",
                id,
              };
            });
          }}
        />
        <div className="space-x-2 px-4">
          {!ccBccVisibility.cc && (
            <Button
              noGutter
              rounded={false}
              hotkey={["alt+shift+c"]}
              className="text-secondary"
              variant={ButtonVariant.None}
              onClick={() => {
                setCcBccVisibility((prev) => ({
                  ...prev,
                  cc: true,
                }));
              }}
            >
              Cc
            </Button>
          )}
          {!ccBccVisibility.bcc && (
            <Button
              noGutter
              rounded={false}
              hotkey={["alt+shift+b"]}
              className="text-secondary"
              variant={ButtonVariant.None}
              onClick={() => {
                setCcBccVisibility((prev) => ({
                  ...prev,
                  bcc: true,
                }));
              }}
            >
              Bcc
            </Button>
          )}
        </div>
      </div>

      {ccBccVisibility.cc && (
        <div className="flex w-full items-center text-secondary">
          <div className="text-label text-sm px-4 w-16">Cc</div>
          <EmailRecipientInput
            field="cc"
            containerHeight={maxPopoverHeight * 0.9}
            recipients={recipients.cc}
            moveRecipients={getMoveRecipientHandler("cc")}
            replaceRecipient={getReplaceRecipientEmailHandler("cc")}
            setRecipients={(cc) => {
              setRecipients({
                ...recipients,
                cc,
              });
            }}
            focusedRecipient={focusedRecipient?.field === "cc" ? focusedRecipient : undefined}
            setFocusedRecipient={(id: string | undefined) => {
              setFocusedRecipient((prev) => {
                if (!id || prev?.id === id) {
                  return undefined;
                }
                return {
                  field: "cc",
                  id,
                };
              });
            }}
          />
        </div>
      )}

      {ccBccVisibility.bcc && (
        <div className="flex w-full items-center text-secondary">
          <div className="text-label text-sm px-4 w-16">Bcc</div>
          <EmailRecipientInput
            field="bcc"
            containerHeight={maxPopoverHeight * 0.8}
            moveRecipients={getMoveRecipientHandler("bcc")}
            replaceRecipient={getReplaceRecipientEmailHandler("bcc")}
            recipients={recipients.bcc}
            setRecipients={(bcc) => {
              setRecipients({
                ...recipients,
                bcc,
              });
            }}
            focusedRecipient={focusedRecipient?.field === "bcc" ? focusedRecipient : undefined}
            setFocusedRecipient={(id: string | undefined) => {
              setFocusedRecipient((prev) => {
                if (!id || prev?.id === id) {
                  return undefined;
                }
                return {
                  field: "bcc",
                  id,
                };
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EmailRecipients;
