import type { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import {
  DangerColor,
  DangerFontColor,
  SecondaryColor,
  SecondaryFontColor,
  SpecialColor,
  SpecialFontColor,
  SuccessColor,
  SuccessFontColor,
  WarningColor,
  WarningFontColor,
} from "@/components/core/colorVariant";

export const SuccessBadge: FC<PropsWithChildren<{ className?: string }>> & {
  color: string;
  fontColor: string;
} = ({ children, className }) => (
  <span
    className={twMerge(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      SuccessColor,
      className
    )}
  >
    {children}
  </span>
);
SuccessBadge.color = SuccessColor;
SuccessBadge.fontColor = SuccessFontColor;

export const SecondaryBadge: FC<PropsWithChildren<{ className?: string }>> & {
  color: string;
  fontColor: string;
} = ({ children, className }) => (
  <span
    className={twMerge(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      SecondaryColor,
      className
    )}
  >
    {children}
  </span>
);
SecondaryBadge.color = SecondaryColor;
SecondaryBadge.fontColor = SecondaryFontColor;

export const WarningBadge: FC<PropsWithChildren<{ className?: string }>> & {
  color: string;
  fontColor: string;
} = ({ children, className }) => (
  <span
    className={twMerge(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      WarningColor,
      className
    )}
  >
    {children}
  </span>
);
WarningBadge.color = WarningColor;
WarningBadge.fontColor = WarningFontColor;

export const DangerBadge: FC<PropsWithChildren<{ className?: string }>> & {
  color: string;
  fontColor: string;
} = ({ children, className }) => (
  <span
    className={twMerge(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      DangerColor,
      className
    )}
  >
    {children}
  </span>
);
DangerBadge.color = DangerColor;
DangerBadge.fontColor = DangerFontColor;

export const SpecialBadge: FC<PropsWithChildren<{ className?: string }>> & {
  color: string;
  fontColor: string;
} = ({ children, className }) => (
  <span
    className={twMerge(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      SpecialColor,
      className
    )}
  >
    {children}
  </span>
);
SpecialBadge.color = SpecialColor;
SpecialBadge.fontColor = SpecialFontColor;
