import { RadioGroup } from "@headlessui/react";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { IsDefault } from "@shared/models/types";
import classNames from "clsx";
import { ContactRecipient, EmailRecipient } from "core/types/userMessaging";
import { FC, useMemo } from "react";
import { capitalizeFirstLetter } from "utils/string";
import uuid from "utils/uuid";

import { ContactData } from "@/components/contacts/v2/types";
import { SecondaryBadge, SuccessBadge } from "@/components/core/Badge";
import Button, { ButtonVariant } from "@/components/core/Button";

const EmailRecipientOptions: FC<{
  recipient: ContactRecipient;
  selectedRecipients: EmailRecipient[];
  entity: ContactData | ContactGroupRowForDisplay | undefined;
  field: "to" | "cc" | "bcc";
  replaceRecipient: (id: string, recipient: EmailRecipient) => void;
  moveRecipients: (field: "to" | "cc" | "bcc", idList: string[]) => void;
}> = ({ recipient, entity, field, replaceRecipient, moveRecipients, selectedRecipients }) => {
  const MoveRecipient = useMemo(() => {
    return (
      <span className="isolate flex rounded-md h-7">
        {(["to", "cc", "bcc"] as const)
          .filter((f) => field !== f)
          .map((f, i) => {
            return (
              <Button
                key={f}
                variant={ButtonVariant.None}
                rounded={false}
                onClick={() => {
                  moveRecipients(f, [recipient.id]);
                }}
                className={classNames(
                  "capitalize relative inline-flex items-center bg-secondary text-secondary text-sm font-semibold ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 hover:dark:bg-gray-600 focus:z-10",
                  i === 0 ? "rounded-l-md" : "-ml-px rounded-r-md",
                )}
              >
                {f}
              </Button>
            );
          })}
      </span>
    );
  }, [field, moveRecipients, recipient.id]);

  return (
    <RadioGroup
      value={recipient.email}
      onChange={(email) => {
        replaceRecipient(recipient.id, {
          ...recipient,
          email,
          id: uuid(email?.toLowerCase().trim()),
        });
      }}
    >
      <div className="-space-y-px rounded-md bg-primary">
        {entity
          ? (entity as ContactData)?.emails?.map((email, i) => {
              const isSelected = recipient.email === email.value;
              const isReplacementTarget = !selectedRecipients.some(
                (recipient) =>
                  recipient.type !== "contactGroup" && recipient?.email === email.value,
              );

              if (!isReplacementTarget && !isSelected) {
                // if the email is already included in the selected recipients, don't show it
                return null;
              }

              return (
                <RadioGroup.Option
                  key={i}
                  value={email.value}
                  disabled={!isReplacementTarget}
                  className={({ checked }) =>
                    classNames(
                      i === 0 ? "rounded-tl-md rounded-tr-md" : "",
                      i === ((entity as ContactData)?.emails?.length || 0) - 1
                        ? "rounded-bl-md rounded-br-md"
                        : "",
                      checked && "z-10 bg-indigo-50 dark:bg-gray-800",
                      "relative flex border border-color-primary py-3 px-4 focus:outline-none",
                      !isReplacementTarget && !isSelected ? "grayscale disabled" : "cursor-pointer",
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked || (!isReplacementTarget && !isSelected)
                            ? "bg-indigo-600 border-transparent"
                            : "bg-white border-gray-300",
                          active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                          "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center",
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <span className="ml-3 flex flex-col flex-1">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            checked ? "text-primary font-semibold" : "text-secondary",
                            "flex text-sm font-medium mb-2",
                          )}
                        >
                          {email.value}
                          <div className="flex pl-4 space-x-2">
                            {email.isDefault === IsDefault.YES && <SuccessBadge>Pref</SuccessBadge>}

                            {email.type && (
                              <SecondaryBadge>{capitalizeFirstLetter(email.type)}</SecondaryBadge>
                            )}
                          </div>
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={classNames(
                            checked ? "text-indigo-700" : "text-gray-500",
                            "block text-sm",
                          )}
                        >
                          {isSelected && MoveRecipient}
                        </RadioGroup.Description>
                      </span>
                    </>
                  )}
                </RadioGroup.Option>
              );
            })
          : MoveRecipient}
      </div>
    </RadioGroup>
  );
};

export default EmailRecipientOptions;
