import type { Contact } from "@shared/models/Contact";
import classNames from "clsx";
import React, { type ChangeEvent, FC, useMemo } from "react";

import { ContactData } from "@/components/contacts/v2/types";
import { WarningFontColor } from "@/components/core/colorVariant";
import { ExclamationCircleSolidIcon } from "@/components/core/Icon";
import TextInput from "@/components/core/TextInput";

const fieldLabels: { [key in keyof Contact]?: string } = {
  givenName: "First Name",
  middleName: "Middle Name",
  surname: "Last Name",
  prefix: "Prefix",
  suffix: "Suffix",
  managerName: "Manager",
  departmentName: "Department",
  companyName: "Company",
};

const MailMergeMissingFieldBanner: FC<{
  missingFields: (keyof Contact)[] | undefined;
  contactOverride: Partial<Contact> | undefined;
  updateContactOverride: (updates: Partial<ContactData>) => void;
}> = ({ missingFields, updateContactOverride, contactOverride }) => {
  const Fields = useMemo(() => {
    return missingFields?.map((field) => {
      switch (field) {
        case "givenName":
        case "middleName":
        case "surname":
        case "prefix":
        case "suffix":
        case "managerName":
        case "departmentName":
        case "companyName":
          return (
            <div className="flex items-center space-x-4 font-semibold sm:mx-7" key={field}>
              <label htmlFor={`missingField${field}`}>{fieldLabels[field]}</label>
              <TextInput
                id={`missingField${field}`}
                name={field}
                type="text"
                value={contactOverride?.[field] || ""}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  updateContactOverride({
                    [field]: event.target.value,
                  });
                }}
                className="flex-1"
              />
            </div>
          );
      }
    });
  }, [contactOverride, missingFields, updateContactOverride]);

  return (missingFields?.length || 0) > 0 && Fields ? (
    <div
      className={classNames(
        WarningFontColor,
        "-mt-2 mb-2 p-4 text-sm space-y-3 flex-1 border-0 border-b border-color-primary items-center",
      )}
    >
      <div className="flex space-x-3">
        <ExclamationCircleSolidIcon className={WarningFontColor} size="lg" />
        <h3 className="text-sm">Fill in missing fields, or remove them for this recipient.</h3>
      </div>

      <div>{Fields}</div>
    </div>
  ) : null;
};

export default MailMergeMissingFieldBanner;
