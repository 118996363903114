import classNames from "clsx";
import type { FC } from "react";
import { twMerge } from "tailwind-merge";

type LoadingSpinnerProps = {
  loadingText?: string;
  forceShowSpinner?: boolean;
  className?: string;
  bgClassName?: string;
  fullScreen?: boolean;
  spinnerSize?: "sm" | "md" | "lg";
};

const spinnerSizeClassNames = {
  sm: {
    container: "h-5",
    icon: "w-6 h-6",
    textMargin: "mt-10",
  },
  md: {
    container: "h-10",
    icon: "w-12 h-12",
    textMargin: "mt-20",
  },
  lg: {
    container: "h-20",
    icon: "w-24 h-24",
    textMargin: "mt-32",
  },
};

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  loadingText,
  className,
  bgClassName,
  fullScreen = false,
  spinnerSize = "lg",
  forceShowSpinner = false,
}) => {
  const spinerClassName = spinnerSizeClassNames[spinnerSize];

  return (
    <div
      className={twMerge(
        classNames(
          "flex flex-col h-full justify-center items-center w-full text-center",
          { "h-screen": fullScreen },
          bgClassName || "bg-primary",
        ),
        className,
      )}
    >
      {(!loadingText || forceShowSpinner) && (
        <>
          <img
            className={classNames("absolute w-auto", spinerClassName.container)}
            src="/titledock-logo.svg"
            alt="TitleDock light text logo"
          />
          <svg
            className={classNames("absolute animate-spin text-purple-700", spinerClassName.icon)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-10"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-20 dark:opacity-50"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </>
      )}
      {loadingText && (
        <p
          className={classNames("text-sm font-medium text-primary", {
            [spinerClassName.textMargin]: forceShowSpinner,
          })}
        >
          {loadingText}
        </p>
      )}
    </div>
  );
};

export default LoadingSpinner;
