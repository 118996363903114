import type { Contact, ContactRow } from "services/src/shared/models/Contact";
import type { ContactVersion } from "services/src/shared/models/ContactVersion";
import type { ImHandle, Photo, WebPage } from "services/src/shared/models/types";
import { IsDefault } from "services/src/shared/models/types";

import { getSocialProfileService } from "@/components/contacts/details/helpers";
import { ContactData } from "@/components/contacts/v2/types";
import type { IconType } from "@/components/core/Icon";
import {
  BirthdayCakeIcon,
  BriefcaseIcon,
  CommentsAltIcon,
  EnvelopeIcon,
  GlobeIcon,
  MapMarkerAltIcon,
  PenIcon,
  PhoneIcon,
  UserAltIcon,
} from "@/components/core/Icon";

// Used when ordering contact field names by "importance"
export const CONTACT_FIELD_SORT_VALUE: { [key: string]: number } = {
  givenName: 0,
  middleName: 1,
  surname: 2,
  suffix: 3,
  prefix: 4,
  nickname: 5,
  birthday: 6,
  companyName: 7,
  departmentName: 8,
  jobTitle: 9,
  managerName: 10,
  notes: 11,
  pictureUrl: 12,
  emails: 13,
  phoneNumbers: 14,
  physicalAddresses: 15,
  imHandles: 16,
  webPages: 17,
};

export function getJobDescriptionList(contact: Partial<ContactRow>, includeCompany = true) {
  return [
    [contact?.jobTitle, contact?.departmentName].filter(Boolean).join(" - "),
    includeCompany ? contact?.companyName : null,
  ].filter(Boolean);
}

export function getSocialProfileList(webPages: WebPage[]) {
  return webPages
    .map((webPage, index) => ({ webPage, originalIndex: index }))
    .filter((o) => getSocialProfileService(o.webPage) !== null);
}

export const getDisplayName = ({
  givenName,
  surname,
  suffix,
}: Pick<Contact, "givenName" | "surname" | "suffix">) =>
  [givenName?.trim(), surname?.trim(), suffix?.trim()].filter(Boolean).join(" ");

/**
 * useful for delete contact modal etc where we need to confirm user action referring to a contact
 * @param contact
 */
export function getContactIdentityForDisplay(contact: ContactData | undefined) {
  const displayName = getDisplayName(contact || ({} as ContactData));
  if (displayName) return displayName;

  if (contact?.emails && contact.emails.length > 0) {
    return contact.emails[0].value;
  }
  if (contact?.phoneNumbers && contact.phoneNumbers.length > 0) {
    return contact.phoneNumbers[0].value;
  }
}

export function getHumanReadableLastUpdatedBy(
  contactVersion: ContactVersion,
  includePrefix?: boolean
) {
  switch (contactVersion.lastUpdatedBy) {
    case "icloud":
      return `${includePrefix ? "via " : ""}iCloud`;
    case "user":
      return `${includePrefix ? "by " : ""}You`;
    default:
      return `${includePrefix ? "by " : ""}TitleDock`;
  }
}

export function getHumanReadableFieldName(
  field: keyof ContactRow | string,
  usePlural: boolean = false
): string {
  const plural = usePlural ? "s" : "";
  switch (field) {
    case "givenName":
      return "first name";
    case "middleName":
      return "middle name";
    case "surname":
      return "last name";
    case "companyName":
      return "company name";
    case "departmentName":
      return "department";
    case "jobTitle":
      return "job title";
    case "managerName":
      return "manager's name";
    case "emails":
      return `email${plural}`;
    case "imHandles":
      return `instant messager handle${plural}`;
    case "physicalAddresses":
      return `address${plural}`;
    case "phoneNumbers":
      return `phone number${plural}`;
    case "webPages":
      return `web page${plural}`;
    default:
      return field;
  }
}

export function getIconTypeForContactField(field: keyof ContactRow | string): IconType | null {
  switch (field) {
    case "givenName":
    case "middleName":
    case "surname":
    case "suffix":
    case "prefix":
    case "nickname":
      return UserAltIcon;

    case "birthday":
      return BirthdayCakeIcon;

    case "companyName":
    case "departmentName":
    case "jobTitle":
    case "managerName":
      return BriefcaseIcon;

    case "notes":
      return PenIcon;

    case "emails":
      return EnvelopeIcon;

    case "imHandles":
      return CommentsAltIcon;

    case "physicalAddresses":
      return MapMarkerAltIcon;

    case "phoneNumbers":
      return PhoneIcon;

    case "webPages":
      return GlobeIcon;

    default:
      return null;
  }
}

export function getSanitizedImHandleValue(imHandle: ImHandle) {
  if (!imHandle || !imHandle.value) return "";
  return imHandle.value?.replace("xmpp:", "");
}

export const getAge = ({ birthDate, toDate }: { birthDate: Date; toDate: Date }) => {
  let age = toDate.getFullYear() - birthDate.getFullYear();
  const numMonths = toDate.getMonth() - birthDate.getMonth();
  if (numMonths < 0 || (numMonths === 0 && toDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getDefaultPhoto = (photos?: Photo[]): Photo | undefined => {
  const storedPhotos = photos?.filter((photo) => photo.id) || [];
  let [defaultPhoto] = storedPhotos.filter((photo) => photo.isDefault === IsDefault.YES);

  if (!defaultPhoto) {
    [defaultPhoto] = storedPhotos;
  }

  return defaultPhoto || undefined;
};

export const getPhotoUrl = (photo: Photo | undefined, userIdToQuery: string) => {
  if (photo && photo?.id) {
    // eslint-disable-next-line turbo/no-undeclared-env-vars
    return `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/${
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? "production" : "staging"
    }/${userIdToQuery}/contact/photos/${photo.id}`;
  }
};

export const sortByDefaultFirst = <T extends { isDefault?: IsDefault }>(a: T, b: T) => {
  const aVal = a.isDefault === IsDefault.YES ? IsDefault.YES : IsDefault.NO;
  const bVal = b.isDefault === IsDefault.YES ? IsDefault.YES : IsDefault.NO;
  return bVal - aVal;
};
