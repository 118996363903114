import { Switch } from "@headlessui/react";
import classNames from "clsx";
import { FC, useEffect, useState } from "react";

import usePrevious from "@/hooks/usePrevious";

const SliderToggle: FC<{
  title?: React.ReactElement | string;
  isActive: boolean | undefined;
  description?: React.ReactElement | string;
  onDisable?: () => void;
  onEnable?: () => void;
  enabledColor?: string;
  disabledColor?: string;
  leftLabel?: React.ReactElement | string;
  rightLabel?: React.ReactElement | string;
}> = ({
  title,
  description,
  isActive,
  onDisable,
  onEnable,
  enabledColor = "bg-green-400 dark:bg-green-700",
  disabledColor = "bg-gray-200 dark:bg-gray-700",
  leftLabel,
  rightLabel,
}) => {
  const [enabled, setEnabled] = useState<boolean | undefined>(isActive);
  const prevEnabled = usePrevious(enabled);

  useEffect(() => {
    if (!prevEnabled && enabled && onEnable) {
      onEnable();
    } else if (prevEnabled && !enabled && onDisable) {
      onDisable();
    }
  }, [enabled, onDisable, onEnable, prevEnabled]);

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      {(title || description) && (
        <span className="flex-grow flex flex-col">
          {title && (
            <Switch.Label as="span" className="text-primary" passive>
              {title}
            </Switch.Label>
          )}
          {description && (
            <Switch.Description as="span" className="text-sm font-light text-secondary pr-4">
              {description}
            </Switch.Description>
          )}
        </span>
      )}

      {leftLabel && (
        <Switch.Label as="span" className="mr-2 text-sm text-secondary">
          {leftLabel}
        </Switch.Label>
      )}
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? enabledColor : disabledColor,
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus-visible:ring-white",
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            typeof enabled === "undefined"
              ? "translate-x-2.5"
              : enabled
                ? "translate-x-5"
                : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
          )}
        />
      </Switch>

      {rightLabel && (
        <Switch.Label as="span" className="ml-2 text-sm text-secondary">
          {rightLabel}
        </Switch.Label>
      )}
    </Switch.Group>
  );
};
export default SliderToggle;
