import { chunkList } from "./array";

const DEFAULT_BATCH_SIZE = 25;

export async function batchPromiseAll<T, R>(
  list: T[],
  callback: (item: T) => Promise<R>,
  size = DEFAULT_BATCH_SIZE
) {
  const chunks = chunkList(list, size);
  let result: Awaited<R>[] = [];
  for (const chunk of chunks) {
    const r = await Promise.all(
      chunk.map((i) => {
        return callback(i).catch((error) => {
          console.error(error); // Console error on rejected promise
          return Promise.reject(error); // Re-throw the error
        });
      })
    );
    result = result.concat(r);
  }
  return result;
}

export async function batchPromiseAllSettled<T, R>(
  list: T[],
  callback: (item: T) => Promise<R>,
  size = DEFAULT_BATCH_SIZE
) {
  const chunks = chunkList(list, size);
  let results: PromiseSettledResult<Awaited<R>>[] = [];
  for (const chunk of chunks) {
    const result = await Promise.allSettled(
      chunk.map((item) => {
        return callback(item);
      })
    );
    results = results.concat(result);
  }
  return results;
}

export async function delayForMs(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, ms);
  });
}
