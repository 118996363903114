import * as Sentry from "@sentry/nextjs";
import {
  getFilledContactGroup,
  isComboGroup,
  isIntersectGroup,
  isSmartGroup,
} from "@shared/helpers/contactGroup";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { DdbBoolean, IsDeleted, IsDoNotSync } from "@shared/models/types";
import { searchContactGroupByQuery } from "core/helpers/contactSearch";
import { useLiveQuery } from "dexie-react-hooks";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { getCurEpochMs, getMs } from "utils/dateTime";

import { getContactDb, LocalDbSyncOp } from "@/database/contactDb";
import { frontendContactGroupSearch } from "@/database/search";
import { queryFetch } from "@/helpers/fetch";

export function useLiveAllContactGroups({
  isDeleted = IsDeleted.NO,
  sortKey = "name",
  sortDirection = "asc",
  doNotReturnComboGroupContactIds,
}: {
  isDeleted?: IsDeleted;
  sortKey?: "name" | "createdAt";
  sortDirection?: "asc" | "desc";
  doNotReturnComboGroupContactIds?: boolean;
}) {
  const removeOrphanedGroupIds = useCallback(
    async (contactGroupId: string, orphanedGroupIds: Set<string>) => {
      const frontendDb = getContactDb();
      return frontendDb?.transaction("rw", frontendDb.contactGroups, async () => {
        const contactGroup = await frontendDb?.contactGroups.get(contactGroupId);
        if (!contactGroup) return;

        const subGroupIds: ContactGroupRowForDisplay["subGroupIds"] = {};
        const intersectGroupIds: ContactGroupRowForDisplay["intersectGroupIds"] = {};

        for (const id in contactGroup.subGroupIds || {}) {
          if (!orphanedGroupIds.has(id)) {
            subGroupIds[id] = null;
          }
        }

        for (const id in contactGroup.intersectGroupIds || {}) {
          if (!orphanedGroupIds.has(id)) {
            intersectGroupIds[id] = null;
          }
        }

        await frontendDb?.contactGroups.update(contactGroupId, { subGroupIds, intersectGroupIds });
      });
    },
    [],
  );

  const contactGroups = useLiveQuery(async () => {
    const frontendDb = getContactDb();

    return sortDirection === "asc"
      ? frontendDb?.contactGroups.where("isDeleted").equals(isDeleted).sortBy(sortKey)
      : frontendDb?.contactGroups.where("isDeleted").equals(isDeleted).reverse().sortBy(sortKey);
  });

  const finalizedContactGroups = [];
  for (const contactGroup of contactGroups || []) {
    let orphanedGroupIds = new Set<string>();

    const isCombo = isComboGroup(contactGroup);
    const isIntersect = isIntersectGroup(contactGroup);

    let finalizedContactGroup = contactGroup;

    if (!doNotReturnComboGroupContactIds && (isCombo || isIntersect)) {
      const result = getFilledContactGroup(contactGroup, contactGroups || []);
      finalizedContactGroup = result.contactGroup;
      orphanedGroupIds = new Set([...orphanedGroupIds, ...result.orphanedSubGroupIds]);
      orphanedGroupIds = new Set([...orphanedGroupIds, ...result.orphanedIntersectGroupIds]);
    }

    if (orphanedGroupIds.size > 0) {
      removeOrphanedGroupIds(contactGroup.id, orphanedGroupIds);
    }

    finalizedContactGroups.push(finalizedContactGroup);
  }

  return {
    contactGroups: finalizedContactGroups,
    isLoaded: typeof contactGroups !== "undefined",
  };
}

export function useLiveContactGroup(contactGroupId?: string) {
  const contactGroup = useLiveQuery(async () => {
    const frontendDb = getContactDb();

    if (!contactGroupId) return;
    return frontendDb?.contactGroups.get(contactGroupId);
  }, [contactGroupId]);

  const editContactGroup = useCallback(
    async (contactGroup: Partial<ContactGroupRowForDisplay> & { id: string }) => {
      const { id, ...updated } = contactGroup;
      const frontendDb = getContactDb();

      return frontendDb?.transaction(
        "rw",
        frontendDb.contactGroups,
        frontendDb.updateOps,
        async () => {
          await Promise.all([
            frontendDb?.contactGroups.update(id, updated),
            frontendDb?.updateOps.put({
              id,
              type: "contactGroup",
              data: contactGroup,
              _opCreatedAt: getCurEpochMs(),
              _opRetryCount: 0,
              _opIsDone: DdbBoolean.NO,
              _opIsInProgress: DdbBoolean.NO,
            }),
          ]);
        },
      );
    },
    [],
  );

  const deleteContactGroup = useCallback(
    async (contactGroupId: ContactGroupRowForDisplay["id"]) => {
      const frontendDb = getContactDb();
      return frontendDb?.transaction(
        "rw",
        frontendDb.contactGroups,
        frontendDb.deleteOps,
        async () => {
          await Promise.all([
            frontendDb?.contactGroups.delete(contactGroupId),
            frontendDb?.deleteOps.put({
              id: contactGroupId,
              type: "contactGroup",
              data: undefined,
              _opCreatedAt: getCurEpochMs(),
              _opRetryCount: 0,
              _opIsDone: DdbBoolean.NO,
              _opIsInProgress: DdbBoolean.NO,
            }),
          ]);
        },
      );
    },
    [],
  );

  const createContactGroup = useCallback(
    async (contactGroup: Partial<ContactGroupRowForDisplay>) => {
      const { id } = contactGroup;
      if (!id) return;

      if (typeof contactGroup.isDeleted === "undefined") {
        contactGroup.isDeleted = IsDeleted.NO;
      }
      if (typeof contactGroup.createdAt === "undefined") {
        contactGroup.createdAt = getCurEpochMs();
      }
      if (typeof contactGroup.updatedAt === "undefined") {
        contactGroup.updatedAt = getCurEpochMs();
      }
      if (typeof contactGroup.isDoNotSync === "undefined") {
        contactGroup.isDoNotSync = IsDoNotSync.NO;
      }

      const frontendDb = getContactDb();

      return frontendDb?.transaction(
        "rw",
        frontendDb.contactGroups,
        frontendDb.createOps,
        async () => {
          await Promise.all([
            frontendDb?.contactGroups.put(contactGroup as ContactGroupRowForDisplay),
            frontendDb?.createOps.put({
              id,
              type: "contactGroup",
              data: contactGroup,
              _opCreatedAt: getCurEpochMs(),
              _opRetryCount: 0,
              _opIsDone: DdbBoolean.NO,
              _opIsInProgress: DdbBoolean.NO,
            }),
          ]);
        },
      );
    },
    [],
  );

  const createContactGroups = useCallback(
    async (contactGroupList: Partial<ContactGroupRowForDisplay>[]) => {
      const frontendDb = getContactDb();
      return frontendDb?.transaction(
        "rw",
        frontendDb.contactGroups,
        frontendDb.createOps,
        async () => {
          const createOps: LocalDbSyncOp[] = contactGroupList.map((contactGroup) => {
            return {
              id: contactGroup.id!,
              type: "contactGroup",
              data: contactGroup,
              _opCreatedAt: getCurEpochMs(),
              _opRetryCount: 0,
              _opIsDone: DdbBoolean.NO,
              _opIsInProgress: DdbBoolean.NO,
            };
          });

          await frontendDb?.contactGroups.bulkPut(contactGroupList as ContactGroupRowForDisplay[]);
          await frontendDb?.createOps.bulkPut(createOps);
        },
      );
    },
    [],
  );

  return {
    contactGroup,
    editContactGroup,
    deleteContactGroup,
    createContactGroup,
    createContactGroups,
    isLoaded: typeof contactGroup !== "undefined",
  };
}

export function useLiveSearchedContactGroups({
  query = "",
  isDeleted = IsDeleted.NO,
  allowedTypes,
}: {
  query?: string;
  isDeleted?: IsDeleted;
  allowedTypes?: ("smart" | "combo" | "static")[];
}) {
  const retryTimer = useRef<NodeJS.Timeout>();

  const [debouncedQuery] = useDebounce(query, 70, {
    leading: false,
    trailing: true,
  });

  const [contactGroups, setContactGroups] = useState<ContactGroupRowForDisplay[] | undefined>(
    undefined,
  );

  const searchContactGroups = useCallback(async () => {
    if (!frontendContactGroupSearch) {
      if (retryTimer.current) {
        clearTimeout(retryTimer.current);
      }

      retryTimer.current = setTimeout(searchContactGroups, 250);
      return;
    }

    const frontendDb = getContactDb();

    try {
      const matchedPkeys = await searchContactGroupByQuery(
        debouncedQuery,
        frontendContactGroupSearch,
      );

      const matchedContactGroups = await (debouncedQuery
        ? frontendDb?.contactGroups.where("id").anyOf(matchedPkeys).sortBy("name")
        : frontendDb?.contactGroups.where("isDeleted").equals(isDeleted).sortBy("name"));

      // filter groups if allowed types are provided
      const matchedContactGroupsFiltered = matchedContactGroups?.filter((contactGroup) => {
        if (allowedTypes && allowedTypes.length > 0) {
          const isSmart = isSmartGroup(contactGroup);
          const isCombo = isComboGroup(contactGroup);

          if (allowedTypes.includes("smart") && isSmart) {
            return true;
          }
          if (allowedTypes.includes("combo") && isCombo) {
            return true;
          }
          return allowedTypes.includes("static") && !isSmart && !isCombo;
        }
        return true;
      });

      setContactGroups(
        matchedContactGroupsFiltered?.filter(
          (contactGroup) => contactGroup.isDeleted === isDeleted,
        ),
      );
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }, [allowedTypes, debouncedQuery, isDeleted]);

  useEffect(() => {
    searchContactGroups();
  }, [searchContactGroups]);

  return {
    contactGroups,
    isLoaded: typeof contactGroups !== "undefined",
  };
}

export const loadPartialContactGroupUpdate = debounce(
  async (updatedAt?: number) => {
    const frontendDb = getContactDb();
    if (!updatedAt) {
      // fetch last updated contact group
      const lastUpdatedContactGroup = await frontendDb?.contactGroups
        .orderBy("updatedAt")
        .reverse()
        .limit(1)
        .first();
      if (lastUpdatedContactGroup) updatedAt = lastUpdatedContactGroup?.updatedAt;
    }

    const result = await queryFetch<ContactGroupRowForDisplay[]>(
      `/contacts/groups?updatedAt=${updatedAt}`,
    );

    if (result && result.data && result.data.length > 0) {
      return frontendDb?.transaction("rw", frontendDb?.contactGroups, async () => {
        return frontendDb?.contactGroups.bulkPut(result.data as ContactGroupRowForDisplay[]);
      });
    }
  },
  getMs("3s"),
  {
    leading: false,
    trailing: true,
  },
);
