import { getCityStateLookupKey } from "@shared/helpers/location";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { SimpleDocumentSearchResultSetUnit } from "flexsearch-ts";
import { getUniqueList } from "utils/array";
import { getNumsFromString } from "utils/string";
import {
  ChainableFilter,
  ContactGroupSearchIndex,
  ContactMetadataSearchIndex,
  ContactSearchIndex,
  KeywordFilter,
  SearchIndex,
} from "../types/contactSearch";
import { getIndexableString } from "./contact";

const SEARCH_LIMIT = 50000;

export function getQuertyFragments(query: string) {
  const { inQuotes, rest } = getValuesInQuotes(query);
  return [...inQuotes, ...rest];
}

export function getContactIdFromContactMetadataId(metadataId: string) {
  return metadataId.split("_")[0];
}

export async function searchContactGroupByQuery(
  query: string,
  contactGroupSearchIndex: ContactGroupSearchIndex,
) {
  const { inQuotes, rest } = getValuesInQuotes(query);
  const queryFragments = [...inQuotes, ...rest];

  const [phraseResult, tokenResultsToCombine] = await Promise.all([
    contactGroupSearchIndex?.searchAsync(query),
    Promise.all(
      queryFragments.map((token) => {
        return contactGroupSearchIndex?.searchAsync(token);
      }),
    ),
  ]);

  const tokenResultsCombined = getCombinedSearchResult(
    tokenResultsToCombine?.filter(Boolean) as SimpleDocumentSearchResultSetUnit[][],
  );

  const allResults = { ...getUniqueSearchResultIds(phraseResult), ...tokenResultsCombined };

  return Object.keys(allResults);
}

export async function searchContactByQuery(query: string, searchIndex: SearchIndex) {
  const queryFragments = getQuertyFragments(query);

  const [phraseResult, phoneNumberResult, tokenResultsToCombine] = await Promise.all([
    searchIndex?.searchAsync(query, { limit: SEARCH_LIMIT }),
    isLikelyPhoneNumber(query)
      ? searchIndex?.searchAsync(stripCountryCode(getNumsFromString(getIndexableString(query))), {
          limit: SEARCH_LIMIT,
          index: ["_phoneNumberValueList[]"],
        })
      : undefined,
    queryFragments.length > 1
      ? Promise.all(
          queryFragments.map((token) => {
            return searchIndex.searchAsync(token, { limit: SEARCH_LIMIT });
          }),
        )
      : Promise.resolve([]),
  ]);

  const tokenResultsCombined = getCombinedSearchResult(
    tokenResultsToCombine?.filter(Boolean) as SimpleDocumentSearchResultSetUnit[][],
  );

  let allResults = { ...getUniqueSearchResultIds(phraseResult), ...tokenResultsCombined };

  if (phoneNumberResult) {
    allResults = { ...allResults, ...getUniqueSearchResultIds(phoneNumberResult) };
  }

  return Object.keys(allResults);
}

export async function searchContactByQueryIndex(
  query: string,
  contactSearchIndex: ContactSearchIndex,
  index: string[],
) {
  const queryFragments = getQuertyFragments(query);

  const [phraseResult, tokenResultsToCombine] = await Promise.all([
    contactSearchIndex?.searchAsync({
      query,
      limit: SEARCH_LIMIT,
      index,
    }),
    queryFragments.length > 1
      ? Promise.all(
          queryFragments.map((token) => {
            return contactSearchIndex?.searchAsync({
              query: token,
              limit: SEARCH_LIMIT,
              index,
            });
          }),
        )
      : Promise.resolve([]),
  ]);

  const tokenResultsCombined = getCombinedSearchResult(
    tokenResultsToCombine?.filter(Boolean) as SimpleDocumentSearchResultSetUnit[][],
  );

  const allResults = { ...getUniqueSearchResultIds(phraseResult), ...tokenResultsCombined };

  return Object.keys(allResults);
}

export async function searchContactByCityStateList(
  cityStateLookupKeyList: string[],
  contactSearchIndex: ContactSearchIndex,
) {
  const resultsToCombine = await Promise.all(
    cityStateLookupKeyList.map((cityStateLookupKey) => {
      return contactSearchIndex?.searchAsync(cityStateLookupKey, {
        limit: SEARCH_LIMIT,
        index: ["_cityStateLookupKeyList[]"],
      });
    }),
  );

  const resultsCombined = resultsToCombine.reduce((acc, result) => {
    return { ...acc, ...getUniqueSearchResultIds(result) };
  }, {});

  return Object.keys(resultsCombined);
}

export async function searchContactBySearchFilters({
  filters,
  contactGroups,
  msaIndex,
  contactSearchIndex,
  contactMetadataSearchIndex,
  op = "and",
}: {
  filters: (Partial<ChainableFilter> | KeywordFilter | undefined)[];
  contactGroups: ContactGroupRowForDisplay[] | undefined;
  msaIndex:
    | {
        msaIdIndex: { [p: string]: { [p: string]: string } };
        msaCityIndex: { [p: string]: { city: string; stateKey: string }[] };
      }
    | undefined;
  contactSearchIndex: ContactSearchIndex | undefined;
  contactMetadataSearchIndex?: ContactMetadataSearchIndex | undefined;
  op: "and" | "or";
}) {
  let resultContactIdLists: string[][] = [];
  let metadataResultContactIds: string[] = [];

  for (const filter of filters) {
    if (!filter || !contactSearchIndex) continue;
    // filters
    if (filter.type === "search" || filter.type === "keyword") {
      const { index, query, selected } = filter;
      if (!query) continue;

      if (!index) {
        // generic keyword search without specific index
        const keywordResult = await searchContactByQuery(query, contactSearchIndex);
        resultContactIdLists.push(keywordResult);

        if (contactMetadataSearchIndex) {
          const result = await searchContactByQuery(query, contactMetadataSearchIndex);
          metadataResultContactIds = getUniqueList(result.map(getContactIdFromContactMetadataId));
        }
      } else {
        const indexSpecificResult = await searchContactByQueryIndex(
          selected || query,
          contactSearchIndex,
          [index],
        );
        resultContactIdLists.push(indexSpecificResult);
      }
    } else if (filter.type === "location") {
      const locationState = filter.selected;
      if (!locationState || (locationState.type === "msa" && !msaIndex)) {
        continue;
      }

      if (locationState.type === "city") {
        const cityStateLookupKey = getCityStateLookupKey(
          locationState.name,
          locationState.stateKey,
        );
        const cityStateLookupResult = await searchContactByCityStateList(
          [cityStateLookupKey],
          contactSearchIndex,
        );
        resultContactIdLists.push(cityStateLookupResult);
      } else if (locationState.type === "msa" && locationState.extId) {
        const cities = msaIndex?.msaCityIndex[locationState.extId];
        const citiesLookupKeys = cities?.map(({ city, stateKey }) =>
          getCityStateLookupKey(city, stateKey),
        );
        const results = await searchContactByCityStateList(
          citiesLookupKeys || [],
          contactSearchIndex,
        );
        resultContactIdLists.push(results);
      }
    } else if (filter.type === "contactGroup") {
      if (!contactGroups) continue;
      const contactGroup = contactGroups.find((g) => g.id === filter.selected);
      if (contactGroup) resultContactIdLists.push(contactGroup.contactIds);
    }
  }

  if (op === "and") {
    const contactIdCounts: { [id: string]: number } = {};
    for (const contactIds of resultContactIdLists) {
      if (!contactIds.length) return [];
      const idSet = new Set(contactIds);
      for (const id of idSet) {
        contactIdCounts[id] = (contactIdCounts[id] || 0) + 1;
      }
    }

    const finalResultContactIds = Object.keys(contactIdCounts).filter(
      (id) => contactIdCounts[id] === resultContactIdLists.length,
    );

    return [...finalResultContactIds, ...metadataResultContactIds];
  }

  // combine all unique ids from each list (essentially an OR operation)
  const idSet = new Set<string>();
  for (const contactIds of resultContactIdLists) {
    for (const id of contactIds) {
      idSet.add(id);
    }
  }

  return [...idSet, ...metadataResultContactIds];
}

export const contactSearchOptions = {
  async: true,
  worker: false, // should be left on main thread, more stable on chrome
  tokenize: "forward",
  optimize: true,
  depth: 0,
  document: {
    id: "id",
    index: [
      { field: "nickname", tokenize: "forward" },
      { field: "givenName", tokenize: "forward" },
      { field: "surname", tokenize: "forward" },
      { field: "_fullName", tokenize: "forward" },
      { field: "companyName", tokenize: "forward" },
      { field: "jobTitle", tokenize: "forward" },
      { field: "departmentName", tokenize: "forward" },
      { field: "notes", tokenize: "strict" },
      { field: "emails[]:value", tokenize: "full" },
      { field: "imHandles[]:value", tokenize: "forward" },
      { field: "_phoneNumberValueList[]", tokenize: "forward", encode: false },
      { field: "_cityStateLookupKeyList[]", tokenize: "strict", encode: false },
      { field: "physicalAddresses[]:street", tokenize: "forward" },
      { field: "physicalAddresses[]:line2", tokenize: "forward" },
      { field: "physicalAddresses[]:city", tokenize: "forward" },
      { field: "physicalAddresses[]:postalCode", tokenize: "strict" },
      { field: "physicalAddresses[]:state", tokenize: "strict" },
      { field: "webPages[]:value", tokenize: "forward" },
      { field: "webPages[]:service", tokenize: "strict" },
      { field: "relatives[]:value", tokenize: "full" },
    ],
  },
};
export const contactGroupSearchOptions = {
  async: true,
  worker: true,
  tokenize: "full",
  optimize: true,
  depth: 0,
  document: {
    id: "id",
    index: [{ field: "name", tokenize: "full" }],
  },
};
export const contactMetadataSearchOptions = {
  async: true,
  worker: true,
  tokenize: "strict",
  optimize: true,
  depth: 0,
  document: {
    id: "contactId_type",
    index: [
      { field: "gender[]", tokenize: "strict", encode: false },
      { field: "industry[]", tokenize: "full" },
    ],
  },
};
export const prospectSearchOptions = {
  async: true,
  worker: true,
  tokenize: "forward",
  optimize: true,
  depth: 0,
  document: {
    id: "id",
    index: [
      { field: "nickname", tokenize: "forward" },
      { field: "_fullName", tokenize: "full" },
      { field: "companyName", tokenize: "forward" },
      { field: "jobTitle", tokenize: "forward" },
      { field: "departmentName", tokenize: "forward" },
      { field: "emails[]:value", tokenize: "full" },
      { field: "_phoneNumberValueList[]", tokenize: "forward", encode: false },
      { field: "_cityStateLookupKeyList[]", tokenize: "strict", encode: false },
    ],
  },
};

export function getValuesInQuotes(input: string) {
  // Regular expression pattern to match content inside single or double quotes or non-quoted content
  const pattern = /(["'])(?:(?=(\\?))\2.)*?\1|[^"' ]+/g;

  // Extract the values inside the quotes and non-quoted values
  const matches = input.match(pattern);

  // Initialize the result object
  const parsedValues: {
    inQuotes: string[];
    rest: string[];
  } = {
    inQuotes: [],
    rest: [],
  };

  if (matches) {
    // Process each match and add it to the appropriate array
    matches.forEach((match) => {
      const inQuotes = match.startsWith('"') || match.startsWith("'");
      const value = inQuotes ? match.slice(1, -1) : match;

      if (inQuotes) {
        parsedValues.inQuotes.push(value);
      } else {
        parsedValues.rest.push(value);
      }
    });
  }

  return parsedValues;
}

export function getUniqueSearchResultIds(results: SimpleDocumentSearchResultSetUnit[] | undefined) {
  const allIds: { [id: string]: true } = {};
  for (const r of results || []) {
    for (const id of r.result) {
      allIds[id] = true;
    }
  }

  return allIds;
}

export function getCombinedSearchResult(results: SimpleDocumentSearchResultSetUnit[][]) {
  if (results.length === 0) {
    return {};
  }
  const uniqueIdList = results.map((r) => getUniqueSearchResultIds(r));

  const commonKeysObject: { [key: string]: true } = {};

  // Iterate over the keys of the first object
  for (const key in uniqueIdList[0]) {
    let isCommon = true;

    // Check if the key is present in all other objects
    for (let i = 1; i < uniqueIdList.length; i++) {
      if (!uniqueIdList[i].hasOwnProperty(key)) {
        isCommon = false;
        break;
      }
    }

    // If the key is common, add it to the result object
    if (isCommon) {
      commonKeysObject[key] = true;
    }
  }

  return commonKeysObject;
}

export function isLikelyPhoneNumber(str: string): boolean {
  const pattern = /^(\+?\d{1,2}[-\s\.]?)?(\(\d{1,4}\)|\d{1,4})[-\s\.]?\d{1,4}([-_\s\.]?\d{1,4})*$/;
  return pattern.test(str);
}

export function stripCountryCode(phoneNumber: string): string {
  // Regular expression pattern to detect a leading country code (optional '+' followed by 1 or 2 digits)
  const countryCodePattern = /^\+?\d{1,2}/;

  // Remove the detected country code from the phone number
  return phoneNumber.replace(countryCodePattern, "");
}
