import { getPersonEnrichmentParamsFromContact } from "@shared/helpers/pdl";
import { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import { PersonResponse } from "peopledatalabs";
import { PersonPreviewResponse } from "peopledatalabs/dist/types/enrichment-types";
import type { UpdatedContactPayload } from "services/src/http/get-contacts/types";
import type { PostContactsMergeResolutionPayload } from "services/src/http/post-contacts-merge/types";
import type { ContactRow } from "services/src/shared/models/Contact";
import type { ContactGroupRowForDisplay } from "services/src/shared/models/ContactGroup";
import type { ContactSyncConflict } from "services/src/shared/models/ContactSyncConflict";
import type { ContactVersion } from "services/src/shared/models/ContactVersion";
import { getCurEpochMs } from "utils/dateTime";

import { queryFetch } from "@/helpers/fetch";
import { getEdgeCacheTimestamp } from "@/hooks/useContacts";
import { appApi, appNoCacheApi } from "@/integrations/app/api";

import TitledockWebSocket from "../titledockWS";
import { getIdIndex } from "./helpers";

export const contactNoCacheApi = appNoCacheApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getContactsUpdated: builder.query<UpdatedContactPayload, number>({
      providesTags: ["CONTACTS_UPDATED"],
      query: (timestamp) => `/contacts?updatedAt=${timestamp}`,
    }),
    // dedupe
    dedupeContacts: builder.mutation<void, PostContactsMergeResolutionPayload>({
      query: (contactsMergePayload) => ({
        url: "/contacts/merge",
        method: "POST",
        body: contactsMergePayload,
      }),
      invalidatesTags: ["CONTACTS_UPDATED"],
    }),
  }),
});

export const contactApi = appApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getPinnedContactIds: builder.query<ContactRow[], void>({
      query: () => "/contacts/pinned",
      providesTags: ["PINNED_CONTACT_IDS"],
    }),
    createContact: builder.mutation<ContactRow, ContactRow>({
      query: (contact) => ({
        url: `contacts`,
        method: "POST",
        body: contact,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsUpdated",
            getEdgeCacheTimestamp(),
            (draft) => {
              draft.updated.push(patch);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateContact: builder.mutation<ContactRow, Partial<ContactRow>>({
      query: (contact) => ({
        url: `contacts/${contact.id}`,
        method: "POST",
        body: contact,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsUpdated",
            getEdgeCacheTimestamp(),
            (draft) => {
              const updated = draft.updated;
              const index = updated.findIndex((contact) => contact.id === patch.id);
              const contact = { ...updated[index], ...patch };
              if (index > -1) {
                updated.splice(index, 1, contact);
              } else {
                updated.push(contact);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          console.error("Contact update failed");
          patchResult.undo();
        }
      },
    }),
    deleteContact: builder.mutation<ContactRow, string>({
      query: (contactId) => ({
        url: `contacts/${contactId}`,
        method: "DELETE",
      }),
      async onQueryStarted(contactId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsUpdated",
            getEdgeCacheTimestamp(),
            (draft) => {
              draft.deleted.push({ contactId, createdAt: getCurEpochMs() });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteContacts: builder.mutation<void, { contactIds: string[] }>({
      query: (contactIds) => ({
        url: "bulk/contacts",
        method: "DELETE",
        body: contactIds,
      }),
      async onQueryStarted({ contactIds }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsUpdated",
            getEdgeCacheTimestamp(),
            (draft) => {
              for (const id of contactIds) {
                draft.deleted.push({
                  contactId: id,
                  createdAt: getCurEpochMs(),
                });
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    // Groups
    getContactGroups: builder.query<ContactGroupRowForDisplay[], void>({
      query: () => "/contacts/groups",
      keepUnusedDataFor: 0,
      providesTags: ["GROUPS"],
      transformResponse: (response: ContactGroupRowForDisplay[]) => {
        return response
          .filter((a) => a.isDeleted !== 1)
          .sort((a, b) => a.name.localeCompare(b.name));
      },
      async onCacheEntryAdded(_arg, { updateCachedData, cacheDataLoaded }) {
        // Create a websocket connection when the cache subscription starts
        let timestamp = getCurEpochMs();
        await cacheDataLoaded;

        try {
          TitledockWebSocket.subscribe("/contacts/groups", async (_e, body) => {
            if (body.entity === "/contacts/groups") {
              const { data: contactGroupUpdates } = await queryFetch<ContactGroupRowForDisplay[]>(
                `/contacts/groups?updatedAt=${timestamp}`
              );
              timestamp = getCurEpochMs();
              if (contactGroupUpdates) {
                updateCachedData((groups) => {
                  const groupIndex = getIdIndex(groups);
                  for (const group of contactGroupUpdates) {
                    const i = groupIndex[group.id];
                    if (groups[i]) groups[i] = group;
                    else groups.push(group);
                  }
                });
              }
            }
          });
        } catch (e) {
          console.log(e);
        }
      },
    }),

    // Changelog
    getContactChangelog: builder.query<ContactVersion[], string>({
      query: (contactId) => `/contacts/${contactId}/changelog`,
      providesTags: (_result, _error, contactId) => [{ type: "CHANGELOG", id: contactId }],
      async onCacheEntryAdded(contactId, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const url = `/contacts/${contactId}/changelog`;
        try {
          await cacheDataLoaded;
          TitledockWebSocket.subscribe(url, async (_e, body) => {
            if (body.entity === url) {
              const { data: contactVersions } = await queryFetch<ContactVersion[]>(
                `/contacts/${contactId}/changelog`
              );
              if (contactVersions) {
                updateCachedData((versions) => {
                  versions.length = 0;
                  for (const v of contactVersions) {
                    versions.push(v);
                  }
                });
              }
            }
          });
          await cacheEntryRemoved;
          TitledockWebSocket.unsubscribe(url);
        } catch {}
      },
    }),

    // Conflicts
    getConflicts: builder.query<ContactSyncConflict[], void>({
      query: () => "/contacts/conflicts",
      providesTags: ["CONFLICTS"],
    }),
    getConflict: builder.query<ContactSyncConflict[], string>({
      query: (contactId) => `/contacts/conflicts/${contactId}`,
      providesTags: (_result, _error, contactId) => [{ type: "CONFLICTS", id: contactId }],
    }),
    resolveConflict: builder.mutation<any, ContactRow>({
      query: (contact) => ({
        url: `/contacts/conflicts/${contact.id}/resolve`,
        method: "POST",
        body: contact,
      }),
      invalidatesTags: (contact) => ["CONFLICTS", { type: "CONFLICTS", id: contact.id }],
      async onQueryStarted(contact, { dispatch, queryFulfilled }) {
        // Update GET: /contacts/conflicts/${contactId}
        const getConflictPatchResult = dispatch(
          contactApi.util.updateQueryData("getConflict", contact.id, (draft) => {
            const index = draft.findIndex((conflict) => conflict.entityId === contact.id);
            if (index > -1) {
              draft.splice(index, 1);
            }
          })
        );

        // Update GET: /contacts/conflicts
        const getConflictsPatchResult = dispatch(
          contactApi.util.updateQueryData("getConflicts", undefined, (draft) => {
            const index = draft.findIndex((conflict) => conflict.entityId === contact.id);
            if (index > -1) {
              draft.splice(index, 1);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          getConflictPatchResult.undo();
          getConflictsPatchResult.undo();

          // On failure, invalidate CONTACTS tag to trigger a re-fetch
          dispatch(
            contactApi.util.invalidateTags(["CONFLICTS", { type: "CONFLICTS", id: contact.id }])
          );
        }
      },
    }),

    getPdlContactEnrichment: builder.query<
      PersonResponse & { rateLimit?: RemoteApiForDisplay["pdlPersonEnrichRateLimit"] },
      {
        remoteApi: RemoteApiForDisplay;
        params: ReturnType<typeof getPersonEnrichmentParamsFromContact>;
        contactId: string;
      }
    >({
      query: ({ remoteApi, params }) => {
        return {
          url: "/contact/enrich/pdl",
          method: "POST",
          body: {
            ...params,
          },
          headers: {
            "x-api-key": remoteApi.auth.apiKey,
            "x-remote-api-id": remoteApi.id,
          },
        };
      },
      extraOptions: { maxRetries: 0 },
      providesTags: (_result, _error, { contactId }) => [{ type: "ENRICHMENT", id: contactId }],
    }),

    getContactEnrichmentPreview: builder.query<
      PersonPreviewResponse,
      {
        contactId: string;
        params: ReturnType<typeof getPersonEnrichmentParamsFromContact>;
      }
    >({
      query: ({ params }) => {
        return {
          url: "/person/enrich/preview",
          method: "POST",
          body: {
            ...params,
          },
        };
      },
      extraOptions: { maxRetries: 0 },
      providesTags: (_result, _error, { contactId }) => [
        { type: "ENRICHMENT_PREVIEW", id: contactId },
      ],
    }),
  }),
});

export const {
  useGetPinnedContactIdsQuery,
  useGetContactChangelogQuery,
  useGetPdlContactEnrichmentQuery,
  useGetContactEnrichmentPreviewQuery,
  useGetConflictsQuery,
  useResolveConflictMutation,
} = contactApi;
