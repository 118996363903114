import { ChainableFilter, FilterOptionToken, KeywordFilter } from "core/types/contactSearch";
import { objKeys } from "utils/object";
import uuid from "utils/uuid";

import { SelectedSearchFilterState } from "@/components/contacts/search/types";

type Token = {
  token: FilterOptionToken;
  query: string;
};

export const filterOptionLabels: {
  [key in FilterOptionToken]: string;
} = {
  "in:": "group, location",
  "co:": "company",
  "em:": "email",
  "ph:": "phone",
};

export function splitStringIntoTokens(input: string): (string | Token)[] {
  // Escape token patterns for regex and dynamically construct a regex pattern to identify tokens
  const escapedPatterns = objKeys(filterOptionLabels)
    .map((p) => p.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"))
    .join("|");
  const regexPattern = `(${escapedPatterns})`;
  const tokenRegex = new RegExp(regexPattern, "g");

  const result: (string | Token)[] = [];
  let lastIdx = 0;

  // Find all matches for token patterns
  Array.from(input.matchAll(tokenRegex)).forEach((match) => {
    const tokenStartIdx = match.index!;
    const tokenEndIdx = tokenStartIdx + match[0].length;
    const tokenType = match[0];

    // Add preceding text as a regular string if there's any
    if (tokenStartIdx > lastIdx) {
      const text = input.substring(lastIdx, tokenStartIdx);
      if (text) result.push(text);
    }

    // Find the start of the next token or end of the string to extract the query
    const nextTokenMatch = input.slice(tokenEndIdx).search(tokenRegex);
    const nextTokenStartIdx = nextTokenMatch >= 0 ? tokenEndIdx + nextTokenMatch : undefined;

    const query = input.substring(tokenEndIdx, nextTokenStartIdx).trim();
    result.push({ token: tokenType as FilterOptionToken, query });

    // Update lastIdx for the next iteration
    lastIdx = nextTokenStartIdx || input.length;
  });

  // Add any remaining text after the last token as a regular string
  const remainingText = input.substring(lastIdx);
  if (remainingText) {
    result.push(remainingText);
  }

  return result;
}

export function getDefaultFilterState(token: FilterOptionToken, query = "") {
  let filter: (Partial<ChainableFilter> | KeywordFilter) | undefined = undefined;

  switch (token) {
    case "in:":
      filter = {
        token,
        query,
        placeholder: `Search ${filterOptionLabels["in:"]}`,
        type: undefined,
      };
      break;
    case "co:":
      filter = {
        token,
        query,
        index: "companyName",
        type: "search",
        placeholder: `Search ${filterOptionLabels["co:"]}`,
      };
      break;
    case "em:":
      filter = {
        token,
        query,
        index: "emails[]:value",
        type: "search",
        placeholder: `Search ${filterOptionLabels["em:"]}`,
      };
      break;
    case "ph:":
      filter = {
        token,
        query,
        index: "_phoneNumberValueList[]",
        type: "search",
        placeholder: `Search ${filterOptionLabels["ph:"]}`,
      };
      break;
  }

  return filter;
}

export function getFilterIdFromSelectedFilters(selectedFilters: SelectedSearchFilterState) {
  return uuid(
    selectedFilters
      .filter((filter) => filter.selected || filter.query)
      .reduce(
        (acc, filter) =>
          acc +
          [
            filter.type === "location" ? filter.selected?.extId : filter.selected,
            filter.query,
            filter.type,
            filter.token,
          ]
            .join("_")
            .toLowerCase()
            .trim(),
        "",
      ),
  );
}

export function getValidFilters(
  selectedFilters: SelectedSearchFilterState,
): (ChainableFilter | KeywordFilter)[] {
  return selectedFilters
    .filter((filter) => {
      if (filter.type === "search") {
        return filter.query?.trim() && filter.index;
      }
      if (filter.type === "location") {
        return filter.selected?.extId;
      }
      return filter.query;
    })
    .map(({ backspacePressed, hidePopover, ...filter }) => filter) as (
    | ChainableFilter
    | KeywordFilter
  )[];
}
