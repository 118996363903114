import { MeActvitiesPayload } from "@http/get-me-activities/types";
import { remoteApiVendorsForDirectPurge } from "@shared/helpers/remoteApi";
import type { UserProfilePayload } from "services/src/http/get-me";
import type { PendingContactRow } from "services/src/shared/models/PendingContact";
import type { RecurringTaskTableRow } from "services/src/shared/models/RecurringTask";
import type { RemoteApiForDisplay } from "services/src/shared/models/RemoteApi";
import type { RemoteApiSnapshot } from "services/src/shared/models/RemoteApiSnapshot";
import type { EventType } from "services/src/shared/models/types";
import { DdbBoolean, IsDeleted, IsRemoteApiActive } from "services/src/shared/models/types";
import type { UserEvent } from "services/src/shared/models/UserEvent";
import type { UserInvite } from "services/src/shared/models/UserInvite";
import type { UserNotificationRow } from "services/src/shared/models/UserNotification";
import { getCurEpochMs } from "utils/dateTime";

import { queryFetch } from "@/helpers/fetch";
import { appApi } from "@/integrations/app/api";

export async function fetchPaginatedPendingContacts(
  remoteApiId: string,
  startKey: string | undefined
) {
  const uri = `/me/integrations/${remoteApiId}/pendingContacts`;
  const { data, response, error } = await queryFetch<PendingContactRow[]>(
    startKey ? `${uri}?startKey=${startKey}` : uri
  );

  return { startKey: response?.headers?.get("next-start-key"), error, data };
}

const userApi = appApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getApp: builder.query<{ WEB_APP_VERSION: string }, void>({
      query: () => "/app",
    }),
    getUser: builder.query<UserProfilePayload, void>({
      query: () => "/me",
      providesTags: ["USER", "USER_DELEGATIONS"],
    }),
    getIntegration: builder.query<
      {
        integration: RemoteApiForDisplay;
        task: RecurringTaskTableRow | undefined;
      },
      string
    >({
      query: (remoteApiId: string) => `/me/integrations/${remoteApiId}`,
      providesTags: (result) => [{ type: "INTEGRATIONS" as const, id: result?.integration?.id }],

    }),
    getIntegrationApiKey: builder.query<RemoteApiForDisplay, string>({
      query: (remoteApiId: string) => `/me/integrations/${remoteApiId}/apiKey`,
      providesTags: (remoteApi) => [{ type: "INTEGRATIONS" as const, id: remoteApi?.id }],
    }),
    getIntegrations: builder.query<RemoteApiForDisplay[], void>({
      query: () => "/me/integrations",
      providesTags: (result) =>
        result
          ? [...result.map((remoteApi) => ({ type: "INTEGRATIONS" as const, id: remoteApi.id }))]
          : ["INTEGRATIONS"],
    }),
    getUserActivities: builder.query<MeActvitiesPayload, number>({
      query: (tzOffset: number) => `/me/activities?tzOffset=${tzOffset}`,
    }),
    getUserEvents: builder.query<UserEvent[], { createdDaySince?: string }>({
      query: ({ createdDaySince }) => {
        const uri = "/me/userEvents";
        return createdDaySince ? `${uri}?createdDaySince=${createdDaySince}` : uri;
      },
      providesTags: ["USER_EVENTS"],
    }),
    addUserEvents: builder.mutation<
      void,
      {
        entityId: string;
        createdAt: number;
        type: EventType;
      }[]
    >({
      query: (body) => ({
        url: "/me/userEvents",
        method: "POST",
        body,
      }),
      invalidatesTags: ["USER_EVENTS"],
    }),
    createIntegration: builder.mutation<RemoteApiForDisplay, RemoteApiForDisplay>({
      query: (integration) => ({
        url: "/me/integrations",
        method: "POST",
        body: integration,
      }),
      invalidatesTags: ["INTEGRATIONS"],
      async onQueryStarted(integration, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getIntegrations", undefined, (draft) => {
            draft?.push(integration);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateIntegration: builder.mutation<
      RemoteApiForDisplay,
      Pick<RemoteApiForDisplay, "id"> & Partial<RemoteApiForDisplay>
    >({
      query: (integration) => ({
        url: `/me/integrations/${integration.id}`,
        method: "POST",
        body: integration,
      }),
      invalidatesTags: ["INTEGRATIONS"],
      async onQueryStarted({ id, ...update }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getIntegrations", undefined, (draft) => {
            for (const [i, remoteApi] of draft?.entries() || []) {
              if (remoteApi.id === id) {
                draft[i] = {
                  ...remoteApi,
                  ...update,
                };
              }
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    purgeIntegration: builder.mutation<void, string>({
      query: (remoteApiId) => ({
        url: `/me/integrations/${remoteApiId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["INTEGRATIONS"],
      async onQueryStarted(remoteApiId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getIntegrations", undefined, (draft) => {
            for (const [index, integration] of (draft || []).entries()) {
              if (integration.id === remoteApiId) {
                if (remoteApiVendorsForDirectPurge.includes(integration.vendor)) {
                  draft.splice(index, 1);
                  break;
                }
                draft[index].isDeleted = IsDeleted.YES;
                draft[index].isActive = IsRemoteApiActive.YES;
                draft[index].updatedAt = getCurEpochMs();
                break;
              }
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    createIntegrationFromGoogleOauth: builder.mutation<{ idToken: string }, string>({
      query: (code: string) => ({
        url: "/me/integrations/oauth/google",
        method: "POST",
        body: { code },
      }),
      invalidatesTags: ["INTEGRATIONS"],
      extraOptions: { maxRetries: 0 },
    }),
    createIntegrationFromIcloudPassword: builder.mutation<
      void,
      { email: string; appSpecificPassword: string }
    >({
      query: ({ email, appSpecificPassword }) => ({
        url: "/me/integrations/password/icloud",
        method: "POST",
        body: { email, appSpecificPassword },
      }),
      invalidatesTags: ["INTEGRATIONS"],
      extraOptions: { maxRetries: 0 },
    }),
    deleteIntegration: builder.mutation<void, string>({
      query: (remoteApiId: string) => ({
        url: `/me/integrations/${remoteApiId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["INTEGRATIONS"],
    }),
    getSnapshots: builder.query<RemoteApiSnapshot[], { integrationId: string; limit?: number }>({
      query: ({ integrationId, limit }) => {
        const url = `/me/snapshots/integrations/${integrationId}`;
        return limit ? `${url}?limit=${limit}` : url;
      },
      providesTags: ["SNAPSHOTS"],
    }),
    getAllSnapshots: builder.query<
      (RemoteApiForDisplay & { snapshots: RemoteApiSnapshot[] })[],
      number
    >({
      query: (limit) => {
        const url = "/me/snapshots/integrations";
        return limit ? `${url}?limit=${limit}` : url;
      },
      providesTags: ["ALL_SNAPSHOTS"],
    }),
    restoreSnapshot: builder.mutation<void, { integrationId: string; createdAt: number }>({
      query: ({ integrationId, createdAt }) => ({
        url: `/me/integrations/${integrationId}/restore`,
        method: "POST",
        body: {
          createdAt,
        },
      }),
    }),
    inviteCollaborator: builder.mutation<
      void,
      Omit<UserInvite, "userId" | "id" | "updatedAt" | "userId_inviteType">
    >({
      query: (invite) => ({
        url: `/me/invites`,
        method: "POST",
        body: { ...invite },
      }),
      invalidatesTags: ["INVITES"],
    }),
    getInvites: builder.query<UserInvite[], void>({
      query: () => "/me/invites",
      providesTags: ["INVITES"],
    }),
    updateUserTimeZone: builder.mutation<void, string>({
      query: (timeZone) => ({
        url: "/me/timezone",
        method: "POST",
        body: { timeZone },
      }),
      invalidatesTags: ["USER"],
    }),
    updateUserTosConsent: builder.mutation<void, boolean>({
      query: (hasConsented) => ({
        url: "/me/tos",
        method: "POST",
        body: { acceptedTosAt: hasConsented ? getCurEpochMs() : DdbBoolean.NO },
      }),
      invalidatesTags: ["USER"],
      async onQueryStarted(hasConsented, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUser", undefined, (draft) => {
            draft.user.acceptedTosAt = hasConsented ? getCurEpochMs() : DdbBoolean.NO;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getUserNotifications: builder.query<UserNotificationRow[], void>({
      query: () => "/me/notifications",
      providesTags: ["USER_NOTIFICATIONS"],
      transformResponse: (response: { notifications: UserNotificationRow[] }) => {
        return response.notifications;
      },
    }),
    updateUserNotificationRead: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/me/notifications/${id}`,
        method: "POST",
        body: { isRead: 1 },
      }),
      invalidatesTags: ["USER_NOTIFICATIONS"],
    }),
    deleteUserNotification: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/me/notifications/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["USER_NOTIFICATIONS"],
    }),
  }),
});

export default userApi;

export const {
  useGetAppQuery,
  useGetUserActivitiesQuery,
  useGetIntegrationsQuery,
  useGetIntegrationApiKeyQuery,
  useLazyGetIntegrationApiKeyQuery,
  useGetIntegrationQuery,
  useGetInvitesQuery,
  useGetUserQuery,
  useGetUserEventsQuery,
  useAddUserEventsMutation,
  useLazyGetUserQuery,
  useGetSnapshotsQuery,
  useRestoreSnapshotMutation,
  useGetAllSnapshotsQuery,
  useInviteCollaboratorMutation,
  useUpdateIntegrationMutation,
  useCreateIntegrationMutation,
  useCreateIntegrationFromGoogleOauthMutation,
  useCreateIntegrationFromIcloudPasswordMutation,
  useDeleteIntegrationMutation,
  useUpdateUserTimeZoneMutation,
  useUpdateUserTosConsentMutation,
  useGetUserNotificationsQuery,
  usePurgeIntegrationMutation,
} = userApi;
