import { FC, ReactNode } from "react";

const DetailPanelLayout: FC<{ Nav?: ReactNode; Detail: ReactNode; Side?: ReactNode }> = ({
  Nav,
  Detail,
  Side,
}) => {
  return (
    <section
      aria-labelledby="message-heading"
      className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last relative"
    >
      {Nav && <div className="flex-shrink-0 border-b border-color-primary bg-primary">{Nav}</div>}

      <div className="min-h-0 flex-1 bg-primary flex flex-row">{Detail}</div>
      {Side}
    </section>
  );
};

export default DetailPanelLayout;
