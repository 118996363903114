import { getEntityId, sanitizeContactData } from "@shared/helpers/contact";
import type { Contact, ContactRow } from "@shared/models/Contact";
import { ContactMetadataRow, ContactMetadataValueMap } from "@shared/models/ContactMetadata";
import { ContactVersion } from "@shared/models/ContactVersion";
import { IsDeleted, IsDoNotSync, IsReadOnly } from "@shared/models/types";
import { applyPatch, deepClone, Operation } from "fast-json-patch";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useCallback, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { ContactGroupRowForDisplay } from "services/src/shared/models/ContactGroup";
import { sortPortionByList } from "utils/array";
import { getCurEpochMs } from "utils/dateTime";
import { objKeys } from "utils/object";
import uuid from "utils/uuid";

import ArbitraryDatesField from "@/components/contacts/details/fields/ArbitraryDatesField";
import BirthdayField from "@/components/contacts/details/fields/BirthdayField";
import EmailsField from "@/components/contacts/details/fields/EmailsField";
import GroupsField from "@/components/contacts/details/fields/GroupsField";
import ImHandlesField from "@/components/contacts/details/fields/ImHandlesField";
import JobsField from "@/components/contacts/details/fields/JobsField";
import NamesField from "@/components/contacts/details/fields/NamesField";
import NotesField from "@/components/contacts/details/fields/NotesField";
import PhoneNumbersField from "@/components/contacts/details/fields/PhoneNumbersField";
import PhysicalAddressesField from "@/components/contacts/details/fields/PhysicalAddressesField";
import RelativesField from "@/components/contacts/details/fields/RelativesField";
import SocialProfilesField from "@/components/contacts/details/fields/SocialProfilesField";
import SyncToggleField from "@/components/contacts/details/fields/SyncToggleField";
import WebPagesField from "@/components/contacts/details/fields/WebPagesField";
import type { ContextualMenuFieldValue } from "@/components/contacts/details/shared/ContextualMenuField";
import {
  ContactDataFieldProps,
  FieldKey,
  UpdateContactDataAction,
  UpsertContactMetadataAction,
} from "@/components/contacts/details/types";
import { ContactData, ContactPageOp, ProspectPageOp } from "@/components/contacts/v2/types";
import { showToast, ToastLevel } from "@/components/core/Toast";
import { isEmpty } from "@/helpers/array";
import { inverse } from "@/helpers/operation";
import { useContactMetadata } from "@/hooks/data/useContactMetadataFields";
import {
  useLiveContactCompany,
  useLiveEmailDomainCompany,
  warmContactCompanyCache,
  warmContactEmailCompanyCache,
} from "@/hooks/data/useLiveCompany";
import { useLiveContactGroup } from "@/hooks/data/useLiveContactGroup";
import { useLiveContact } from "@/hooks/data/useLiveContacts";
import usePrevious from "@/hooks/usePrevious";
import { selectCompanyDataQuery, selectContactDrawer } from "@/integrations/contact/selectors";
import contactSlice from "@/integrations/contact/slice";

export const allFieldKeys: FieldKey[] = [
  "sync",
  "names",
  "jobs",
  "emails",
  "phoneNumbers",
  "physicalAddresses",
  "imHandles",
  "socialProfiles",
  "webPages",
  "birthday",
  "dates",
  "relatives",
  "groups",
  "notes",
];

export const useContactFields = ({
  contactData,
  dispatch,
  isEditing,
  focusedField,
  onFocusField,
  directlyUpdateContactData,
  fieldKeys = allFieldKeys,
  isNotesDirectlyEditable = true,
  showNamedEntities = false,
  setSearchQuery,
  contextualMenuBlacklist,
  contactMetadataValueMap,
  contactMetadataDispatch,
}: ContactDataFieldProps) => {
  const { createContactGroup, editContactGroup: updateContactGroup } = useLiveContactGroup();

  const createGroup = useCallback(
    async (group: Partial<ContactGroupRowForDisplay>) => {
      await createContactGroup(group as ContactGroupRowForDisplay);
    },
    [createContactGroup],
  );

  const addContactToGroup = useCallback(
    async (contactId: string, group: ContactGroupRowForDisplay) => {
      await updateContactGroup({
        ...group,
        contactIds: [...(group.contactIds || []), contactId],
      });
    },
    [updateContactGroup],
  );

  const removeContactFromGroup = useCallback(
    async (contactId: string, group: ContactGroupRowForDisplay) => {
      await updateContactGroup({
        ...group,
        contactIds: group.contactIds?.filter((id) => id !== contactId) || [],
      });
    },
    [updateContactGroup],
  );

  return useMemo(() => {
    const fields: { [fieldKey: FieldKey]: JSX.Element } = {};
    for (const key of fieldKeys) {
      switch (key) {
        case "sync": {
          fields[key] = (
            <SyncToggleField contactData={contactData} dispatch={dispatch} isEditing={isEditing} />
          );
          break;
        }
        case "names": {
          fields[key] = (
            <NamesField
              key="names"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
            />
          );
          break;
        }
        case "jobs": {
          fields[key] = (
            <JobsField
              key="jobs"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
            />
          );
          break;
        }
        case "emails": {
          fields[key] = (
            <EmailsField
              key="emails"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              directlyUpdateContactData={directlyUpdateContactData}
              contextualMenuBlacklist={contextualMenuBlacklist}
            />
          );
          break;
        }
        case "phoneNumbers": {
          fields[key] = (
            <PhoneNumbersField
              key="phoneNumbers"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              directlyUpdateContactData={directlyUpdateContactData}
              contextualMenuBlacklist={contextualMenuBlacklist}
            />
          );
          break;
        }

        case "physicalAddresses": {
          fields[key] = (
            <PhysicalAddressesField
              key="physicalAddresses"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              directlyUpdateContactData={directlyUpdateContactData}
              contextualMenuBlacklist={contextualMenuBlacklist}
            />
          );
          break;
        }
        case "imHandles": {
          fields[key] = (
            <ImHandlesField
              key="imHandles"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              directlyUpdateContactData={directlyUpdateContactData}
              contextualMenuBlacklist={contextualMenuBlacklist}
            />
          );

          break;
        }
        case "socialProfiles": {
          fields[key] = (
            <SocialProfilesField
              key="socialProfiles"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              directlyUpdateContactData={directlyUpdateContactData}
              contextualMenuBlacklist={contextualMenuBlacklist}
            />
          );

          break;
        }
        case "webPages": {
          fields[key] = (
            <WebPagesField
              key="webPages"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              directlyUpdateContactData={directlyUpdateContactData}
              contextualMenuBlacklist={contextualMenuBlacklist}
            />
          );
          break;
        }
        case "dates": {
          fields[key] = (
            <ArbitraryDatesField
              key="dates"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              contextualMenuBlacklist={contextualMenuBlacklist}
              contactMetadataValueMap={contactMetadataValueMap}
              contactMetadataDispatch={contactMetadataDispatch}
            />
          );
          break;
        }
        case "relatives": {
          fields[key] = (
            <RelativesField
              key="relatives"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              focusedField={focusedField}
              onFocusField={onFocusField}
              contextualMenuBlacklist={contextualMenuBlacklist}
            />
          );
          break;
        }
        case "notes": {
          fields[key] = (
            <NotesField
              key="notes"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              directlyUpdateContactData={directlyUpdateContactData}
              isNotesDirectlyEditable={isNotesDirectlyEditable}
              showNamedEntities={showNamedEntities}
              setSearchQuery={setSearchQuery}
            />
          );
          break;
        }
        case "birthday": {
          fields[key] = (
            <BirthdayField
              key="birthday"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              contactMetadataValueMap={contactMetadataValueMap}
              contactMetadataDispatch={contactMetadataDispatch}
            />
          );
          break;
        }
        case "groups": {
          fields[key] = (
            <GroupsField
              key="groups"
              contactData={contactData}
              dispatch={dispatch}
              isEditing={isEditing}
              createGroup={createGroup}
              addContactToGroup={addContactToGroup}
              removeContactFromGroup={removeContactFromGroup}
            />
          );
        }
      }
    }
    return { fields };
  }, [
    addContactToGroup,
    contactData,
    contextualMenuBlacklist,
    createGroup,
    directlyUpdateContactData,
    dispatch,
    fieldKeys,
    focusedField,
    isEditing,
    isNotesDirectlyEditable,
    onFocusField,
    removeContactFromGroup,
    setSearchQuery,
    showNamedEntities,
  ]);
};

export function useContactFieldList(props: ContactDataFieldProps) {
  const { fields } = useContactFields(props);

  return useMemo(() => {
    const fieldKeys = objKeys(fields) as FieldKey[];
    const sortedFieldKeys = sortPortionByList(allFieldKeys, fieldKeys);

    const list = [];
    for (const key of sortedFieldKeys) {
      const jsx = fields[key];
      list.push(jsx);
    }
    return list;
  }, [fields]);
}

const contactDataReducer = (state: ContactData | undefined, action: UpdateContactDataAction) => {
  if (action.type === "RESET") {
    return action.payload;
  }

  if (action.type) {
    return { ...state, [action.type]: action.payload } as Partial<ContactData>;
  }

  return state;
};

export function useContactEditBuffer({
  contact,
  isEditing,
  setIsEditing,
  resetOnEditClose,
}: {
  contact: ContactRow | Contact | undefined;
  isEditing: boolean;
  setIsEditing?: (val: boolean) => void;
  resetOnEditClose?: boolean;
}) {
  const [focusedField, setFocusedField] = useState<ContextualMenuFieldValue<any>>();
  const prevIsEditing = usePrevious(isEditing);
  const prevContactId = usePrevious(contact?.id);

  // Use reducer to hold updated contact data

  const [contactEditBuffer, dispatch] = useReducer(contactDataReducer, contact || {});

  useEffect(() => {
    if (contact?.id !== prevContactId || isEditing !== prevIsEditing) {
      dispatch({ type: "RESET", payload: { ...contact } });
    }
  }, [contact, isEditing, prevContactId, prevIsEditing]);

  const onFocusField = useCallback(
    (field: ContextualMenuFieldValue<any>) => {
      if (setIsEditing) setIsEditing(true);
      setFocusedField(field);
    },
    [setIsEditing],
  );

  return {
    contactData: (isEditing ? contactEditBuffer : contact) as Partial<ContactData>,
    onFocusField,
    dispatch,
    focusedField,
  };
}

export function useCreateContactBuffer({
  isDoNotSync,
  isDeleted = IsDeleted.NO,
  isReadOnly = IsReadOnly.NO,
}: {
  isDoNotSync: IsDoNotSync;
  isDeleted?: IsDeleted;
  isReadOnly?: IsReadOnly;
}) {
  const [contactData, dispatch] = useReducer(contactDataReducer, {
    isDoNotSync,
    isDeleted,
    isReadOnly,
  });

  return {
    contactData,
    dispatch,
  };
}

const contactMetadataReducer = (
  state: ContactMetadataValueMap,
  action: UpsertContactMetadataAction,
): ContactMetadataValueMap => {
  if (action.type === "RESET") {
    return action.payload;
  }
  return {
    ...state,
    [action.type]: action.payload,
  };
};

export function useContactMetadataEditBuffer(
  contactId: string,
  existingContactMetaValues: ContactMetadataValueMap,
  isEditing: boolean,
) {
  const [contactMetadataValueMap, dispatch] = useReducer(
    contactMetadataReducer,
    existingContactMetaValues,
  );

  const prevContactId = usePrevious(contactId);
  const prevIsEditing = usePrevious(isEditing);

  useEffect(() => {
    if (contactId !== prevContactId || isEditing !== prevIsEditing) {
      dispatch({ type: "RESET", payload: existingContactMetaValues });
    }
  }, [contactId, existingContactMetaValues, isEditing, prevContactId, prevIsEditing]);

  return {
    contactMetadataValueMap: isEditing ? contactMetadataValueMap : existingContactMetaValues,
    dispatch,
  };
}

export function getContactMetadataRowsFromValueMap(
  contact: ContactData,
  valueMap: ContactMetadataValueMap,
) {
  const rows: Partial<ContactMetadataRow>[] = [];
  const upsertedAt = getCurEpochMs();

  for (const typeKey in valueMap) {
    if (valueMap.hasOwnProperty(typeKey)) {
      const type = typeKey as keyof ContactMetadataValueMap;
      const values = valueMap[type];
      rows.push({
        contactId_type: `${contact.id}_${type}`,
        contactId: contact.id,
        upsertedAt,
        type: type as any, // or a more specific type if applicable
        values,
      });
    }
  }

  return rows;
}

export type ContactEditClickHandlerProps = {
  contactData: ContactRow;
  curOp: ContactPageOp | ProspectPageOp | string;
  setCurOp: Dispatch<SetStateAction<ContactPageOp | ProspectPageOp | string>>;
  redirectToCDP?: boolean;
  onEditSave?: (contact: ContactRow, prevOp: "create" | "edit" | "delete") => void;
  onCreateSave?: (contact: ContactRow, prevOp: "create" | "edit" | "delete") => void;
  onDeleteSave?: (contact: ContactRow, prevOp: "create" | "edit" | "delete") => void;
  onCancel?: () => void;
  dispatch?: Dispatch<UpdateContactDataAction>;
  contactMetadataValueMap?: ContactMetadataValueMap;
  contactMetadataDispatch?: Dispatch<UpsertContactMetadataAction>;
};
export function useContactEditClickHandlers({
  contactData,
  dispatch,
  onEditSave,
  onCreateSave,
  onDeleteSave,
  onCancel,
  redirectToCDP,
  setCurOp,
  contactMetadataValueMap,
  contactMetadataDispatch,
}: ContactEditClickHandlerProps) {
  const router = useRouter();
  const { slug } = router.query;

  const { validContact: sanitizedContact } = sanitizeContactData(contactData);

  const { createContact, editContact, deleteContact } = useLiveContact();

  const { editContactGroup: updateContactGroup } = useLiveContactGroup();

  const { bulkUpsertContactMetadata, bulkPurgeContactMetadata } = useContactMetadata();

  const resetBuffers = useCallback(() => {
    if (dispatch) dispatch({ type: "RESET", payload: {} });
    if (contactMetadataDispatch)
      contactMetadataDispatch({
        type: "RESET",
        payload: {},
      });
  }, [contactMetadataDispatch, dispatch]);

  const onClickCreateContact = useCallback(
    async (groupsToUpdate: ContactGroupRowForDisplay[]) => {
      const { validContact: sanitizedContact } = sanitizeContactData(contactData);

      const newContact: ContactRow = {
        ...sanitizedContact,
        id: getEntityId({ id: uuid(), isContactGroup: false, remoteApiId: "" }),
      };

      try {
        await createContact(newContact);
        if (contactMetadataValueMap) {
          await bulkUpsertContactMetadata(
            getContactMetadataRowsFromValueMap(newContact, contactMetadataValueMap),
          );
        }
        setCurOp("viewContact");
        if (onCreateSave) onCreateSave(newContact, "create");

        resetBuffers();

        // Add the newly created contact to groups
        if (groupsToUpdate && groupsToUpdate.length > 0) {
          groupsToUpdate.forEach((group) => {
            updateContactGroup({
              ...group,
              contactIds: [...(group.contactIds || []), newContact.id],
            });
          });
        }

        showToast({
          title: `Added ${newContact.givenName} ${newContact.surname} to your contacts!`,
          level: ToastLevel.Success,
        });
        await router.push("/contacts/[[...slug]]", `/contacts/${newContact.id}`, {
          shallow: true,
        });
      } catch (error) {
        console.error("Encountered error creating data. Error: ", error);
        showToast({
          title: `Failed to create ${sanitizedContact.givenName} ${sanitizedContact.surname}`,
          level: ToastLevel.Warning,
        });
      }
    },
    [contactData, createContact, dispatch, onCreateSave, router, setCurOp, updateContactGroup],
  );

  const onClickEditSave = useCallback(async () => {
    try {
      await editContact(sanitizedContact);
      if (contactMetadataValueMap) {
        await bulkUpsertContactMetadata(
          getContactMetadataRowsFromValueMap(sanitizedContact, contactMetadataValueMap),
        );
      }
      resetBuffers();

      if (onEditSave) onEditSave(sanitizedContact, "edit");
      if (slug !== sanitizedContact.id && redirectToCDP) {
        await router.push("/contacts/[[...slug]]", `/contacts/${sanitizedContact.id}`, {
          shallow: true,
        });
      }
      showToast({
        title: `Changes to ${sanitizedContact.givenName} ${sanitizedContact.surname} has been saved`,
        level: ToastLevel.Success,
      });
    } catch (error) {
      console.error("Encountered error updating contact data. Error: ", error);
      showToast({
        title: `Failed to update ${sanitizedContact.givenName} ${sanitizedContact.surname}`,
        level: ToastLevel.Warning,
      });
    }
  }, [editContact, sanitizedContact, resetBuffers, onEditSave, slug, redirectToCDP, router]);

  const onClickCancel = useCallback(() => {
    resetBuffers();
    setCurOp("viewContact");
    if (onCancel) onCancel();
  }, [onCancel, resetBuffers, setCurOp]);

  const onClickDeleteContact = useCallback(async () => {
    try {
      await deleteContact(contactData);
      if (contactMetadataValueMap) {
        await bulkPurgeContactMetadata(
          Object.keys(contactMetadataValueMap).map((type) => ({
            contactId: sanitizedContact.id,
            type,
          })),
        );
      }
      if (onDeleteSave) onDeleteSave(contactData, "delete");
      showToast({
        title: `Successfully deleted ${contactData.givenName} ${contactData.surname}`,
        level: ToastLevel.Success,
      });
    } catch (error) {
      console.error("Encountered error deleting contact data. Error: ", error);
      showToast({
        title: `Failed to delete ${contactData.givenName} ${contactData.surname}`,
        level: ToastLevel.Warning,
      });
    } finally {
      setCurOp("viewContact");
    }
  }, [contactData, deleteContact, onDeleteSave, setCurOp]);

  const onClickArchiveProspect = useCallback(async () => {
    try {
      await editContact({
        ...sanitizedContact,
        isDeleted: IsDeleted.YES,
        userId_isDeleted: `${sanitizedContact.userId}_${IsDeleted.YES}`,
      });
      if (onDeleteSave) onDeleteSave(contactData, "delete");
      showToast({
        title: `Successfully archived ${contactData.givenName} ${contactData.surname}`,
        level: ToastLevel.Success,
      });
    } catch (error) {
      console.error("Encountered error deleting contact data. Error: ", error);
      showToast({
        title: `Failed to delete ${contactData.givenName} ${contactData.surname}`,
        level: ToastLevel.Warning,
      });
    }
  }, [contactData, editContact, onDeleteSave, sanitizedContact]);

  const onClickConvertProspect = useCallback(async () => {
    try {
      const {
        givenName,
        userId,
        surname,
        suffix,
        prefix,
        photos,
        emails,
        phoneNumbers,
        companyName,
        jobTitle,
        departmentName,
        birthday,
        physicalAddresses,
        webPages,
        imHandles,
        notes,
      } = sanitizedContact;
      await createContact({
        userId,
        updatedAt: getCurEpochMs(),
        id: uuid(),
        givenName,
        surname,
        suffix,
        prefix,
        photos,
        emails,
        phoneNumbers,
        companyName,
        jobTitle,
        departmentName,
        birthday,
        physicalAddresses,
        webPages,
        imHandles,
        notes,
      });
      await editContact({
        ...sanitizedContact,
        isDeleted: IsDeleted.YES,
        userId_isDeleted: `${sanitizedContact.userId}_${IsDeleted.YES}`,
      });
      showToast({
        title: `Added ${givenName} ${surname} to your contacts!`,
        level: ToastLevel.Success,
      });
    } catch (error) {
      console.error("Encountered error creating data. Error: ", error);
      showToast({
        title: `Failed to convert ${sanitizedContact.givenName} ${sanitizedContact.surname}`,
        level: ToastLevel.Warning,
      });
    }
  }, [createContact, editContact, sanitizedContact]);

  return {
    onClickCreateContact,
    onClickDeleteContact,
    onClickCancel,
    onClickEditSave,
    onClickArchiveProspect,
    onClickConvertProspect,
  };
}

export function useCompanyDetail(contact?: ContactData) {
  const appDispatch = useDispatch();

  const setIsCompanyDetailOpen = useCallback(
    (isOpen: boolean) => {
      appDispatch(contactSlice.actions.setIsContactCompanyDetailOpen(isOpen));
    },
    [appDispatch],
  );

  const setCompanyDataQuery = useCallback(
    ({ email, domain, name }: { email?: string; domain?: string; name?: string }) => {
      appDispatch(contactSlice.actions.setCompanyDataQuery({ email, domain, name }));
    },
    [appDispatch],
  );

  const isContactCompanyDetailOpen = useSelector(selectContactDrawer) === "contactCompanyDetail";
  const companyDataQuery = useSelector(selectCompanyDataQuery);

  useEffect(() => {
    warmContactCompanyCache(contact);
    warmContactEmailCompanyCache(contact);
  }, []);

  const contactCompanyDataByName = useLiveContactCompany(contact?.companyName);
  const contactCompanyDataByEmail = useLiveEmailDomainCompany(companyDataQuery.email);

  return {
    companyDataQuery,
    setCompanyDataQuery,
    isContactCompanyDetailOpen,
    setIsCompanyDetailOpen,
    contactCompanyDataByEmail,
    contactCompanyDataByName,
  };
}

export function useContactHistory(
  contact?: ContactRow,
  editBufferDispatch?: Dispatch<SetStateAction<any>>,
) {
  const isContactHistoryOpen = useSelector(selectContactDrawer) === "contactHistory";
  const appDispatch = useDispatch();
  const setIsContactHistoryOpen = useCallback(
    (isOpen: boolean) => {
      appDispatch(contactSlice.actions.setIsContactHistoryOpen(isOpen));
    },
    [appDispatch],
  );

  const { editContact } = useLiveContact();

  const undoVersion = useCallback(
    async (version: ContactVersion | null) => {
      const diff = version?.contactDiff;
      if (diff) {
        // Deep clone current contact data
        const updatedContact: ContactRow = deepClone(contact);

        // Calculate inverse operation pairs and apply them, collect failed operations
        const inversePatches = inverse(diff, version.prevContact);
        const failedPatches: Operation[][] = [];
        inversePatches.forEach((patch) => {
          try {
            applyPatch(updatedContact, patch, true, true);
          } catch (error) {
            failedPatches.push(patch);
            console.log(
              `Error applying inverse operation pair: ${JSON.stringify(patch)}. Error: `,
              error,
            );
          }
        });

        // TODO: Ask user if we should force failed patches through + implement logic for forcing
        // patches.
        if (!isEmpty(failedPatches)) {
          console.log("Failed patches: ", JSON.stringify(failedPatches));
        }

        try {
          await editContact(updatedContact);
        } catch (error) {
          console.error("Encountered error reverting changes. Error: ", error);
          showToast({ title: "Failed to revert changes." });
          return;
        }
        showToast({ title: "Successfully reverted changes!" });
        if (editBufferDispatch) editBufferDispatch({ type: "RESET", payload: updatedContact });
        setIsContactHistoryOpen(false);
      }
    },
    [contact, editBufferDispatch, editContact, setIsContactHistoryOpen],
  );

  const rollbackToVersion = useCallback(
    async (version: ContactVersion | null) => {
      const updatedContact = version?.curContact;
      if (updatedContact) {
        try {
          await editContact(updatedContact);
        } catch (error) {
          console.error("Encountered error rolling back contact data. Error: ", error);
          showToast({ title: "Failed to rollback contact data." });
          return;
        }
        showToast({ title: "Successfully rolled back!" });
        if (editBufferDispatch) editBufferDispatch({ type: "RESET", payload: updatedContact });
        setIsContactHistoryOpen(false);
      }
    },
    [editBufferDispatch, editContact, setIsContactHistoryOpen],
  );

  return {
    rollbackToVersion: contact && editBufferDispatch ? rollbackToVersion : undefined,
    undoVersion: contact && editBufferDispatch ? undoVersion : undefined,
    isContactHistoryOpen,
    setIsContactHistoryOpen,
  };
}
