import { useMemo } from "react";
import { getCurEpochMs, getMs } from "utils/dateTime";

import { useLiveAllContacts } from "@/hooks/data/useLiveContacts";
import { getIdIndex } from "@/integrations/contact/helpers";

import { useGetPinnedContactIdsQuery } from "../integrations/contact/api";

const timestamps = {
  updatesFetchedAt: 0, // last call to /contacts, or /contacts?updatedAt= or /contacts?/deletedAt=
  edgeCacheTimestamp: 0,
};

export function setUpdatesLastFetchedAt(timestamp: number) {
  timestamps.updatesFetchedAt = timestamp;
}

export function setEdgeCacheTimestamp(timestamp: number) {
  if (timestamp) timestamps.edgeCacheTimestamp = timestamp;
}

export function getEdgeCacheTimestamp() {
  return timestamps.edgeCacheTimestamp || getCurEpochMs() - getMs("4h");
}

export function usePinnedContacts() {
  const { data: pinnedContactIds } = useGetPinnedContactIdsQuery();
  const { contacts } = useLiveAllContacts();

  const contactIndex = useMemo(() => {
    return getIdIndex(contacts);
  }, [contacts]);

  return useMemo(() => {
    return (pinnedContactIds || []).map(({ id }) => {
      return contacts[contactIndex[id]];
    });
  }, [contactIndex, contacts, pinnedContactIds]);
}
