import { ContactMetadataValueMap } from "@shared/models/ContactMetadata";
import classNames from "clsx";
import { Dispatch, FC, useCallback, useMemo } from "react";

import {
  ContactDataFieldProps,
  UpsertContactMetadataAction,
} from "@/components/contacts/details/types";
import { SecondaryBadge } from "@/components/core/Badge";
import { TagItem } from "@/components/core/Tag";
import TagsInput from "@/components/core/TagsInput";
import HighlightText from "@/components/HighlightText";
import { allIndustries } from "@/constants/industries";

import TypeLabel from "../../shared/TypeLabel";

const IndustryField: FC<{
  isEditing: boolean;
  setSearchQuery?: ContactDataFieldProps["setSearchQuery"];
  contactMetadataValueMap?: ContactMetadataValueMap;
  contactMetadataDispatch?: Dispatch<UpsertContactMetadataAction>;
}> = ({ contactMetadataValueMap, contactMetadataDispatch, isEditing, setSearchQuery }) => {
  const industries = useMemo(() => {
    return contactMetadataValueMap?.["industry"]
      ?.sort()
      .map((value) => ({ id: value, name: value }));
  }, [contactMetadataValueMap]);

  const suggestions = useMemo<TagItem[]>(() => {
    // industries may contain user created values, add these to the canonical list
    const list = Array.from(new Set([...allIndustries, ...(industries || []).map((i) => i.name)]));
    return list.map((value: string) => ({ id: value, name: value }));
  }, [industries]);

  const onClickTag = useCallback(
    (tag: string) => {
      if (setSearchQuery) {
        setSearchQuery(tag);
      }
    },
    [setSearchQuery]
  );

  // tagId and name
  const onCreateTag = useCallback(
    async (tag: string) => {
      if (!tag || !contactMetadataDispatch) return;
      const newIndustries = new Set<string>(
        (industries || []).map((industry) => industry.id || "").filter(Boolean)
      );
      newIndustries.add((tag || "").toLowerCase());
      const values = Array.from(newIndustries);

      contactMetadataDispatch({
        type: "industry",
        payload: values,
      });
    },
    [contactMetadataDispatch, industries]
  );

  const onRemoveTag = useCallback(
    async (tagId: string) => {
      if (!contactMetadataDispatch) return;
      const values = (industries || [])
        .map((industry) => industry.id.toLowerCase())
        .filter((id) => id !== tagId);

      contactMetadataDispatch({
        type: "industry",
        payload: values,
      });
    },
    [contactMetadataDispatch, industries]
  );

  if (!isEditing && !industries) {
    return null;
  }

  return (
    (isEditing || (industries?.length || 0) > 0) && (
      <div className={classNames("flex mt-1", isEditing ? "flex-row" : "flex-col")}>
        {isEditing ? (
          <TypeLabel className="-ml-3">Industries</TypeLabel>
        ) : (
          <div className="text-secondary uppercase">Industries</div>
        )}
        <div className="flex gap-x-1">
          {isEditing ? (
            <TagsInput
              tagItems={industries || []}
              suggestions={suggestions}
              onCreateTag={onCreateTag}
              onAddTag={onCreateTag}
              onRemoveTag={onRemoveTag}
              placeholder="Add industry"
            />
          ) : (
            industries?.map((industry, i) => (
              <button key={i} onClick={() => onClickTag(industry.name)}>
                <SecondaryBadge className="capitalize">
                  <HighlightText value={industry.name} />
                </SecondaryBadge>
              </button>
            ))
          )}
        </div>
      </div>
    )
  );
};

export default IndustryField;
