import {
  faApple,
  faFacebook,
  faFlickr,
  faGoogle,
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
  faWeibo,
  faWeixin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAddressBook as faAddressBook,
  faAddressCard,
  faAlarmClock,
  faArrowLeft,
  faArrowLeftToLine,
  faArrowRight,
  faArrowRightToLine,
  faArrowsLeftRight,
  faArrowsRotate,
  faAtom,
  faBars,
  faBirthdayCake,
  faBolt,
  faBoltAuto,
  faBookArrowRight,
  faBookUser,
  faBoxArchive,
  faBriefcase,
  faBuildings,
  faCalendar,
  faCalendarClock,
  faCalendarDays,
  faCheck,
  faCheckToSlot,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNodes,
  faCirclesOverlap,
  faClockRotateLeft,
  faCloud,
  faCloudCheck,
  faCloudDownload,
  faCloudPlus,
  faCloudXmark,
  faCommentsAlt,
  faCopy,
  faDolly,
  faDoNotEnter,
  faEllipsisV,
  faEnvelope,
  faEnvelopes,
  faEnvelopesBulk,
  faFaceClouds,
  faFaceSmilePlus,
  faFileCsv,
  faFileExport,
  faFileImport,
  faFileSpreadsheet,
  faFileUpload,
  faFilter,
  faFloppyDisk,
  faGlobe,
  faHashtag,
  faHeartCirclePlus,
  faIndustryWindows,
  faKeyboard,
  faLayerGroup,
  faLink,
  faMapMarkerAlt,
  faMaximize,
  faMerge,
  faMessageCheck,
  faMinimize,
  faObjectGroup,
  faOption,
  faPaperclip,
  faPaperPlaneTop,
  faPause,
  faPauseCircle,
  faPen,
  faPenToSquare,
  faPhone,
  faPlus,
  faProcedures,
  faRotateLeft,
  faSearch,
  faSpinnerThird,
  faSquareCode,
  faSquareInfo,
  faStar,
  faStopwatch,
  faTag,
  faTelescope,
  faTimes,
  faTrash,
  faUpRightFromSquare,
  faUserAlt,
  faUserMagnifyingGlass,
  faUserPlus,
  faUsers,
  faWandMagicSparkles,
  faWaveform,
  faWaveformLines,
  faWindowMaximize,
  faWindowMinimize,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAddressBook as faAddressBookSolid,
  faArrowRightArrowLeft as faArrowRightArrowLeftSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faBell as faBellSolid,
  faBirthdayCake as faBirthdayCakeSolid,
  faBookCopy as faBookCopySolid,
  faBookmark as faBookmarkSolid,
  faCalendar as faCalendarSolid,
  faCheckCircle as faCheckCircleSolid,
  faCircle as faCircleSolid,
  faCircleNodes as faCircleNodesSolid,
  faCloud as faCloudSolid,
  faCloudXmark as faCloudXMarkSolid,
  faCog as faCogSolid,
  faCopy as faCopySolid,
  faDiagramSuccessor as faDiagramSuccessorSolid,
  faDotCircle as faDotCircleSolid,
  faEdit as faEditSolid,
  faEnvelopesBulk as faEnvelopesBulkSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faExternalLink as faExternalLinkSolid,
  faFileCsv as faFileCsvSolid,
  faFilter as faFilterSolid,
  faHistory as faHistorySolid,
  faHome as faHomeSolid,
  faIdBadge as faIdBadgeSolid,
  faInfoCircle as faInfoCircleSolid,
  faLeftLong as faLeftLongSolid,
  faLockKeyhole as faLockKeyholeSolid,
  faLockKeyholeOpen as faLockKeyholeOpenSolid,
  faMapMarkedAlt as faMapMarkedAltSolid,
  faMinusCircle as faMinusCircleSolid,
  faMoon as faMoonSolid,
  faObjectsColumn as faObjectsColumnSolid,
  faPaperPlane as faPaperPlaneSolid,
  faPen as faPenSolid,
  faPhone as faPhoneSolid,
  faPlusCircle as faPlusCircleSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faRightFromBracket as faRightFromBracketSolid,
  faSeedling as faSeedlingSolid,
  faSortAmountDown as faSortAmountDownSolid,
  faSortCircle as faSortCircleSolid,
  faStar as faStarSolid,
  faStopCircle as faStopCircleSolid,
  faSun as faSunSolid,
  faTag as faTagSolid,
  faTimesCircle as faTimesCircleSolid,
  faTools as faToolsSolid,
  faTriangleExclamation as faTriangleExclamationSolid,
  faUser as faUserSolid,
  faUserCircle as faUserCircleSolid,
  faUserFriends as faUserFriendsSolid,
  faUserMagnifyingGlass as faUserMagnifyingGlassSolid,
  faUsers as faUsersSolid,
} from "@fortawesome/pro-solid-svg-icons";
import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen as faFolderOpenSharpLight } from "@fortawesome/sharp-light-svg-icons";
import React from "react";

export type IconType = (props?: Partial<FontAwesomeIconProps>) => JSX.Element;

export const AppleIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faApple} {...props} />
);

export const ArrowsLeftRightIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowsLeftRight} {...props} />
);

export const AddressBookIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faAddressBook} {...props} />
);
export const AddressBookSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faAddressBookSolid} {...props} />
);

export const AddressCardIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faAddressCard} {...props} />
);

export const ArrowRightArrowLeftSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowRightArrowLeftSolid} {...props} />
);

export const ArrowRotateIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowsRotate} {...props} />
);

export const ArrowUpRightFromSquareIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUpRightFromSquare} {...props} />
);

export const AtomIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faAtom} {...props} />
);

export const BadgeCheckSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBadgeCheckSolid} {...props} />
);

export const BookUserIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBookUser} {...props} />
);

export const BookArrowRight = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBookArrowRight} {...props} />
);

export const BoltIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBolt} {...props} />
);

export const BuildingsIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBuildings} {...props} />
);

export const ClockRotateLeftIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faClockRotateLeft} {...props} />
);

export const CirclesOverlapIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCirclesOverlap} {...props} />
);

export const CircleNodesIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCircleNodes} {...props} />
);

export const CirlceNodesSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCircleNodesSolid} {...props} />
);

export const FaceSmilePlusIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFaceSmilePlus} {...props} />
);

export const FileImportIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFileImport} {...props} />
);

export const WaveformIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWaveform} {...props} />
);

export const FolderOpenSharpLightIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFolderOpenSharpLight} {...props} />
);

export const FloopyDiskIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFloppyDisk} {...props} />
);

export const WaveformLinesIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWaveformLines} {...props} />
);

export const ObjectsColumnSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faObjectsColumnSolid} {...props} />
);

export const BarsIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBars} {...props} />
);

export const BellSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBellSolid} {...props} />
);

export const BoxArchiveIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBoxArchive} {...props} />
);

export const ArrowLeftIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowLeft} {...props} />
);

export const ArrowRightToLineIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowRightToLine} {...props} />
);

export const AlarmClockIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faAlarmClock} {...props} />
);

export const ArrowLeftToLineIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowLeftToLine} {...props} />
);

export const ProcedureIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faProcedures} {...props} />
);

export const FaceCloudsIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFaceClouds} {...props} />
);

export const FileExportIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFileExport} {...props} />
);

export const BirthdayCakeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBirthdayCake} {...props} />
);

export const BirthdayCakeSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBirthdayCakeSolid} {...props} />
);

export const BookmarkIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBookmarkSolid} {...props} />
);

export const BriefcaseIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBriefcase} {...props} />
);

export const BookCopySolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBookCopySolid} {...props} />
);

export const BoltAutoIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faBoltAuto} {...props} />
);

export const FileUploadIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFileUpload} {...props} />
);

export const CalendarIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCalendar} {...props} />
);

export const CalendarClockIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCalendarClock} {...props} />
);

export const ArrowRightIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faArrowRight} {...props} />
);

export const CalendarDaysIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCalendarDays} {...props} />
);

export const CalendarSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCalendarSolid} {...props} />
);

export const CheckIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCheck} {...props} />
);

export const CheckCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCheckCircleSolid} {...props} />
);

export const ChevronDownIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faChevronDown} {...props} />
);

export const ChevronRightIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faChevronRight} {...props} />
);

export const ChevronLeftIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faChevronLeft} {...props} />
);

export const CircleIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCircle} {...props} />
);

export const CopyIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCopy} {...props} />
);

export const CircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCircleSolid} {...props} />
);

export const CloudSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudSolid} {...props} />
);

export const CloudXMarkSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudXMarkSolid} {...props} />
);

export const CloudDownloadIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudDownload} {...props} />
);

export const CloudIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloud} {...props} />
);

export const CloudXIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudXmark} {...props} />
);

export const CloudXIconSolid = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudXMarkSolid} {...props} />
);

export const CloudCheckIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudCheck} {...props} />
);

export const CloudPlusIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCloudPlus} {...props} />
);

export const CommentsAltIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCommentsAlt} {...props} />
);

export const CheckToSlotIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCheckToSlot} {...props} />
);

export const CogSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCogSolid} {...props} />
);

export const CopySolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faCopySolid} {...props} />
);

export const DollyIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faDolly} {...props} />
);

export const DotCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faDotCircleSolid} {...props} />
);

export const DiagramSuccessorSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faDiagramSuccessorSolid} {...props} />
);

export const DoNotEnterIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faDoNotEnter} {...props} />
);

export const EditSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEditSolid} {...props} />
);

export const EllipsisVIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEllipsisV} {...props} />
);

export const EnvelopeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEnvelope} {...props} />
);

export const EnvelopesIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEnvelopes} {...props} />
);

export const EnvelopeBulkIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEnvelopesBulk} {...props} />
);

export const EnvelopeBulkSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faEnvelopesBulkSolid} {...props} />
);

export const ExclamationCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faExclamationCircleSolid} {...props} />
);

export const ExternalLinkSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faExternalLinkSolid} {...props} />
);

export const HeartCirclePlusIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faHeartCirclePlus} {...props} />
);

export const FacebookIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFacebook} {...props} />
);

export const FilterSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFilterSolid} {...props} />
);

export const FilterIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFilter} {...props} />
);

export const FileCsvIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFileCsv} {...props} />
);

export const FileCsvSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFileCsvSolid} {...props} />
);

export const FlickrIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFlickr} {...props} />
);

export const GlobeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faGlobe} {...props} />
);

export const GoogleIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faGoogle} {...props} />
);

export const HashtagIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faHashtag} {...props} />
);

export const HistorySolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faHistorySolid} {...props} />
);

export const HomeSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faHomeSolid} {...props} />
);

export const IndustryWindowsIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faIndustryWindows} {...props} />
);

export const InstagramIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faInstagram} {...props} />
);

export const InfoCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faInfoCircleSolid} {...props} />
);

export const KeyboardIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faKeyboard} {...props} />
);

export const LayerGroupIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLayerGroup} {...props} />
);

export const LeftLongSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLeftLongSolid} {...props} />
);

export const LockSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLockKeyholeSolid} {...props} />
);

export const LockOpenSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLockKeyholeOpenSolid} {...props} />
);

export const LinkIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLink} {...props} />
);

export const LinkedinIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faLinkedin} {...props} />
);

export const MapMarkerAltIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMapMarkerAlt} {...props} />
);

export const MapMarkedAltSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMapMarkedAltSolid} {...props} />
);

export const MessageCheckIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMessageCheck} {...props} />
);

export const MergeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMerge} {...props} />
);

export const MinusCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMinusCircleSolid} {...props} />
);

export const MinimizeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMinimize} {...props} />
);

export const MaximizeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMaximize} {...props} />
);

export const MoonSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faMoonSolid} {...props} />
);

export const IdBadgeSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faIdBadgeSolid} {...props} />
);

export const ObjectsGroupIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faObjectGroup} {...props} />
);

export const OptionIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faOption} {...props} />
);

export const PaperClipIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPaperclip} {...props} />
);

export const PaperPlaneSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPaperPlaneSolid} {...props} />
);

export const PaperPlaneTopIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPaperPlaneTop} {...props} />
);

export const PauseIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPause} {...props} />
);

export const PauseCircleIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPauseCircle} {...props} />
);

export const PenIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPen} {...props} />
);

export const PenSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPenSolid} {...props} />
);

export const PenToSquareIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPenToSquare} {...props} />
);

export const PhoneIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPhone} {...props} />
);

export const PhoneSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPhoneSolid} {...props} />
);

export const PlusIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPlus} {...props} />
);

export const PlusCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faPlusCircleSolid} {...props} />
);

export const QuestionCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faQuestionCircleSolid} {...props} />
);

export const RotateLeftIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faRotateLeft} {...props} />
);

export const ReplaceCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSortCircleSolid} rotation={90} {...props} />
);
export const SeedlingSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSeedlingSolid} {...props} />
);
export const SearchIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSearch} {...props} />
);

export const SortCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSortCircleSolid} {...props} />
);

export const SortAmountDownSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSortAmountDownSolid} {...props} />
);

export const SpinnerThirdIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSpinnerThird} {...props} />
);

export const StarIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStar} {...props} />
);

export const SpreadsheetIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faFileSpreadsheet} {...props} />
);

export const StarSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStarSolid} {...props} />
);

export const StopCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStopCircleSolid} {...props} />
);

export const StopwatchIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faStopwatch} {...props} />
);

export const SquareInfoIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSquareInfo} {...props} />
);

export const SquareCodeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSquareCode} {...props} />
);

export const SunSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faSunSolid} {...props} />
);

export const RightFromBracketSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faRightFromBracketSolid} {...props} />
);

export const TagIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTag} {...props} />
);

export const TagSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTagSolid} {...props} />
);

export const TelescopeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTelescope} {...props} />
);

export const ToolsSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faToolsSolid} {...props} />
);

export const TikTokIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTiktok} {...props} />
);

export const TimesIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTimes} {...props} />
);

export const TimesCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTimesCircleSolid} {...props} />
);

export const TrashIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTrash} {...props} />
);

export const TriangleExclamationSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTriangleExclamationSolid} {...props} />
);

export const TwitterIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faTwitter} {...props} />
);

export const UserAltIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserAlt} {...props} />
);

export const UserCircleSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserCircleSolid} {...props} />
);

export const UserFriendsSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserFriendsSolid} {...props} />
);

export const UsersIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUsers} {...props} />
);

export const UserInvite = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserPlus} {...props} />
);

export const UserMagnifyingGlassIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserMagnifyingGlass} {...props} />
);

export const UserMagnifyingGlassSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserMagnifyingGlassSolid} {...props} />
);

export const UserSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUserSolid} {...props} />
);

export const UsersSolidIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faUsersSolid} {...props} />
);

export const WandMagicSparklesIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWandMagicSparkles} {...props} />
);

export const WeiboIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWeibo} {...props} />
);

export const WeixinIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWeixin} {...props} />
);

export const WindowMaximizeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWindowMaximize} {...props} />
);

export const WindowMinimizeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faWindowMinimize} {...props} />
);

export const XmarkIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faXmark} {...props} />
);

export const YoutubeIcon = (props?: Partial<FontAwesomeIconProps>) => (
  <FontAwesomeIcon icon={faYoutube} {...props} />
);
