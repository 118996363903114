import { CONTACT_DB_NAME } from "@/database/contactDb";
import { EMAIL_DRAFT_DB_NAME } from "@/database/emailDraftDb";

(async function cleanUpLegacyDb() {
  if (typeof indexedDB !== "undefined" && typeof window !== "undefined" && indexedDB.databases) {
    const dbs = await indexedDB?.databases();
    for (const db of dbs) {
      if (
        db?.name?.startsWith("titledock") &&
        ![CONTACT_DB_NAME, EMAIL_DRAFT_DB_NAME].includes(db.name)
      ) {
        indexedDB?.deleteDatabase(db.name);
      }
    }
  }
})();

export async function deleteAllDbs() {
  if (typeof indexedDB !== "undefined" && typeof window !== "undefined") {
    indexedDB.deleteDatabase(CONTACT_DB_NAME);
    indexedDB.deleteDatabase(EMAIL_DRAFT_DB_NAME);
  }
}
