import classNames from "clsx";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { PhoneNumber } from "services/src/shared/models/types";
import { IsDefault } from "services/src/shared/models/types";

import { isEmpty } from "@/helpers/array";
import { formatPhoneNumber } from "utils/phoneNumber";
import { openLink } from "@/helpers/window";

import {
  CopySolidIcon,
  PenSolidIcon,
  PhoneIcon,
  PhoneSolidIcon,
  StarIcon,
  StarSolidIcon,
} from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import { showToast } from "../../../core/Toast";
import HighlightText from "../../../HighlightText";
import { generateTypeSelectItems } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import type { ContextualMenuItem } from "../shared/ContextualMenuField";
import ContextualMenuField from "../shared/ContextualMenuField";
import MarkDefaultItemButton from "../shared/MarkDefaultItemButton";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeSelect from "../shared/TypeSelect";
import type { ContactDataFieldProps } from "../types";

const PhoneNumbersField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
  focusedField,
  onFocusField,
}) => {
  const phoneNumbers = useMemo(() => contactData?.phoneNumbers || [], [contactData?.phoneNumbers]);
  const phoneNumbersWithIndices: {
    phoneNumber: PhoneNumber;
    originalIndex: number;
  }[] = useMemo(
    () =>
      [...phoneNumbers]
        .map((phoneNumber, index) => ({ phoneNumber, originalIndex: index }))
        .sort((a, b) => (b.phoneNumber.isDefault || 0) - (a.phoneNumber.isDefault || 0)),
    [phoneNumbers]
  );

  const lastPhoneNumberRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedPhoneNumberRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastPhoneNumberInput = useCallback(() => {
    lastPhoneNumberRowInputRef.current?.focus();
  }, [lastPhoneNumberRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "phoneNumbers") {
      focusedPhoneNumberRowInputRef.current?.focus();
      focusedPhoneNumberRowInputRef.current?.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [focusedField]);

  // Resolve phone number types from the default list + types that are in-use
  const phoneNumberTypeSelectItems = generateTypeSelectItems(
    "phoneNumbers",
    phoneNumbers.map((phoneNumber) => phoneNumber.type)
  );

  const updatePhoneNumbers = (phoneNumbers: PhoneNumber[]) => {
    if (dispatch) dispatch({ type: "phoneNumbers", payload: phoneNumbers });
  };

  const onAddNewPhoneNumber = (type?: PhoneNumber["type"]) => {
    const phoneNumbersToUpdate: PhoneNumber[] = [
      ...phoneNumbers,
      { value: "", type: type || phoneNumberTypeSelectItems[0]?.value },
    ];
    updatePhoneNumbers(phoneNumbersToUpdate);

    // After a short delay, focus on last phone number input (that was just added)
    setTimeout(() => {
      focusOnLastPhoneNumberInput();
    }, 100);
  };

  const onUpdatePhoneNumber = ({ index, data }: { index: number; data: Partial<PhoneNumber> }) => {
    const phoneNumbersToUpdate: PhoneNumber[] = [...phoneNumbers];
    phoneNumbersToUpdate[index] = {
      ...phoneNumbersToUpdate[index],
      ...data,
    };
    updatePhoneNumbers(phoneNumbersToUpdate);
  };

  const onRemovePhoneNumberAtIndex = (index: number) => {
    const phoneNumbersToUpdate: PhoneNumber[] = [...phoneNumbers];
    phoneNumbersToUpdate.splice(index, 1);
    updatePhoneNumbers(phoneNumbersToUpdate);
  };

  const onMarkPhoneNumberDefaultAtIndex = useCallback(
    (isDefault: boolean, index: number) => {
      // If marking a phone number default, first reset isDefault state for every object in the array
      const phoneNumbersToUpdate: PhoneNumber[] = isDefault
        ? phoneNumbers.map((o) => ({
            ...o,
            isDefault: IsDefault.NO,
          }))
        : [...phoneNumbers];
      phoneNumbersToUpdate[index] = {
        ...phoneNumbersToUpdate[index],
        isDefault: isDefault ? IsDefault.YES : IsDefault.NO,
      };
      if (!isEditing) {
        directlyUpdateContactData?.({ phoneNumbers: phoneNumbersToUpdate });
      }
      updatePhoneNumbers(phoneNumbersToUpdate);
    },
    [phoneNumbers, isEditing, updatePhoneNumbers, directlyUpdateContactData]
  );

  const phoneNumberContextualItems = useMemo<ContextualMenuItem<PhoneNumber>[]>(() => {
    return [
      {
        icon: (payload) => (payload.value.isDefault ? StarSolidIcon : StarIcon),
        tooltip: (payload) => (payload.value.isDefault ? "Unmark as Default" : "Mark as Default"),
        action: (payload) => {
          if (payload.index !== undefined) {
            onMarkPhoneNumberDefaultAtIndex(!payload.value.isDefault, payload.index);
          }
        },
        isPinned: (payload) => payload.value.isDefault === IsDefault.YES,
      },
      {
        icon: () => PhoneSolidIcon,
        tooltip: () => "Call phone number",
        action: (payload) => {
          openLink(`tel:${payload.value.value}`);
        },
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy phone number to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(payload.value.value);
            showToast({ title: "Phone number copied to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit phone number",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [onFocusField, onMarkPhoneNumberDefaultAtIndex]);

  // Do not show for read-only mode if there are no phone numbers
  if (!isEditing && isEmpty(phoneNumbers)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Phone numbers"
      isEditing={isEditing}
      icon={<PhoneIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {phoneNumbersWithIndices.map(({ phoneNumber, originalIndex }, index) => {
            const isLastPhoneNumberInput = originalIndex === phoneNumbers.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "phoneNumbers" && focusedField?.index === originalIndex;
            const isDefault = phoneNumber.isDefault === IsDefault.YES;
            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <TypeSelect
                  items={phoneNumberTypeSelectItems}
                  initialItemId={phoneNumber.type}
                  onSelectItem={(item) =>
                    onUpdatePhoneNumber({
                      index: originalIndex,
                      data: { type: item.value },
                    })
                  }
                  createNewItemTitle="Custom"
                />
                <TextInput
                  name="phoneNumber"
                  type="tel"
                  value={phoneNumber.value}
                  onChange={(event) =>
                    onUpdatePhoneNumber({
                      index: originalIndex,
                      data: { value: (event.target as HTMLInputElement).value },
                    })
                  }
                  forwardedRef={
                    (isFocusedField && focusedPhoneNumberRowInputRef) ||
                    (isLastPhoneNumberInput && lastPhoneNumberRowInputRef) ||
                    undefined
                  }
                  className="flex-1 mr-2"
                />
                <MarkDefaultItemButton
                  isDefault={isDefault}
                  onClickButton={() => onMarkPhoneNumberDefaultAtIndex(!isDefault, originalIndex)}
                  tooltip={isDefault ? "Unmark as Default" : "Mark as Default"}
                  className={classNames(
                    "group-hover:visible",
                    isDefault ? "visible opacity-90" : "invisible opacity-70"
                  )}
                />
                <RemoveDetailsItemButton
                  onClickButton={() => onRemovePhoneNumberAtIndex(originalIndex)}
                  tooltip="Remove Phone Number"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-phone-number" className="flex flex-row w-full">
            <TypeSelect
              items={phoneNumberTypeSelectItems}
              onSelectItem={(item) => onAddNewPhoneNumber(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewPhoneNumber()}
            >
              Add a phone number
            </button>
          </dd>
        </>
      ) : (
        phoneNumbersWithIndices.map(({ phoneNumber, originalIndex }, index) => (
          <dd key={index}>
            <ContextualMenuField
              items={phoneNumberContextualItems}
              actionPayload={{
                fieldName: "phoneNumbers",
                value: phoneNumber,
                index: originalIndex,
              }}
            >
              <a
                href={`tel:${phoneNumber.value}`}
                target="_blank"
                rel="noreferrer"
                className="text-primary text-underline whitespace-pre"
              >
                <HighlightText value={formatPhoneNumber(phoneNumber.value)} matchPhrase={false} />
              </a>
              <div className="uppercase text-label">{phoneNumber.type}</div>
            </ContextualMenuField>
          </dd>
        ))
      )}
    </BaseDetailsField>
  );
};

export default PhoneNumbersField;
