import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import type { ContactState } from "./types";

// Initial slice state
const initialState: ContactState = {
  selectedContactId: undefined,
  multiSelectedContacts: [],
  selectedGroups: [],
  isMultiSelectAddToGroupDialogOpen: false,
  isDeleteContactsAlertOpen: false,
  isContactInteractionDialogOpen: false,
  contactDrawer: null,
  companyDataQuery: { email: "", domain: "", name: "" },
};

// Slice
const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setIsContactInteractionDialogOpen(state, action: PayloadAction<boolean>) {
      state.isContactInteractionDialogOpen = action.payload;
    },
    setIsContactCompanyDetailOpen(state, action: PayloadAction<boolean>) {
      if (state.contactDrawer === "contactCompanyDetail") {
        state.contactDrawer = null;
      } else if (typeof action.payload !== "undefined") {
        state.contactDrawer = "contactCompanyDetail";
      }
    },
    setIsContactHistoryOpen(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.contactDrawer = "contactHistory";
      } else if (state.contactDrawer === "contactHistory") {
        state.contactDrawer = null;
      }
    },
    setCompanyDataQuery(state, action: PayloadAction<ContactState["companyDataQuery"]>) {
      state.companyDataQuery = action.payload;
    },
    setSelectedContactId(state, action: PayloadAction<string | undefined>) {
      state.selectedContactId = action.payload;
    },
    setIsMultiSelectGroupDialogOpen(
      state,
      action: PayloadAction<ContactState["isMultiSelectAddToGroupDialogOpen"]>
    ) {
      state.isMultiSelectAddToGroupDialogOpen = action.payload;
    },
    setIsDeleteContactsAlertOpen(
      state,
      action: PayloadAction<ContactState["isDeleteContactsAlertOpen"]>
    ) {
      state.isDeleteContactsAlertOpen = action.payload;
    },
    setMultiSelectedContacts(
      state,
      action: PayloadAction<{ contacts: ContactState["multiSelectedContacts"] }>
    ) {
      const { contacts } = action.payload;
      state.multiSelectedContacts = contacts;
    },
    clearMultiSelectedContacts(state) {
      state.multiSelectedContacts = [];
    },
    setSelectedGroups(state, action: PayloadAction<{ groups: ContactState["selectedGroups"] }>) {
      const { groups } = action.payload;
      state.selectedGroups = groups;
    },
    removeSelectedGroup(state, action: PayloadAction<{ groupId: string }>) {
      const { groupId } = action.payload;
      state.selectedGroups = state.selectedGroups.filter((group) => group.id !== groupId);
    },
    clearSelectedGroups(state) {
      state.selectedGroups = [];
    },
  },
});

export default contactSlice;
