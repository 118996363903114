import "@fortawesome/fontawesome-svg-core/styles.css";
import "../styles/globals.css";
import "../styles/react-csv-importer.css";
import "../styles/tooltip.css";

import { config } from "@fortawesome/fontawesome-svg-core";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/nextjs";
import classNames from "clsx";
import type { AppProps } from "next/app";
import { Inter } from "next/font/google";
import { Toaster } from "react-hot-toast";
import { Provider as ReduxProvider } from "react-redux";

import { FatalError } from "@/components/AppError";
import AuthedOnly from "@/components/AuthedOnly";
import EmailDraftProvider from "@/components/email/EmailDraftProvider";
import Sidebar from "@/components/sidebar";
import type { PageWithLayout } from "@/hocs/types";

import store from "../integrations/redux/store";

// https://fontawesome.com/v6.0/docs/web/use-with/react/use-with#next-js
config.autoAddCss = false;

if (typeof window !== "undefined") {
  // catch the resizer observer error
  // https://github.com/petyosi/react-virtuoso/issues/254
  // can be safely ignored
  window.addEventListener("error", (e) => {
    if (
      e.message.includes("ResizeObserver loop completed with undelivered notifications") ||
      e.message.includes("ResizeObserver loop limit exceeded")
    ) {
      e.stopImmediatePropagation();
    }
  });
}
// If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ["latin"] });

const TitledockApp = ({ Component, pageProps }: AppProps) => {
  // Grab per-page layout info
  const getLayout = (Component as PageWithLayout).getLayout || ((page) => page);

  return (
    <Sentry.ErrorBoundary fallback={FatalError}>
      <ReduxProvider store={store}>
        <>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ""}>
            <EmailDraftProvider queryData openedOnly>
              <div className={classNames("flex h-full w-full", inter.className)}>
                <AuthedOnly>
                  <Sidebar />
                </AuthedOnly>
                {getLayout(<Component {...pageProps} />)}
              </div>
            </EmailDraftProvider>
          </GoogleOAuthProvider>
          <Toaster />
        </>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  );
};

export default TitledockApp;
