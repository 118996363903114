import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import approx from "approximate-number";
import classNames from "clsx";
import { FC, useMemo } from "react";
import { formatPhoneNumber } from "utils/phoneNumber";
import { getUrlFromDomain } from "utils/string";

import { useCompanyDetail } from "@/components/contacts/details/hooks";
import { CompanyData } from "@/components/contacts/v2/types";
import Button, { ButtonVariant } from "@/components/core/Button";
import {
  FacebookIcon,
  FolderOpenSharpLightIcon,
  LinkedinIcon,
  LinkIcon,
  TwitterIcon,
} from "@/components/core/Icon";
import HighlightText from "@/components/HighlightText";

const CompanyDetailDrawer: FC<{
  company?: CompanyData;
  isLoading: boolean;
}> = ({ isLoading, company }) => {
  const { isContactCompanyDetailOpen, setIsCompanyDetailOpen, companyDataQuery } =
    useCompanyDetail();

  const companyFields = useMemo(() => {
    return {
      Founded: company?.foundedYr || company?.extra?.founded_year || null,
      Website: company?.website ? (
        <a href={`http://${company.website}`} target="_blank">
          {company.website}
        </a>
      ) : null,
      Address: company?.extra?.raw_address ? (
        <span className="capitalize">{company?.extra?.raw_address}</span>
      ) : null,
      About:
        company?.extra?.short_description || company?.extra?.seo_description ? (
          <>
            {company.extra.short_description && <p>{company.extra.short_description}</p>}
            {company.extra.seo_description && <p>{company.extra.seo_description}</p>}
          </>
        ) : null,
      Employees: company?.extra?.estimated_num_employees
        ? approx(company?.extra?.estimated_num_employees)
        : company?.size || null,
      Revenue: company?.extra?.annual_revenue
        ? approx(Number(company?.extra?.annual_revenue))
        : null,
      "Market Cap": company?.extra?.market_cap || null,
      Stock: company?.extra?.publicly_traded_symbol
        ? [company?.extra?.publicly_traded_exchange, company?.extra?.publicly_traded_symbol]
            .filter(Boolean)
            .map((s) => s.toUpperCase())
            .join(" : ")
        : null,
    };
  }, [company?.extra, company?.foundedYr, company?.size, company?.website]);

  return (
    <Transition
      as="div"
      show={isContactCompanyDetailOpen}
      className={classNames("w-1/2 min-w-[400px] h-full overflow-y-auto border-l")}
      enter="transform transition ease-linear duration-250"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-linear duration-250"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      {!company ? (
        <div
          className={classNames("h-full overflow-hidden", {
            "animate-pulse": isLoading,
          })}
        >
          <div className="flex items-start justify-between py-6 overflow-hidden">
            <div className="flex flex-row justify-between space-x-2 sm:space-x-4 flex-1 items-center ml-6">
              <div
                className={classNames("flex flex-row items-center space-x-4 h-20 w-20", {
                  "border bg-secondary": isLoading,
                })}
              />
            </div>
            <div className="ml-3 flex h-7 items-center text-primary">
              <Button
                variant={ButtonVariant.None}
                hotkey={["esc"]}
                onClick={() => setIsCompanyDetailOpen(false)}
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </Button>
            </div>
          </div>

          <div className="px-4 py-5 sm:px-0 sm:py-0 h-[50%]">
            {!isLoading ? (
              <div className="h-full items-center justify-center w-full flex text-center">
                <FolderOpenSharpLightIcon size="2x" className="text-gray-400 mr-2" />
                <div className="text-secondary text-sm">No company data found</div>
              </div>
            ) : (
              <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div className="sm:flex sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-secondary sm:w-16 sm:flex-shrink-0 lg:w-20 bg-secondary h-4"></dt>
                  <dd className="mt-1 text-sm text-secondary sm:col-span-2 sm:ml-6 sm:mt-0 pr-6 bg-secondary h-4 w-full"></dd>
                </div>
                <div className="sm:flex sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-secondary sm:w-16 sm:flex-shrink-0 lg:w-20 bg-secondary h-4"></dt>
                  <dd className="mt-1 text-sm text-secondary sm:col-span-2 sm:ml-6 sm:mt-0 pr-6 bg-secondary h-4 w-full"></dd>
                </div>
                <div className="sm:flex sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-secondary sm:w-16 sm:flex-shrink-0 lg:w-20 bg-secondary h-4"></dt>
                  <dd className="mt-1 text-sm text-secondary sm:col-span-2 sm:ml-6 sm:mt-0 pr-6 bg-secondary h-4 w-full"></dd>
                </div>
                <div className="sm:flex sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-secondary sm:w-16 sm:flex-shrink-0 lg:w-20 bg-secondary h-4"></dt>
                  <dd className="mt-1 text-sm text-secondary sm:col-span-2 sm:ml-6 sm:mt-0 pr-6 bg-secondary h-4 w-full"></dd>
                </div>
                <div className="sm:flex sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-secondary sm:w-16 sm:flex-shrink-0 lg:w-20 bg-secondary h-4"></dt>
                  <dd className="mt-1 text-sm text-secondary sm:col-span-2 sm:ml-6 sm:mt-0 pr-6 bg-secondary h-4 w-full"></dd>
                </div>
              </dl>
            )}
          </div>
        </div>
      ) : (
        <div className="divide-y divide-gray-200 pt-6 overflow-hidden">
          <div className="flex items-start justify-between">
            <div className="flex flex-row justify-between space-x-2 sm:space-x-4 flex-1 items-start ml-6">
              <div className="flex flex-row items-center space-x-4 border">
                <a href={getUrlFromDomain(company?.website || undefined)}>
                  <img className="h-20 w-20 flex-shrink-0" src={company?.extra?.logo_url} alt="" />
                </a>
              </div>
              <div className="flex-1 min-w-0 -mt-1 sm:mt-0">
                <h1 className="mb-1 capitalize text-lg font-semibold whitespace-break-spaces truncate overflow-hidden text-primary sm:font-bold sm:text-2xl">
                  <HighlightText matchPhrase={false} value={company?.name} />
                </h1>
                <div className="flex-1 mt-4 space-x-4 text-secondary">
                  {company?.website && (
                    <a href={getUrlFromDomain(company.website)} target="_blank">
                      <LinkIcon size="xl" className="hover:text-blue-700" />
                    </a>
                  )}
                  {(company?.linkedinUrl || company?.extra?.linkedin_url) && (
                    <a
                      href={getUrlFromDomain(company?.linkedinUrl || company?.extra?.linkedin_url)}
                      target="_blank"
                    >
                      <LinkedinIcon size="xl" className="hover:text-blue-700" />
                    </a>
                  )}
                  {company?.extra?.twitter_url && (
                    <a href={getUrlFromDomain(company.extra.twitter_url)} target="_blank">
                      <TwitterIcon size="xl" className="hover:text-blue-700" />
                    </a>
                  )}
                  {company?.extra?.facebook_url && (
                    <a href={getUrlFromDomain(company.extra.facebook_url)} target="_blank">
                      <FacebookIcon size="xl" className="hover:text-blue-700" />
                    </a>
                  )}
                  {company?.extra?.sanitized_phone && (
                    <a href={`tel:${company?.extra?.sanitized_phone}`} target="_blank">
                      {formatPhoneNumber(company?.extra?.sanitized_phone)}
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="ml-3 flex h-7 items-center text-primary">
              <Button
                variant={ButtonVariant.None}
                hotkey={["esc"]}
                onClick={() => setIsCompanyDetailOpen(false)}
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </Button>
            </div>
          </div>

          <div className="px-4 py-5 sm:px-0 sm:py-0 mt-6">
            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-color-primary">
              {Object.entries(companyFields).map(([key, value]) => {
                if (!value) return null;
                return (
                  <div className="sm:flex sm:px-6 sm:py-5" key={key}>
                    <dt className="text-sm font-medium text-secondary sm:w-16 sm:flex-shrink-0 lg:w-20">
                      {key}
                    </dt>
                    <dd className="mt-1 text-sm text-secondary sm:col-span-2 sm:ml-6 sm:mt-0 pr-6">
                      {value}
                    </dd>
                  </div>
                );
              })}
            </dl>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default CompanyDetailDrawer;
