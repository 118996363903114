import { FlattenedContactRecipientList } from "core/types/userMessaging";
import type { FC, ReactElement } from "react";

import Alert from "@/components/core/Alert";
import { ButtonVariant } from "@/components/core/Button";
import { useContactDataOverride, useDraftBodyOverride } from "@/components/email/hooks";
import MailMergePreview from "@/components/email/MailMergePreview";
import LoadingSpinner from "@/components/loading/LoadingSpinner";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

const MailMergePreviewModal: FC<{
  draft: LocallyPersistedEmailDraft;
  flattenedRecipientList: FlattenedContactRecipientList;
  sendScheduleControls?: ReactElement;
  sendScheduleBanner?: ReactElement;
  isOpen: boolean;
  onClose: () => void;
  showSchedule: boolean;
}> = ({
  showSchedule,
  isOpen,
  onClose,
  draft,
  flattenedRecipientList,
  sendScheduleControls,
  sendScheduleBanner,
}) => {
  const { draftBodyOverride, setDraftBodyOverride, onContentChange } = useDraftBodyOverride(
    draft.id,
  );
  const { contactDataOverride, updateContactOverride } = useContactDataOverride(draft.id);

  return (
    <Alert
      isAlertOpen={isOpen}
      onClose={onClose}
      title="Mail Merge Preview"
      cancelButtonTitle="Close"
      hideCancelButton={showSchedule}
      description="Select a contact to preview the mail merge. Each email can be further edited before sending."
      variant={ButtonVariant.Special}
      actionElement={<div>{sendScheduleControls}</div>}
      width="max-w-5xl"
      className="border border-color-primary bg-primary overflow-hidden ring-1 ring-primary w-full"
      actionButtonClassName=""
    >
      {!flattenedRecipientList ? (
        <LoadingSpinner loadingText="Loading Mail Merge Preview..." />
      ) : (
        <>
          {sendScheduleBanner && <div className="my-4">{sendScheduleBanner}</div>}
          <MailMergePreview
            key={draft.id}
            draft={draft}
            flattenedRecipientList={flattenedRecipientList}
            draftBodyOverride={draftBodyOverride}
            setDraftBodyOverride={setDraftBodyOverride}
            onEditorBodyContentChange={onContentChange}
            contactDataOverride={contactDataOverride}
            updateContactOverride={updateContactOverride}
          />
        </>
      )}
    </Alert>
  );
};

export default MailMergePreviewModal;
