import ms, { StringValue } from "./ms";

import { getRandomInt } from "./number";
import { pluralize } from "./string";

export function getExpiresAtSec(config: StringValue, startSec = getCurEpocSec()) {
  return startSec + getSec(config);
}

export function getExpiresAtMs(config: StringValue, startMs = getCurEpochMs()) {
  return startMs + getMs(config);
}

export function getSec(config: StringValue | string) {
  return ms(config as StringValue) / 1000;
}

export function getMs(config: StringValue | string) {
  return ms(config as StringValue);
}

export function getSecFromMs(ms: number) {
  return Math.round(ms / 1000);
}

export function getMsFromSec(sec: number) {
  return sec * 1000;
}

export function getEpochMsFromDate(date: Date) {
  return date.getTime();
}

export function getCurEpochMs() {
  const now = new Date();
  return now.getTime();
}

export function getCurEpocSec() {
  const curEpochMs = getCurEpochMs();
  return getSecFromMs(curEpochMs);
}

export const getDateFromEpochMs = (epochNum: number | string) => new Date(Number(epochNum));

export function getEpochMsFromStr(dateTime: string | null) {
  if (dateTime) {
    const date = new Date(dateTime);
    return getEpochMsFromDate(date) || 0;
  }
  return 0;
}

export function getEpochMs1YearFromNow() {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  return getEpochMsFromDate(oneYearFromNow);
}

export function getDateStampFromDateIsoStr(dateIsoString: string) {
  return dateIsoString.replace("-", "/").split("T")[0].replace("-", "/");
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(Number(d));
}

export function parseNoneDashDelimitedDateString(icsDate: string) {
  if (/^[0-9]{8}T[0-9]{6}Z$/.test(icsDate)) {
    const year = icsDate.substr(0, 4);
    const month = icsDate.substr(4, 2);
    const day = icsDate.substr(6, 2);

    const hour = icsDate.substr(9, 2);
    const minute = icsDate.substr(11, 2);
    const second = icsDate.substr(13, 2);

    const date = new Date(
      Date.UTC(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hour),
        Number(minute),
        Number(second)
      )
    );
    return isValidDate(date) ? date : undefined;
  }
  return undefined;
}

export function get_M_d_yyyy(datestamp?: number | string) {
  const date = datestamp ? new Date(datestamp) : new Date();
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear()
  );
}

export function toDateTime(secs: number) {
  const date = new Date(1970, 0, 1); // Epoch
  return date.setSeconds(secs);
}

export function getMonthName(
  { date, monthIndex, locale = "en-US" }: { date?: Date; monthIndex?: number; locale?: "en-US" },
  shortName = false
) {
  const monthNames = [...Array(12).keys()].map((key) =>
    new Date(0, key).toLocaleString(locale, {
      month: shortName ? "short" : "long",
    })
  );

  const i = typeof monthIndex !== "undefined" ? monthIndex : date?.getMonth();
  if (typeof i !== "undefined") return monthNames[i];
  return "";
}

export function getDateInTimeZone(date: Date, timeZone: string, locale: string = "en-US") {
  return new Date(
    date.toLocaleString(locale, {
      timeZone,
    })
  );
}

export function chunkDateList(start: string, end: string, chunkSize = getMs("30 days")) {
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();

  const delta = Math.abs(endDate - startDate);
  let numChunks = Math.ceil(delta / chunkSize);

  const chunks = Array(numChunks);

  let startTime = startDate;
  for (const [i] of chunks.entries()) {
    let endTime = startTime + chunkSize;
    chunks[i] = { startDate: new Date(startTime), endDate: new Date(endTime) };
    startTime = endTime;
  }
  return chunks;
}

export function getFormattedDateStr(val: string | Date) {
  const date = typeof val === "string" ? new Date(val) : val;
  if (!isValidDate(date)) return "";
  const formattedDateStr = date.toISOString().slice(0, 10);
  const [year, month, day] = formattedDateStr.split("-");
  return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
}

export function getNextRetryAtMs({
  retryCount,
  maxRetries,
  maxDelay,
  baseDelay,
  jitter = { low: 0.3, high: 0.3 },
}: {
  retryCount: number;
  maxRetries: number;
  baseDelay: number;
  maxDelay: number;
  jitter?: { low: number; high: number };
}): number | null {
  if (retryCount > maxRetries) {
    return null;
  }
  // jitter = +/- 30% of baseDelay
  let delay = jitter
    ? getRandomInt(baseDelay * (1 - jitter.low), baseDelay * (1 + jitter.high))
    : baseDelay;
  for (let i = 0; i < retryCount; i++) {
    delay = Math.min(delay * 2, maxDelay);
  }
  return getCurEpochMs() + delay;
}

export function getTimestampRanges(weeks: number = 1, timezoneOffset?: number) {
  const now = new Date();
  if (timezoneOffset !== undefined) {
    now.setMinutes(now.getMinutes() + now.getTimezoneOffset() + timezoneOffset);
  }

  const currentDayOfWeek = now.getDay();
  const startOfCurrentWeek = new Date(now);
  startOfCurrentWeek.setDate(now.getDate() - currentDayOfWeek);
  startOfCurrentWeek.setHours(0, 0, 0, 0);

  const startOfFirstWeek = new Date(startOfCurrentWeek);
  startOfFirstWeek.setDate(startOfFirstWeek.getDate() - 7 * weeks);

  const days = 7 * (weeks + 1);
  const ranges = [];

  for (let i = 0; i < days; i++) {
    const dayStart = new Date(startOfFirstWeek);
    dayStart.setDate(dayStart.getDate() + i);

    const dayEnd = new Date(dayStart);
    dayEnd.setHours(23, 59, 59, 999);

    const startTimestamp = dayStart.getTime();
    const endTimestamp = dayEnd.getTime();

    ranges.push([startTimestamp, endTimestamp]);
  }

  return ranges;
}

export function getTimestampWeeksAgo(weeksAgo: number, timezoneOffset: number): number {
  // Get current date object with timezone offset
  const currentDate = new Date();
  currentDate.setMinutes(
    currentDate.getMinutes() + currentDate.getTimezoneOffset() + timezoneOffset
  );

  // Set the date to the specified number of weeks ago
  currentDate.setDate(currentDate.getDate() - weeksAgo * 7);

  // Set the time to midnight
  currentDate.setHours(0, 0, 0, 0);

  // Get Unix timestamp in milliseconds
  const timestampWeeksAgo: number = currentDate.getTime();

  return timestampWeeksAgo;
}

export function getWeek(timestamp?: number) {
  let currentDate = new Date();
  if (timestamp) currentDate = new Date(timestamp);

  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate?.getTime() - startDate?.getTime()) / (24 * 60 * 60 * 1000));

  return Math.ceil(days / 7);
}

export function getCurWeek() {
  return getWeek();
}

export function getMonth(timestamp?: number) {
  let date = new Date();
  if (timestamp) date = new Date(timestamp);
  return date.getMonth();
}

export function getCurMonth() {
  return getMonth();
}

export function getYear(timestamp?: number) {
  if (!timestamp) return undefined;
  let date = new Date(timestamp);
  if (timestamp) date = new Date(timestamp);
  return date.getFullYear();
}

export function getCurYear() {
  return getYear();
}

export function getLocaleStringForDisplay(timestamp?: number) {
  if (!timestamp) return undefined;
  return new Date(timestamp).toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function getTimeAgo(timestamp: number | undefined): string {
  if (!timestamp) return "";
  // Get current Unix timestamp in milliseconds
  const currentTimestampMs: number = getCurEpochMs();
  // Calculate the difference in milliseconds
  const diffMs: number = currentTimestampMs - timestamp;

  // Convert difference from milliseconds to seconds
  const diff = Math.floor(diffMs / 1000);

  // Define periods
  const minute: number = 60;
  const hour: number = minute * 60;
  const day: number = hour * 24;
  const week: number = day * 7;
  const month: number = day * 30;
  const year: number = day * 365;

  if (diff < minute) {
    return pluralize(diff, "second ago", "seconds ago", true);
  } else if (diff < hour) {
    return pluralize(Math.floor(diff / minute), "minute ago", "minutes ago", true);
  } else if (diff < day) {
    return pluralize(Math.floor(diff / hour), "hour ago", "hours ago", true);
  } else if (diff < week) {
    return pluralize(Math.floor(diff / day), "day ago", "days ago", true);
  } else if (diff < month) {
    return pluralize(Math.floor(diff / week), "week ago", "weeks ago", true);
  } else if (diff < year) {
    return pluralize(Math.floor(diff / month), "month ago", "months ago", true);
  } else {
    return pluralize(Math.floor(diff / year), "year ago", "years ago", true);
  }
}

function formatTime(date: Date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  const strHours = hours < 10 ? `0${hours}` : hours.toString();
  const strMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

  return `${strHours}:${strMinutes} ${ampm}`;
}

export function getTimeStr(unixTimestampMs: number): string {
  // Array of short month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Create a date object for the current time
  const now = new Date();
  // Create a date object from the Unix timestamp in milliseconds
  const date = new Date(unixTimestampMs);

  // Check if the date is from today
  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    // Format the time as "hour:min AM/PM"
    return formatTime(date);
  }

  // For other dates, return the short month name, day, and time
  const monthName = months[date.getMonth()];
  const day = date.getDate();
  return `${monthName} ${day}, ${formatTime(date)}`;
}

export function isLeapYear(year: number) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function isLeapDayFromDate(date: Date | undefined): boolean {
  return date?.getMonth() === 1 && date?.getDate() === 29;
}

export function getMonthDayFromDateStr(dateStr: string) {
  const [, month, day] = dateStr.split("-").map((val) => parseInt(val, 10));
  return { month, day };
}

export function getMonthDayFromDateStrCompDate(
  dayMonth: { day: number; month: number },
  compDate: Date,
  leapDayPreference: number = 28
): { month: number; day: number; compMonth: number; compDay: number } {
  // default leap date preference is 28, set undefined to use both 02-28 and 03-01
  const defaultLeapDayPreference: 28 | 1 | undefined = 28;

  const { month, day } = dayMonth;

  const isLeapDay = month === 2 && day === 29;
  const compYear = compDate.getFullYear();
  const compMonth = Number(compDate.getMonth()) + 1; // getMonth() returns 0-11
  const compDay = compDate.getDate();

  if (!isLeapDay || isLeapYear(compYear)) return { month, day, compMonth, compDay };

  // dateStr is leap day, but current year is not a leap year, return day and month based on leapDayPreference

  // override to either 02-28 or 03-01
  if (leapDayPreference === 28) {
    return {
      month: 2,
      day: 28,
      compMonth,
      compDay,
    };
  } else if (leapDayPreference === 1) {
    return {
      month: 3,
      day: 1,
      compMonth,
      compDay,
    };
  }

  const isCompDatePassedLeapDay = compMonth <= 2 && compDay <= 28;

  return {
    month: isCompDatePassedLeapDay ? 2 : 3,
    day: isCompDatePassedLeapDay ? 28 : 1,
    compMonth,
    compDay,
  };
}

export function isTodaysDate(dateStr: string, timeZone?: string, leapDayPreference?: number) {
  const curDate: Date = timeZone ? getDateInTimeZone(new Date(), timeZone) : new Date();
  const { month, day, compMonth, compDay } = getMonthDayFromDateStrCompDate(
    getMonthDayFromDateStr(dateStr),
    curDate,
    leapDayPreference
  );

  return month === compMonth && day === compDay;
}

export function isTomorrowsDate(dateStr: string, timeZone?: string, leapDayPreference?: number) {
  const tmr: Date = timeZone ? getDateInTimeZone(new Date(), timeZone) : new Date();
  tmr.setDate(Number(tmr.getDate()) + 1);

  const { month, day, compMonth, compDay } = getMonthDayFromDateStrCompDate(
    getMonthDayFromDateStr(dateStr),
    tmr,
    leapDayPreference
  );

  return month === compMonth && day === compDay;
}
