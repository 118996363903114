import { PartialBlock } from "@blocknote/core";
import { Contact } from "@shared/models/Contact";
import { DdbBoolean } from "@shared/models/types";
import { LocallyPersistedContactRow } from "../helpers/contact";
import { SendMessagePayload } from "../services/UserMessaging/types";

export type ContactRecipient = {
  id: string;
  contactId?: string;
  name: string;
  email: string;
  type: "contact" | "prospect";
};

export type ContactGroupRecipient = {
  id: string;
  type: "contactGroup";
  contactGroupId: string;
  name: string;
  excludedContactIds: string[];
  emailAddresses: {
    [contactId: string]: string; // RFC 5322 email address
  };
};

export type EmailRecipient = ContactRecipient | ContactGroupRecipient;

export type ContactRecipientPreview = ContactRecipient & {
  contact?: LocallyPersistedContactRow;
};

export enum UserMessageType {
  EMAIL = "email",
}

export enum UserMessageEmailVendor {
  GOOGLE = "google",
}

export type UserMessageVendor = UserMessageEmailVendor;

export type UserMessageDraft = {
  id: string;
  createdAt: number;
  updatedAt: number;
  scheduledAt?: number;

  subject: string;
  attachments?: string[];

  isTracked?: DdbBoolean;

  remoteApiId?: string;
  type: UserMessageType;

  from?: { name?: string; email: string };
  to: EmailRecipient[];
  cc: EmailRecipient[];
  bcc: EmailRecipient[];

  body: PartialBlock[];

  bodyOverride?: DraftBodyOverride;
  contactRecipientOverride?: ContactRecipientOverride;

  remoteBodyKey?: string;
};

export type FlattenedContactRecipientList = {
  flattenedContactList: ContactRecipientPreview[];
  groupedLabels: string[];
  groupCounts: number[];
  contactIdsWithoutEmail: Set<string>;
  isMailMerge: boolean;
  missingMailMergeFieldsByRecipientId: { [recipientId: string]: (keyof Contact)[] };
};
export type DraftBodyOverride = { [recipientId: string]: UserMessageDraft["body"] };
export type ContactRecipientOverride = { [recipientId: string]: Partial<Contact> };
