import type { PostContactsMergeResolutionPayload } from "@http/post-contacts-merge/types";
import type { PendingContactResolution } from "@http/post-pendingContacts-resolve/types";
import * as Sentry from "@sentry/nextjs";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import type { ContactInteractionRow } from "@shared/models/ContactInteraction";
import { ContactMergeLedgerRow } from "@shared/models/ContactMergeLedger";
import type {
  ContactMetadataRow,
  ContactMetadataRowForDisplay,
} from "@shared/models/ContactMetadata";
import type { DdbBoolean } from "@shared/models/types";
import type { LocallyPersistedContactRow } from "core/helpers/contact";
import { getContactLocations, getContactMappableLocations } from "core/helpers/contactLocation";
import type { DedupeContactRow } from "core/helpers/contactMatching";
import { Dexie, Table } from "dexie";

import { SelectedSearchFilterState } from "@/components/contacts/search/types";
import { CompanyData, ContactData } from "@/components/contacts/v2/types";
import {
  bulkIndexContactGroupRowsInSearch,
  bulkIndexContactMetadataRowsInSearch,
  bulkIndexContactRowsInSearch,
  removeContactMetadataDbRowFromSearch,
  removeDbRowFromSearch,
} from "@/database/search";

const localDbSyncOpIndex =
  "id, _opCreatedAt, _opIsInProgress, [_opIsDone+_opIsInProgress], _opRetryCount";
export type ContactEmailMetadataRow = { email: string; domain: string; isDisposable: boolean };
export type MsaIdIndexRow = { id: string; data: { [p: string]: string } };
export type MsaCityIndexRow = { id: string; data: { city: string; stateKey: string }[] };

export const USE_DB_TO_SEARCH = false;
export const CONTACT_DB_NAME = "titledock-v1.21";
export type DbSyncOp = {
  id: string;
  _opCreatedAt: number;
  _opIsDone: DdbBoolean;
  _opIsInProgress: DdbBoolean;
  _opRetryCount: number;
};
export type LocalDbSyncOp = DbSyncOp &
  (
    | {
        type: "contact";
        data: ContactData | Partial<ContactData>;
      }
    | {
        type: "contactGroup";
        data: ContactGroupRowForDisplay | Partial<ContactGroupRowForDisplay> | undefined;
      }
    | {
        type: "contactMetadata";
        data: ContactMetadataRow | Partial<ContactMetadataRow>;
      }
  );
export type DedupeOp = DbSyncOp &
  (
    | { dedupe: PostContactsMergeResolutionPayload; type: "contactDedupe" }
    | { type: "pendingContactResolve"; pendingContactResolution: PendingContactResolution }
  );
export type SnapshotMetadata =
  | {
      id: "contact" | "prospect" | "contactMetadata";
      delegatedUserId?: string;
      snapshotCreatedAt?: number;
      updateFetchedAt?: number;
      lastOpAt?: number;
      createdAt?: undefined;
      isLoading: boolean;
      didRefreshFail?: boolean;
      data?: undefined;
      count: number;
    }
  | {
      id: "duplicate";
      type: "lowConfidence" | "highConfidence";
      createdAt?: number;
      delegatedUserId?: string;
      snapshotCreatedAt?: undefined;
      updateFetchedAt?: undefined;
      lastOpAt?: number;
      isLoading: boolean;
      didRefreshFail?: undefined;
      data?: undefined;
      count: number;
    }
  | {
      id: "pendingContactsMerge";
      createdAt?: number;
      delegatedUserId?: string;
      snapshotCreatedAt?: undefined;
      updateFetchedAt?: undefined;
      lastOpAt?: number;
      isLoading: boolean;
      didRefreshFail?: undefined;
      data?: undefined;
      count: number;
    }
  | {
      id: "pendingContactIdToVendorContactId";
      createdAt?: number;
      delegatedUserId?: string;
      snapshotCreatedAt?: undefined;
      updateFetchedAt?: undefined;
      lastOpAt?: undefined;
      didRefreshFail?: undefined;
      isLoading: boolean;
      data: {
        [pendingContactId: string]: string;
      };
      count?: undefined;
    }
  | {
      id: "msaIndex";
      createdAt?: number;
      delegatedUserId?: undefined;
      snapshotCreatedAt?: undefined;
      updateFetchedAt?: undefined;
      lastOpAt?: undefined;
      didRefreshFail?: undefined;
      isLoading?: undefined;
      data?: undefined;
      count?: undefined;
    };

export type SearchHistoryRow = {
  id: string;
  createdAt: number;
  filters: SelectedSearchFilterState;
};

export class ContactDb extends Dexie {
  contacts!: Table<LocallyPersistedContactRow>;
  contactMetadata!: Table<ContactMetadataRowForDisplay>;
  contactCompanies!: Table<{ id: string; contactCount: number }>;
  contactLocationCache!: Table<
    | {
        id: "mappableLocations";
        data: ReturnType<typeof getContactMappableLocations>;
      }
    | {
        id: "flattenedLocations";
        data: Omit<ReturnType<typeof getContactLocations>, "getContactsByLocation">;
      }
  >;
  companies!: Table<CompanyData & { createdAt: number }>;
  companyQueries!: Table<{ companyId?: string; query: string; createdAt: number }>;
  snapshotMetadata!: Table<SnapshotMetadata>;
  contactGroups!: Table<ContactGroupRowForDisplay>;
  createOps!: Table<LocalDbSyncOp>;
  updateOps!: Table<LocalDbSyncOp>;
  deleteOps!: Table<LocalDbSyncOp>;
  duplicates!: Table<DedupeContactRow & { isLowConfMatch?: DdbBoolean }>;
  pendingContacts!: Table<DedupeContactRow>;
  dedupeOps!: Table<DedupeOp>;
  dedupeExcludedContactIds!: Table<ContactMergeLedgerRow & { type: "excluded" }>;
  contactInteraction!: Table<ContactInteractionRow>;
  contactEmailMetadata!: Table<ContactEmailMetadataRow>;
  msaIdIndex!: Table<MsaIdIndexRow>;
  msaCityIndex!: Table<MsaCityIndexRow>;
  searchHistory!: Table<SearchHistoryRow>;
  private indexRowsForSearch: boolean;

  constructor(indexRowsForSearch = true) {
    super(CONTACT_DB_NAME, {
      modifyChunkSize: 10000,
    });

    this.indexRowsForSearch = indexRowsForSearch;

    this.version(1).stores({
      contacts: `id, givenName, surname, nickname, prefix, suffix, companyName, departmentName, jobTitle, birthday, isDeleted, isReadOnly, updatedAt, createdAt,
        _surnameSort,
        _givenNameSort,
        _companyNameSort,
        _fullName,
        [isDeleted+isReadOnly],
        [_givenName+_surname],
        *_searchableNotes,
        *_searchableWords,
        *_emailValueList,
        *_phoneNumberValueList`,
      contactMetadata: "contactId_type, contactId, type",
      contactCompanies: "id, contactCount",
      contactEmailMetadata: "email, domain",
      snapshotMetadata: "id",
      contactMergeLedger: "id, type",
      contactGroups:
        "id, name, createdAt, updatedAt, *contactIds, isAutoFilling, isSubGroupIds, isDeleted",
      createOps: localDbSyncOpIndex,
      updateOps: localDbSyncOpIndex,
      deleteOps: localDbSyncOpIndex,
      duplicates: "id, isLowConfMatch",
      pendingContacts: "id",
      dedupeOps: localDbSyncOpIndex,
      dedupeExcludedContactIds: "id, mainContactId, excludedContactId",
      companies: "id, name, website",
      companyQueries: "query, companyId, createdAt",
      contactInteraction:
        "[email+vendorTable_vendorTableId], *labelIds, createdAt, contactId, remoteApiId",
      msaIdIndex: "id",
      msaCityIndex: "id",
      searchHistory: "id, createdAt",
      contactLocationCache: "id",
    });
  }

  shouldIndexRowsForSearch() {
    return this.indexRowsForSearch;
  }
}

export let contactDb: ContactDb | undefined = undefined;

export function getContactDb(isAutoSearchIndex?: boolean): ContactDb | undefined {
  if (!contactDb) {
    contactDb = new ContactDb(isAutoSearchIndex);
    addDbMiddleware(contactDb);
  }
  return contactDb;
}

function getTokenStream(str: string) {
  // Replace all special characters with whitespace
  const sanitizedInput = str.toLowerCase().replace(/[^\w\s]/g, " ");
  // Split the sanitized input by whitespace
  return sanitizedInput.split(/\s+/);
}

const indexableFields: string[] = [
  "givenName",
  "surname",
  "nickname",
  "prefix",
  "suffix",
  "companyName",
  "departmentName",
  "jobTitle",
  "_emailValueList",
  "_phoneNumberValueList",
  "_imHandleValueList",
  "_webPageValueList",
  "_webPageServiceList",
  "_physicalAddressStreetList",
  "_physicalAddressLine2List",
  "_physicalAddressCityList",
  "_physicalAddressStateList",
  "_physicalAddressPostalCodeList",
  "_physicalAddressCountryList",
  "_relativeValueList",
  "_relativeLabelList",
];

function addSearchableWordsInDbRow(row: any) {
  const searchableWords = new Set<string>();
  const searchableNotes = new Set<string>();
  for (const field of indexableFields) {
    const value = row[field];
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          if (v) {
            getTokenStream(v).forEach((token) => {
              searchableWords.add(token.toString());
            });
          }
        });
      } else {
        getTokenStream(value).forEach((token) => {
          searchableWords.add(token.toString());
        });
      }
    }
  }

  row._searchableWords = Array.from(searchableWords);
  getTokenStream(row.notes || "").forEach((token) => {
    searchableNotes.add(token.toString());
  });
  row._searchableNotes = Array.from(searchableNotes);
}

function addDbMiddleware(db: ContactDb) {
  const isAutoSearchIndex = db.shouldIndexRowsForSearch();

  db.use({
    stack: "dbcore", // The only stack supported so far by dexie
    name: "MultiIndexPrep",
    create(downlevelDatabase) {
      return {
        ...downlevelDatabase,
        // Override table method
        table(tableName) {
          // Call default table method
          const downlevelTable = downlevelDatabase.table(tableName);

          if (tableName === "contactGroup") {
            return {
              ...downlevelTable,
              mutate: async (req) => {
                if (isAutoSearchIndex) {
                  if (req.type === "add" || req.type === "put") {
                    bulkIndexContactGroupRowsInSearch(req.values as ContactGroupRowForDisplay[]);
                  }
                  if (req.type === "delete") {
                    for (const id of req.keys) {
                      removeDbRowFromSearch(id);
                    }
                  }
                }
                try {
                  return await downlevelTable.mutate(req);
                } catch (e) {
                  console.log(req);
                  console.error(e);
                  Sentry.captureException(e);
                  throw e;
                }
              },
            };
          }

          if (tableName === "contactMetadata") {
            return {
              ...downlevelTable,
              mutate: async (req) => {
                if (isAutoSearchIndex) {
                  if (req.type === "add" || req.type === "put") {
                    bulkIndexContactMetadataRowsInSearch(
                      req.values as ContactMetadataRowForDisplay[],
                    );
                  }

                  if (req.type === "delete") {
                    for (const contactId_type of req.keys) {
                      removeContactMetadataDbRowFromSearch(contactId_type);
                    }
                  }
                }

                try {
                  return await downlevelTable.mutate(req);
                } catch (e) {
                  console.log(req);
                  console.error(e);
                  Sentry.captureException(e);
                  throw e;
                }
              },
            };
          }

          if (tableName === "contacts") {
            // Derive your own table from it:
            return {
              // Copy default table implementation:
              ...downlevelTable,
              // Override the mutate method:
              mutate: async (req) => {
                if (isAutoSearchIndex) {
                  // add data for MultiIndex
                  if (req.type === "add" || req.type === "put") {
                    req.values = req.values.map((row: LocallyPersistedContactRow) => {
                      if (USE_DB_TO_SEARCH) {
                        addSearchableWordsInDbRow(row);
                      }
                      return row;
                    });

                    if (!USE_DB_TO_SEARCH) {
                      bulkIndexContactRowsInSearch(req.values as LocallyPersistedContactRow[]);
                    }
                  }

                  if (req.type === "delete" && !USE_DB_TO_SEARCH) {
                    for (const id of req.keys) {
                      removeDbRowFromSearch(id);
                    }
                  }
                }

                try {
                  return await downlevelTable.mutate(req);
                } catch (e) {
                  console.log(req);
                  console.error(e);
                  Sentry.captureException(e);
                  throw e;
                }
              },
            };
          }

          return downlevelTable;
        },
      };
    },
  });
}
