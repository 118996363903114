import { AppState } from "@/integrations/redux/store";

export const selectIsMobileSidebarOpen = (state: AppState) => {
  return state.appUi.isMobileSidebarOpen;
};

export const selectIsNotificationPanelOpen = (state: AppState) => {
  return state.appUi.isNotificationPanelOpen;
};

export const selectIsSearchOptionOpen = (state: AppState) => {
  return state.appUi.isSearchOptionOpen;
};
