import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import classNames from "clsx";
import { ChainableFilter, KeywordFilter } from "core/types/contactSearch";
import React, {
  type Dispatch,
  type SetStateAction,
  FC,
  FormEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { getCurEpochMs } from "utils/dateTime";

import {
  getDefaultFilterState,
  getFilterIdFromSelectedFilters,
  splitStringIntoTokens,
} from "@/components/contacts/search/helpers";
import SearchSelectFilter from "@/components/contacts/search/SearchSelectFilter";
import { SelectedSearchFilterState } from "@/components/contacts/search/types";
import { SecondaryBadge } from "@/components/core/Badge";
import Button, { ButtonVariant } from "@/components/core/Button";
import ContentEditable from "@/components/core/ContentEditable";
import { KeyboardIcon } from "@/components/core/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/core/Popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/core/Tooltip";
import { getContactDb, SearchHistoryRow } from "@/database/contactDb";
import { useGetRef } from "@/hooks/useGetRef";
import appSlice from "@/integrations/app/slice";

const SearchInputWithChainableFilter: FC<{
  selectedFilters: SelectedSearchFilterState;
  setSelectedFilters: Dispatch<SetStateAction<SelectedSearchFilterState>>;
  hideIcon?: true;
  savedSearchLimit?: number;
  placeholder?: string;
}> = ({ savedSearchLimit, hideIcon, selectedFilters, setSelectedFilters, placeholder }) => {
  const getRef = useGetRef();

  // pending filter is set while option is "active" ie. user used dir key to select an option
  // but not quite ready to commit to selectedFilters state
  const pendingFilter = useRef<Partial<ChainableFilter> | KeywordFilter | undefined>(undefined);
  const pendingSavedSearch = useRef<SearchHistoryRow | undefined>(undefined);

  const [focusedFilterIndex, setFocusedFilterIndex] = useState<number | undefined>(undefined);

  // timer to track if user is double tapped esc
  const escPressed = useRef(0);

  useHotkeys(
    ["alt+f"],
    (e) => {
      const input = getRef(0);
      e.preventDefault();
      input.current?.focus();
    },
    {
      enableOnFormTags: true,
      enableOnContentEditable: true,
    },
  );

  const dispatch = useDispatch();
  const setIsSearchOptionClose = useCallback(() => {
    dispatch(appSlice.actions.setIsSearchOptionOpen(false));
    setFocusedFilterIndex(undefined);
  }, [dispatch]);

  const setIsSearchOptionOpen = useCallback(
    (index: number) => {
      dispatch(appSlice.actions.setIsSearchOptionOpen(true));
      setFocusedFilterIndex(index);
    },
    [dispatch],
  );

  // handle merging of filters
  const handleSetSelectedFilters = useCallback(
    ({
      index,
      filter,
      newFilters,
      query,
    }: {
      index: number;
      filter: ((Partial<ChainableFilter> | KeywordFilter) & { hidePopover?: boolean }) | undefined;
      newFilters: (Partial<ChainableFilter> | KeywordFilter)[];
      query: string;
    }) => {
      setSelectedFilters((prevState) => {
        const newState = [...prevState];
        if (filter) {
          newState[index] = { ...filter, hidePopover: true };
        } else if (newState[index]) {
          newState[index].query = query;
          newState[index].selected = undefined;
          newState[index].backspacePressed = 0;
          newState[index].hidePopover = false;
        }

        if (newFilters.length > 0) {
          newState.push(...newFilters);
        }

        const sortedNewState: typeof newState = [];
        let keywordFilter: (typeof newState)[0] | undefined;
        for (const filter of newState) {
          if (filter.type === "keyword") {
            keywordFilter = filter;
          } else {
            sortedNewState.push(filter);
          }
        }

        return keywordFilter
          ? [...sortedNewState, keywordFilter]
          : [...sortedNewState, { type: "keyword", query: "" }];
      });
    },
    [setSelectedFilters],
  );

  const onChange = useCallback(
    (e: FormEvent<HTMLElement>, index: number) => {
      // spy on input, if input starts with any of the filterOptionLabels, then add it to selectedFilters, and wait for user to specify a respective query
      const { textContent } = e.currentTarget;
      // query defined in addition to tokened queries
      const tokenResults = splitStringIntoTokens(textContent || "");

      let searchQuery = "";
      const newFilters: Partial<ChainableFilter>[] = [];

      for (const result of tokenResults) {
        if (typeof result === "string") {
          searchQuery += result;
          continue;
        }

        const { token, query } = result;

        const filter = getDefaultFilterState(token, query);

        if (filter) {
          newFilters.push(filter);
        }
      }

      handleSetSelectedFilters({ index, filter: undefined, newFilters, query: searchQuery });
    },
    [handleSetSelectedFilters],
  );

  const onSaveSearchFilters = useCallback(async () => {
    if (savedSearchLimit === 0) {
      return;
    }
    const canSaveFilters = selectedFilters.some((filter) => filter.selected || filter.query);
    if (!canSaveFilters) {
      return;
    }

    const selectedFilterId = getFilterIdFromSelectedFilters(selectedFilters);

    const frontendDb = getContactDb();
    const now = getCurEpochMs();

    return frontendDb?.transaction("rw", frontendDb?.searchHistory, async () => {
      await frontendDb?.searchHistory.put({
        id: selectedFilterId,
        filters: selectedFilters.map(({ hidePopover, backspacePressed, ...filter }) => filter),
        createdAt: now,
      });

      // max only store 100 search filters, so we need to remove oldest rows once 100 is reached.
      const savedSearches = await frontendDb?.searchHistory
        .where("createdAt")
        .belowOrEqual(now)
        .reverse()
        .sortBy("createdAt");

      const validSavedSearches = savedSearches.slice(0, 100);

      return frontendDb?.searchHistory
        .where("id")
        .noneOf(validSavedSearches.map((search) => search.id))
        .delete();
    });
  }, [savedSearchLimit, selectedFilters]);

  return (
    <div
      className={classNames(
        "relative flex items-center space-x-2 w-full",
        selectedFilters.length === 1 && "w-96",
      )}
      onKeyUp={(e) => {
        if (e.key === "Escape") {
          const now = getCurEpochMs();
          if (now - escPressed.current < 350) {
            setSelectedFilters([{ type: "keyword", query: "" }]);
          }
          escPressed.current = now;
        }
      }}
    >
      {!hideIcon && (
        <Tooltip>
          <TooltipTrigger>
            <div className="pointer-events-none inset-y-0 items-center justify-evenly ml-4">
              <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <div className="px-1 rounded text-sm border bg-secondary text-secondary whitespace-nowrap">
              <KeyboardIcon className="mr-1.5" size="sm" />
              <span>Opt + F</span>
            </div>
          </TooltipContent>
        </Tooltip>
      )}

      <div className="flex overflow-x-auto" tabIndex={0} onBlur={onSaveSearchFilters}>
        {selectedFilters.map((filter, index) => {
          const isFocused = index === focusedFilterIndex;
          return (
            <Popover
              key={index}
              open={isFocused}
              onOpenChange={(val) => {
                if (!val) setIsSearchOptionClose();
              }}
              placement="bottom-start"
            >
              <PopoverTrigger asChild>
                <div
                  className={classNames(
                    "flex items-center pr-2",
                    index > 0 && "pl-2 border-l border-color-primary",
                  )}
                  onClick={() => {
                    setIsSearchOptionOpen(index);
                  }}
                >
                  {filter.token && <SecondaryBadge className="mx-2">{filter.token}</SecondaryBadge>}
                  <ContentEditable
                    tabIndex={0}
                    key={index}
                    innerRef={getRef(index)}
                    onChange={(e) => {
                      const { textContent } = e.currentTarget;
                      if (textContent !== filter.query || "") onChange(e, index);
                    }}
                    onFocus={(e) => {
                      setIsSearchOptionOpen(index);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Backspace" && !e.currentTarget.textContent) {
                        setSelectedFilters((prev) => {
                          if (prev.length > 1) {
                            const newState = [...prev];
                            if (Date.now() - (newState[index].backspacePressed || 0) < 350) {
                              if (prev[index].type === "keyword") {
                                // focus on filter in front of placeholder
                                if (index > 0) setFocusedFilterIndex(index - 1);
                              } else {
                                newState.splice(index, 1);
                                pendingFilter.current = undefined;
                              }
                            }

                            newState[index].backspacePressed = Date.now();
                            return newState;
                          }
                          return prev;
                        });
                      }
                    }}
                    className={classNames(
                      "w-full flex flex-1 z-10 border-transparent text-primary focus:border-transparent bg-transparent focus:ring-0 outline-0 cursor-text text-sm",
                      selectedFilters.length > 1 && "hover:bg-secondary ",
                      selectedFilters.length === 1 && "min-w-[300px]",
                    )}
                    placeholder={
                      filter.type === "keyword" && selectedFilters.length > 1
                        ? "Search Keywords"
                        : filter.placeholder || placeholder || "Search Contacts"
                    }
                    html={filter.query || ""}
                  />
                </div>
              </PopoverTrigger>

              <PopoverContent initialFocus={-1} className={classNames("mt-1 z-30 w-96")}>
                <SearchSelectFilter
                  savedSearchLimit={savedSearchLimit}
                  filter={filter}
                  pendingFilter={pendingFilter}
                  pendingSavedSearch={pendingSavedSearch}
                  setFilter={(val: Partial<ChainableFilter> | KeywordFilter) => {
                    handleSetSelectedFilters({
                      index,
                      filter: val,
                      newFilters: [],
                      query: val.query || "",
                    });
                  }}
                  setSelectedFilters={(val) => {
                    setSelectedFilters(val);
                    setIsSearchOptionClose();
                  }}
                />
              </PopoverContent>
            </Popover>
          );
        })}
      </div>
      <div className="">
        {selectedFilters.length > 1 && (
          <Tooltip placement={"right"}>
            <TooltipTrigger>
              <Button
                variant={ButtonVariant.Secondary}
                onClick={() => {
                  setSelectedFilters([{ type: "keyword", query: "" }]);
                }}
              >
                Reset
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <div className="px-1 rounded text-sm border bg-secondary text-secondary whitespace-nowrap">
                <KeyboardIcon className="mr-1.5" size="sm" />
                <span>2x Escape</span>
              </div>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SearchInputWithChainableFilter;
