import { Dialog } from "@headlessui/react";
import classNames from "clsx";
import {
  ButtonHTMLAttributes,
  FC,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from "react";
import React, { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { twMerge } from "tailwind-merge";

import Button, { ButtonProps, ButtonVariant } from "./Button";
import { ExclamationCircleSolidIcon, QuestionCircleSolidIcon } from "./Icon";
import Modal, { ModalProps } from "./Modal";

export type AlertProps = Omit<ModalProps, "isModalOpen" | "setIsClosed"> & {
  title: string;
  description?: string;
  variant: ButtonVariant;
  AlertIcon?: ReactElement;
  isAlertOpen: boolean;
  onClose: (open: boolean) => void;
  actionElement?: ReactElement;
  actionButtonTitle?: string;
  actionHandler?: () => void;
  actionButtonProps?: ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;
  actionButtonClassName?: string;
  cancelButtonTitle?: string;
  cancelHandler?: () => void;
  hideCancelButton?: boolean;
  cancelButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  width?: "sm" | "md" | "lg" | "xl" | "3/5" | string;
  initialFocusRef?: MutableRefObject<HTMLElement | null>;
  doNotCloseOnSave?: boolean;
  noBackdrop?: boolean;
  className?: string;
};

const Alert: FC<PropsWithChildren<AlertProps>> = ({
  title,
  description,
  variant,
  isAlertOpen,
  onClose,
  actionElement,
  actionButtonTitle,
  actionHandler,
  actionButtonProps,
  actionButtonClassName,
  cancelButtonTitle,
  cancelHandler,
  cancelButtonProps,
  hideCancelButton,
  children,
  AlertIcon,
  width = "lg",
  initialFocusRef,
  doNotCloseOnSave,
  noBackdrop,
  className,
  ...rest
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const actionButtonRef = useRef<HTMLButtonElement>(null);

  useHotkeys("left, right", () => {
    if (document.activeElement === cancelButtonRef.current) {
      actionButtonRef.current?.focus();
    } else {
      cancelButtonRef.current?.focus();
    }
  });

  useHotkeys("escape", () => {
    onClose(false);
  });

  const Icon = useMemo(() => {
    let icon = AlertIcon;
    if (!icon) {
      switch (variant) {
        case ButtonVariant.Danger:
          icon = (
            <ExclamationCircleSolidIcon className="text-red-400 dark:text-red-600" size="lg" />
          );
          break;
        default:
          icon = <QuestionCircleSolidIcon className="text-zinc-400 dark:text-zinc-600" size="lg" />;
          break;
      }
    }
    return (
      <div className="mt-3 icon-container text-primary" aria-hidden="true">
        {icon}
      </div>
    );
  }, [AlertIcon, variant]);

  const widthClassName = useMemo(() => {
    if (width) {
      switch (width) {
        case "sm":
          return "sm:max-w-sm";
        case "md":
          return "sm:max-w-md";
        case "lg":
          return "sm:max-w-lg";
        case "xl":
          return "sm:max-w-xl";
        case "3/5":
          return "sm:w-3/5";
        default:
          return width;
      }
    }

    return "sm:w-full";
  }, [width]);

  return (
    <Modal
      {...rest}
      noBackdrop={noBackdrop}
      initialFocusRef={initialFocusRef || cancelButtonRef}
      isModalOpen={isAlertOpen}
      setIsClosed={onClose}
      className={classNames("p-4 text-left align-middle sm:p-6", widthClassName, className)}
    >
      <div className="sm:flex sm:items-start">
        {/* Icon */}
        {Icon}

        <div className="mt-4 text-center sm:mt-2 sm:ml-4 sm:text-left flex-1 min-w-0">
          {/* Title */}
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100"
          >
            {title}
          </Dialog.Title>

          {/* Description */}
          {description && (
            <div className="mt-1.5">
              <p className="text-sm text-zinc-500">{description}</p>
            </div>
          )}

          {/* Content */}
          {children && <div className="mt-1.5 w-full">{children} </div>}
        </div>
      </div>

      <div
        className={twMerge("flex mt-6 gap-4 flex-col sm:flex-row-reverse", actionButtonClassName)}
      >
        {/* Action button (if specified) */}
        {actionElement}
        {actionButtonTitle && (
          <Button
            className="w-full sm:w-auto sm:text-sm"
            variant={variant}
            onClick={() => {
              if (actionHandler) {
                actionHandler();
              }
              if (!doNotCloseOnSave) onClose(false);
            }}
            innerRef={actionButtonRef}
            {...actionButtonProps}
          >
            {actionButtonTitle || "Ok"}
          </Button>
        )}

        {/* Cancel button */}
        {!hideCancelButton && (
          <Button
            className="w-full sm:w-auto sm:text-sm"
            variant={ButtonVariant.Secondary}
            onClick={() => {
              if (cancelHandler) {
                cancelHandler();
              }
              onClose(false);
            }}
            innerRef={cancelButtonRef}
            {...cancelButtonProps}
          >
            {cancelButtonTitle || "Cancel"}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default Alert;
