import { DdbBoolean } from "@shared/models/types";
import classNames from "clsx";
import { FC, useState } from "react";

import Button, { ButtonVariant } from "@/components/core/Button";
import { SecondaryColor, SecondaryHoverColor } from "@/components/core/colorVariant";
import { PaperClipIcon, WandMagicSparklesIcon } from "@/components/core/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/core/Popover";
import SliderToggle from "@/components/SliderToggle";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

const EmailTools: FC<{
  draft: LocallyPersistedEmailDraft;
  updateDraft: (update: Partial<Omit<LocallyPersistedEmailDraft, "id">>) => Promise<void>;
}> = ({ updateDraft, draft }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      placement="top-start"
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setOpen(false);
        }
      }}
    >
      <PopoverContent className="z-50">
        <div className="flex-1 border border-color-primary p-2 bg-primary rounded-md space-y-2">
          <div>
            <Button variant={ButtonVariant.Secondary} icon={<PaperClipIcon size="lg" />} full>
              Attachments
            </Button>
          </div>

          <div
            className={classNames(
              "border border-color-primary rounded-md px-2.5 py-1.5",
              SecondaryColor,
              SecondaryHoverColor,
            )}
          >
            <SliderToggle
              isActive={draft.isTracked === DdbBoolean.YES}
              leftLabel="Tracking"
              onDisable={() => updateDraft({ isTracked: DdbBoolean.NO })}
              onEnable={() => updateDraft({ isTracked: DdbBoolean.YES })}
            />
            <div className="text-label mt-2">Track open and click-through</div>
          </div>
        </div>
      </PopoverContent>

      <PopoverTrigger asChild>
        <Button
          variant={ButtonVariant.Secondary}
          icon={<WandMagicSparklesIcon size="lg" />}
          onClick={() => setOpen(true)}
          tooltip="Attachments, Tracking"
        >
          Tools
        </Button>
      </PopoverTrigger>
    </Popover>
  );
};

export default EmailTools;
