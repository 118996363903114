import classNames from "clsx";
import type { FC, PropsWithChildren } from "react";

type BaseDetailsFieldProps = {
  label: string;
  icon: JSX.Element;
  isEditing?: boolean;
  className?: string;
};

const BaseDetailsField: FC<PropsWithChildren<BaseDetailsFieldProps>> = ({
  label,
  icon,
  isEditing,
  className,
  children,
}) => {
  return (
    <div className={classNames("flex flex-row w-full mb-2", isEditing ? "py-2" : "space-x-4")}>
      <dt
        className={classNames(
          "w-6 h-6 flex items-center justify-center py-2",
          isEditing && "pt-1.5"
        )}
      >
        <span className="sr-only">{label}</span>
        {icon}
      </dt>
      <div className={classNames("flex-1 space-y-1 max-w-xl", className)}>{children}</div>
    </div>
  );
};

export default BaseDetailsField;
