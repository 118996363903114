import { getContactIdsToDelete } from "@http/post-contacts-merge/helpers";
import * as Sentry from "@sentry/nextjs";
import { getPartialDbRowFields } from "@shared/helpers/contact";
import { ContactRow } from "@shared/models/Contact";
import { DdbBoolean } from "@shared/models/types";
import { LocallyPersistedContactRow } from "core/helpers/contact";
import {
  getDupeGroupsFromContacts,
  getDuplicatesAndExactContacts,
} from "core/helpers/contactMatching";
import { useLiveQuery } from "dexie-react-hooks";
import debounce from "lodash/debounce";
import { useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { getMs } from "utils/dateTime";

import { DedupeOp, getContactDb } from "@/database/contactDb";
import { isSearchInUse } from "@/database/search";
import { queryFetch } from "@/helpers/fetch";
import { getDelegatedUser } from "@/hooks/useDelegatedUser";
import { getAuth } from "@/integrations/firebase";

async function setOpIsInProgress(dedupeOps: DedupeOp[]) {
  const frontendDb = getContactDb();
  return frontendDb?.transaction("rw", frontendDb.dedupeOps, async () => {
    if (dedupeOps) {
      await Promise.all(
        dedupeOps.map(({ id }) => {
          return frontendDb.dedupeOps.update(id, { _opIsInProgress: DdbBoolean.YES });
        }),
      );
    }
  });
}

async function updateDedupeOps(dedupeOps: { key: string; changes: Partial<DedupeOp> }[]) {
  const frontendDb = getContactDb();
  return frontendDb?.transaction("rw", frontendDb.dedupeOps, async () => {
    if (dedupeOps) {
      await Promise.all(
        dedupeOps.map(({ key, changes }) => {
          return frontendDb.dedupeOps.update(key, changes);
        }),
      );
    }
  });
}

async function unsetInProgressOps() {
  const frontendDb = getContactDb();
  if (!window.navigator.onLine)
    return frontendDb?.transaction("rw", frontendDb.dedupeOps, async (tx) => {
      await frontendDb.dedupeOps.where({ _opIsInProgress: DdbBoolean.YES }).modify({
        _opIsInProgress: DdbBoolean.NO,
      });
    });
}

let refreshDedupeTimer: number | null = null;

export const refreshDupeState = debounce(
  async (contacts: ContactRow[]) => {
    if (isSearchInUse) {
      if (refreshDedupeTimer) clearTimeout(refreshDedupeTimer);
      refreshDedupeTimer = setTimeout(refreshDupeState, getMs("5s"));
      return;
    }

    try {
      const frontendDb = getContactDb();
      await frontendDb?.snapshotMetadata.update("duplicate", {
        createdAt: Date.now(),
        isLoading: true,
      });

      const { high, low } = await getDupeGroupsFromContacts(contacts);
      const shouldUseLowConfienceMatches = false; //  !high || high.length === 0;
      const dupeGroups = shouldUseLowConfienceMatches ? low : high;
      const dupes = await (dupeGroups ? getDuplicatesAndExactContacts(dupeGroups, contacts) : []);

      if (contacts?.length > 0 && dupes?.length === 0) {
        return frontendDb?.transaction(
          "rw",
          frontendDb.duplicates,
          frontendDb.snapshotMetadata,
          async () => {
            await frontendDb.duplicates.clear();
            return frontendDb.snapshotMetadata.put({
              id: "duplicate",
              createdAt: Date.now(),
              isLoading: false,
              count: dupes.length,
              type: shouldUseLowConfienceMatches ? "lowConfidence" : "highConfidence",
            });
          },
        );
      }

      return frontendDb?.transaction(
        "rw",
        frontendDb.duplicates,
        frontendDb.snapshotMetadata,
        async () => {
          await frontendDb.snapshotMetadata.put({
            id: "duplicate",
            createdAt: Date.now(),
            isLoading: false,
            count: dupes.length,
            type: shouldUseLowConfienceMatches ? "lowConfidence" : "highConfidence",
          });

          if (contacts?.length > 0 && dupes?.length === 0) {
            await frontendDb.duplicates.clear();
          }

          if (dupes && dupes.length > 0) {
            return Promise.all([
              frontendDb.duplicates.bulkPut(dupes),
              frontendDb?.duplicates
                .where("id")
                .noneOf(dupes.map(({ id }) => id))
                .delete(),
            ]);
          }
        },
      );
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  },
  getMs("30s"),
  { leading: false, trailing: true },
);

export function useContactDedupeStore() {
  useEffect(() => {
    window.removeEventListener("offline", unsetInProgressOps);
    window.addEventListener("offline", unsetInProgressOps);
    return () => {
      window.removeEventListener("offline", unsetInProgressOps);
    };
  }, []);

  const dedupeSnapshotWorker = useRef<Worker | null>(null);

  useEffect(() => {
    dedupeSnapshotWorker.current = new Worker(
      new URL("../../workers/contactDedupe.worker.ts", import.meta.url),
    );

    dedupeSnapshotWorker.current.onmessage = (
      event: MessageEvent<{
        pendingContact: { success: boolean; error: Error | undefined };
        dedupeContact: { success: boolean; error: Error | undefined };
      }>,
    ) => {
      if (!event.data.pendingContact.success || !event.data.dedupeContact.success) {
        console.log({ event });
        console.log("Error fetching pending contact and dedupe contact snapshots");
      }
    };

    return () => {
      dedupeSnapshotWorker.current?.terminate();
    };
  }, []);

  const loadPendingContactDupeSnapshots = useDebouncedCallback(
    async () => {
      const auth = getAuth();
      if (!auth) return;
      const delegatedUser = getDelegatedUser();

      const authToken = await auth.currentUser?.getIdToken();
      if (authToken)
        dedupeSnapshotWorker.current?.postMessage({
          authToken,
          baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
          delegatedUserId: delegatedUser?.delegatedUserId,
        });
    },
    getMs("10s"),
    {
      leading: true,
      trailing: true,
    },
  );

  const contactMetadata = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return frontendDb?.snapshotMetadata.get("contact");
  });

  useEffect(() => {
    if (!contactMetadata) return;
    if (contactMetadata && contactMetadata.count && !contactMetadata.isLoading) {
      loadPendingContactDupeSnapshots();
    }
  }, [contactMetadata, loadPendingContactDupeSnapshots]);

  const dedupeOps = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    return (
      frontendDb?.dedupeOps
        .where("[_opIsDone+_opIsInProgress]")
        .equals([DdbBoolean.NO, DdbBoolean.NO])
        .toArray() || []
    );
  });

  useEffect(() => {
    if (!window.navigator.onLine || !dedupeOps) return;

    (async () => {
      const frontendDb = getContactDb();
      await setOpIsInProgress(dedupeOps);
      const failedOps = [];
      for (const op of dedupeOps) {
        if (!window.navigator.onLine) {
          await unsetInProgressOps();
          return;
        }
        try {
          if (op.type === "contactDedupe") {
            await queryFetch("/contacts/merge", "POST", op.dedupe);
          }

          if (op.type === "pendingContactResolve") {
            await queryFetch("/pendingContacts/resolve", "POST", op.pendingContactResolution);
          }
          await frontendDb?.dedupeOps.delete(op.id);
        } catch (e) {
          console.error(e);
          failedOps.push(op);
          Sentry.captureException(e);
        }
      }
      await updateDedupeOps(
        failedOps.map((op) => ({
          key: op.id,
          changes: {
            _opIsInProgress: DdbBoolean.NO,
            _opRetryCount: op._opRetryCount + 1,
            _opIsDone: DdbBoolean.NO,
          },
        })),
      );
    })();
  }, [dedupeOps]);
}

export async function handleContactDedupeOp(dedupeOp: DedupeOp) {
  const frontendDb = getContactDb();
  if (dedupeOp.type === "contactDedupe") {
    return frontendDb?.transaction(
      "rw",
      frontendDb.duplicates,
      frontendDb.contacts,
      frontendDb.dedupeOps,
      async () => {
        const { dedupe } = dedupeOp;
        if (dedupe.type === "merge") {
          const contactIdsToDelete = getContactIdsToDelete(
            dedupe.sourceContacts,
            dedupe.mergedContact,
            dedupe.excludedContactIds,
          );

          if (contactIdsToDelete.length > 0) {
            return Promise.all([
              // store merged contact
              frontendDb.contacts.update(dedupe.mergedContact.id, dedupe.mergedContact), // delete deduped contacts
              frontendDb.contacts.bulkDelete(contactIdsToDelete),
              // delete dupe row
              frontendDb.duplicates.delete(dedupeOp.id),
              // create dedupeOp to sync with td
              frontendDb.dedupeOps.put(dedupeOp),
            ]);
          } else {
            return frontendDb.contacts.update(dedupe.mergedContact.id, dedupe.mergedContact);
          }
        } else if (dedupe.type === "exclude") {
          return Promise.all([
            frontendDb.duplicates.delete(dedupeOp.id),
            // create dedupeOp to sync with td
            frontendDb.dedupeOps.put(dedupeOp),
          ]);
        }
      },
    );
  }
  if (dedupeOp.type === "pendingContactResolve") {
    return frontendDb?.transaction(
      "rw",
      frontendDb.pendingContacts,
      frontendDb.contacts,
      frontendDb.dedupeOps,
      async () => {
        const { pendingContactResolution } = dedupeOp;
        const { tdContactIdsToPurge, mergedContact, vendorContactsToCreate } =
          pendingContactResolution;

        return Promise.all([
          // store merged contact
          frontendDb.contacts.bulkPut(
            [
              ...(vendorContactsToCreate.map((contact) => contact.pendingContact) as ContactRow[]),
              mergedContact as ContactRow,
            ].map((contact) => {
              return getPartialDbRowFields(contact);
            }) as LocallyPersistedContactRow[],
          ),
          // delete deduped contacts
          frontendDb.contacts.bulkDelete(tdContactIdsToPurge),
          // delete dupe row
          frontendDb.pendingContacts.delete(dedupeOp.id),
          // create dedupeOp to sync with td
          frontendDb.dedupeOps.put(dedupeOp),
        ]);
      },
    );
  }
}
