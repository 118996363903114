import classNames from "clsx";
import { SortableKeys } from "core/helpers/contact";
import type { FC, MouseEventHandler, PropsWithChildren, ReactElement } from "react";

import ContactItemFragment from "@/components/contacts/list/ContactItemFragment";
import { ContactData } from "@/components/contacts/v2/types";

import Avatar from "../../core/Avatar";

type ContactListItemProps = {
  contact: ContactData;
  description?: string | ReactElement;
  endIcon?: ReactElement;
  sortKey: SortableKeys;
  isSelected: boolean;
  isMultiSelected?: boolean;
  onClickItem?: MouseEventHandler<HTMLElement>;
  onClickToSelectItem?: (item: ContactData) => void;
  isProspect?: boolean;
  className?: string;
  noGutter?: boolean;
  endContent?: ReactElement;
};

const ContactListItem: FC<PropsWithChildren<ContactListItemProps>> = ({
  contact,
  description,
  endIcon,
  sortKey,
  isSelected,
  isMultiSelected,
  onClickItem,
  onClickToSelectItem,
  isProspect,
  noGutter,
  className,
  children,
  endContent,
}) => {
  return (
    <div
      className={classNames(
        "relative flex items-center border-l-4 space-x-3 focus-within:ring-purple-200 dark:focus-within:ring-purple-800",
        !noGutter && "pl-5 pr-7 py-5",
        isSelected
          ? "bg-blue-50 dark:bg-zinc-800 hover:bg-blue-50 dark:hover:bg-zinc-800 border-blue-400 dark:border-blue-800"
          : "hover:bg-zinc-50 dark:hover:bg-zinc-900 border-transparent",
        className,
      )}
      onClick={onClickItem}
    >
      <div className="flex-shrink-0 transition-all delay-200">
        <Avatar
          className="text-lg h-12 w-12"
          firstName={contact.givenName}
          lastName={contact.surname}
          photos={contact.photos}
          isProspect={isProspect}
          onClickToSelectItem={onClickToSelectItem ? () => onClickToSelectItem(contact) : undefined}
          isMultiSelected={isMultiSelected}
        />
      </div>
      <div className="flex-1 min-w-0 truncate text-left">
        <a className="focus:outline-none m-0">
          {/* Primary name labels */}
          <ContactItemFragment
            description={description}
            contact={contact}
            sortKey={sortKey}
            isProspect={isProspect}
          />
        </a>
        {children}
      </div>
      <div className="text-gray-300">{endIcon && endIcon}</div>
      {endContent}
    </div>
  );
};

export default ContactListItem;
