import { Switch } from "@headlessui/react";
import { ContactRow } from "@shared/models/Contact";
import type { FC } from "react";
import { IsDoNotSync } from "services/src/shared/models/types";

import { ArrowRotateIcon } from "../../../core/Icon";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import type { ContactDataFieldProps } from "../types";

const SyncToggleField: FC<ContactDataFieldProps> = ({ contactData, dispatch, isEditing }) => {
  const isSync = (contactData as ContactRow)?.isDoNotSync !== IsDoNotSync.YES;

  return (
    <BaseDetailsField
      label="Sync"
      isEditing={isEditing}
      icon={<ArrowRotateIcon size="lg" className="icon-color-purple items-center" />}
    >
      <dd key="Sync" className="flex flex-row w-full">
        {!isEditing && (
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
              isSync ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800"
            } `}
          >
            {isSync ? "Sync ON" : "Sync OFF"}
          </span>
        )}
        {isEditing && (
          <>
            <TypeLabel>Sync </TypeLabel>
            <Switch.Group as="div" className="flex items-center mt-1">
              <Switch.Label as="span" className="mr-2">
                <span className="text-xs text-gray-500">OFF </span>
              </Switch.Label>
              <Switch
                checked={isSync}
                onChange={(value) => {
                  if (dispatch)
                    dispatch({
                      type: "isDoNotSync",
                      payload: value ? IsDoNotSync.NO : IsDoNotSync.YES,
                    });
                }}
                className={`${isSync ? "bg-green-400" : "bg-gray-200"}
          relative inline-flex flex-shrink-0 h-[28px] w-[56px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Sync ON/OFF</span>
                <span
                  aria-hidden="true"
                  className={`${isSync ? "translate-x-7" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
              <Switch.Label as="span" className="ml-2">
                <span className="text-xs text-gray-500">ON </span>
              </Switch.Label>
            </Switch.Group>
          </>
        )}
      </dd>
    </BaseDetailsField>
  );
};

export default SyncToggleField;
