import classNames from "clsx";
import { FC, useCallback, useContext } from "react";

import Button, { ButtonVariant } from "@/components/core/Button";
import { SecondaryColor } from "@/components/core/colorVariant";
import { PaperPlaneTopIcon } from "@/components/core/Icon";
import { EmailDraftContext } from "@/components/email/EmailDraftProvider";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

const SendScheduleBanner: FC<{
  draft: LocallyPersistedEmailDraft;
}> = ({ draft }) => {
  const { editDraft } = useContext(EmailDraftContext);

  const onSendAtCancel = useCallback(async () => {
    editDraft(draft.id, { scheduledAt: undefined });
  }, [draft.id, editDraft]);

  return draft.scheduledAt ? (
    <div
      className={classNames(
        SecondaryColor,
        "-mt-2 mb-2 px-4 py-1 text-sm flex-1 space-x-2 border-0 border-b flex items-center",
      )}
    >
      <PaperPlaneTopIcon />
      <span>
        Send scheduled for{" "}
        <span className="font-semibold">{new Date(draft.scheduledAt).toLocaleString()}</span>
      </span>
      <div className="flex-1 flex justify-end">
        <Button
          variant={ButtonVariant.Secondary}
          onClick={onSendAtCancel}
          noGutter
          className="px-2"
        >
          Cancel Send
        </Button>
      </div>
    </div>
  ) : null;
};

export default SendScheduleBanner;
