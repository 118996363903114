import { getCityLookupKey, getStateLookupKey, stateAbbrvToCity } from "@shared/helpers/location";
import { ContactRow } from "@shared/models/Contact";
import { objKeys } from "utils/object";
import { LocationState, PlaceKeyContacts } from "../types/contactSearch";
import { getSortedContacts, SortableKeys } from "./contact";

let cityIndexCache: { [stateKey: string]: { [cityLookup: string]: string } } = {};

export function getCityIndex() {
  if (objKeys(cityIndexCache).length > 0) return cityIndexCache;
  const index: { [stateKey: string]: { [cityLookup: string]: string } } = {};

  for (const stateKey in stateAbbrvToCity) {
    if (!index[stateKey]) {
      index[stateKey] = {};
    }
    for (const city of stateAbbrvToCity[stateKey as keyof typeof stateAbbrvToCity]) {
      const lookupKey = getCityLookupKey(city);
      index[stateKey][lookupKey] = city;
    }
  }
  cityIndexCache = index;

  return index;
}

export function getCityFromCityIndex(
  stateKey: keyof typeof stateAbbrvToCity | string,
  cityName: string
) {
  const lookupKey = getCityLookupKey(cityName);
  const cityIndex = getCityIndex();

  if (cityIndex[stateKey]) {
    if (cityIndex[stateKey][lookupKey]) {
      return cityIndex[stateKey][lookupKey];
    }
  }
  return cityName;
}

export function getContactMappableLocations(contacts: ContactRow[]) {
  const placeKeyedLocation: {
    [stateKey: string]: {
      [cityName: string]: {
        [placeKey: string]: PlaceKeyContacts;
      };
    };
  } = {};

  const placeKeysByContactId: {
    [contactId: string]: {
      placeKey: string;
      long: number;
      lat: number;
    }[];
  } = {};

  const contactsByState: { [stateAbbrv: string]: ContactRow[] } = {};
  const contactsByStateCity: {
    [state: string]: { [city: string]: ContactRow[] };
  } = {};

  for (const contact of contacts) {
    for (const loc of contact.physicalAddresses || []) {
      const { lat, long, state, city, placeKey } = loc;

      if (state) {
        const stateKey = getStateLookupKey(state);
        if (stateKey) {
          // add city
          if (city) {
            const cityName = getCityFromCityIndex(stateKey, city);

            if (placeKey && state && city && long && lat) {
              if (!placeKeysByContactId[contact.id]) {
                placeKeysByContactId[contact.id] = [];
              }
              placeKeysByContactId[contact.id].push({ placeKey, lat, long });

              if (!placeKeyedLocation[stateKey]) {
                placeKeyedLocation[stateKey] = {};
              }
              if (!placeKeyedLocation[stateKey][cityName]) {
                placeKeyedLocation[stateKey][cityName] = {};
              }
              if (!placeKeyedLocation[stateKey][cityName][placeKey]) {
                placeKeyedLocation[stateKey][cityName][placeKey] = {
                  stateKey,
                  cityName,
                  placeKey,
                  long,
                  lat,
                  contacts: [contact],
                };
              } else {
                placeKeyedLocation[stateKey][cityName][placeKey].contacts.push(contact);
              }
            }

            if (!contactsByState[stateKey]) {
              contactsByState[stateKey] = [contact];
            } else {
              contactsByState[stateKey].push(contact);
            }

            if (!contactsByStateCity[stateKey]) contactsByStateCity[stateKey] = {};
            if (!contactsByStateCity[stateKey][cityName]) {
              contactsByStateCity[stateKey][cityName] = [contact];
            }
            // contact may have multiple locations within the same city
            else if (!contactsByStateCity[stateKey][cityName].some(({ id }) => id === contact.id)) {
              contactsByStateCity[stateKey][cityName].push(contact);
            }
          }
        }
      }
    }
  }

  const sortedStateKeys = objKeys(contactsByStateCity).sort();

  const sortedStateCityKeys: { [stateKey: string]: string[] } = {};

  const uniqueCities: { [city: string]: true } = {};

  for (const stateKey of sortedStateKeys) {
    const unsortedCities: string[] = [];
    for (const city in contactsByStateCity[stateKey as keyof typeof contactsByStateCity]) {
      if (!uniqueCities[city]) {
        uniqueCities[city] = true;
        unsortedCities.push(city);
      }
    }
    sortedStateCityKeys[stateKey] = unsortedCities.sort();
  }

  return {
    placeKeyedLocation,
    placeKeysByContactId,
    contactsByState,
    contactsByStateCity,
    sortedStateKeys,
    sortedStateCityKeys,
  };
}

export function getContactLocations({
  mappableLocations,
  filteredLocationIds,
  msaIndex,
  sortKey = "_surnameSort",
}: {
  mappableLocations: ReturnType<typeof getContactMappableLocations>;
  filteredLocationIds: string[];
  msaIndex: {
    msaIdIndex: { [p: string]: { [p: string]: string } };
    msaCityIndex: { [p: string]: { city: string; stateKey: string }[] };
  };
  sortKey?: SortableKeys;
}) {
  const { contactsByStateCity, sortedStateKeys, sortedStateCityKeys, placeKeyedLocation } =
    mappableLocations;

  function getContactsByLocation(location: LocationState) {
    if (location.type === "city") {
      const hasPlaceKey =
        placeKeyedLocation?.[location.stateKey] &&
        placeKeyedLocation?.[location.stateKey]?.[location.name];

      return {
        contacts: contactsByStateCity?.[location.stateKey]?.[location.name] || [],
        markers: hasPlaceKey ? placeKeyedLocation?.[location.stateKey]?.[location.name] : [],
      };
    }

    if (location.type === "msa") {
      const { msaCityIndex } = msaIndex || {};
      const cities: { city: string; stateKey: string }[] = msaCityIndex
        ? msaCityIndex[location.extId!]
        : [];

      const contactIdIndex: { [id: string]: ContactRow } = {};
      let markers = {};
      for (const c of cities || []) {
        const { city, stateKey } = c;
        if (
          stateKey &&
          city &&
          contactsByStateCity[stateKey] &&
          contactsByStateCity[stateKey][city]
        ) {
          for (const contact of contactsByStateCity[stateKey][city]) {
            contactIdIndex[contact.id] = contact;
          }

          if (placeKeyedLocation[stateKey] && placeKeyedLocation[stateKey][city]) {
            markers = { ...markers, ...placeKeyedLocation[stateKey][city] };
          }
        }
      }

      const contacts: ContactRow[] = [];
      for (const contactId in contactIdIndex) {
        contacts.push(contactIdIndex[contactId]);
      }

      return {
        contacts: getSortedContacts(contacts, sortKey),
        markers,
      };
    }
  }

  const sortedStateLocationKeys: { [p: string]: LocationState[] } = {};

  const searchFilterIndex: { [id: string]: true } = {};
  for (const id of filteredLocationIds) {
    searchFilterIndex[id] = true;
  }

  const shouldFilter = filteredLocationIds.length > 0;

  // populate sortedStateLocationKeys
  for (const stateKey in sortedStateCityKeys) {
    const stateResult: LocationState[] = [];
    for (const city of sortedStateCityKeys[stateKey]) {
      const id = `${stateKey}_${city}`;
      if (shouldFilter) {
        if (!searchFilterIndex[id]) continue;
      }

      const location: LocationState = {
        id,
        name: city,
        type: "city",
        stateKey,
      };

      if ((getContactsByLocation(location)?.contacts?.length || 0) > 0) {
        stateResult.push(location);
      }
    }
    if (msaIndex) {
      const { msaIdIndex } = msaIndex;
      for (const msa in msaIdIndex[stateKey]) {
        const extId = msaIdIndex[stateKey][msa];
        const id = `${stateKey}_${msa}_${extId}`;
        if (shouldFilter) {
          if (!searchFilterIndex[id]) continue;
        }

        const location: LocationState = {
          id,
          name: msa,
          type: "msa",
          stateKey,
          extId,
        };
        if ((getContactsByLocation(location)?.contacts?.length || 0) > 0) {
          stateResult.push(location);
        }
      }
    }

    if (stateResult.length > 0) {
      sortedStateLocationKeys[stateKey] = stateResult.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  // populate locationCountByState
  const locationCountByState: number[] = [];
  for (const stateKey of sortedStateKeys || []) {
    if (sortedStateLocationKeys[stateKey]) {
      locationCountByState.push(sortedStateLocationKeys[stateKey].length);
    }
  }

  const flattenedLocations: LocationState[] = [];
  for (const stateKey of sortedStateKeys || []) {
    if (sortedStateLocationKeys[stateKey]) {
      for (const location of sortedStateLocationKeys[stateKey]) {
        flattenedLocations.push(location);
      }
    }
  }

  return {
    flattenedLocations,
    locationCountByState,
    sortedStateLocationKeys,
    sortedStateKeys,
    getContactsByLocation,
  };
}
