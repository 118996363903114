import {
  getUnmergeableFields,
  getUnmergeableToken,
  unmergeableFields,
} from "@shared/helpers/contact";
import { EMPTY_PLACEHOLDER_FOR_SORT, OMIT_YEAR } from "@shared/models/constants";
import type { Contact, ContactRow } from "@shared/models/Contact";
import { areContactsEqual } from "@shared/models/helpers";
import { Email, ImHandle, IsDefault, PhoneNumber, WebPage } from "@shared/models/types";
import type {
  Document,
  EnrichedDocumentSearchResultSetUnit,
  EnrichedDocumentSearchResultSetUnitResultUnit,
  IndexOptionsForDocumentSearch,
} from "flexsearch-ts";
import { roundToTwo } from "utils/number";
import { objKeys, removeUndefinedFromObj } from "utils/object";
import {
  getNumsFromString,
  getStrDiff,
  getTokenizedStr,
  removeAllWhitespace,
  removeSpecialChars,
  replaceMultipleSpaces,
} from "utils/string";
import uuid from "utils/uuid";

import type { SearchableContact } from "../types/contactSearch";

import { getFullName } from "./contact";

const noOpTokenize = function (str: any) {
  return [(str || "")?.toLowerCase()?.trim()];
};

export type DedupeSearchableContact = Omit<SearchableContact, "_phoneNumbers"> & {
  _dates?: string[];
  _relatives?: string[];
  _phones: PhoneNumber[];
  _contactKey: number;
};

export type DedupeStrictSearchIndex = typeof Document<DedupeSearchableContact, string[]>;

function getContactPhoneList(contact: ContactRow | Contact) {
  return (
    contact.phoneNumbers?.map((phone) => {
      return {
        ...phone,
        value: getNumsFromString(phone.value) || removeSpecialChars(phone.value) || phone.value,
      };
    }) || []
  );
}

function getContactEmailList(contact: ContactRow | Contact) {
  return (
    contact.emails?.map((email) => ({
      ...email,
      value: email.value?.toLowerCase()?.trim() || "",
    })) || []
  );
}
function getContactRelativeList(contact: ContactRow | Contact) {
  return contact.relatives?.map((relative) => {
    return relative.value?.toLowerCase()?.trim() || "";
  });
}

export function getContactDedupeRowId(contactIds: string[]) {
  return uuid([...new Set(contactIds)].sort().join("_"));
}

const fieldsToStore = [
  "_fullName",
  "givenName",
  "surname",
  "_contactKey",
  "_phones",
  "emails",
  "birthday",
  "companyName",
  "jobTitle",
  "_dates",
  "_relatives",
  "imHandles",
  "webPages",
];

function pickSearchableFieldsToDiff(contact: ContactRow | Contact) {
  const fields = fieldsToStore.filter((f) => !f.startsWith("_"));
  const picked: Partial<Contact | ContactRow> = {};

  for (const field of fields) {
    const key = field as keyof (Contact | ContactRow);
    // @ts-ignore
    picked[key] = contact[key];
  }

  return picked;
}

export const strictContactIndexSchema: IndexOptionsForDocumentSearch<
  DedupeSearchableContact,
  string[]
> = {
  tokenize: "strict",
  encode: noOpTokenize,
  async: true,
  document: {
    id: "id",
    store: fieldsToStore,
    index: [
      { field: "_fullName", tokenize: "full" },
      { field: "_unmergeable" },
      { field: "emails[]:value" },
      { field: "_phones[]:value" },
      { field: "imHandles[]:value" },
      { field: "webPages[]:value" },
    ],
  },
};

export async function createStrictContactIndex(
  contactList: ContactRow[],
  searchInstance: DedupeStrictSearchIndex
) {
  const list: DedupeSearchableContact[] = [];
  const idIndex: { [id: string]: number } = {};
  const searchIndex = new searchInstance(strictContactIndexSchema);

  await Promise.all(
    contactList.map(async (contact, _contactKey) => {
      contact.surname = contact.surname?.replace(EMPTY_PLACEHOLDER_FOR_SORT, "") || "";
      const searchableContact = {
        ...contact,
        _fullName: getTokenizedStr(getFullName(contact)),
        _unmergeable: getUnmergeableToken(contact),
        _phones: getContactPhoneList(contact),
        _dates: contact.dates?.map((date) => date.value) || [],
        _relatives: getContactRelativeList(contact),
        emails: getContactEmailList(contact),
        _contactKey,
      } as DedupeSearchableContact;
      list.push(searchableContact);
      idIndex[contact.id] = list.length - 1;
      return searchIndex.addAsync(searchableContact.id, searchableContact);
    })
  );

  return searchIndex;
}

const EMAIL_MATCH_SCORE = 50;
const PHONE_MATCH_SCORE = 50;
const FULLNAME_MATCH_SCORE = 40;
const IMHANDLE_MATCH_SCORE = 30;
const WEBPAGE_MATCH_SCORE = 20;

const HIGH_CONFIDENCE_THRESHOLD = Math.min(EMAIL_MATCH_SCORE, PHONE_MATCH_SCORE);
const MID_CONFIDENCE_THRESHOLD = FULLNAME_MATCH_SCORE; // at least a full name match
const AT_LEAST_MID_CONFIDENCE_THRESHOLD = MID_CONFIDENCE_THRESHOLD + 1;
const incrementFromMidToHigh = 1 - MID_CONFIDENCE_THRESHOLD / HIGH_CONFIDENCE_THRESHOLD;

export type ScoredResult = {
  score: number;
  contactKey: number;
  needles: { matched: any; score: number; multiplier?: number }[];
  minScore?: number;
  isExcluded: boolean;
};
export type MergedScoredResult = {
  [id: string]: ScoredResult;
};
export type DupeGroup = { [contactId: string]: number | null };
export type SearchResult = EnrichedDocumentSearchResultSetUnitResultUnit<DedupeSearchableContact>;

export class ContactDedupe {
  public contact: DedupeSearchableContact & { _unmergeable?: string };
  public strictSearchIndex: Document<DedupeSearchableContact, string[]>;
  public excludedIds: { [p: string]: boolean };
  protected nameMultiplierCache: { [id: string]: number } = {};
  protected includeUnmergeableToken: boolean;
  public matchScores: {
    email: number;
    phone: number;
    fullName: number;
    imHandle: number;
    webPage: number;
  };

  protected highValStrDiffThreshold = 0.8;

  constructor({
    contact,
    contactKey,
    strictSearchIndex,
    excludedIds,
    matchScores = {
      email: EMAIL_MATCH_SCORE,
      phone: PHONE_MATCH_SCORE,
      fullName: FULLNAME_MATCH_SCORE,
      imHandle: IMHANDLE_MATCH_SCORE,
      webPage: WEBPAGE_MATCH_SCORE,
    },
    includeUnmergeableToken,
  }: {
    contact: ContactRow | Contact;
    contactKey: number;
    strictSearchIndex: Document<DedupeSearchableContact, string[]>;
    excludedIds?: { [id: string]: true };
    matchScores?: {
      email: number;
      phone: number;
      fullName: number;
      imHandle: number;
      webPage: number;
    };
    excludedSelf?: boolean;
    includeUnmergeableToken?: boolean;
  }) {
    this.includeUnmergeableToken = includeUnmergeableToken || false;
    contact.surname = (contact.surname || "").replace(EMPTY_PLACEHOLDER_FOR_SORT, "");
    this.contact = {
      ...(contact as ContactRow),
      _fullName: getTokenizedStr(getFullName(contact)),
      _contactKey: contactKey,
      _unmergeable: getUnmergeableToken(contact),
      _phones: getContactPhoneList(contact),
      _relatives: getContactRelativeList(contact),
      _dates: contact.dates?.map((date) => date.value) || [],
      emails: getContactEmailList(contact),
    };
    if (includeUnmergeableToken) {
      this.contact._unmergeable = getUnmergeableToken(this.contact);
    }
    this.strictSearchIndex = strictSearchIndex;
    this.excludedIds = { ...(excludedIds || {}) };
    this.matchScores = matchScores;
  }

  private async getEmailMatches(email: string) {
    return (await this.strictSearchIndex.searchAsync<true>(email, {
      enrich: true,
      index: ["emails[]:value"],
      limit: 50000,
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  private async getPhoneMatches(phone: string) {
    return (await this.strictSearchIndex.searchAsync<true>(phone, {
      enrich: true,
      index: ["_phones[]:value"],
      limit: 50000,
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  private async getImHandleMatches(handle: string) {
    return (await this.strictSearchIndex.searchAsync<true>(handle, {
      enrich: true,
      index: ["imHandles[]:value"],
      limit: 20000,
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  private async getWebPageMatches(webPage: string) {
    return (await this.strictSearchIndex.searchAsync<true>(webPage, {
      enrich: true,
      index: ["webPages[]:value"],
      limit: 50000,
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  private hasField(contact: DedupeSearchableContact, field: keyof DedupeSearchableContact) {
    if (!contact[field]) {
      return false;
    }

    const val = contact[field];
    return Array.isArray(val) ? val.length > 0 : false;
  }

  protected getHighValStrDiff(strA: string, strB: string) {
    const nodeA = replaceMultipleSpaces(strA).toLowerCase().trim();
    const nodeB = replaceMultipleSpaces(strB).toLowerCase().trim();

    const diffScore = getStrDiff(nodeA, nodeB);
    if (diffScore >= this.highValStrDiffThreshold) return diffScore;
    return 0;
  }

  /**
   * Avg name similarity score as multiplier to boost or reduce match score
   * @private
   * @param id
   * @param doc
   */
  private getNameMultiplier({ id, doc }: SearchResult) {
    if (!this.nameMultiplierCache[id]) {
      let nameSimilarityMultiplier =
        (!this.contact.surname && !this.contact.givenName) || (!doc.surname && !doc.givenName)
          ? 1
          : 0;

      if (this.contact.givenName && this.contact.surname && doc.givenName && doc.surname) {
        const fullNameDiffScore = this.getHighValStrDiff(
          `${this.contact.givenName || ""} ${this.contact.surname || ""}`,
          `${doc.givenName || ""} ${doc.surname || ""}`
        );
        nameSimilarityMultiplier = fullNameDiffScore;
      } else if (
        this.contact.givenName &&
        doc.givenName &&
        (!this.contact.surname || !doc.surname)
      ) {
        const givenNameDiffScore = this.getHighValStrDiff(
          this.contact.givenName || "",
          doc.givenName || ""
        );
        nameSimilarityMultiplier = givenNameDiffScore;
      } else if (
        this.contact.surname &&
        doc.surname &&
        (!this.contact.givenName || !doc.givenName)
      ) {
        const surnameDiffScore = this.getHighValStrDiff(
          this.contact.surname || "",
          doc.surname || ""
        );
        nameSimilarityMultiplier = surnameDiffScore;
      }

      this.nameMultiplierCache[id] = nameSimilarityMultiplier;

      let hasNoSignificantFields = false;
      const significantContactFields: (keyof DedupeSearchableContact)[] = [
        "emails",
        "_phones",
        "imHandles",
        "webPages",
        "birthday",
      ];

      if (
        significantContactFields.every((field) => {
          return !this.hasField(this.contact, field) && !this.hasField(doc, field);
        })
      ) {
        hasNoSignificantFields = true;
      }

      let hasOnlyNameFields = false;
      if (hasNoSignificantFields) {
        const restContactFields: (keyof DedupeSearchableContact)[] = [
          "_dates",
          "jobTitle",
          "companyName",
          "relatives",
          "departmentName",
        ];
        if (
          restContactFields.every((field) => {
            return !this.hasField(this.contact, field) && !this.hasField(doc, field);
          })
        ) {
          hasOnlyNameFields = true;
        }
      }

      if (hasOnlyNameFields && nameSimilarityMultiplier === 1) {
        this.nameMultiplierCache[id] = 1 + incrementFromMidToHigh;
      } else if (this.matchScores.fullName * nameSimilarityMultiplier >= MID_CONFIDENCE_THRESHOLD) {
        let hasDateMatch = false;
        let hasBdayMatch = false;
        let hasRelativeMatch = false;

        let hasJobTitleMatch = false;
        let hasCompanyNameMatch = false;

        if (this.contact.jobTitle && doc.jobTitle) {
          hasJobTitleMatch = this.contact.jobTitle.toLowerCase() === doc.jobTitle.toLowerCase();
        }
        if (this.contact.companyName && doc.companyName) {
          hasCompanyNameMatch =
            this.contact.companyName.toLowerCase() === doc.companyName.toLowerCase();
        }

        if (
          this.contact?.dates &&
          doc?._dates &&
          this.contact.dates.length > 0 &&
          doc._dates.length > 0
        ) {
          const docDateValues: { [date: string]: true } = {};
          for (const date of doc._dates) {
            if (date) {
              docDateValues[date] = true;
            }
          }
          if (this.contact.dates.some((d) => docDateValues[d.value])) {
            hasDateMatch = true;
          }
        }

        if (this.contact.birthday && doc.birthday) {
          hasBdayMatch = this.contact.birthday === doc.birthday;
        }

        if (
          this.contact.relatives &&
          doc._relatives &&
          this.contact.relatives.length > 0 &&
          doc._relatives.length > 0
        ) {
          const docRelativeValues: { [relative: string]: true } = {};
          for (const relative of doc._relatives) {
            if (relative) {
              docRelativeValues[String(relative).toLowerCase()] = true;
            }
          }
          if (
            this.contact.relatives.some((d) => docRelativeValues[String(d.value).toLowerCase()])
          ) {
            hasRelativeMatch = true;
          }
        }

        if (
          hasDateMatch ||
          hasBdayMatch ||
          hasRelativeMatch ||
          (hasNoSignificantFields && (hasCompanyNameMatch || hasJobTitleMatch))
        ) {
          this.nameMultiplierCache[id] = 1 + incrementFromMidToHigh;
        }
      }
    }
    return this.nameMultiplierCache[id];
  }

  private async getFullNameMatches() {
    const scoredResult: { [id: string]: ScoredResult } = {};
    const searchResult = (await this.strictSearchIndex.searchAsync<true>(this.contact._fullName, {
      enrich: true,
      index: ["_fullName"],
      limit: 50000,
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
    for (const r of searchResult) {
      for (const i of r.result) {
        if (i.id === this.contact.id) continue;
        const isExcluded = Boolean(this.excludedIds[i.id]);

        let multiplier = this.getNameMultiplier(i);
        let score = this.matchScores.fullName;
        if (score <= 0) continue;

        let areEqual = false;
        if (multiplier >= 0.9) {
          // if names match is high enough, we look at other fields to boost score
          const boostMultiplier = this.getBoostMultiplier(i.doc);
          areEqual = boostMultiplier.areEqual;
          if (boostMultiplier?.multiplier && boostMultiplier?.multiplier > 1)
            multiplier = boostMultiplier.multiplier;
        }

        if (areEqual) {
          // equal contacts are always put into high conf
          score = HIGH_CONFIDENCE_THRESHOLD + 1;
        } else {
          score = roundToTwo(this.matchScores.fullName * multiplier);
        }

        const contactKey = i.doc._contactKey;
        scoredResult[i.id] =
          typeof scoredResult[i.id] !== "undefined"
            ? {
                score: scoredResult[i.id].score + score,
                contactKey,
                isExcluded,
                needles: scoredResult[i.id].needles.concat({
                  matched: this.contact._fullName,
                  score,
                  multiplier,
                }),
              }
            : {
                score,
                contactKey,
                isExcluded,
                needles: [{ matched: this.contact._fullName, score, multiplier }],
              };
      }
    }
    return scoredResult;
  }

  private async getUnmergeableMatches() {
    const scoredResult: { [id: string]: ScoredResult } = {};

    if (this.contact._unmergeable) {
      const searchResult = (await this.strictSearchIndex.searchAsync<true>(
        this.contact._unmergeable,
        {
          enrich: true,
          index: ["_unmergeable"],
          limit: 50000,
        }
      )) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];

      for (const r of searchResult) {
        for (const i of r.result) {
          if (i.id === this.contact.id) continue;

          const isExcluded = Boolean(this.excludedIds[i.id]);
          const contactKey = i.doc._contactKey;
          scoredResult[i.id] =
            typeof scoredResult[i.id] !== "undefined"
              ? {
                  score: Infinity,
                  contactKey,
                  isExcluded,
                  needles: scoredResult[i.id].needles.concat({
                    matched: this.contact._unmergeable,
                    score: Infinity,
                  }),
                }
              : {
                  score: Infinity,
                  contactKey,
                  isExcluded,
                  needles: [{ matched: this.contact._unmergeable, score: Infinity }],
                };
        }
      }
    }

    return scoredResult;
  }

  private async multiMatchBase(
    list: (Email | PhoneNumber)[],
    getter: (
      item: Email | PhoneNumber
    ) => Promise<EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[] | null>,
    score: number,
    getMultiplier?: (
      p: PhoneNumber | Email,
      result: SearchResult
    ) => { multiplier: number; minScore?: number }
  ) {
    const scoredResult: { [id: string]: ScoredResult } = {};

    for (const item of list) {
      const results = await getter(item);

      if (!results) continue;
      for (const r of results) {
        for (const i of r.result) {
          if (i.id === this.contact.id) continue;

          const isExcluded = Boolean(this.excludedIds[i.id]);

          const nameMultiplier = this.getNameMultiplier(i);

          let { multiplier, minScore } = getMultiplier
            ? getMultiplier(item, i)
            : { minScore: undefined, multiplier: 1 };

          multiplier *= nameMultiplier;

          score = roundToTwo(score * multiplier);

          if (score <= 0 && !minScore) continue;
          const contactKey = i.doc._contactKey;
          scoredResult[i.id] =
            typeof scoredResult[i.id] !== "undefined"
              ? {
                  score: scoredResult[i.id].score + score,
                  contactKey,
                  isExcluded,
                  needles: scoredResult[i.id].needles.concat({ matched: item, score }),
                  minScore,
                }
              : { score, contactKey, needles: [{ matched: item, score }], isExcluded, minScore };
        }
      }
    }
    return scoredResult;
  }

  private async getAllEmailMatches() {
    const getter = async (item: Email) => {
      return this.getEmailMatches(item.value);
    };
    return this.multiMatchBase(this.contact.emails || [], getter, this.matchScores.email);
  }

  private async getAllPhoneMatches() {
    const getter = async (item: PhoneNumber) => {
      return this.getPhoneMatches(item.value);
    };
    const multiplier = (needle: PhoneNumber, matchedResult: SearchResult) => {
      const lowScoreTypes: PhoneNumber["type"][] = [
        "business",
        "businessFax",
        "school",
        "assistant",
        "work",
        "homeFax",
        "organizationMain",
      ];
      if (lowScoreTypes.includes(needle.type) && (this.contact._phones || []).length > 1) {
        return { multiplier: 0.5, minScore: undefined };
      } else {
        const matchedPhone = matchedResult.doc._phones.find(
          (phone: PhoneNumber) => phone.value === needle.value
        );

        if (matchedPhone) {
          if (needle.isDefault === IsDefault.YES && matchedPhone.isDefault === IsDefault.YES) {
            return { multiplier: 1.3, minScore: undefined };
          }
          if (needle.type === matchedPhone.type) {
            return { multiplier: 1.2, minScore: undefined };
          }
          return { multiplier: 1, minScore: undefined };
        }
      }
      return { multiplier: 1, minScore: undefined };
    };
    return this.multiMatchBase(
      this.contact._phones || [],
      getter,
      this.matchScores.phone,
      multiplier
    );
  }

  private linkMatchMultiplier = (needle: WebPage | ImHandle, matchedResult: SearchResult) => {
    const highValueServices: { service: WebPage["service"]; domain?: string }[] = [
      { service: "linkedin", domain: "linkedin.com" },
      { service: "twitter", domain: "twitter.com" },
      { service: "x", domain: "x.com" },
      { service: "instagram", domain: "instagram.com" },
      { service: "facebook", domain: "facebook.com" },
      { service: "github", domain: "github.com" },
    ];

    const matchedLink = [
      ...(matchedResult.doc.webPages || []),
      ...(matchedResult.doc.imHandles || []),
    ].find((link) => link.value === needle.value);

    if (matchedLink) {
      const hasHighValueLink = highValueServices.some((high) => {
        if (high.domain) {
          return (
            needle.value?.toLowerCase().includes(high.domain) ||
            matchedLink.value?.toLowerCase().includes(high.domain)
          );
        }
        return high.service === needle.service || high.service === matchedLink.service;
      });

      if (hasHighValueLink) {
        return { multiplier: 1, minScore: AT_LEAST_MID_CONFIDENCE_THRESHOLD }; // boost to full name match,
      }
    }
    return { multiplier: 1, minScore: undefined };
  };

  private async getAllImHandleMatches() {
    const getter = async (item: ImHandle) => {
      return this.getImHandleMatches(item.value);
    };

    return this.multiMatchBase(
      this.contact.imHandles || [],
      getter,
      this.matchScores.imHandle,
      this.linkMatchMultiplier
    );
  }

  private async getAllWebPageMatches() {
    const getter = async (item: WebPage) => {
      return this.getWebPageMatches(item.value);
    };

    return this.multiMatchBase(
      this.contact.webPages || [],
      getter,
      this.matchScores.webPage,
      this.linkMatchMultiplier
    );
  }

  private static mergeScoredResults(list: { [id: string]: ScoredResult }[]) {
    const scoredResult: MergedScoredResult = {};

    for (const result of list) {
      for (const id in result) {
        const r = result[id];
        const minScore = r.minScore || 0;

        if (!scoredResult[id]) {
          scoredResult[id] = {
            score: Math.max(r.score, minScore),
            contactKey: r.contactKey,
            needles: r.needles,
            isExcluded: r.isExcluded,
          };
        } else {
          scoredResult[id] = {
            contactKey: scoredResult[id].contactKey,
            score: Math.max(scoredResult[id].score + r.score, minScore),
            needles: [...scoredResult[id].needles, ...r.needles],
            isExcluded: scoredResult[id].isExcluded || r.isExcluded,
          };
        }
      }
    }

    return scoredResult;
  }

  /**
   * score <60, >50 - similar contacts, but most likely not dupe
   * score > 60 - most likely dupe, this is a combination of a high match + high degree of similarity in name multiplier
   */
  public async getScoredMatches() {
    const emailMatches = await this.getAllEmailMatches();
    const phoneMatches = await this.getAllPhoneMatches();
    const fullNameMatches = await this.getFullNameMatches();
    const imHandleMatches = await this.getAllImHandleMatches();
    const webPageMatches = await this.getAllWebPageMatches();

    const scoredMatches = [
      emailMatches,
      phoneMatches,
      fullNameMatches,
      imHandleMatches,
      webPageMatches,
    ];

    if (this.includeUnmergeableToken && this.contact._unmergeable) {
      const unmergeableMatches = await this.getUnmergeableMatches();
      scoredMatches.push(unmergeableMatches);
    }

    return ContactDedupe.mergeScoredResults(scoredMatches);
  }

  /**
   * @param contactB
   */
  getBoostMultiplier(
    contactB: Partial<ContactRow>
  ): { areEqual: true; multiplier: undefined } | { areEqual: false; multiplier: number } {
    let boostMultiplier = 1;
    const contactA = this.contact;

    if (
      areContactsEqual([
        pickSearchableFieldsToDiff(contactA),
        pickSearchableFieldsToDiff(contactB as Contact),
      ])
    ) {
      return { areEqual: true, multiplier: undefined };
    }

    for (const addressA of contactA.physicalAddresses || []) {
      if (
        contactB.physicalAddresses &&
        contactB.physicalAddresses?.find((addressB) =>
          areContactsEqual([{ physicalAddresses: [addressA] }, { physicalAddresses: [addressB] }])
        )
      ) {
        boostMultiplier += incrementFromMidToHigh;
      }
    }

    if (
      contactA.notes &&
      contactB.notes &&
      getStrDiff(
        removeAllWhitespace(removeSpecialChars(contactA.notes))?.toLowerCase(),
        removeAllWhitespace(removeSpecialChars(contactB.notes))?.toLowerCase()
      ) > 0.9
    ) {
      boostMultiplier += incrementFromMidToHigh;
    }

    return { areEqual: false, multiplier: boostMultiplier };
  }
}

export type ConfidenceLevels = "high" | "low";

export async function getDupeGroupsFromContacts(
  haystackContacts: ContactRow[] | undefined,
  strictSearchInstance?: DedupeStrictSearchIndex
): Promise<{ [key in ConfidenceLevels]: DupeGroup[] }> {
  if (!haystackContacts || haystackContacts?.length === 0) return { high: [], low: [] };

  const searchIndex = await createStrictContactIndex(
    haystackContacts,
    // @ts-ignore
    strictSearchInstance || window.FlexSearch.Document
  );

  const keyGroupsToMergeByConfidence: { [key in ConfidenceLevels]: DupeGroup[] } = {
    high: [],
    low: [],
  };

  await Promise.all(
    (haystackContacts || []).map(async (contact, contactKey) => {
      const dedupe = new ContactDedupe({
        contact,
        contactKey,
        strictSearchIndex: searchIndex,
        excludedSelf: true,
      });

      const result = await dedupe.getScoredMatches();

      const highConfidenceKeys: string[] = [];
      const lowConfidenceKeys: string[] = [];

      // should return high confidence and low confidence matches

      for (const key in result) {
        if (result[key].score >= HIGH_CONFIDENCE_THRESHOLD) {
          highConfidenceKeys.push(key);
        } else if (result[key].score > MID_CONFIDENCE_THRESHOLD) {
          lowConfidenceKeys.push(key);
        }
      }

      for (const confidenceKey in keyGroupsToMergeByConfidence) {
        const keyGroupsToMerge =
          keyGroupsToMergeByConfidence[confidenceKey as keyof typeof keyGroupsToMergeByConfidence];

        const confidenceKeys = confidenceKey === "high" ? highConfidenceKeys : lowConfidenceKeys;

        if (!confidenceKeys.length) continue;

        const contactIds = confidenceKeys.concat(contact.id);

        let groupIndex;
        for (const [index, dupeGroup] of keyGroupsToMerge.entries()) {
          for (const key in dupeGroup) {
            if (contactIds.includes(key)) {
              groupIndex = index;
            }
          }
        }

        if (groupIndex) {
          // a group has matched with at least one of the contact ids, merge them all
          for (const id of contactIds) {
            keyGroupsToMerge[Number(groupIndex)][id] =
              id === contact.id ? contactKey : result[id].contactKey;
          }
        } else {
          const matched: DupeGroup = {};
          for (const id of contactIds) {
            matched[id] = id === contact.id ? contactKey : result[id].contactKey;
          }
          keyGroupsToMerge.push(matched);
        }
      }
    })
  );

  return keyGroupsToMergeByConfidence;
}

export async function getDuplicatesAndExactContacts(
  dupeGroups: DupeGroup[],
  contacts: ContactRow[]
): Promise<DedupeContactRow[]> {
  if (!contacts || contacts.length === 0) return [];

  const dedupeContactRows: { [dedupeContactRowId: string]: DedupeContactRow } = {};

  for (const dupeGroup of dupeGroups) {
    const dedupeContactRow: Omit<DedupeContactRow, "areEqual"> = {
      id: "",
      contacts: {},
      mainContactId: "",
    };

    for (const contactId in dupeGroup) {
      const contactKey = dupeGroup[contactId];
      dedupeContactRow.contacts[contactId] = contacts[Number(contactKey)];
    }

    dedupeContactRow.id = getContactDedupeRowId(Object.keys(dedupeContactRow.contacts));
    if (dedupeContactRows[dedupeContactRow.id]) continue;

    const contactList = Object.values(dedupeContactRow.contacts);

    // find default main contact, user can change this later
    let [mainContact] = contactList
      .filter(({ updatedAt }) => updatedAt)
      .sort((a, b) => {
        if (a.updatedAt && b.updatedAt) return b.updatedAt - a.updatedAt;
        return a.surname.localeCompare(b.surname);
      });

    if (!mainContact) {
      const [mostDetailedContact] = contactList.sort(
        (a, b) => objKeys(b).length - objKeys(a).length
      );
      mainContact = mostDetailedContact;
    }

    dedupeContactRow.mainContactId = mainContact.id;

    const areEqual = objKeys(dedupeContactRow.contacts)
      .filter((id) => id !== dedupeContactRow.mainContactId)
      .every((id) => {
        return areContactsEqual(
          [
            dedupeContactRow.contacts[dedupeContactRow.mainContactId],
            dedupeContactRow.contacts[id],
          ],
          ["isDefault", "type", "label", "service"]
        );
      });

    const row: DedupeContactRow = { ...dedupeContactRow, areEqual };

    dedupeContactRows[row.id] = row;
  }

  return Object.values(dedupeContactRows);
}

export type DedupeContactRow = {
  id: string;
  mainContactId: string;
  contacts: { [id: string]: ContactRow | PendingContactToMerge };
  sortedContactIds?: string[];
  areEqual?: boolean;
  isExcluded?: boolean;
};

export type PendingContactToMerge = Contact & { remoteApiId: string };
export type ManyMatchedIds = {
  id: string;
  pendingContacts: PendingContactToMerge[];
  tdContactIds: string[];
};

export type PendingContactDuplicateSnapshotPayload = {
  remoteApiId: string;
  manyMatchedIds: ManyMatchedIds[];
  pendingContactIdToVendorContactId: { [id: string]: string };
};

export function combineManyMatches(
  manyMatchesResults: PendingContactDuplicateSnapshotPayload["manyMatchedIds"][]
): PendingContactDuplicateSnapshotPayload["manyMatchedIds"] {
  const manyMatchList = manyMatchesResults.reduce((acc, curr) => {
    // merge array of array into single array
    acc.push(...curr);
    return acc;
  }, []);

  const combinedResultObj: {
    [tdIds: string]: ManyMatchedIds;
  } = {};

  for (const result of manyMatchList) {
    const tdIds = result.tdContactIds.sort().join(",");

    if (tdIds in combinedResultObj) {
      combinedResultObj[tdIds].pendingContacts.push(...result.pendingContacts);
    } else {
      combinedResultObj[tdIds] = result;
    }
  }

  return Object.values(combinedResultObj);
}

export function areContactsMergeable(contacts: Partial<ContactRow | Contact>[]) {
  const [firstContact, ...restContacts] = contacts || [];
  const fieldValues = getUnmergeableFields(firstContact);

  for (const contact of restContacts) {
    const curFieldValues = getUnmergeableFields(contact);
    for (const field of unmergeableFields) {
      if (field === "notes") continue;

      const key = field as keyof typeof fieldValues;
      const val = fieldValues[key];
      const curVal = curFieldValues[key];

      if (!val && curVal) {
        fieldValues[key] = curVal;
      } else if (curVal) {
        if (curVal === val || curVal.length <= (val || "").length) {
          continue;
        }
        if (field === "birthday") {
          const [valYear, valMonth, valDay] = (val || "").split("-");
          const [curYear, curMonth, curDay] = (curVal || "").split("-");

          const shouldCompareYear = [valYear, curYear].every(
            (year) => year && String(year) !== OMIT_YEAR
          );

          if (shouldCompareYear && valYear !== curYear) {
            return false;
          }

          if (valMonth !== curMonth) {
            return false;
          }

          if (valDay !== curDay) {
            return false;
          }

          continue;
        }

        const isPrimaryNameField =
          field === "givenName" ||
          field === "surname" ||
          field === "nickname" ||
          field === "middleName";

        // create tokens from vals
        const valToken = removeSpecialChars(String(val).toLowerCase());
        const curValToken = removeSpecialChars(String(curVal).toLowerCase());

        // cannot merge if vals are diff, and neither starts with the other
        if (
          valToken !== curValToken && isPrimaryNameField
            ? !valToken.startsWith(curValToken) && !curValToken.startsWith(valToken)
            : // if not primary name field, check if neither are substrings of the other
              !valToken.includes(curValToken) && !curValToken.includes(valToken)
        ) {
          return false;
        }
      }
    }
  }

  return true;
}

export function getUnmergeableFieldsFromMergeableContacts(
  contacts: Partial<ContactRow | Contact>[],
  preserveFirstContactValues = false
) {
  const [firstContact, ...restContacts] = contacts || [];
  const fieldValues = getUnmergeableFields(firstContact);

  for (const contact of restContacts) {
    const curFieldValues = getUnmergeableFields(contact);
    for (const field of unmergeableFields) {
      const key = field as keyof typeof fieldValues;
      const val = fieldValues[key];
      if (preserveFirstContactValues && val) {
        continue;
      }

      const curVal = curFieldValues[key];

      if (!val && curVal) {
        fieldValues[key] = curVal;
      } else if (curVal) {
        if (field === "birthday") {
          // since this is a mergeable contact, we have already checked that the year is the same or one is OMIT_YEAR
          // month and day must be the same

          const [valYear, month, day] = (val || "").split("-");
          const [curYear] = (curVal || "").split("-");

          let year =
            [valYear, curYear].find((val) => val && String(val) !== OMIT_YEAR) || OMIT_YEAR;

          fieldValues[key] = `${year}-${month}-${day}`;
          continue;
        }

        if (curVal === val || curVal.length <= (val || "").length) {
          continue;
        }

        const valToken = removeSpecialChars(String(val).toLowerCase());
        const curValToken = removeSpecialChars(String(curVal).toLowerCase());

        if (curValToken.length > valToken.length) {
          fieldValues[key] = String(curVal).trim();
        }
      }

      // trim all fields
      if (fieldValues[key]) {
        fieldValues[key] = String(fieldValues[key]).trim();
      }
    }
  }

  return removeUndefinedFromObj(fieldValues);
}

export class ContactOnboardingMatch extends ContactDedupe {
  // super high threshold to prevent false positives
  // if we end up
  protected highValStrDiffThreshold = 0.9;
}

export async function getPendingContactMatches(
  haystackContacts: ContactRow[],
  needleContacts: (ContactRow | Contact)[],
  strictSearchInstance?: DedupeStrictSearchIndex
): Promise<{
  [key in ConfidenceLevels]: { [id: string]: { matches: DupeGroup; contactKey: number } };
}> {
  const searchIndex = await createStrictContactIndex(
    haystackContacts,
    // @ts-ignore
    strictSearchInstance || window.FlexSearch.Document
  );

  const keyGroupsToMergeByConfidence: { [key in ConfidenceLevels]: DupeGroup[] } = {
    high: [],
    low: [],
  };

  const needleContactIds: { [id: string]: number } = {};

  const haystackContactIndex: { [haystackContactId: string]: number } = {};
  if (needleContacts) {
    for (const [i, contact] of haystackContacts.entries()) {
      haystackContactIndex[contact.id] = i;
    }
  }

  await Promise.all(
    (needleContacts || []).map(async (contact, contactKey) => {
      needleContactIds[contact.id] = contactKey;

      const onboardingMatch = new ContactOnboardingMatch({
        contact,
        contactKey,
        strictSearchIndex: searchIndex,
        excludedSelf: false,
      });

      const result = await onboardingMatch.getScoredMatches();

      const highConfidenceKeys: string[] = [];
      const lowConfidenceKeys: string[] = [];

      // should return high confidence and low confidence matches

      for (const key in result) {
        if (result[key].score >= HIGH_CONFIDENCE_THRESHOLD) {
          highConfidenceKeys.push(key);
        } else if (result[key].score > MID_CONFIDENCE_THRESHOLD) {
          lowConfidenceKeys.push(key);
        }
      }

      for (const confidenceKey in keyGroupsToMergeByConfidence) {
        const keyGroupsToMerge =
          keyGroupsToMergeByConfidence[confidenceKey as keyof typeof keyGroupsToMergeByConfidence];

        const confidenceKeys = confidenceKey === "high" ? highConfidenceKeys : lowConfidenceKeys;

        if (!confidenceKeys.length) continue;

        const contactIds = confidenceKeys.concat(contact.id);

        const matched: DupeGroup = {};
        for (const id of contactIds) {
          matched[id] = id === contact.id ? contactKey : result[id].contactKey;
        }
        keyGroupsToMerge.push(matched);
      }
    })
  );

  const keyGroupsToMergeWithNeedlesByConfidence: {
    [key in ConfidenceLevels]: { [id: string]: { matches: DupeGroup; contactKey: number } };
  } = {
    high: {},
    low: {},
  };

  for (const confidenceKey in keyGroupsToMergeByConfidence) {
    const keyGroupsToMergeWithNeedles =
      keyGroupsToMergeWithNeedlesByConfidence[
        confidenceKey as keyof typeof keyGroupsToMergeWithNeedlesByConfidence
      ];

    for (const keyGroup of keyGroupsToMergeByConfidence[
      confidenceKey as keyof typeof keyGroupsToMergeByConfidence
    ]) {
      const matches: DupeGroup = {};

      const keyGroupCount = Object.keys(keyGroup).length;

      let needleId;
      for (const id in keyGroup) {
        // have a single entry in keyGroup means the needle and match from haystack have matching ids
        // therefore we can just return the match from haystack
        if (keyGroupCount === 1) {
          needleId = id;
          matches[id] = haystackContactIndex[id];
          break;
        }

        if (typeof needleContactIds[id] === "undefined") {
          matches[id] = haystackContactIndex[id];
          continue;
        }
        needleId = id;
      }
      if (needleId)
        keyGroupsToMergeWithNeedles[needleId] = {
          matches,
          contactKey: needleContactIds[needleId],
        };
    }
  }

  return keyGroupsToMergeWithNeedlesByConfidence;
}
