export const allGoogleScopes: {
  contacts: {
    [x: string]: string[];
  };
  calendars: string[];
  emails: {
    [x: string]: string[];
  };
} = {
  contacts: {
    sync: ["https://www.googleapis.com/auth/contacts"],
    readOnly: ["https://www.googleapis.com/auth/contacts.other.readonly"],
  },
  calendars: [
    "https://www.googleapis.com/auth/calendar",
    "https://www.googleapis.com/auth/calendar.events",
    "https://www.googleapis.com/auth/calendar.settings.readonly",
  ],
  emails: {
    send: ["https://www.googleapis.com/auth/gmail.send"],
    metadata: ["https://www.googleapis.com/auth/gmail.metadata"],
    filtersLabels: [
      "https://www.googleapis.com/auth/gmail.labels",
      "https://www.googleapis.com/auth/gmail.settings.basic",
    ],
  },
};

export function getAllScopeList() {
  let list: string[] = [];
  for (const i in allGoogleScopes) {
    const el = allGoogleScopes[i as keyof typeof allGoogleScopes];
    if (Array.isArray(el)) {
      list = list.concat(el);
    } else {
      for (const j in el) {
        list = list.concat(el[j as keyof typeof el]);
      }
    }
  }
  return list;
}

export function hasAllScopes(grantedScopeStr: string) {
  const grantedScopes = new Set(grantedScopeStr.split(" ") || []);
  const allScopeList = getAllScopeList();
  return allScopeList.every((scope) => {
    return grantedScopes.has(scope);
  });
}

export function hasSendGmailScope(grantedScopeStr: string) {
  const grantedScopes = new Set(grantedScopeStr.split(" ") || []);
  return allGoogleScopes.emails.send.every((scope) => {
    return grantedScopes.has(scope);
  });
}
