import { Listbox, Transition } from "@headlessui/react";
import classNames from "clsx";
import { Fragment, useMemo, useState } from "react";
import type { DelegatedUserRole } from "services/src/shared/models/UserDelegation";

import { LockOpenSolidIcon, LockSolidIcon } from "@/components/core/Icon";

type CollaboratorPermission = { name: string; value: DelegatedUserRole };
const permissions: CollaboratorPermission[] = [
  { name: "Can edit", value: "update" },
  { name: "Can view", value: "read" },
];

export default function useCollaboratePermission() {
  const [permission, setPermission] = useState<CollaboratorPermission>(permissions[0]);

  const PermissionSelector = useMemo(() => {
    const Icon = permission.value === "read" ? LockSolidIcon : LockOpenSolidIcon;
    return (
      <Listbox as="div" value={permission} onChange={setPermission} className="flex-shrink-0">
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">Permission</Listbox.Label>
            <div className="relative">
              <Listbox.Button className="relative inline-flex items-center rounded-md py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3">
                <Icon
                  size="lg"
                  className={classNames(
                    permission.value === "read" ? "text-gray-300" : "text-gray-500",
                    "flex-shrink-0 h-5 w-5 sm:-ml-1",
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    permission.value === "read" ? "" : "text-primary",
                    "hidden truncate sm:ml-2 sm:block",
                  )}
                >
                  {permission.name}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute right-0 z-10 mt-1 w-52 bg-primary shadow max-h-56 rounded-lg py-3 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {permissions.map((label) => (
                    <Listbox.Option
                      key={label.value}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-gray-100" : "bg-primary",
                          "cursor-default select-none relative py-2 px-3",
                        )
                      }
                      value={label}
                    >
                      <div className="flex items-center">
                        <span className="block font-medium truncate">{label.name}</span>
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }, [permission]);

  return { permission, setPermission, PermissionSelector };
}
