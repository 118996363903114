import { BlockNoteEditor } from "@blocknote/core";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Contact } from "@shared/models/Contact";
import classNames from "clsx";
import {
  ContactRecipientPreview,
  DraftBodyOverride,
  FlattenedContactRecipientList,
} from "core/types/userMessaging";
import dynamic from "next/dynamic";
import {
  Dispatch,
  FC,
  Fragment,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { pluralize } from "utils/string";
import uuid from "utils/uuid";

import ContactListItem from "@/components/contacts/list/ContactListItem";
import { useGroupedListControls } from "@/components/contacts/list/hooks";
import { ContactData } from "@/components/contacts/v2/types";
import Button, { ButtonVariant } from "@/components/core/Button";
import { WarningColor } from "@/components/core/colorVariant";
import { EnvelopesIcon, RotateLeftIcon } from "@/components/core/Icon";
import EmailOnlyRecipientListItem from "@/components/email/EmailOnlyRecipientListItem";
import { getAllMailMergeFields } from "@/components/email/helpers";
import { useJumpToContactInList } from "@/components/email/hooks";
import MailMergeMissingFieldBanner from "@/components/email/MailMergeMissingFieldBanner";
import SendScheduleBanner from "@/components/email/SendScheduleBanner";
import GroupedVirtualizedList from "@/components/GroupedVirtualizedList";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

const MailMergePreviewEditor = dynamic(() => import("../editor/MailMergePreviewEditor"), {
  ssr: false,
});

export type MailMergePreviewProps = {
  draft: LocallyPersistedEmailDraft;
  flattenedRecipientList: FlattenedContactRecipientList;
  setDraftBodyOverride: Dispatch<SetStateAction<DraftBodyOverride>>;
  draftBodyOverride: DraftBodyOverride;
  onEditorBodyContentChange: (editor: BlockNoteEditor, recipientId: string) => void;
  contactDataOverride: { [key: string]: Partial<ContactData> };
  updateContactOverride: (recipientId: string, data: Partial<Contact> & { id: string }) => void;
};

const MailMergePreview: FC<MailMergePreviewProps> = ({
  draft,
  flattenedRecipientList,
  onEditorBodyContentChange,
  draftBodyOverride,
  setDraftBodyOverride,
  contactDataOverride,
  updateContactOverride,
}) => {
  const editorRef = useRef<BlockNoteEditor<any, any, any>>();

  // a timestamp to force re-render of the editor
  const [editorTimestamp, setEditorTimestamp] = useState(Date.now());

  const [selectedRecipient, setSelectedRecipient] = useState<ContactRecipientPreview | undefined>(
    undefined,
  );

  const [showMissingFieldsOnly, setShowMissingFieldsOnly] = useState(false);

  const outstandingContactMissingFields = useMemo(() => {
    // look for filled in fields in recipientContactDict against flattenedRecipientList?.missingMailMergeFieldsByRecipientId

    const missingMailMergeFieldsByRecipientId: (typeof flattenedRecipientList)["missingMailMergeFieldsByRecipientId"] =
      {};

    for (const recipientId in flattenedRecipientList?.missingMailMergeFieldsByRecipientId) {
      const missingFields =
        flattenedRecipientList?.missingMailMergeFieldsByRecipientId[recipientId];
      if (!missingFields || missingFields.length === 0) continue;

      // check if fields in missingFields are actually fulfilled by contactOverride
      // if so, filter them out. this means the user has entered data, which we will eventually save to the contact

      const contactOverride = contactDataOverride[recipientId] || {};
      const overriddenBody = draftBodyOverride[recipientId];
      const outstandingMailMergeFields = overriddenBody
        ? getAllMailMergeFields(overriddenBody)
        : undefined;

      const missingFieldsAfterOverride = missingFields.filter((field) => {
        return outstandingMailMergeFields
          ? !contactOverride[field] && outstandingMailMergeFields.has(field) // after user edits body, mail merge fields are still referring to these missing fields
          : !contactOverride[field];
      });

      if (missingFieldsAfterOverride.length > 0) {
        missingMailMergeFieldsByRecipientId[recipientId] = missingFieldsAfterOverride;
      }
    }

    return {
      byRecipientId: missingMailMergeFieldsByRecipientId,
      count: Object.keys(missingMailMergeFieldsByRecipientId).length,
    };
  }, [
    contactDataOverride,
    draftBodyOverride,
    flattenedRecipientList?.missingMailMergeFieldsByRecipientId,
  ]);

  const contactRecipientList = useMemo(() => {
    if (showMissingFieldsOnly) {
      const labels: Set<string> = new Set();
      const groupCounts: { [key: string]: number } = {};

      const flattenedList: ContactRecipientPreview[] = [];

      let hasSelectedRecipientInList = !selectedRecipient?.id;

      for (const [i, contactRecipient] of (
        flattenedRecipientList?.flattenedContactList || []
      ).entries()) {
        if (selectedRecipient?.id && contactRecipient.id === selectedRecipient.id) {
          hasSelectedRecipientInList = true;
        }
        if (!outstandingContactMissingFields.byRecipientId[contactRecipient.id]) continue;

        const groupIndex = flattenedRecipientList.groupCounts.findIndex((count) => count > i);
        const label = flattenedRecipientList.groupedLabels[groupIndex];
        labels.add(label);
        groupCounts[label] = (groupCounts[label] || 0) + 1;
        const missingFields = outstandingContactMissingFields.byRecipientId[contactRecipient.id];
        if (missingFields.length > 0) {
          flattenedList.push(contactRecipient);
        }
      }

      return {
        flattenedList: flattenedList,
        hasSelectedRecipientInList,
        groupCounts: Object.values(groupCounts),
        groupedLabels: Array.from(labels),
      };
    }

    return {
      flattenedList: flattenedRecipientList?.flattenedContactList,
      hasSelectedRecipientInList: selectedRecipient?.id
        ? flattenedRecipientList.flattenedContactList.some(
            (contactRecipient) => contactRecipient.id === selectedRecipient?.id,
          )
        : false,
      groupCounts: flattenedRecipientList?.groupCounts,
      groupedLabels: flattenedRecipientList?.groupedLabels,
    };
  }, [
    showMissingFieldsOnly,
    flattenedRecipientList.flattenedContactList,
    flattenedRecipientList.groupCounts,
    flattenedRecipientList.groupedLabels,
    selectedRecipient,
    outstandingContactMissingFields.byRecipientId,
  ]);

  useEffect(() => {
    if (!selectedRecipient?.id) {
      setSelectedRecipient(contactRecipientList.flattenedList[0]);
    } else if (!contactRecipientList.hasSelectedRecipientInList) {
      setSelectedRecipient(contactRecipientList.flattenedList[0]);
    }
  }, [
    contactRecipientList.flattenedList,
    contactRecipientList.hasSelectedRecipientInList,
    selectedRecipient?.id,
  ]);

  const { wrapperRef, ref, onClickItem, currentItemIndex, onSelectAlphabetKey } =
    useGroupedListControls({
      items: contactRecipientList?.flattenedList || [],
      groupCounts: contactRecipientList?.groupCounts || [],
      selectedItem: selectedRecipient,
      onKeySelected: setSelectedRecipient,
    });

  const draftBodyStr = useMemo(() => {
    if (!selectedRecipient?.id) return undefined;
    const body = draftBodyOverride[selectedRecipient.id] || draft.body;
    return JSON.stringify(body);
  }, [draft.body, draftBodyOverride, selectedRecipient?.id]);

  const updateSelectedContactOverride = useCallback(
    (updates: Partial<Contact>) => {
      if (!selectedRecipient?.id) return;
      updateContactOverride(selectedRecipient?.id || "", {
        id: selectedRecipient?.contact?.id || uuid(),
        ...updates,
      });
    },
    [selectedRecipient?.contact?.id, selectedRecipient?.id, updateContactOverride],
  );

  const onResetEdit = useCallback(() => {
    if (selectedRecipient) {
      setDraftBodyOverride((prev) => {
        const newDraftBody = { ...prev };
        delete newDraftBody[selectedRecipient.id];
        return newDraftBody;
      });
      setEditorTimestamp(Date.now());
    }
  }, [selectedRecipient, setDraftBodyOverride]);

  const jumpToPredicate = useCallback(
    (contactRecipient: ContactRecipientPreview) => {
      const missingFields =
        flattenedRecipientList?.missingMailMergeFieldsByRecipientId[contactRecipient.id] || [];
      return missingFields.length > 0;
    },
    [flattenedRecipientList?.missingMailMergeFieldsByRecipientId],
  );

  const { setVisibleRange, scrollToPrev, scrollToNext } = useJumpToContactInList(
    contactRecipientList?.flattenedList || ([] as ContactRecipientPreview[]),
    jumpToPredicate,
    ref,
  );

  console.log(outstandingContactMissingFields);

  return (
    <>
      {outstandingContactMissingFields.count > 0 && (
        <div className={classNames("w-full items-center rounded-md p-4 my-6", WarningColor)}>
          <div className="flex gap-x-4">
            <div className="flex-none">
              <span className="sr-only">
                {outstandingContactMissingFields.count} contacts are missing mail merge fields
              </span>
              <EnvelopesIcon size="lg" />
            </div>

            <div className="text-sm flex-1">
              <div className="flex items-center flex-col sm:flex-row">
                <div className="flex-1">
                  <span className="font-semibold">{outstandingContactMissingFields.count}</span>{" "}
                  {pluralize(outstandingContactMissingFields.count, "contact", "contacts")} missing
                  mail merge fields
                </div>
                <div className="flex gap-2">
                  <span>Jump to contact:</span>
                  <Button
                    noGutter
                    variant={ButtonVariant.Secondary}
                    className="px-2"
                    iconOnly
                    icon={<ChevronDownIcon />}
                    onClick={scrollToNext}
                  />
                  <Button
                    noGutter
                    variant={ButtonVariant.Secondary}
                    className="px-2"
                    iconOnly
                    icon={<ChevronUpIcon />}
                    onClick={scrollToPrev}
                  />
                </div>
              </div>

              <div className="flex my-2 pr-1 gap-x-2 items-center">
                <input
                  id="showMissingFieldsOnly"
                  type="checkbox"
                  className="h-4 w-4 rounded text-indigo-600 focus:ring-indigo-600"
                  checked={showMissingFieldsOnly}
                  onChange={({ target }) => setShowMissingFieldsOnly(target.checked)}
                />
                <label htmlFor="showMissingFieldsOnly" className="text-secondary">
                  Show only contacts with missing fields
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="rounded-md relative flex flex-1 max-h-[60vh] overflow-hidden border border-color-primary"
        style={{ height: "100vh" }}
      >
        <div
          className="border-r border-color-primary flex items-center w-72"
          ref={wrapperRef as RefObject<HTMLDivElement>}
        >
          <GroupedVirtualizedList
            onRangeChanged={setVisibleRange}
            overscan={0}
            noTopBorder
            EmptyState="No recipients"
            className="flex-1"
            isLoaded={typeof flattenedRecipientList?.flattenedContactList !== "undefined"}
            selectedItem={selectedRecipient}
            refHandle={ref}
            showGroupCount
            items={contactRecipientList?.flattenedList || []}
            labelCounts={contactRecipientList?.groupCounts || []}
            labels={contactRecipientList?.groupedLabels || []}
            itemContent={(index) => {
              const recipient = contactRecipientList?.flattenedList[index];
              const contact = recipient?.contact;
              const isSelected = Boolean(
                selectedRecipient && selectedRecipient.id === recipient?.id,
              );

              if (contact) {
                // check if contact is in no email list
                const isNoEmail = flattenedRecipientList?.contactIdsWithoutEmail.has(contact.id);
                const missingFields =
                  outstandingContactMissingFields.byRecipientId[recipient.id] || [];

                return (
                  <ContactListItem
                    className={classNames(
                      "group py-2 px-4 relative border-green-300 dark:border-green-600",
                      missingFields.length > 0 &&
                        "border-l-4 border-yellow-200 dark:border-yellow-600",
                    )}
                    key={`${recipient?.id || ""}_${index}`}
                    contact={contact}
                    onClickItem={(event) => {
                      if (onClickItem) onClickItem(event, recipient, index);
                    }}
                    isSelected={isSelected}
                    sortKey="_surnameSort"
                  />
                );
              }

              // email only
              return (
                <EmailOnlyRecipientListItem
                  key={`${recipient?.id || ""}_${index}`}
                  onClickItem={(event) => {
                    if (onClickItem) onClickItem(event, recipient, index);
                  }}
                  recipient={recipient}
                  isSelected={isSelected}
                  sortKey="_surnameSort"
                />
              );
            }}
          />
        </div>
        <div className="flex-1 w-128 h-full flex flex-col">
          <div className="flex w-full items-center text-secondary border-b border-color-primary">
            <div className="ml-4 text-label text-sm">Subject</div>
            <input
              className="block flex-1 border-transparent text-primary focus:border-transparent bg-transparent focus:ring-0 sm:text-sm outline-0 py-1"
              value={draft.subject || ""}
              readOnly
            />
          </div>

          <div className="flex flex-col flex-1 overflow-hidden">
            <div className="flex-1 py-2 overflow-y-auto">
              {selectedRecipient && (contactDataOverride || selectedRecipient.contact) && (
                <Fragment key={selectedRecipient.id}>
                  <SendScheduleBanner draft={draft} />
                  <MailMergeMissingFieldBanner
                    contactOverride={contactDataOverride[selectedRecipient.id]}
                    missingFields={
                      selectedRecipient
                        ? flattenedRecipientList.missingMailMergeFieldsByRecipientId[
                            selectedRecipient.id || ""
                          ]
                        : []
                    }
                    updateContactOverride={updateSelectedContactOverride}
                  />
                  <MailMergePreviewEditor
                    key={selectedRecipient.id + editorTimestamp}
                    contact={selectedRecipient.contact}
                    contactOverride={contactDataOverride[selectedRecipient.id]}
                    initialContentStr={draftBodyStr}
                    editorRef={editorRef}
                    onContentChange={({ editor }) => {
                      if (selectedRecipient) {
                        onEditorBodyContentChange(editor, selectedRecipient.id);
                      }
                    }}
                  />
                </Fragment>
              )}
            </div>
            {selectedRecipient?.id && draftBodyOverride[selectedRecipient.id] && (
              <div className="flex p-4 justify-end">
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={onResetEdit}
                  icon={<RotateLeftIcon />}
                >
                  Reset Edit
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MailMergePreview;
