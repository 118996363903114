import { Menu, Transition } from "@headlessui/react";
import type { ChangeEvent, ChangeEventHandler, FC, MutableRefObject } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { usePopper } from "react-popper";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import type { ContactRow } from "services/src/shared/models/Contact";
import type { Relative } from "services/src/shared/models/types";
import { isValidUuid } from "utils/string";

import {
  CopySolidIcon,
  PenSolidIcon,
  UserCircleSolidIcon,
  UsersIcon,
} from "@/components/core/Icon";
import TextInput from "@/components/core/TextInput";
import { showToast } from "@/components/core/Toast";
import HighlightText from "@/components/HighlightText";
import { isEmpty } from "@/helpers/array";
import { clamp } from "@/helpers/number";
import { openLink } from "@/helpers/window";
import { useLiveAllContacts } from "@/hooks/data/useLiveContacts";

import { getFullName } from "../../../../../../packages/core/helpers/contact";
import { generateTypeSelectItems } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import type { ContextualMenuItem } from "../shared/ContextualMenuField";
import ContextualMenuField from "../shared/ContextualMenuField";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import type { TypeSelectItem } from "../shared/TypeSelect";
import TypeSelect from "../shared/TypeSelect";
import type { ContactDataFieldProps } from "../types";

const RelativesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  focusedField,
  onFocusField,
}) => {
  const { contacts } = useLiveAllContacts();
  const relatives = useMemo(() => contactData?.relatives || [], [contactData?.relatives]);

  const lastRelativeRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedRelativeRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastRelativeInput = useCallback(() => {
    lastRelativeRowInputRef.current?.focus();
  }, [lastRelativeRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "relatives") {
      focusedRelativeRowInputRef.current?.focus();
      focusedRelativeRowInputRef.current?.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [focusedField]);

  const relativeContextualItems = useMemo<ContextualMenuItem<Relative>[]>(() => {
    return [
      {
        icon: () => UserCircleSolidIcon,
        tooltip: () => "Show on TitleDock",
        action: (payload) => {
          openLink(`/contacts/${payload.value.contactId}`);
        },
        isVisible: (payload) => isValidUuid(payload.value.contactId),
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy name to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(payload.value.value || "");
            showToast({ title: "Name copied to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit relationship",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [contactData]);

  // Resolve relative labels from the default list + types that are in-use
  const relativeTypeSelectItems = generateTypeSelectItems(
    "relatives",
    relatives.map((relative) => relative.label)
  );

  const updateRelatives = (relatives: Relative[]) => {
    if (dispatch) dispatch({ type: "relatives", payload: relatives });
  };

  const onAddNewRelative = (label?: Relative["label"]) => {
    const relativesToUpdate: Relative[] = [
      ...relatives,
      { value: "", label: label || relativeTypeSelectItems[0]?.value },
    ];
    updateRelatives(relativesToUpdate);

    // After a short delay, focus on the last relative input (that was just added)
    setTimeout(() => {
      focusOnLastRelativeInput();
    }, 100);
  };

  const onUpdateRelative = ({ index, data }: { index: number; data: Partial<Relative> }) => {
    const existingContact = contacts?.find((contact) => contact.id === data.contactId);
    const resetContactId = existingContact && getFullName(existingContact) !== data.value;
    const relativesToUpdate: Relative[] = [...relatives];
    relativesToUpdate[index] = {
      ...relativesToUpdate[index],
      ...data,
      ...(resetContactId && { contactId: undefined }),
    };
    updateRelatives(relativesToUpdate);
  };

  const onRemoveRelativeAtIndex = (index: number) => {
    const relativesToUpdate: Relative[] = [...relatives];
    relativesToUpdate.splice(index, 1);
    updateRelatives(relativesToUpdate);
  };

  // Do not show for read-only mode if there are no relatives
  if (!isEditing && isEmpty(relatives)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Relationships"
      isEditing={isEditing}
      icon={<UsersIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {relatives.map((relative, index) => {
            const isLastRelativeInput = index === relatives.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "relatives" && focusedField?.index === index;
            return (
              <RelativeRow
                key={index}
                index={index}
                relative={relative}
                relativeTypeSelectItems={relativeTypeSelectItems}
                onUpdateRelative={onUpdateRelative}
                onRemoveRelativeAtIndex={onRemoveRelativeAtIndex}
                contacts={contacts}
                forwardedRef={
                  (isFocusedField && focusedRelativeRowInputRef) ||
                  (isLastRelativeInput && lastRelativeRowInputRef) ||
                  undefined
                }
              />
            );
          })}
          <dd key="add-new-relationship" className="flex flex-row w-full">
            <TypeSelect
              items={relativeTypeSelectItems}
              onSelectItem={(item) => onAddNewRelative(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewRelative()}
            >
              Add new relationship
            </button>
          </dd>
        </>
      ) : (
        relatives.map((relative, index) => {
          return (
            <dd key={index}>
              <ContextualMenuField
                items={relativeContextualItems}
                actionPayload={{
                  fieldName: "relatives",
                  value: relative,
                  index,
                }}
              >
                {relative.contactId ? (
                  <a
                    href={`/contacts/${relative.contactId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-underline"
                  >
                    {relative.value}
                    <UserCircleSolidIcon className="ml-2 -mt-1 text-label" size="sm" />
                  </a>
                ) : (
                  <p className="text-primary">
                    <HighlightText value={relative.value} />
                  </p>
                )}
                <div className="uppercase text-label">{relative.label}</div>
              </ContextualMenuField>
            </dd>
          );
        })
      )}
    </BaseDetailsField>
  );
};

type RelativeRowProps = {
  index: number;
  relative: Relative;
  relativeTypeSelectItems: TypeSelectItem[];
  onUpdateRelative: ({ index, data }: { index: number; data: Partial<Relative> }) => void;
  onRemoveRelativeAtIndex: (index: number) => void;
  contacts?: ContactRow[];
  forwardedRef?: MutableRefObject<HTMLInputElement | null>;
};

const RelativeRow: FC<RelativeRowProps> = ({
  index,
  relative,
  relativeTypeSelectItems,
  onUpdateRelative,
  onRemoveRelativeAtIndex,
  contacts,
  forwardedRef,
}) => {
  // Contact suggestion dropdown elements
  const popperDivRef = useRef(null);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [currentInputString, setCurrentInputString] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const filteredSuggestions =
    contacts?.filter((contact) =>
      getFullName(contact).toLowerCase().includes(currentInputString.toLowerCase())
    ) || [];

  const computedHeight = 36 * clamp(filteredSuggestions.length, 0, 5);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  });

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrentInputString(e.target.value);

    if (currentInputString.length > 0) {
      setShowSuggestions(true);
    }
  };

  return (
    <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
      <TypeSelect
        items={relativeTypeSelectItems}
        initialItemId={relative.label}
        onSelectItem={(item) => onUpdateRelative({ index, data: { label: item.value } })}
        createNewItemTitle="Custom"
      />
      <Menu as="div" className="relative flex-1 inline-block mr-2">
        <OutsideClickHandler onOutsideClick={() => setShowSuggestions(false)}>
          <div>
            <TextInput
              name="relative"
              type="text"
              value={relative.value}
              onFocus={(ref) => {
                setCurrentInputString(relative.value || "");
                setReferenceElement(ref.target);
                setShowSuggestions(true);
              }}
              onBlur={() => {
                setReferenceElement(null);
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onInputChange(event);
                onUpdateRelative({
                  index,
                  data: { value: event.target.value },
                });
              }}
              autoComplete="off"
              forwardedRef={forwardedRef}
            />
          </div>

          <div ref={popperDivRef} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
            <Transition
              show={showSuggestions}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
              beforeEnter={() => setPopperElement(popperDivRef.current)}
              afterLeave={() => setPopperElement(null)}
            >
              <Menu.Items
                static
                className="z-50 overflow-auto rounded-md shadow-lg bg-primary divide-primary-y max-h-56 ring-primary focus:outline-none"
              >
                <Virtuoso
                  style={{ width: "300px", height: computedHeight }}
                  ref={virtuosoRef}
                  data={filteredSuggestions}
                  itemContent={(_index, suggestion) => {
                    const name = getFullName(suggestion);
                    return (
                      <Menu.Item key={suggestion.id}>
                        <div
                          className="w-full menu-item"
                          onClick={(e) => {
                            e.preventDefault();
                            onUpdateRelative({
                              index,
                              data: { value: name, contactId: suggestion.id },
                            });
                            setShowSuggestions(false);
                          }}
                        >
                          {name}
                        </div>
                      </Menu.Item>
                    );
                  }}
                />
              </Menu.Items>
            </Transition>
          </div>
        </OutsideClickHandler>
      </Menu>
      <RemoveDetailsItemButton
        onClickButton={() => onRemoveRelativeAtIndex(index)}
        tooltip="Remove"
        className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
      />
    </dd>
  );
};

export default RelativesField;
