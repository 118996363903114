import { jaroWinkler } from "@skyra/jaro-winkler";
import sbd from "sbd";
import { getNumberSequence } from "./number";

export function startsWithNonLetterOrIsEmpty(input: string): boolean {
  // Check if the string is empty
  if (input.length === 0) {
    return true;
  }

  // Regular expression to test for a non-letter character at the start
  // ^ asserts position at start of the string
  // [^A-Za-z] matches any character that is not a letter
  const nonLetterAtStartRegex = /^[^A-Za-z]/;

  // Test the input string against the regular expression
  return nonLetterAtStartRegex.test(input);
}

export function getMasked({
  str,
  trailingIntactCount = 4,
  maskingChar = "*",
}: {
  str: string;
  trailingIntactCount?: number;
  maskingChar?: string;
}) {
  const regex = new RegExp(
    ".(?=.{" + (trailingIntactCount <= str.length ? 0 : trailingIntactCount) + "})",
    "g",
  );
  return str.replace(regex, maskingChar);
}

export function getWords(str: string) {
  return str.split(/[^A-Za-z0-9]/);
}

export function camelToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter, index) => {
    return index == 0 ? letter.toLowerCase() : "_" + letter.toLowerCase();
  });
}

export function escapeNewLine(str?: string) {
  return str?.replace(/\n/g, "\\n") || "";
}

export function removeAllWhitespace(str?: string) {
  return String(str || "")?.replace(/\s+/g, "");
}

const urlRegex =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/;
export function isUrl(val: string) {
  return urlRegex.test(val);
}

export function ensureHttpProtocol(url: string): string {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "http://" + url;
  }
  return url;
}

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export function isEmail(email: string) {
  return emailRegex.test(email);
}

export function getNumsFromString(val: string) {
  return val.replace(/[^0-9]/g, "");
}

export function escapeRegExp(val: string) {
  return val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function escapeReplacement(val: string) {
  return val.replace(/\$/g, "$$$$");
}

export function removeSpecialChars(val?: string) {
  return String(val || "").replace(/[^\w]/gi, "");
}

export function removeDoubleSpaces(str: string) {
  return "" + str.replace(/ +(?= )/g, "");
}

export function pluralize(
  count: number,
  singular: string,
  plural: string,
  showCount: boolean = false,
) {
  const pluralized = count === 1 ? singular : plural;
  return (showCount ? `${count} ` : "") + pluralized;
}

export function replaceMultipleSpaces(str: string): string {
  return str.replace(/\s+/g, " ");
}

export function getStrDiff(strA: string, strB: string) {
  if (strA === strB) {
    return 1;
  }
  return jaroWinkler(strA, strB);
}

export function getTokenizedStr(val: string) {
  return removeSpecialChars(val).toLowerCase();
}

export function splitToSentences(text: string): string[] {
  return sbd.sentences(text, { newline_boundaries: true });
}

export function getMergedTextsByLineBreak(texts: string[]): string {
  const combinedText = texts.join("\n\n");

  // Use sbd to accurately split the combined text into sentences
  const sentences = splitToSentences(combinedText);

  // Use a Set to store the unique sentences in their original form
  const seen = new Set<string>();
  const uniqueSentences = [];

  for (let sentence of sentences) {
    const normalized = sentence.trim().toLowerCase();
    if (!seen.has(normalized)) {
      seen.add(normalized);
      uniqueSentences.push(sentence);
    }
  }

  return uniqueSentences.join("\n");
}

export function resolveStringListConflicts(list: string[]): string[] {
  const result: string[] = [];
  let groups: {
    [name: string]: {
      name: string;
      count: number;
      index: number;
    }[];
  } = {};

  list.forEach((name, index) => {
    let baseName = name;
    let count = 0;
    const match = name.match(/^(.+) \((\d+)\)$/);
    if (match) {
      baseName = match[1];
      count = parseInt(match[2]);
    }
    if (!groups[baseName]) {
      groups[baseName] = [];
    }
    groups[baseName].push({ name, count, index });
  });

  for (const baseName in groups) {
    const group = groups[baseName];

    if (group.length <= 1) continue;
    const sorted = group.sort((a, b) => a.index - b.index);
    const usedNumbers: number[] = [0]; // 0 is reserved for the first item

    for (const item of sorted) {
      if (item.count > 0) {
        usedNumbers.push(item.count);
      }
    }

    const possibleNumbers = getNumberSequence(sorted.length, usedNumbers);

    let hasSeenFirst = false;
    for (const item of sorted) {
      if (item.count === 0) {
        if (!hasSeenFirst) {
          hasSeenFirst = true;
          continue;
        }
        const count = possibleNumbers.shift();
        if (typeof count !== "undefined") {
          item.name = `${baseName} (${count})`;
          item.count = count;
        }
      }
    }

    groups[baseName] = sorted;
  }

  for (const baseName in groups) {
    const group = groups[baseName];
    for (const item of group) {
      result[item.index] = item.name;
    }
  }

  return result;
}

export function hasWhiteSpace(str = "") {
  return /\s/g.test(str.trim());
}

export function getUrlFromDomain(domain: string | undefined) {
  if (!domain) return "";
  if (domain.includes("http")) return domain;
  return `http://${domain}`;
}

export function removeCoType(companyName: string) {
  return companyName?.replace(/,?\s*(llc|inc|co)\.?$/i, "");
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const VALID_UUID_REGEX =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
export function isValidUuid(uuid?: string | null): boolean {
  return uuid ? VALID_UUID_REGEX.test(uuid) : false;
}

const EXTRACT_UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
export function extractUUID(val: string) {
  const match = val.match(EXTRACT_UUID_REGEX);
  return match ? match[0] : null;
}

export function standardizeSeparator(input: string, separator = ","): string {
  const separators = /[;|,\s]+/g;
  return input.replace(separators, separator);
}

const htmlTagRegex = /<\/?[a-z]+(\s+[a-z-]+(?:="[^"]*")?)*\s*\/?>/i;
export function hasHtml(val: string) {
  return htmlTagRegex.test(val);
}

export function getStrByteSize(val: string) {
  return new Blob([val]).size;
}

export function truncateStr(val: string, max: number, suffix = "...") {
  if (val.length < max) {
    return val;
  }

  const lastSpaceIndex = val.substring(0, max).lastIndexOf(" ");

  if (lastSpaceIndex === -1) {
    return `${val.substring(0, max)}${suffix}`;
  }

  return `${val.substring(0, lastSpaceIndex)}${suffix}`;
}
