import type { BaseQueryApi } from "@reduxjs/toolkit/dist/query";
import type { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { BaseQueryFn, fetchBaseQuery, retry } from "@reduxjs/toolkit/query";
import * as firebase from "firebase/auth";

import { getDelegatedUser } from "@/hooks/useDelegatedUser";

import { getAuth } from "../firebase";

async function waitForSignIn() {
  let unsubscribe: firebase.Unsubscribe;
  const auth = getAuth();
  return new Promise((resolve) => {
    unsubscribe = auth.onAuthStateChanged(resolve);
  }).then(() => unsubscribe);
}

export const getAuthBaseQuery = (queryArgs: FetchBaseQueryArgs = {}): BaseQueryFn => {
  const auth = getAuth();
  const handler = async (args: any, api: BaseQueryApi, extraOptions: any) => {
    const baseQuery = fetchBaseQuery({
      ...queryArgs,
      prepareHeaders: async (headers) => {
        const token = await auth.currentUser?.getIdToken();
        if (token) {
          // If we have a token set in state, let's assume that we should be passing it.
          headers.set("Authorization", `Bearer ${token}`);
          const delegatedUser = getDelegatedUser();
          if (delegatedUser?.delegatedUserId) {
            headers.set("delegated-user-id", delegatedUser.delegatedUserId);
          }
        }
        return headers;
      },
    });

    const token = await auth.currentUser?.getIdToken();
    if (!token) {
      const unsubscribe = await waitForSignIn();
      if (unsubscribe) unsubscribe();
    }

    return baseQuery(args, api, extraOptions);
  };

  return retry(handler, { maxRetries: 2 });
};
