import { parse } from "date-fns";
import { FC } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";

import type { ArbitraryDate } from "../../../../../services/src/shared/models/types";
import { isEmpty } from "../../../../helpers/array";
import { buildGoogleCalendarEventLink } from "../../../../helpers/calendar";
import { prettyPrintArbitraryDateString } from "../../../../helpers/date";
import { openLink } from "../../../../helpers/window";
import { CalendarIcon, CalendarSolidIcon, CopySolidIcon, PenSolidIcon } from "../../../core/Icon";
import SimpleDateInput from "../../../core/SimpleDateInput";
import { showToast } from "../../../core/Toast";
import { generateTypeSelectItems } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import type { ContextualMenuItem } from "../shared/ContextualMenuField";
import ContextualMenuField from "../shared/ContextualMenuField";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeSelect from "../shared/TypeSelect";
import type { ContactDataFieldProps } from "../types";

const ArbitraryDatesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  focusedField,
  onFocusField,
  contactMetadataDispatch,
  contactMetadataValueMap,
}) => {
  const dates = useMemo(() => contactData?.dates || [], [contactData?.dates]);

  const lastDateRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedDateRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastDateInput = useCallback(() => {
    lastDateRowInputRef.current?.focus();
  }, [lastDateRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "dates") {
      focusedDateRowInputRef.current?.focus();
      focusedDateRowInputRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [focusedField]);

  const dateContextualItems = useMemo<ContextualMenuItem<ArbitraryDate>[]>(() => {
    return [
      {
        icon: () => CalendarSolidIcon,
        tooltip: () => "Add to calendar",
        action: (payload) => {
          const eventLink = buildGoogleCalendarEventLink(
            payload.value.label || "",
            parse(payload.value.value, "yyyy-MM-dd", new Date())
          );
          openLink(eventLink);
        },
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy date to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(payload.value.value);
            showToast({ title: "Date copied to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit date",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [onFocusField]);

  // Resolve date types from the default list + types that are in-use
  const dateTypeSelectItems = generateTypeSelectItems(
    "dates",
    dates.map((date) => date.label)
  );

  const updateDates = (dates: ArbitraryDate[]) => {
    if (dispatch) dispatch({ type: "dates", payload: dates });
  };

  const onAddNewDate = (label?: ArbitraryDate["label"]) => {
    const datesToUpdate: ArbitraryDate[] = [
      ...dates,
      { value: "", label: label || dateTypeSelectItems[0]?.value },
    ];
    updateDates(datesToUpdate);

    // After a short delay, focus on the last date input (that was just added)
    setTimeout(() => {
      focusOnLastDateInput();
    }, 100);
  };

  const onUpdateDate = ({
    index,
    data,
  }: {
    index: number;
    data: { value?: string; label?: string; leapYearPref?: number };
  }) => {
    const datesToUpdate: ArbitraryDate[] = [...dates];
    datesToUpdate[index] = {
      ...datesToUpdate[index],
      ...data,
    };
    updateDates(datesToUpdate);
  };

  const onRemoveDateAtIndex = (index: number) => {
    const datesToUpdate: ArbitraryDate[] = [...dates];
    datesToUpdate.splice(index, 1);
    updateDates(datesToUpdate);
  };

  // Do not show for read-only mode if there are no dates
  if (!isEditing && isEmpty(dates)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Dates"
      isEditing={isEditing}
      icon={<CalendarIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {dates.map((date, index) => {
            const isLastDateInput = index === dates.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "dates" && focusedField?.index === index;
            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <TypeSelect
                  items={dateTypeSelectItems}
                  initialItemId={date.label}
                  onSelectItem={(item) => onUpdateDate({ index, data: { label: item.value } })}
                  createNewItemTitle="Custom"
                />
                <div className="flex-1 mr-2">
                  <SimpleDateInput
                    name="date"
                    initialDateString={prettyPrintArbitraryDateString(date.value)}
                    onChangeDateString={(dateString) => {
                      onUpdateDate({ index, data: { value: dateString } });
                    }}
                    forwardedRef={
                      (isFocusedField && focusedDateRowInputRef) ||
                      (isLastDateInput && lastDateRowInputRef) ||
                      undefined
                    }
                    contactMetadataDispatch={contactMetadataDispatch}
                    contactMetadataValueMap={contactMetadataValueMap}
                  />
                </div>
                <RemoveDetailsItemButton
                  onClickButton={() => onRemoveDateAtIndex(index)}
                  tooltip="Remove Date"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-date" className="flex flex-row w-full">
            <TypeSelect
              items={dateTypeSelectItems}
              onSelectItem={(item) => onAddNewDate(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewDate()}
            >
              Add a new date
            </button>
          </dd>
        </>
      ) : (
        dates.map((date, index) => (
          <dd key={index}>
            <ContextualMenuField
              items={dateContextualItems}
              actionPayload={{
                fieldName: "dates",
                value: date,
                index,
              }}
            >
              <a
                href={buildGoogleCalendarEventLink(
                  date.label || "",
                  parse(date.value, "yyyy-MM-dd", new Date())
                )}
                target="_blank"
                rel="noreferrer"
                className="text-primary text-underline"
              >
                {prettyPrintArbitraryDateString(date.value)}
              </a>
              <div className="uppercase text-label">{date.label}</div>
            </ContextualMenuField>
          </dd>
        ))
      )}
    </BaseDetailsField>
  );
};

export default ArbitraryDatesField;
