import { BlockNoteEditor, PartialBlock } from "@blocknote/core";
import { Contact } from "@shared/models/Contact";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { IsReadOnly } from "@shared/models/types";
import { getFirstLastName, LocallyPersistedContactRow } from "core/helpers/contact";
import {
  ContactGroupRecipient,
  ContactRecipientOverride,
  ContactRecipientPreview,
  DraftBodyOverride,
  FlattenedContactRecipientList,
} from "core/types/userMessaging";
import { useLiveQuery } from "dexie-react-hooks";
import { RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { VirtuosoHandle } from "react-virtuoso";
import { getUniqueList } from "utils/array";

import { EmailDraftContext } from "@/components/email/EmailDraftProvider";
import {
  getAllMailMergeFields,
  getSendToEmailFromContact,
  isMailMerge,
} from "@/components/email/helpers";
import { getContactDb } from "@/database/contactDb";
import { getEmailDraftDb, LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

export function useFlattenedContactRecipientList(
  draft: LocallyPersistedEmailDraft,
): FlattenedContactRecipientList | undefined {
  const isMailMergeDraft = useMemo(() => isMailMerge(draft.body) || false, [draft.body]);

  const recipientEntityIds = useMemo(() => {
    const allContactIds: Set<string> = new Set();
    const allContactGroupIds: Set<string> = new Set();
    const allContactGroupWithContactIds: { [contactGroupId: string]: Set<string> } = {}; // user selected contacts from group
    let allExcludeContactIds: string[] = [];

    for (const recipients of [draft.to, draft.cc, draft.bcc]) {
      if (recipients) {
        for (const recipient of recipients) {
          if (recipient.type === "contact" || recipient.type === "prospect") {
            if (recipient.contactId) allContactIds.add(recipient.contactId);
          } else if (recipient.type === "contactGroup") {
            if (recipient.contactGroupId) {
              allExcludeContactIds.push(...(recipient.excludedContactIds || []));

              // if user selected specific contacts from the group, use these only, check again exclusions
              const contactIdsFromUserSelection = Object.keys(
                recipient.emailAddresses || {},
              ).filter((contactId) => !recipient.excludedContactIds.includes(contactId));

              if (recipient.emailAddresses && contactIdsFromUserSelection.length > 0) {
                for (const contactId of contactIdsFromUserSelection) {
                  if (!allContactGroupWithContactIds[recipient.contactGroupId]) {
                    allContactGroupWithContactIds[recipient.contactGroupId] = new Set();
                  }
                  allContactGroupWithContactIds[recipient.contactGroupId].add(contactId);
                }
              } else {
                // otherwise, use all contacts from the group by placing it in the group list
                allContactGroupIds.add(recipient.contactGroupId);
              }
            }
          }
        }
      }
    }

    return {
      allContactIds,
      allContactGroupIds,
      allContactGroupWithContactIds,
      allExcludedIds: new Set(allExcludeContactIds),
    };
  }, [draft.to, draft.cc, draft.bcc]);

  const data = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    const contacts = await frontendDb?.contacts.bulkGet([...recipientEntityIds.allContactIds]);
    const contactGroupsAllContacts = await frontendDb?.contactGroups.bulkGet([
      ...recipientEntityIds.allContactGroupIds,
    ]);

    const contactGroupsWithContactIds = Object.keys(
      recipientEntityIds.allContactGroupWithContactIds,
    );

    const contactGroupsUserSelectedContacts = await frontendDb?.contactGroups.bulkGet(
      contactGroupsWithContactIds,
    );

    const contactGroupToContacts: { [contactId: string]: LocallyPersistedContactRow } = {};
    const contactGroupToSortedContactIds: { [contactGroupId: string]: string[] } = {};
    const contactIndex: { [contactId: string]: LocallyPersistedContactRow } = {};

    const allContactGroups = [
      ...(contactGroupsAllContacts || []),
      ...(contactGroupsUserSelectedContacts || []),
    ];

    for (const contactGroup of allContactGroups) {
      if (!contactGroup) continue;
      const contacts = await frontendDb?.contacts.bulkGet(getUniqueList(contactGroup.contactIds));
      if (contacts) {
        for (const contact of contacts) {
          if (contact) {
            contactGroupToContacts[contact.id] = contact;
            contactIndex[contact.id] = contact;
          }
        }

        contactGroupToSortedContactIds[contactGroup.id] = getUniqueList(
          contacts
            .filter((contact) => contact && !recipientEntityIds.allExcludedIds.has(contact.id))
            .sort((a, b) => a!._surnameSort.localeCompare(b!._surnameSort))
            .map((contact) => contact!.id) as string[],
        );
      }
    }

    for (const contact of contacts || []) {
      if (contact) {
        contactIndex[contact.id] = contact;
      }
    }

    const contactGroupIndex: { [contactGroupId: string]: ContactGroupRowForDisplay } = {};
    for (const contactGroup of allContactGroups) {
      if (contactGroup) {
        contactGroupIndex[contactGroup.id] = contactGroup;
      }
    }

    return {
      contactIndex,
      contactGroupIndex,
      contactGroupToContacts,
      contactGroupToSortedContactIds,
    };
  }, [
    recipientEntityIds.allContactIds,
    recipientEntityIds.allContactGroupIds,
    recipientEntityIds.allContactGroupWithContactIds,
  ]);

  return useMemo(() => {
    if (!data) return undefined;

    const contactRecipients: ContactRecipientPreview[] = [];
    const contactGroupRecipients: ContactGroupRecipient[] = [];
    const contactIdsWithoutEmail: Set<string> = new Set();

    for (const recipients of [draft.to, draft.cc, draft.bcc]) {
      if (recipients) {
        for (const recipient of recipients) {
          if (recipient.type === "contact" || recipient.type === "prospect") {
            // add to contact without email list, user should manually enter email
            // this is most likely an anomaly, user shouldn't have been able to select a contact without email
            // since it won't be in the search index, but we place it in the list so user can correct it
            if (!recipient.email && recipient.contactId) {
              contactIdsWithoutEmail.add(recipient.contactId);
            }

            // if contact is found in the db
            if (recipient.contactId && data?.contactIndex[recipient.contactId]) {
              const contact = data.contactIndex[recipient.contactId];
              contactRecipients.push({
                ...recipient,
                contact,
              });
            } else if (!isMailMergeDraft) {
              // if not a mail merge draft - display as email only since contact could not be found or not defined
              contactRecipients.push(recipient);
            }
          } else if (
            recipient.type === "contactGroup" &&
            data?.contactGroupIndex[recipient.contactGroupId]
          ) {
            contactGroupRecipients.push(recipient);
          }
        }
      }
    }

    // sort contacts, contact groups by name, then sort contacts within each contact group
    contactRecipients.sort((a, b) => a.name.localeCompare(b.name));
    contactGroupRecipients.sort((a, b) => a.name.localeCompare(b.name));

    // get sorted contacts per group recipient
    const sortedGroupedContactList: ContactRecipientPreview[][] = [];
    for (const contactGroup of contactGroupRecipients) {
      const sortedContactRecipients = data.contactGroupToSortedContactIds[contactGroup.id].map(
        (contactId) => {
          const contact = data.contactGroupToContacts[contactId];
          const email =
            contactGroup.emailAddresses?.[contactId] || getSendToEmailFromContact(contact)?.value;

          // no email for this contact
          if (!email) contactIdsWithoutEmail.add(contactId);

          const recipient: ContactRecipientPreview = {
            id: `${contactGroup.id}_${contactId}`,
            name: getFirstLastName(contact),
            email: email || "",
            type: contact.isReadOnly === IsReadOnly.YES ? "prospect" : "contact",
            contact: {
              ...contact,
              id: `${contactGroup.id}_${contactId}`,
            },
          };

          return recipient;
        },
      );

      sortedGroupedContactList.push(sortedContactRecipients);
    }

    // place contact recipients at the top of the list

    const groupedLabels = contactGroupRecipients.map((group) => group.name);

    if (contactRecipients.length > 0) {
      sortedGroupedContactList.unshift(contactRecipients);
      groupedLabels.unshift("Contacts");
    }

    const groupCounts = sortedGroupedContactList.map((group) => group.length);

    const flattenedContactList = sortedGroupedContactList.reduce(
      (acc, group) => [...acc, ...group],
      [],
    );

    const flattenedUniqueContactList = [];
    const seenContactIds = new Set();
    for (const contact of flattenedContactList) {
      if (!seenContactIds.has(contact.id)) {
        seenContactIds.add(contact.id);
        flattenedUniqueContactList.push(contact);
      }
    }

    const missingMailMergeFields = getAllMailMergeFields(draft.body);
    const missingMailMergeFieldsByRecipientId: { [recipientId: string]: (keyof Contact)[] } = {};
    // generate list of contacts with missing mail merge fields
    for (const recipient of flattenedUniqueContactList) {
      const contact = recipient.contact;
      if (contact) {
        for (const field of missingMailMergeFields) {
          if (!contact[field]) {
            if (!missingMailMergeFieldsByRecipientId[recipient.id]) {
              missingMailMergeFieldsByRecipientId[recipient.id] = [];
            }
            missingMailMergeFieldsByRecipientId[recipient.id].push(field);
          }
        }
      }
    }

    return {
      flattenedContactList: flattenedUniqueContactList,
      missingMailMergeFieldsByRecipientId,
      groupedLabels,
      groupCounts,
      contactIdsWithoutEmail,
      isMailMerge: isMailMergeDraft,
    };
  }, [data, draft.bcc, draft.body, draft.cc, draft.to, isMailMergeDraft]);
}

export function useDraftBodyOverride(draftId: string) {
  const [draftBodyOverride, setDraftBodyOverride] = useState<DraftBodyOverride>({});

  const { editDraft } = useContext(EmailDraftContext);

  useEffect(() => {
    // better to call db directly since it only runs once, where using context (when list is initial undefined) may cause loop and miss the first render
    const emailDraftDb = getEmailDraftDb();
    emailDraftDb?.draft.get(draftId).then((draft) => {
      if (draft?.bodyOverride) {
        setDraftBodyOverride(draft.bodyOverride);
      }
    });
  }, []);

  useEffect(() => {
    // store to db when component dismounts
    return () => {
      if (Object.keys(draftBodyOverride).length > 0) {
        editDraft(draftId, { bodyOverride: draftBodyOverride });
      } else {
        editDraft(draftId, { bodyOverride: undefined });
      }
    };
  }, [draftBodyOverride, draftId, editDraft]);

  const onContentChange = useCallback(async (editor: BlockNoteEditor, recipientId: string) => {
    const body = editor.document;

    if (body) {
      setDraftBodyOverride((prev) => ({
        ...prev,
        [recipientId]: body as PartialBlock[],
      }));
    }
  }, []);

  return {
    draftBodyOverride,
    setDraftBodyOverride,
    onContentChange,
  };
}

export function useContactDataOverride(draftId: string) {
  const [contactDataOverride, setContactDataOverride] = useState<ContactRecipientOverride>({});
  const { editDraft } = useContext(EmailDraftContext);

  useEffect(() => {
    const emailDraftDb = getEmailDraftDb();
    emailDraftDb?.draft.get(draftId).then((draft) => {
      if (draft?.contactRecipientOverride) {
        setContactDataOverride(draft.contactRecipientOverride);
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      if (Object.keys(contactDataOverride).length > 0) {
        editDraft(draftId, { contactRecipientOverride: contactDataOverride });
      } else {
        editDraft(draftId, { contactRecipientOverride: undefined });
      }
    };
  }, [contactDataOverride, draftId]);

  const updateContactOverride = useCallback(
    (recipientId: string, data: Partial<Contact> & { id: string }) => {
      setContactDataOverride((prev) => ({
        ...prev,
        [recipientId]: data,
      }));
    },
    [],
  );

  return {
    contactDataOverride,
    updateContactOverride,
    setContactDataOverride,
  };
}

export function useJumpToContactInList<T>(
  list: T[],
  predicate: (item: T) => boolean,
  ref: RefObject<VirtuosoHandle>,
) {
  const visibleRangeRef = useRef<{ startIndex: number; endIndex: number }>();
  const setVisibleRange = useCallback(
    (range: { startIndex: number; endIndex: number }) => {
      visibleRangeRef.current = {
        startIndex: Math.min(range.startIndex - 1, 0),
        endIndex: Math.min(range.endIndex - 1, list.length - 1),
      };
    },
    [list.length],
  );

  const handleScroll = useCallback(
    (i: number) => {
      const item = list[i];
      if (predicate(item)) {
        const delta =
          (visibleRangeRef.current?.endIndex || 0) - (visibleRangeRef.current?.startIndex || 0);
        setVisibleRange({ startIndex: i, endIndex: i + delta });

        ref.current?.scrollToIndex(i);
        return true;
      }
      return false;
    },
    [list, predicate, ref, setVisibleRange],
  );

  const scrollToNext = useCallback(() => {
    const endIndex =
      typeof visibleRangeRef.current?.endIndex !== "undefined"
        ? visibleRangeRef.current.endIndex
        : 0;

    ref.current?.scrollToIndex(endIndex);

    for (let i = endIndex + 1; i < list.length; i++) {
      if (handleScroll(i)) return;
    }
  }, [handleScroll, list.length, ref]);

  const scrollToPrev = useCallback(() => {
    const startIndex =
      typeof visibleRangeRef.current?.startIndex !== "undefined"
        ? visibleRangeRef.current.startIndex
        : 0;

    ref.current?.scrollToIndex(startIndex);

    for (let i = startIndex - 1; i >= 0; i--) {
      if (handleScroll(i)) return;
    }
  }, [handleScroll, ref]);

  return {
    setVisibleRange,
    scrollToNext,
    scrollToPrev,
  };
}
