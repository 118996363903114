import { Contact, ContactRow } from "@shared/models/Contact";
import classNames from "clsx";
import { SortableKeys } from "core/helpers/contact";
import { FC, ReactElement } from "react";

import { useContactListItemBody } from "@/components/contacts/list/hooks";
import HighlightText from "@/components/HighlightText";

const ContactItemFragment: FC<{
  description?: string | ReactElement;
  contact: ContactRow | Contact;
  sortKey: SortableKeys;
  namesOnly?: boolean;
  isProspect?: boolean;
}> = ({ contact, description, sortKey, isProspect, namesOnly }) => {
  const { showCompanyNameOnly, contactDescription } = useContactListItemBody({
    contact,
    sortKey,
  });

  return (
    <>
      <p className="text-primary m-0 truncate">
        <span
          className={classNames(
            (sortKey === "givenName" || sortKey === "_givenNameSort") && "font-bold",
          )}
        >
          <HighlightText value={contact.givenName} matchPhrase={false} />
        </span>{" "}
        <span
          className={classNames(
            (sortKey === "surname" || sortKey === "_surnameSort") && "font-bold",
          )}
        >
          <HighlightText value={contact.surname} matchPhrase={false} />
        </span>
        {!namesOnly && !contact.givenName?.trim() && !contact.surname?.trim() && (
          <span className="-mt-2 italic">
            {showCompanyNameOnly ? (
              <HighlightText value={contact.companyName || ""} />
            ) : contact.emails && contact.emails.length > 0 ? (
              <HighlightText value={contact.emails[0].value || ""} />
            ) : contact.phoneNumbers && contact.phoneNumbers.length > 0 ? (
              <HighlightText value={contact.phoneNumbers[0].value || ""} />
            ) : (
              "No Name"
            )}
          </span>
        )}
      </p>
      {!namesOnly && (description || contactDescription)}
      {!namesOnly &&
        isProspect &&
        (contact.givenName || contact.surname) &&
        contact.emails &&
        contact.emails.length > 0 && (
          <span className="text-secondary">
            <HighlightText value={contact.emails[0].value || ""} />
          </span>
        )}
    </>
  );
};

export default ContactItemFragment;
