import { Dialog } from "@headlessui/react";
import { LinkIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import Link from "next/link";
import type { Dispatch, FC, SetStateAction } from "react";
import { useRef } from "react";
import * as yup from "yup";

import Button from "@/components/core/Button";
import { QuestionCircleSolidIcon } from "@/components/core/Icon";
import Modal from "@/components/core/Modal";
import { FormTextInput } from "@/components/core/TextInput";
import { useCreateIntegrationFromIcloudPasswordMutation } from "@/integrations/user/api";

const SyncICloudModal: FC<{
  isModalOpen: boolean;
  setIsClosed: Dispatch<SetStateAction<boolean>>;
  email?: string | undefined;
  onSuccess?: () => void;
}> = ({ isModalOpen, setIsClosed, email = "", onSuccess }) => {
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const appSpecPassInputRef = useRef<HTMLInputElement | null>(null);
  const [createIntegrationFromIcloudPassword] = useCreateIntegrationFromIcloudPasswordMutation();

  return (
    <Modal
      initialFocusRef={email ? appSpecPassInputRef : emailInputRef}
      isModalOpen={isModalOpen}
      setIsClosed={setIsClosed}
      className="mx-auto w-full sm:max-w-4xl"
    >
      <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-primary text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setIsClosed(false)}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col p-12 max-w-sm items-center justify-center ">
          <img
            src="/logos/icloud-logo-icon.svg"
            className="mb-2.5 h-10 w-10 text-indigo-400"
            alt="iCloud logo"
          />
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100 mb-3"
          >
            Sync with iCloud
          </Dialog.Title>
          <div className="mb-5">
            <p>Use your iCloud email and</p>
            <strong> app-specific password</strong>
          </div>
          <Formik
            initialValues={{ email, password: "" }}
            validationSchema={yup.object()}
            onSubmit={async (values, actions) => {
              const { setSubmitting } = actions;
              const { email, password } = values;
              await createIntegrationFromIcloudPassword({
                email,
                appSpecificPassword: password,
              });
              setSubmitting(false);
              setIsClosed(false);
              if (onSuccess) {
                onSuccess();
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className="text-left w-72 max-w-full">
                {!email ? (
                  <FormTextInput
                    className="mb-2.5"
                    name="email"
                    label="Email"
                    forwardedRef={emailInputRef}
                  />
                ) : (
                  <FormTextInput
                    className="mb-2.5"
                    name="email"
                    label="Email"
                    value={email}
                    readOnly
                    forwardedRef={emailInputRef}
                  />
                )}
                <FormTextInput
                  labelPostfix={
                    <button className="lg:hidden">
                      <QuestionCircleSolidIcon className="cursor-pointer z-50" />
                    </button>
                  }
                  className="mb-5"
                  name="password"
                  label="App-specific password"
                  placeholder="xxxx-xxxx-xxxx-xxxx"
                  pattern="[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}"
                  autoComplete="off"
                  forwardedRef={appSpecPassInputRef}
                />
                <Button full disabled={isSubmitting} className="mx-auto" type="submit">
                  <div className="mx-auto">Sync my iCloud</div>
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="flex flex-col overflow-y-auto py-6 sm:border-l sm:border-t-0 border-t flex-grow items-center text-primary">
          <p className="font-semibold">Create iCloud app-specific password</p>
          <div className="relative mt-6 flex-1 px-4 sm:px-6">
            <div className="flex mb-9">
              <div className="flex-1 text-center">
                <p className="">
                  <LinkIcon className="h-4 w-4 ml-2 inline" /> Sign-in to &nbsp;
                  <Link
                    href="https://appleid.apple.com"
                    className="text-indigo-600 underline"
                    target="_blank"
                  >
                    https://appleid.apple.com
                  </Link>
                </p>
                <img
                  className="max-w-[350px] inline border-primary mt-2"
                  alt=""
                  src="/apple-id.png"
                />
              </div>
            </div>
            <div className="flex mb-8">
              <div className="flex-1">
                <p>
                  In <strong>Security</strong>, click on <Link href="">App-Specific Passwords</Link>{" "}
                  and generate a password for TitleDock.
                </p>
                <p>
                  <strong>This password will only be accessible once</strong>, be sure to copy it
                  before closing the window.
                </p>
                <img className="" alt="" src="/apple-specific-password.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default SyncICloudModal;
