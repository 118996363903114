import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import classNames from "clsx";
import { getFullName } from "core/helpers/contact";
import { EmailRecipient } from "core/types/userMessaging";
import { useLiveQuery } from "dexie-react-hooks";
import { FC, memo, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { GroupedVirtuoso } from "react-virtuoso";

import { useGroupedListControls } from "@/components/contacts/list/hooks";
import { ContactData } from "@/components/contacts/v2/types";
import Avatar from "@/components/core/Avatar";
import Button, { ButtonVariant } from "@/components/core/Button";
import { CheckIcon, TagSolidIcon } from "@/components/core/Icon";
import { PopoverContent } from "@/components/core/Popover";
import {
  EmailSelection,
  getEmailListFromContacts,
  getEmailRecipientFromEmailSelection,
} from "@/components/email/helpers";
import HighlightText from "@/components/HighlightText";
import { getContactDb } from "@/database/contactDb";

const EmailRecipientPopoverContent: FC<{
  selectedRecipients: EmailRecipient[];
  searchResults: { contactIds: string[]; prospectIds: string[]; contactGroupIds: string[] };
  input: string;
  setOpenPopover: (open: boolean) => void;
  containerHeight: number;
  setSelectedRecipients: (recipients: EmailRecipient[]) => void;
}> = ({
  setSelectedRecipients,
  searchResults,
  containerHeight,
  input,
  selectedRecipients,
  setOpenPopover,
}) => {
  const [selected, setSelected] = useState<EmailSelection | undefined>();

  const entities = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    const { contactIds, prospectIds, contactGroupIds } = searchResults;

    const [contacts, prospects, contactGroups] = await Promise.all([
      frontendDb?.contacts.bulkGet(contactIds),
      frontendDb?.contacts.bulkGet(prospectIds),
      frontendDb?.contactGroups.bulkGet(contactGroupIds),
    ]);

    const data = {
      contacts: contacts?.filter(Boolean),
      // .sort((a, b) => (a?._surnameSort || "").localeCompare(b?._surnameSort || "")) || [],
      prospects: prospects?.filter(Boolean),
      // .sort((a, b) => (a?._surnameSort || "").localeCompare(b?._surnameSort || "")) || [],
      contactGroups: contactGroups?.filter(Boolean),
      // .sort((a, b) => (a?.name || "").localeCompare(b?.name || "")) || [],
    } as {
      contacts: ContactData[];
      prospects: ContactData[];
      contactGroups: ContactGroupRowForDisplay[];
    };

    const total = data.contacts.length + data.prospects.length + data.contactGroups.length;
    if (total > 0) {
      setOpenPopover(true);
    } else {
      setOpenPopover(false);
    }

    const resultList: EmailSelection[] = [];

    const labels: string[] = [];
    const groupCounts: number[] = [];

    const contactEmails = getEmailListFromContacts(data.contacts, "contact");
    if (contactEmails.length > 0) {
      labels.push("Contacts");
      groupCounts.push(contactEmails.length);
      resultList.push(...contactEmails);
    }
    const prospectEmails = getEmailListFromContacts(data.prospects, "prospect");
    if (prospectEmails.length > 0) {
      labels.push("Prospects");
      groupCounts.push(prospectEmails.length);
      resultList.push(...prospectEmails);
    }

    if (data.contactGroups.length > 0) {
      labels.push("Groups");
      groupCounts.push(data.contactGroups.length);
      resultList.push(
        ...data.contactGroups.map((group) => ({
          row: group,
          rowType: "contactGroup" as const,
          id: group.id,
        })),
      );
    }

    setSelected(resultList[0]);

    return {
      resultList,
      groupCounts,
      scrollIndex: groupCounts.map((_, i) => groupCounts.slice(0, i).reduce((a, b) => a + b, 0)),
      labels,
      total: contactEmails.length + prospectEmails.length + data.contactGroups.length,
    };
  }, [searchResults]);

  const { onClickItem, ref, currentItemIndex, setCurrentItemIndex } = useGroupedListControls({
    selectedItem: selected,
    items: entities?.resultList || [],
    groupCounts: entities?.groupCounts || [],
    onKeySelected: setSelected,
    enableDirKeys: true,
  });

  const onSelectRecipient = useCallback(
    async (
      event: MouseEvent<HTMLElement> | KeyboardEvent,
      recipient: EmailSelection | undefined,
      index?: number,
    ) => {
      if (!recipient || selectedRecipients.some((r) => r.id === recipient.id)) {
        return;
      }

      onClickItem(event, recipient, index);
      const newEmailRecipient = await getEmailRecipientFromEmailSelection(recipient);
      if (newEmailRecipient) {
        setSelectedRecipients([...selectedRecipients, newEmailRecipient]);
      }
      setSelected(undefined);
      setCurrentItemIndex(0);
    },
    [onClickItem, selectedRecipients, setCurrentItemIndex, setSelectedRecipients],
  );

  useHotkeys(
    "enter",
    (e) => {
      onSelectRecipient(e, selected);
    },
    {
      enabled: Boolean(entities?.total || input),
      enableOnFormTags: true,
      enableOnContentEditable: true,
    },
  );

  const buttonControlRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => setSelected(undefined);
  }, []);

  useEffect(() => {
    setSelected(undefined);
    setCurrentItemIndex(0);
  }, [setCurrentItemIndex, searchResults]);

  if (!entities || entities.total === 0) {
    return null;
  }

  return (
    <PopoverContent
      initialFocus={-1}
      className="relative z-40 rounded-md space-y-2"
      style={{
        height: `${Math.min(entities?.total * 56 + 30, containerHeight)}px`,
      }}
    >
      <GroupedVirtuoso
        ref={ref}
        tabIndex={-1}
        className="w-96 flex-1 h-full bg-secondary text-secondary shadow-xl rounded-md"
        groupCounts={entities?.groupCounts}
        groupContent={(index) => {
          return (
            <div
              key={index}
              className={classNames(
                "flex px-4 py-1 bg-secondary text-sm border-color-primary",
                index !== 2 && "border-b",
                index === 1 && "border-t",
              )}
            >
              <div className="flex-1">{entities.labels[index]}</div>
              <div className="text-xs text-secondary border border-color-primary px-2 rounded-md">
                {entities.groupCounts[index]}
              </div>
            </div>
          );
        }}
        itemContent={(index) => {
          const item = entities.resultList[index];
          const isSelected = selectedRecipients.some((recipient) => {
            return recipient.id === item.id;
          });

          return (
            <div
              onClick={(e) => {
                onSelectRecipient(e, item, index);
              }}
              className={classNames(
                "p-2 flex cursor-pointer w-full items-center border-l-2 border-transparent",
                isSelected
                  ? "grayscale opacity-80 bg-zinc-300 dark:bg-zinc-700"
                  : "hover:bg-zinc-200 dark:hover:bg-zinc-800",
                currentItemIndex === index && "border-color-primary bg-zinc-200 dark:bg-zinc-800",
              )}
            >
              {item.rowType === "contact" || item.rowType === "prospect" ? (
                <>
                  <Avatar
                    firstName={item.row.givenName}
                    lastName={item.row.surname}
                    isProspect={item.rowType === "prospect"}
                    photos={item.row.photos}
                    className="h-8 w-8 mr-4 border border-color-primary"
                  />
                  <div className="flex-1 flex flex-col text-sm overflow-hidden">
                    <div className="flex flex-1">
                      <div className="flex-1 truncate">
                        <HighlightText value={getFullName(item.row)} highlight={input} />
                      </div>
                      <div className="text-label">{`(${[item.isDefault ? "default" : "", item.emailType].filter(Boolean).join(", ")})`}</div>
                    </div>
                    <div className="truncate flex">
                      <div className="flex-1 ">
                        <HighlightText value={item.email} highlight={input} />
                      </div>
                      {isSelected && <CheckIcon />}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="h-8 w-8 mr-5 pl-2 items-center flex justify-center ">
                    <TagSolidIcon size="lg" />
                  </div>
                  <div className="text-sm w-full flex items-center overflow-hidden">
                    <div className="flex-1 truncate">
                      <HighlightText value={item.row?.name} highlight={input} />
                    </div>
                    <div className="text-xs text-secondary border border-color-primary px-2 rounded-md mx-2">
                      {Object.keys(item.row?.contactIds).length}
                    </div>
                  </div>
                  {isSelected && <CheckIcon />}
                </>
              )}
            </div>
          );
        }}
      />

      {entities.labels.length > 1 && (
        <div
          className="text-secondary flex justify-center z-50 items-center space-x-2"
          ref={buttonControlRef}
        >
          {entities.labels.map((label, index) => (
            <Button
              full
              small
              variant={ButtonVariant.Secondary}
              key={index}
              hotkey={["alt+" + (index + 1)]}
              hotkeyOnFormTags
              onClick={(e) => {
                e.preventDefault();
                ref.current?.scrollToIndex({ index: entities.scrollIndex[index] });
              }}
            >
              {label} {entities.groupCounts[index] > 0 && `(${entities.groupCounts[index]})`}
            </Button>
          ))}
        </div>
      )}
    </PopoverContent>
  );
};

export default memo(EmailRecipientPopoverContent);
