import { Menu, Transition } from "@headlessui/react";
import classNames from "clsx";
import type { FC, PropsWithChildren} from "react";
import React, { useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { CheckIcon } from "./Icon";

type MenuButtonProps = {
  menuItems: MenuButtonMenuItem[];
  currentId?: string; // Define if we wish to highlight selected item
  small?: boolean;
  className?: string;
};

export interface MenuButtonMenuItem {
  id: string;
  title: string;
  onClickHandler: (id: string) => void;
}

const MenuButton: FC<PropsWithChildren<MenuButtonProps>> = ({
  menuItems,
  currentId,
  small,
  className,
  children,
}) => {
  const popperDivRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  });

  const sizeClassNames = small
    ? "px-2 py-1 text-xs rounded"
    : "px-2.5 py-1.5 sm:px-3 sm:py-2 text-sm rounded-md";

  const menuItemsDropdown = useMemo(() => {
    return (
      <Menu.Items
        static
        className="z-50 overflow-hidden rounded-md shadow-lg bg-primary divide-primary-y ring-primary focus:outline-none"
      >
        {menuItems.map((menuItem) => {
          const isSelected = currentId === menuItem.id;
          return (
            <Menu.Item key={menuItem.id}>
              <div
                className={classNames(
                  "menu-item relative",
                  isSelected && "text-zinc-800 dark:text-zinc-300"
                )}
                onClick={() => menuItem.onClickHandler(menuItem.id)}
              >
                {menuItem.title}

                {/* Checkmark (if selected) */}
                {isSelected && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <CheckIcon className="text-purple-600" aria-hidden="true" />
                  </span>
                )}
              </div>
            </Menu.Item>
          );
        })}
      </Menu.Items>
    );
  }, [menuItems, currentId]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button
            ref={setReferenceElement}
            className={classNames(
              "inline-flex items-center align-middle justify-center font-medium disabled:opacity-50 disabled:cursor-default space-x-1 text-zinc-700 bg-white border border-zinc-300 dark:text-zinc-300 dark:bg-zinc-800 hover:bg-zinc-50 dark:hover:bg-zinc-700 dark:border-zinc-600",
              sizeClassNames,
              className
            )}
          >
            {children}
          </Menu.Button>

          <div ref={popperDivRef} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperDivRef.current)}
              afterLeave={() => setPopperElement(null)}
            >
              {menuItemsDropdown}
            </Transition>
          </div>
        </>
      )}
    </Menu>
  );
};

export default MenuButton;
