import { Contact, ContactRow } from "@shared/models/Contact";
import classNames from "clsx";
import { getSingleInitial } from "core/helpers/contact";
import { FC } from "react";
import { useMemo, useState } from "react";

import { stringToHslColor } from "@/helpers/color";
import { getDefaultPhoto, getPhotoUrl } from "@/helpers/contact";
import { useDelegatedUser } from "@/hooks/useDelegatedUser";
import { useGetUserQuery } from "@/integrations/user/api";

import useDarkMode from "../../hooks/useDarkMode";

type AvatarNameProps =
  | {
      displayName?: string;
      firstName?: string;
      lastName?: string;
      contact?: undefined;
    }
  | {
      displayName?: undefined;
      firstName?: undefined;
      lastName?: undefined;
      contact?: Contact | ContactRow;
    };

type AvatarImageProps =
  | {
      imageUrl?: string;
      photos?: undefined;
    }
  | {
      imageUrl?: undefined;
      photos?: Contact["photos"];
    };

type AvatarProps = AvatarImageProps &
  AvatarNameProps & {
    className?: string;
    isProspect?: boolean;
    onClickToSelectItem?: () => void;
    isMultiSelected?: boolean;
  };

const Avatar: FC<AvatarProps> = ({
  displayName,
  firstName,
  lastName,
  imageUrl,
  photos,
  isProspect,
  className = "text-xl h-14 w-14",
  onClickToSelectItem,
  isMultiSelected,
}) => {
  const { delegatedUser } = useDelegatedUser();
  const delegatedUserId = delegatedUser?.delegatedUserId || null;
  const { data: userData } = useGetUserQuery();
  const userId = delegatedUserId || userData?.user?.id || null;

  const [isDarkMode] = useDarkMode();
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageSrc = useMemo(() => {
    if ((photos?.length || 0) > 0) {
      const defaultPhoto = getDefaultPhoto(photos);
      return getPhotoUrl(defaultPhoto, userId || "");
    }
    return imageUrl;
  }, [imageUrl, photos, userId]);

  const { backgroundHslColor, initials } = useMemo(() => {
    let initials: string;
    let fullName: string;
    if (displayName) {
      initials = displayName.split(" ").map(getSingleInitial).join("");
      fullName = displayName;
    } else {
      initials = getSingleInitial(firstName) + getSingleInitial(lastName);
      fullName = [firstName, lastName].join(" ");
    }

    const backgroundHslColor = isProspect
      ? undefined
      : fullName
        ? stringToHslColor(fullName, isDarkMode ? 20 : 30, isDarkMode ? 30 : 70)
        : "rgb(209, 213, 219)";

    return {
      initials,
      backgroundHslColor,
    };
  }, [displayName, firstName, isDarkMode, isProspect, lastName]);

  return (
    <div
      className="flex group relative"
      onClick={(e) => {
        e.stopPropagation();
        if (onClickToSelectItem) onClickToSelectItem();
      }}
    >
      {!imageLoaded || !imageSrc ? (
        initials ? (
          <span
            className={classNames(
              "inline-flex items-center text-center mx-auto justify-center rounded-full font-medium leading-none text-white uppercase",
              className,
              isProspect && "bg-cyan-600 dark:bg-cyan-900",
            )}
            style={{ backgroundColor: backgroundHslColor }}
          >
            {!Boolean(isMultiSelected) && (
              <span
                className={classNames(
                  onClickToSelectItem && "group-hover:opacity-0",
                  "delay-50 transition-all opacity-100 ease-in-out duration-150",
                )}
              >
                {initials}
              </span>
            )}
          </span>
        ) : (
          <span
            className={classNames(
              "inline-block text-center mx-auto rounded-full overflow-hidden",
              isProspect
                ? "bg-cyan-600 dark:bg-cyan-900 shadow-inner"
                : "bg-zinc-200 dark:bg-zinc-800",
              className,
            )}
          >
            <svg className="w-full h-full text-secondary" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        )
      ) : null}
      <img
        referrerPolicy="no-referrer"
        className={classNames(
          "inline-block rounded-full",
          imageLoaded && imageSrc ? "inline-block" : "hidden",
          Boolean(isMultiSelected) && "opacity-30",
          className,
        )}
        onLoad={() => {
          setImageLoaded(true);
        }}
        onError={() => setImageLoaded(false)}
        src={imageSrc}
        alt="profile avatar"
      />
      {onClickToSelectItem && (
        <input
          type="checkbox"
          className={classNames(
            "h-5 w-5 border-gray-300 text-blue-500 rounded-md absolute left-0 right-0 top-0 bottom-0 m-auto",
            !Boolean(isMultiSelected) &&
              "scale-0 duration-150 group-hover:scale-100 delay-150 transition transform",
          )}
          onChange={(e) => {
            e.stopPropagation();
            onClickToSelectItem();
          }}
          checked={Boolean(isMultiSelected)}
        />
      )}
    </div>
  );
};

export default Avatar;
