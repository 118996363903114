import { DdbBoolean } from "@shared/models/types";
import type { UserMessageDraft } from "core/types/userMessaging";
import { Dexie, Table } from "dexie";

export const EMAIL_DRAFT_DB_NAME = "titledock-emailDraft-v1.0";

export type LocallyPersistedEmailDraft = UserMessageDraft &
  (
    | {
        _isOpenedAt: number; // 0 for unopened, Date.now() for opened
        _isMinimized: DdbBoolean.NO;
        _isFullScreened: DdbBoolean.NO;
      }
    | {
        _isOpenedAt: number;
        _isMinimized: DdbBoolean.YES;
        _isFullScreened: DdbBoolean.NO;
      }
    | {
        _isOpenedAt: number;
        _isMinimized: DdbBoolean.NO;
        _isFullScreened: DdbBoolean.YES;
      }
  );

class EmailDraftDb extends Dexie {
  draft!: Table<LocallyPersistedEmailDraft>;

  constructor() {
    super(EMAIL_DRAFT_DB_NAME);
    this.version(2).stores({
      draft: `id, createdAt, _isOpenedAt, _isMinimized, _isFullScreened, isTracked, sendAt, *_toEmailList, *_ccEmailList, *_bccEmailList`,
    });
  }
}

export default EmailDraftDb;

export let emailDraftDb: EmailDraftDb | undefined = undefined;

export function getEmailDraftDb(): EmailDraftDb | undefined {
  if (!emailDraftDb) {
    emailDraftDb = new EmailDraftDb();
  }
  return emailDraftDb;
}
