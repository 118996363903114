import { PartialBlock } from "@blocknote/core";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { DdbBoolean, Email, IsDefault, IsReadOnly } from "@shared/models/types";
import { getFirstLastName, getFullName, LocallyPersistedContactRow } from "core/helpers/contact";
import { EmailRecipient, UserMessageDraft, UserMessageType } from "core/types/userMessaging";
import uuid from "utils/uuid";

import { ContactData } from "@/components/contacts/v2/types";
import { getContactDb } from "@/database/contactDb";
import { LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

export type EmailSelection =
  | {
      id: string;
      email: string;
      emailType?: Email["type"];
      isDefault?: boolean;
      row: ContactData;
      rowType: "contact";
    }
  | {
      id: string;
      email: string;
      emailType?: Email["type"];
      isDefault?: boolean;
      row: ContactData;
      rowType: "prospect";
    }
  | {
      id: string;
      email?: undefined;
      emailType?: Email["type"];
      isDefault?: boolean;
      row: ContactGroupRowForDisplay;
      rowType: "contactGroup";
      include?: { [contactId: string]: string[] };
      exclude?: { [contactId: string]: string[] };
    };
export function getEmailListFromContacts(contacts: ContactData[], rowType: "contact" | "prospect") {
  const emailList: EmailSelection[] = [];

  for (const contact of contacts) {
    const uniqueEmails = new Set<string>();
    let defaultEmail: EmailSelection | undefined;
    const emails: EmailSelection[] = [];

    for (const email of contact.emails || []) {
      const val = email.value?.toLowerCase().trim();
      if (uniqueEmails.has(val)) continue;
      uniqueEmails.add(val);

      if (email.isDefault === IsDefault.YES && !defaultEmail) {
        defaultEmail = {
          id: uuid(val),
          email: val,
          row: contact,
          emailType: email.type,
          isDefault: email.isDefault === IsDefault.YES,
          rowType,
        };
      } else {
        emails.push({
          id: uuid(val),
          email: val,
          row: contact,
          emailType: email.type,
          isDefault: email.isDefault === IsDefault.YES,
          rowType,
        });
      }
    }

    if (defaultEmail) {
      emails.unshift(defaultEmail);
    }

    emailList.push(...emails);
  }

  return emailList;
}

export function getEmailRecipientLabel(recipient: EmailRecipient) {
  if (recipient.type === "contact" || recipient.type === "prospect") {
    return `${recipient.name} <${recipient.email}>`;
  }

  return recipient.name;
}

export async function getEmailRecipientFromEmailSelection(
  emailSelection: EmailSelection,
): Promise<EmailRecipient> {
  if (emailSelection.rowType === "contact" || emailSelection.rowType === "prospect") {
    return {
      type: emailSelection.rowType,
      id: emailSelection.id,
      contactId: emailSelection.row.id,
      name: getFullName(emailSelection.row),
      email: emailSelection.email,
    };
  }

  if (emailSelection.rowType === "contactGroup") {
    // prefill email addresses based on contacts in the group

    const frontendDb = getContactDb();
    const contacts = await frontendDb?.contacts
      .where("id")
      .anyOf(emailSelection.row.contactIds || [])
      .toArray();

    if (contacts && contacts.length > 0) {
      const emailAddresses: { [contactId: string]: string } = {};
      for (const contact of contacts) {
        const email = getSendToEmailFromContact(contact);
        if (!email) continue;

        emailAddresses[contact.id] = email.value;
      }

      return {
        type: emailSelection.rowType,
        id: emailSelection.id,
        name: emailSelection.row.name,
        contactGroupId: emailSelection.row.id,
        excludedContactIds: [],
        emailAddresses,
      };
    }
  }

  return {
    type: emailSelection.rowType,
    id: emailSelection.id,
    name: emailSelection.row.name,
    contactGroupId: emailSelection.row.id,
    excludedContactIds: [],
    emailAddresses: {},
  };
}

export function getRfc5322FromContact(contact: ContactData, email: string) {
  return getRfc5322FromNameEmail(getFirstLastName(contact), email);
}

export function getRfc5322FromNameEmail(name: string, email: string) {
  const nameStr = name.trim();
  if (!nameStr) return email.trim().toLowerCase();
  return `${nameStr} <${email.trim().toLowerCase()}>`;
}

export function getRfc5322FromEmailRecipient(recipients: EmailRecipient[]) {
  const recipientList = recipients.map((recipient) => {
    if (recipient.type === "contact" || recipient.type === "prospect") {
      return getRfc5322FromNameEmail(recipient.name, recipient.email);
    }

    if (recipient.type === "contactGroup") {
      // user has gone thru contact selection in the group
      return Object.values(recipient.emailAddresses).join(", ");
    }
  });

  return recipientList.join(",");
}

export function getEmailType(email: Email) {
  if (!email) return undefined;
  const type = email.type?.trim().toLowerCase();

  return type !== "internet" ? type : undefined;
}

export function isMailMerge(body: PartialBlock | PartialBlock[]) {
  try {
    function isPartialBlockMailMerge(partialBlock: PartialBlock) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (partialBlock.type === "contactFieldPath") {
        return true;
      }
    }
    if (Array.isArray(body)) {
      for (const block of body) {
        if (isPartialBlockMailMerge(block)) {
          return true;
        }
        if (block.content && isMailMerge(block.content as PartialBlock[])) {
          return true;
        }
        if (block.children && isMailMerge(block.children)) {
          return true;
        }
      }
    } else {
      return isPartialBlockMailMerge(body);
    }
    return false;
  } catch (e) {
    console.log(e);

    return false;
  }
}

export function getAllMailMergeFields(body: PartialBlock[]) {
  let fields = new Set<keyof ContactData>();

  try {
    for (const block of body || []) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (block.type === "contactFieldPath") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fields.add(block.props.field);
      }
      if (block.content) {
        fields = new Set([...fields, ...getAllMailMergeFields(block.content as PartialBlock[])]);
      }
      if (block.children) {
        fields = new Set([...fields, ...getAllMailMergeFields(block.children)]);
      }
    }
  } catch (e) {
    console.log(body);
  }

  return fields;
}

export function renderMailMerge(body: PartialBlock[], contact: Partial<ContactData>) {
  let missingFields = new Set<keyof ContactData>();

  for (const [i, block] of body.entries()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (block && block.type === "contactFieldPath") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const field = block.props.field;

      if (
        typeof contact[field as keyof ContactData] === "undefined" ||
        !contact[field as keyof ContactData]
      ) {
        missingFields.add(field as keyof ContactData);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        block.props.isFieldMissing = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        block.props.contactId = contact.id;
        continue;
      }

      const value = contact[field as keyof ContactData] || "";

      let styles = null;
      if (i > 0) {
        // has previous text
        if (
          body[i - 1] &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          body[i - 1].type === "text" &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Object.keys(body[i - 1].styles || {}).length > 0
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          styles = body[i - 1].styles;
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (
        !styles &&
        body[i + 1] &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        body[i + 1].type === "text" &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.keys(body[i + 1].styles || {}).length > 0
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        styles = body[i + 1].styles;
      }

      body[i] = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: "text",
        text: String(value).trim() || "",
        // inherit the style from the adjacent text
        styles: styles || {},
      };
    } else if (block.content) {
      const result = renderMailMerge(block.content as PartialBlock[], contact);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      block.content = result.body;
      missingFields = new Set([...missingFields, ...result.missingFields]);
    } else if (block.children) {
      const result = renderMailMerge(block.children, contact);
      block.children = result.body;
      missingFields = new Set([...missingFields, ...result.missingFields]);
    }
  }

  return { body, missingFields };
}

// todo: add support for last used email
export function getSendToEmailFromContact(contact: ContactData) {
  if (!contact.emails || contact.emails.length === 0) return;

  const defaultEmail = contact.emails.find((email) => email.isDefault === IsDefault.YES);
  return defaultEmail?.value ? defaultEmail : contact.emails[0];
}

export function getFromLineFromEmailDraft(draft: UserMessageDraft) {
  if (draft.from) {
    return getRfc5322FromNameEmail(draft.from.name || "", draft.from.email);
  }

  return "";
}

export function getEmailRecipientFromContact(contact: ContactData): EmailRecipient | undefined {
  const email = getSendToEmailFromContact(contact);
  if (email) {
    return {
      contactId: contact.id,
      email: email.value,
      id: contact.id,
      name: getFirstLastName(contact),
      type:
        (contact as LocallyPersistedContactRow).isReadOnly === IsReadOnly.YES
          ? "prospect"
          : "contact",
    };
  }
}

export const defaultBody: PartialBlock[] = Array.from({ length: 4 }, () => ({
  children: [],
  content: [],
  id: uuid(),
  props: {
    textColor: "default",
    backgroundColor: "default",
    textAlignment: "left",
  },
  type: "paragraph",
}));

export function getNewEmailDraftFromContact(
  contact: ContactData,
  defaultValues: Partial<LocallyPersistedEmailDraft> = {},
): LocallyPersistedEmailDraft | undefined {
  const emailRecipient = getEmailRecipientFromContact(contact);
  if (!emailRecipient) return;

  const { subject = "", body = defaultBody, to = [], cc = [], bcc = [], ...rest } = defaultValues;

  return {
    ...rest,
    id: uuid(),
    createdAt: Date.now(),
    updatedAt: Date.now(),
    subject,
    type: UserMessageType.EMAIL,
    to: [emailRecipient, ...to],
    cc,
    bcc,
    body,
    _isOpenedAt: Date.now(),
    _isMinimized: DdbBoolean.NO,
    _isFullScreened: DdbBoolean.NO,
  };
}
