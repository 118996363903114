import { AppState } from "../redux/store";

export const selectSelectedContactGroupId = (state: AppState) =>
  state.contactGroup.selectedContactGroupId;

export const selectMultiSelectedContactGroups = (state: AppState) =>
  state.contactGroup.multiSelectedContactGroups;

export const selectIsContactGroupCreateDialogOpen = (state: AppState) =>
  state.contactGroup.isContactGroupCreateDialogOpen;

export const selectIsDeleteContactGroupAlertOpen = (state: AppState) =>
  state.contactGroup.isDeleteContactGroupAlertOpen;
