import { getSortedContacts, SortableKeys } from "core/helpers/contact";
import { getContactLocations } from "core/helpers/contactLocation";
import { LocationState } from "core/types/contactSearch";
import { Document } from "core/types/flexsearch";
import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ContactRow } from "services/src/shared/models/Contact";

import { getContactDb } from "@/database/contactDb";

export function useContactMsaIndex() {
  return useLiveQuery(async () => {
    const frontend = getContactDb();
    const msaIndexRows = await frontend?.msaIdIndex.toArray();
    const msaCityIndexRows = await frontend?.msaCityIndex.toArray();

    const msaIdIndex: { [stateKey: string]: { [msa: string]: string } } = {};
    for (const row of msaIndexRows || []) {
      msaIdIndex[row.id] = row.data;
    }

    const msaCityIndex: { [msa: string]: { city: string; stateKey: string }[] } = {};
    for (const row of msaCityIndexRows || []) {
      msaCityIndex[row.id] = row.data;
    }

    return {
      msaIdIndex,
      msaCityIndex,
    };
  });
}

const createSearchIndex = () => {
  return new window.FlexSearch.Document<LocationState>({
    cache: 1000,
    async: true,
    worker: true,
    tokenize: "full",
    document: {
      id: "id",
      index: [
        {
          field: "name",
        },
      ],
    },
  });
};

let contactLocationSearch: Document<LocationState>;

export function useLocationsAndContacts(query: string, sortKey: SortableKeys = "_surnameSort") {
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const mappableLocations = useLiveQuery(async () => {
    const frontendDb = getContactDb();
    const result = await frontendDb?.contactLocationCache.get("mappableLocations");
    return result?.data;
  });

  const isLoaded = typeof mappableLocations !== "undefined";

  const msaIndex = useContactMsaIndex();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { contactsByStateCity, sortedStateKeys, sortedStateCityKeys, placeKeyedLocation } =
    mappableLocations || {
      contactsByStateCity: {},
      sortedStateKeys: [],
      sortedStateCityKeys: {},
      placeKeyedLocation: {},
    };

  const getContactsByLocation = useCallback(
    (location: LocationState) => {
      if (isLoaded) {
        if (location.type === "city") {
          const hasPlaceKey =
            placeKeyedLocation?.[location.stateKey] &&
            placeKeyedLocation?.[location.stateKey]?.[location.name];

          return {
            contacts: contactsByStateCity?.[location.stateKey]?.[location.name] || [],
            markers: hasPlaceKey ? placeKeyedLocation?.[location.stateKey]?.[location.name] : [],
          };
        }

        if (location.type === "msa") {
          const { msaCityIndex } = msaIndex || {};
          const cities: { city: string; stateKey: string }[] = msaCityIndex
            ? msaCityIndex[location.extId!]
            : [];

          const contactIdIndex: { [id: string]: ContactRow } = {};
          let markers = {};
          for (const c of cities || []) {
            const { city, stateKey } = c;
            if (
              stateKey &&
              city &&
              contactsByStateCity[stateKey] &&
              contactsByStateCity[stateKey][city]
            ) {
              for (const contact of contactsByStateCity[stateKey][city]) {
                contactIdIndex[contact.id] = contact;
              }

              if (placeKeyedLocation[stateKey] && placeKeyedLocation[stateKey][city]) {
                markers = { ...markers, ...placeKeyedLocation[stateKey][city] };
              }
            }
          }

          const contacts: ContactRow[] = [];
          for (const contactId in contactIdIndex) {
            contacts.push(contactIdIndex[contactId]);
          }

          return {
            contacts: getSortedContacts(contacts, sortKey),
            markers,
          };
        }
      }

      return {
        contacts: [],
        markers: [],
      };
    },
    [contactsByStateCity, isLoaded, msaIndex, placeKeyedLocation, sortKey],
  );

  useLiveQuery(async () => {
    const frontendDb = getContactDb();
    const result = await frontendDb?.contactLocationCache.get("flattenedLocations");

    contactLocationSearch = createSearchIndex() as Document<LocationState>;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (result?.data || {}).flattenedLocations?.forEach((location) => {
      contactLocationSearch?.updateAsync(location.id, location);
    });
  });

  const { flattenedLocations, sortedStateLocationKeys, locationCountByState } = useMemo(() => {
    return getContactLocations({
      msaIndex: msaIndex || { msaIdIndex: {}, msaCityIndex: {} },
      filteredLocationIds: searchResult,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappableLocations: mappableLocations || {
        contactsByStateCity: {},
        sortedStateKeys: [],
        sortedStateCityKeys: {},
        placeKeyedLocation: {},
      },
    });
  }, [mappableLocations, msaIndex, searchResult]);

  useEffect(() => {
    if (query) {
      contactLocationSearch
        ?.searchAsync(query, {
          limit: 200,
        })
        .then((res) => {
          const [list] = res;
          const { result } = list || {};
          if (result) setSearchResult(result as string[]);
        });
    } else {
      setSearchResult([]);
    }
  }, [query]);

  return {
    getContactsByLocation,
    flattenedLocations,
    locationCountByState,
    sortedStateLocationKeys,
    sortedStateKeys,
    isLoaded,
  };
}
