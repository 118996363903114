import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { Document } from "core/types/flexsearch";
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDebounce } from "use-debounce";

import { getIdIndex } from "@/integrations/contact/helpers";

export const ContactSearchQueryContext = React.createContext("");

export function useContactGroupSearch(
  groups: ContactGroupRowForDisplay[] | undefined,
  initQuery: string = "",
  q?: string
) {
  const groupIndex = useRef<Document<ContactGroupRowForDisplay>>();

  useEffect(() => {
    groupIndex.current = new window.FlexSearch.Document<ContactGroupRowForDisplay>({
      cache: 100,
      tokenize: "forward",
      document: {
        id: "id",
        index: [{ field: "name" }],
      },
    });
  }, []);

  const [query, setQuery] = useState(initQuery);
  const [result, setResult] = useState<ContactGroupRowForDisplay[]>([]);

  const queryOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
    setQuery(target.value);
  }, []);

  const [debouncedQuery] = useDebounce(typeof q !== "undefined" ? q : query, 50);

  useEffect(() => {
    if (groups) {
      for (const group of groups) {
        groupIndex.current?.add(group.id, group);
      }
    }
  }, [groups]);

  const groupIdIndex = useMemo(() => {
    return groups ? getIdIndex(groups) : {};
  }, [groups]);

  useEffect(() => {
    if (!debouncedQuery) {
      setResult(groups || []);
      return;
    }
    if (debouncedQuery && groups) {
      const result = groupIndex.current?.search(debouncedQuery);
      if (result) {
        const filteredGroups = [];
        for (const r of result) {
          if (r.field === "name") {
            for (const i of r.result) {
              const group = groups[groupIdIndex[i]];
              filteredGroups.push(group);
            }
          }
        }
        setResult(filteredGroups);
      }
    }
  }, [debouncedQuery, groupIdIndex, groups]);

  return {
    result,
    query,
    queryOnChange,
  };
}
