import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import classNames from "clsx";
import type { FC, MutableRefObject, ReactNode } from "react";
import React, { Fragment } from "react";

export type ModalProps = {
  isModalOpen: boolean;
  setIsClosed: (open: boolean) => void;
  initialFocusRef?: MutableRefObject<HTMLElement | null>;
  className?: string;
  children?: ReactNode;
  backdropChildren?: ReactNode;
  noBackdrop?: boolean;
  backdropZIndex?: string;
};

const Modal: FC<ModalProps> = ({
  isModalOpen,
  setIsClosed,
  initialFocusRef,
  className,
  children,
  backdropChildren,
  noBackdrop,
  backdropZIndex = "z-40",
}) => {
  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={initialFocusRef}
        open={isModalOpen}
        onClose={() => setIsClosed(false)}
        className={classNames("fixed inset-0 overflow-y-auto", backdropZIndex)}
        static
      >
        {/* Background overlay */}
        {!noBackdrop && (
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-overlay" />
          </TransitionChild>
        )}

        {backdropChildren}

        <div className="justify-center block min-h-screen p-4 text-center sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          {/* Main content */}
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={classNames(
                "inline-block align-middle bg-primary rounded-lg shadow-xl transform transition-all z-50",
                className,
              )}
            >
              {children}
            </Dialog.Panel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
