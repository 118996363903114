import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";

import { ContactGroupState } from "@/integrations/contactGroup/types";

const initialState: ContactGroupState = {
  isContactGroupCreateDialogOpen: false,
  isDeleteContactGroupAlertOpen: false,
  multiSelectedContactGroups: [],
  selectedContactGroupId: undefined,
};

const contactGroupSlice = createSlice({
  name: "contactGroup",
  initialState,
  reducers: {
    setSelectedGroupId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedContactGroupId = action.payload;
    },

    setMultiSelectedContactGroups: (
      state,
      action: PayloadAction<ContactGroupState["multiSelectedContactGroups"]>
    ) => {
      state.multiSelectedContactGroups = action.payload;
      state.selectedContactGroupId = undefined;
    },

    addToMultiSelectedContactGroups: (state, action: PayloadAction<ContactGroupRowForDisplay>) => {
      state.multiSelectedContactGroups = [
        ...new Set([...state.multiSelectedContactGroups, action.payload]),
      ];
      state.selectedContactGroupId = action.payload.id;
    },

    removeFromMultiSelectedContactGroups: (state, action: PayloadAction<string>) => {
      state.multiSelectedContactGroups = state.multiSelectedContactGroups.filter(
        ({ id }) => id !== action.payload
      );
    },

    clearMultiSelectedContactGroups: (state) => {
      state.multiSelectedContactGroups = [];
    },
  },
});

export default contactGroupSlice;
