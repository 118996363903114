import { FC, PropsWithChildren } from "react";

import { SearchFilterOptionProps } from "@/components/contacts/search/types";
import { SecondaryBadge } from "@/components/core/Badge";
import { ArrowLeftIcon } from "@/components/core/Icon";

const SearchFilterOptionWrapper: FC<
  PropsWithChildren<{ filter: SearchFilterOptionProps["filter"] }>
> = ({ filter, children }) => {
  return (
    <div
      tabIndex={-1}
      className="outline-0 bg-primary text-secondary divide-y divide-color-primary rounded-md bg-primary shadow-2xl ring-1 ring-black dark:ring-zinc-600 ring-opacity-5 transition-all"
    >
      <div className="p-2">{children}</div>
      {!filter.query && filter.type !== "keyword" && (
        <div className="px-4 py-1 text-label text-center">
          Double press
          <SecondaryBadge className="mx-2">
            <span className="text-label">
              Backspace <ArrowLeftIcon className="ml-2" />
            </span>
          </SecondaryBadge>
          to remove filter
        </div>
      )}
    </div>
  );
};

export default SearchFilterOptionWrapper;
