import * as Sentry from "@sentry/nextjs";
import { useLiveQuery } from "dexie-react-hooks";
import { getCurEpochMs, getExpiresAtMs } from "utils/dateTime";
import { removeCoType } from "utils/string";

import { CompanyData, ContactData } from "@/components/contacts/v2/types";
import { getContactDb } from "@/database/contactDb";
import { queryFetch } from "@/helpers/fetch";

export async function fetchCompanyByName(companyName: string) {
  const result = await queryFetch<CompanyData>(`/company/lookup?name=${companyName}`);
  return result.response?.ok ? result.data : undefined;
}

export async function fetchCompanyByEmail(email: string) {
  const result = await queryFetch<CompanyData>(`/company/lookup?email=${email}`);
  return result.response?.ok ? result.data : undefined;
}

function isExpired({ createdAt }: { createdAt: number }) {
  const expiredAt = getExpiresAtMs("10 days", createdAt);

  return getCurEpochMs() > expiredAt;
}

export async function getCachedCompanyDataByName(companyName?: string) {
  if (!companyName)
    return {
      email: undefined,
      name: companyName,
      company: undefined,
    };

  const frontendDb = getContactDb();
  const companyMapping = await frontendDb?.companyQueries
    .where("query")
    .equals(companyName)
    .first();

  if (companyMapping && !isExpired(companyMapping)) {
    if (!companyMapping?.companyId)
      return { name: companyName, email: undefined, company: undefined };

    const company = await frontendDb?.companies.get(companyMapping.companyId);
    if (company && !isExpired(company)) {
      return {
        email: undefined,
        name: companyName,
        company,
      };
    }
  }

  return {
    email: undefined,
    name: companyName,
    company: undefined,
  };
}

export function useLiveContactCompany(contactCompanyName?: ContactData["companyName"]) {
  return useLiveQuery(async () => {
    const companyName = removeCoType(contactCompanyName || "");
    return getCachedCompanyDataByName(companyName);
  }, [contactCompanyName]);
}

export async function warmContactCompanyCache(contact?: ContactData) {
  const companyName = removeCoType(contact?.companyName || "");
  if (!companyName)
    return {
      company: undefined,
    };

  const result = await getCachedCompanyDataByName(companyName);
  if (result?.company) return result;

  try {
    const frontendDb = getContactDb();
    const fetchedCompany = await fetchCompanyByName(companyName);
    await frontendDb?.transaction(
      "rw",
      frontendDb.companies,
      frontendDb.companyQueries,
      async () => {
        await frontendDb.companyQueries.put({
          query: companyName,
          companyId: fetchedCompany?.id,
          createdAt: getCurEpochMs(),
        });
        if (fetchedCompany?.id)
          await frontendDb.companies.put({ ...fetchedCompany, createdAt: getCurEpochMs() });
      },
    );
    return {
      name: companyName,
      company: fetchedCompany,
    };
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }

  return {
    name: companyName,
    company: undefined,
  };
}

async function getCachedCompanyMappings(contact: ContactData) {
  const frontendDb = getContactDb();

  return frontendDb?.transaction("r", frontendDb.companyQueries, frontendDb.companies, async () => {
    const emails = contact.emails?.map(({ value }) => value) || [];
    const companyMappings = (
      await frontendDb.companyQueries.where("query").anyOf(emails).toArray()
    ).map((companyMapping) => {
      if (companyMapping && !isExpired(companyMapping)) {
        if (!companyMapping?.companyId) return { email: companyMapping.query };
        return {
          email: companyMapping.query,
          companyId: companyMapping.companyId,
        };
      }
      return {
        email: companyMapping.query,
      };
    });

    const companyIds = companyMappings
      .filter((companyMapping) => companyMapping?.companyId)
      .map((companyMapping) => companyMapping.companyId) as string[];

    const companies = await frontendDb.companies.bulkGet(companyIds);

    return companyMappings.map((companyMapping) => {
      const company = companyMapping.companyId
        ? companies.find((company) => company?.id === companyMapping.companyId)
        : undefined;

      return {
        ...companyMapping,
        company,
      };
    });
  });
}

export async function warmContactEmailCompanyCache(contact?: ContactData) {
  if (!contact?.emails || contact?.emails?.length === 0) {
    return {
      company: undefined,
    };
  }

  const companyMappings = await getCachedCompanyMappings(contact);
  const companiesToFetch =
    companyMappings?.filter((companyMapping) => !companyMapping?.company) || [];

  const companiesFetched = await Promise.all(
    companiesToFetch.map(async (companyMapping) => {
      const company = await fetchCompanyByEmail(companyMapping.email);
      return {
        ...companyMapping,
        company: {
          ...company,
          createdAt: getCurEpochMs(),
        },
      };
    }),
  );

  const companiesToCache = companiesFetched.filter(({ company }) => company?.id);

  const frontendDb = getContactDb();
  frontendDb?.transaction("rw", frontendDb.companies, frontendDb.companyQueries, async () => {
    await frontendDb.companies.bulkPut(
      companiesToCache.map(({ company }) => company as CompanyData & { createdAt: number }),
    );

    return frontendDb.companyQueries.bulkPut(
      companiesToCache.map((mapping) => {
        return {
          query: mapping.email,
          companyId: mapping.company?.id,
          createdAt: getCurEpochMs(),
        };
      }),
    );
  });
}

export function useLiveEmailDomainCompany(email: string | undefined) {
  return useLiveQuery(async () => {
    if (!email)
      return {
        name: undefined,
        email,
        company: undefined,
      };

    const frontendDb = getContactDb();
    const companyMapping = await frontendDb?.companyQueries.where("query").equals(email).first();
    if (companyMapping && !isExpired(companyMapping)) {
      if (!companyMapping.companyId) return { email, name: undefined, company: undefined };

      const company = await frontendDb?.companies.get(companyMapping.companyId);
      if (company && !isExpired(company)) {
        return {
          name: undefined,
          email,
          company,
        };
      }
    }
    return {
      name: undefined,
      email,
      company: undefined,
    };
  }, [email]);
}
