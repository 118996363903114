import { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import { RemoteApiStatus } from "@shared/models/types";
import { ElementType } from "react";

import ContactDuplicateBadge from "@/components/contacts/duplicates/ContactDuplicateBadge";
import {
  AddressBookSolidIcon,
  BellSolidIcon,
  BirthdayCakeSolidIcon,
  BookCopySolidIcon,
  CalendarSolidIcon,
  CogSolidIcon,
  DiagramSuccessorSolidIcon,
  EnvelopeBulkSolidIcon,
  MapMarkedAltSolidIcon,
  ObjectsColumnSolidIcon,
  SeedlingSolidIcon,
  TagSolidIcon,
  ToolsSolidIcon,
} from "@/components/core/Icon";
import { defaultToolRoute } from "@/components/tools/tabs";

type RouteResolver = (slug?: string) => string;

export interface BaseRoute {
  title: string;
  href: string | RouteResolver;
  as?: RouteResolver;
  icon?: ElementType;
  Right?: JSX.Element;
  isCurrent?: (pathname: string) => boolean;
  isActive?: (pathname: string) => boolean;
  isHidden?: boolean;
  subRoutes?: {
    title: string;
    href: string;
    icon?: ElementType;
    Right?: JSX.Element;
    isCurrent: (pathname: string) => boolean;
    isHidden?: boolean;
    color?: string;
  }[];
}

export function isContactRoute(pathname: string) {
  return (
    pathname.startsWith("/contacts") ||
    pathname.startsWith("/prospects") ||
    pathname.startsWith("/groups")
  );
}

export function getContactsRoute({
  remoteApis,
  dupeCount,
  onboardingCount,
}: {
  remoteApis?: RemoteApiForDisplay[] | undefined;
  dupeCount?: number;
  onboardingCount?: number;
}): BaseRoute {
  const shouldHideDuplicates =
    !dupeCount ||
    Boolean(onboardingCount) ||
    typeof remoteApis === "undefined" ||
    remoteApis.some((remote) => remote.status === RemoteApiStatus.USER_MERGE);

  return {
    title: "Contacts",
    href: (slug) => (slug ? "/contacts/[slug]" : "/contacts"),
    as: (slug) => (slug ? `/contacts/${slug}` : "/contacts"),
    icon: AddressBookSolidIcon,
    isCurrent: isContactRoute,
    isActive: isContactRoute,
    subRoutes: [
      {
        title: "Onboarding",
        href: "/contacts/onboarding",
        icon: DiagramSuccessorSolidIcon,
        Right: <ContactDuplicateBadge dupeCount={onboardingCount} />,
        isCurrent: (pathname: string) => pathname === "/contacts/onboarding",
        isHidden: !onboardingCount,
      },
      {
        title: "Duplicates",
        href: "/contacts/duplicates",
        icon: BookCopySolidIcon,
        Right: <ContactDuplicateBadge dupeCount={dupeCount} />,
        isCurrent: (pathname: string) => pathname === "/contacts/duplicates",
        isHidden: shouldHideDuplicates,
      },
      {
        title: "Dates",
        href: "/contacts/dates",
        icon: BirthdayCakeSolidIcon,
        isCurrent: (pathname: string) => pathname === "/contacts/dates",
      },
      {
        title: "Locations",
        href: "/contacts/locations",
        icon: MapMarkedAltSolidIcon,
        isCurrent: (pathname: string) => pathname === "/contacts/locations",
      },
      {
        title: "Prospects",
        href: "/prospects",
        icon: SeedlingSolidIcon,
        isCurrent: (pathname: string) => pathname.startsWith("/prospects"),
      },
    ].filter((route) => !route.isHidden),
  };
}

export const GroupsRoute: BaseRoute = {
  title: "Groups",
  href: (slug) => (slug ? "/groups/[slug]" : "/groups"),
  as: (slug) => (slug ? `/groups/${slug}` : "/groups"),
  icon: TagSolidIcon,
  isCurrent: (pathname) => pathname === "/groups",
  isActive: (pathname) => pathname.startsWith("/groups"),
};

export const SettingsRoute: BaseRoute = {
  title: "Settings",
  href: (slug) => `/settings/${slug ? "[slug]" : "account"}`,
  as: (slug) => `/settings/${slug || "account"}`,
  icon: CogSolidIcon,
  isCurrent: (pathname) => pathname === "/settings",
  isActive: (pathname) => pathname.startsWith("/settings"),
};

export const UpdatesRoute: BaseRoute = {
  title: "Updates",
  href: "/updates",
  icon: BellSolidIcon,
  isCurrent: (pathname) => pathname === "/updates",
  isActive: (pathname) => pathname.startsWith("/updates"),
};

export const DashboardRoute: BaseRoute = {
  title: "Dashboard",
  href: "/dashboard",
  icon: ObjectsColumnSolidIcon,
  isCurrent: (pathname) => pathname === "/dashboard",
  isActive: (pathname) => pathname.startsWith("/dashboard"),
};

export const ToolsRoute: BaseRoute = {
  title: "Tools",
  href: (slug) => `/tools/${slug ? "[slug]" : defaultToolRoute}`,
  as: (slug) => `/tools/${slug || defaultToolRoute}`,
  icon: ToolsSolidIcon,
  isCurrent: (pathname) => pathname === "/tools",
  isActive: (pathname) => pathname.startsWith("/tools"),
};

export const AccountsRoute: BaseRoute = {
  title: "Accounts",
  href: "/accounts",
  icon: EnvelopeBulkSolidIcon,
  isCurrent: (pathname) => pathname === "/accounts",
  isActive: (pathname) => pathname.startsWith("/accounts"),
};
export const CalendarRoute: BaseRoute = {
  title: "Calendar",
  href: "/calendar",
  icon: CalendarSolidIcon,
  isCurrent: (pathname) => pathname === "/calendar",
  isActive: (pathname) => pathname.startsWith("/calendar"),
};

export const allRoutes: BaseRoute[] = [
  getContactsRoute({}),
  GroupsRoute,
  SettingsRoute,
  UpdatesRoute,
];

export function getBaseRoute(pathname: string): BaseRoute {
  return allRoutes.find((route) => route.isCurrent?.(pathname)) || getContactsRoute({});
}
