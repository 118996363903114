import classNames from "clsx";
import type { DetailedHTMLProps, FC, HTMLAttributes, ReactElement } from "react";

export type TagItem = {
  id: string;
  name: string;
};

type TagProps = {
  tagItem: TagItem;
  onClickTag?: (tagItem: TagItem) => void;
  enableRemoveButton: boolean;
  onRemoveTag: () => void;
  backgroundColor?: string;
  foregroundColor?: string;
  className?: string;
  Icon?: ReactElement;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Tag: FC<TagProps> = ({
  tagItem,
  enableRemoveButton,
  onClickTag,
  onRemoveTag,
  backgroundColor,
  foregroundColor,
  className,
  Icon,
  ...props
}) => {
  const textColorClassNames = foregroundColor || "text-indigo-700 dark:text-indigo-200";
  const backgroundColorClassNames = backgroundColor || "bg-indigo-100 dark:bg-indigo-900";
  const hoverClassNames = !!onClickTag ? "hover:opacity-90 cursor-pointer" : "";

  return (
    <div
      {...props}
      className={classNames(
        "inline-flex rounded-md items-center py-0.5 pl-2.5 pr-1.5 text-sm font-medium",
        textColorClassNames,
        backgroundColorClassNames,
        hoverClassNames,
        className,
      )}
      onClick={() => onClickTag?.(tagItem)}
      style={{ color: foregroundColor, backgroundColor }}
    >
      {Icon && <span className="pr-1.5">{Icon}</span>}
      {tagItem.name}
      <button
        type="button"
        tabIndex={-1}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRemoveTag();
        }}
        disabled={!enableRemoveButton}
        className="flex-shrink ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-secondary hover:bg-zinc-200 hover:text-zinc-500 focus:outline-none opacity-90"
      >
        <span className="sr-only">Remove {tagItem.name} button</span>
        <svg className="w-2 h-2 items-center" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </div>
  );
};

export default Tag;
