import { Transition } from "@headlessui/react";
import type { Placement } from "@popperjs/core";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { usePopper } from "react-popper";
import { capitalizeFirstLetter } from "utils/string";

import { KeyboardIcon } from "@/components/core/Icon";
import { isMac } from "@/helpers/device";

const useHotKeyWithPrompt = (
  hotkey?: string[],
  hotkeyHandler?: (e: KeyboardEvent) => void,
  options?: {
    referenceRef: HTMLElement | undefined;
    placement?: Placement;
    strategy?: "absolute" | "fixed";
    offset?: [number, number];
  }
) => {
  const isHotKeyEnabled = Boolean(hotkey);

  const [showHotKey, setShowHotKey] = useState(false);
  const hotKeyTimerRef = useRef<NodeJS.Timeout | null>(null);

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (isHotKeyEnabled && e.key === "Alt") {
        hotKeyTimerRef.current = setTimeout(() => {
          e.preventDefault();
          e.stopPropagation();
          setShowHotKey(true);
        }, 500);
      }
    },
    [isHotKeyEnabled]
  );

  useEffect(() => {
    window.removeEventListener("keydown", keyDownHandler);
    if (isHotKeyEnabled) {
      window.addEventListener("keydown", keyDownHandler);
    }
    return () => window.removeEventListener("keydown", keyDownHandler);
  }, []);

  const keyUpHandler = useCallback(() => {
    if (hotKeyTimerRef.current) {
      clearTimeout(hotKeyTimerRef.current);
      setShowHotKey(false);
    }
  }, []);

  useEffect(() => {
    window.removeEventListener("keyup", keyUpHandler);
    if (isHotKeyEnabled) {
      window.addEventListener("keyup", keyUpHandler);
    }
    return () => window.removeEventListener("keyup", keyUpHandler);
  }, [isHotKeyEnabled, keyUpHandler]);

  useHotkeys(hotkey || [], (e) => {
    if (hotkeyHandler) hotkeyHandler(e);
  });

  const popperDivRef = useRef(null);

  const { styles, attributes } = usePopper(options?.referenceRef, popperDivRef?.current, {
    placement: options?.placement || "top-start",
    strategy: options?.strategy || "absolute",
    modifiers: [{ name: "offset", options: { offset: options?.offset || [0, 2] } }],
  });

  const Prompt = useMemo(() => {
    return hotkey ? (
      <div ref={popperDivRef} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
        <Transition
          appear
          show={showHotKey}
          enter="transition-opacity duration-400"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-700"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div
            className="px-1 rounded text-sm border bg-secondary text-secondary whitespace-nowrap"
            // style={{ marginTop: "-35px", marginLeft: "-2rem" }} // some space-x class in the parent that will override margin class, so hardcoding as inline
            style={{ zIndex: 1000 }}
          >
            <KeyboardIcon className="mr-1.5" size="sm" />
            <span>
              {hotkey
                .map((keyStr) => {
                  const keys = keyStr.split("+");

                  return keys
                    .map((key) => {
                      let k = key.toLowerCase().trim();
                      if (k === "alt") k = isMac() ? "Opt" : "Alt";
                      if (k === "delete") k = "del";
                      return capitalizeFirstLetter(k);
                    })
                    .join(" + ");
                })
                .join(", ")}
            </span>
          </div>
        </Transition>
      </div>
    ) : null;
  }, [attributes.popper, hotkey, showHotKey, styles.popper]);

  return {
    Prompt,
    showHotKey,
    setShowHotKey,
  };
};

export default useHotKeyWithPrompt;
