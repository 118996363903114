import * as Sentry from "@sentry/nextjs";
import classNames from "clsx";
import type { MutableRefObject, ReactElement } from "react";
import { GroupedVirtuoso, VirtuosoHandle } from "react-virtuoso";

import { SecondaryBadge } from "@/components/core/Badge";
import LoadingSpinner from "@/components/loading/LoadingSpinner";

function GroupedVirtualizedList<T extends { id: string }>({
  refHandle,
  items,
  selectedItem,
  isLoaded,
  labelCounts,
  labels,
  itemContent,
  scrollRef,
  EmptyState = "No contacts",
  showGroupCount,
  noTopBorder,
  className,
  onRangeChanged,
  overscan,
}: {
  items: T[];
  isLoaded: boolean;
  labels?: string[];
  labelCounts?: number[];
  showItemCount?: boolean;
  selectedItem?: T | null;
  refHandle: MutableRefObject<VirtuosoHandle | null>;
  scrollRef?: (ref: HTMLElement | Window | null) => any;
  itemContent: (index: number) => ReactElement;
  showGroupCount?: boolean;
  EmptyState?: ReactElement | string;
  GroupItem?: (index: number) => ReactElement;
  noTopBorder?: boolean;
  className?: string;
  overscan?: number;
  onRangeChanged?: (range: { startIndex: number; endIndex: number }) => void;
}) {
  if (isLoaded && items.length === 0) {
    return (
      <div className={classNames("relative block w-full p-12 text-center", className)}>
        {typeof EmptyState === "string" ? (
          <span className="mt-2 block text-sm font-medium text-primary">{EmptyState}</span>
        ) : (
          EmptyState
        )}
      </div>
    );
  }

  if (!isLoaded) {
    return <LoadingSpinner loadingText="Loading..." />;
  }

  try {
    return (
      <GroupedVirtuoso
        overscan={overscan || 20}
        rangeChanged={onRangeChanged}
        className={className}
        key={labelCounts?.join("")} // fixes when list height doesn't reset when contacts are filtered
        initialTopMostItemIndex={Math.max(
          items?.findIndex(({ id }) => id === selectedItem?.id) || 0,
          0,
        )}
        ref={refHandle}
        scrollerRef={scrollRef}
        groupCounts={labelCounts ? labelCounts : undefined}
        groupContent={(index) => {
          const count = labelCounts ? labelCounts[index] : 0;
          return (
            <div
              className={classNames(
                !noTopBorder && "border-t",
                "flex px-6 py-1 text-sm font-medium border-b border-color-primary bg-secondary text-secondary dark:bg-zinc-900",
              )}
            >
              {labels && (
                <span className="mx-3 items-center font-bold truncate">{labels[index]}</span>
              )}
              {showGroupCount && <SecondaryBadge className="ml-auto">{count}</SecondaryBadge>}
            </div>
          );
        }}
        itemContent={itemContent}
      />
    );
  } catch (error) {
    Sentry.captureException(error);
    console.trace(error);
    return null;
  }
}

export default GroupedVirtualizedList;
