import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";

import { deleteAllDbs } from "@/database";
import emailSlice from "@/integrations/email/slice";

import { appApi, appNoAuthNoCacheApi, appNoCacheApi } from "../app/api";
import appSlice from "../app/slice";
import authSlice, { signOut } from "../auth/slice";
import contactSlice from "../contact/slice";
import contactGroupSlice from "../contactGroup/slice";
import { geoApi } from "../geo/api";

const appReducer = combineReducers({
  [appNoAuthNoCacheApi.reducerPath]: appNoAuthNoCacheApi.reducer,
  [appApi.reducerPath]: appApi.reducer,
  [appNoCacheApi.reducerPath]: appNoCacheApi.reducer,
  [geoApi.reducerPath]: geoApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [contactSlice.name]: contactSlice.reducer,
  [contactGroupSlice.name]: contactGroupSlice.reducer,
  [appSlice.name]: appSlice.reducer,
  [emailSlice.name]: emailSlice.reducer,
});

function deleteAllCookies() {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function clearAllStorage() {
  localStorage?.clear();
  sessionStorage?.clear();
  deleteAllCookies();
  deleteAllDbs();
}

const store = configureStore({
  reducer: (state, action): AppState => {
    if (signOut.pending.match(action)) {
      // reset entire redux state upon signing out
      state = undefined;
      console.log("clearing all storage");
      clearAllStorage();
    }
    return appReducer(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(appApi.middleware)
      .concat(appNoCacheApi.middleware)
      .concat(appNoAuthNoCacheApi.middleware)
      .concat(geoApi.middleware),
});

setupListeners(store.dispatch);

export type AppState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
