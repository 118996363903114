import type { Operation } from "fast-json-patch";
import type { FC } from "react";
import { Fragment } from "react";
import type { ContactRow } from "services/src/shared/models/Contact";
import type { PhysicalAddress } from "services/src/shared/models/types";

import { formatAddressToString } from "../../../../helpers/address";
import { getHumanReadableFieldName } from "../../../../helpers/contact";
import { OPERATION_ACTION_VERB_MAPPINGS } from "../../../../helpers/operation";
import { addSpaceBetweenCapitalLetters } from "../../../../helpers/string";

type OperationValueFieldProps = {
  operation: Operation["op"];
  isPastOperation: boolean;
  fieldName: keyof ContactRow;
  fieldValue: any;
};

const OperationValueField: FC<OperationValueFieldProps> = ({
  operation,
  isPastOperation,
  fieldName,
  fieldValue,
}) => {
  const isValueEmpty = !fieldValue;

  // Store final components to render
  const components: (string | JSX.Element)[] = [];

  // Initial verb ("Change", "Add", etc.). Treat setting value to empty string as a removal.
  const verbMapping = OPERATION_ACTION_VERB_MAPPINGS[isValueEmpty ? "remove" : operation];
  if (verbMapping) {
    components.push(isPastOperation ? verbMapping.past : verbMapping.present);
  }

  // Resolve how to display value for each contact field
  let displayValue: string | JSX.Element = "";
  switch (fieldName) {
    case "emails":
    case "phoneNumbers":
    case "webPages":
      if (Array.isArray(fieldValue)) {
        displayValue = fieldValue.map((o) => o.value).join(", ");
      } else {
        displayValue = fieldValue?.value || "";
      }
      break;
    case "physicalAddresses":
      if (Array.isArray(fieldValue)) {
        displayValue = fieldValue
          .map((o) => formatAddressToString(o as PhysicalAddress))
          .join(", ");
      } else {
        displayValue = formatAddressToString(fieldValue as PhysicalAddress);
      }
      break;
    case "imHandles":
      if (Array.isArray(fieldValue)) {
        displayValue = fieldValue.map((o) => o.value?.replace("xmpp:", "")).join(", ");
      } else {
        displayValue = fieldValue.value?.replace("xmpp:", "");
      }
      break;
    default:
      displayValue = fieldValue;
  }
  if (operation === "add" || operation === "remove") {
    components.push(<strong>{displayValue}</strong>);
  } else {
    // Sanitized field name ("email", "phone number", etc.) and type name
    const humanReadableFieldName = getHumanReadableFieldName(fieldName);
    const humanReadableType = addSpaceBetweenCapitalLetters(fieldValue?.type).toLowerCase();
    if (humanReadableType) {
      components.push(humanReadableType);
    }
    components.push(humanReadableFieldName);

    // Show the changed value if not empty
    if (!isValueEmpty) {
      components.push("to");
      components.push(<strong>{displayValue}</strong>);
    }
  }

  return (
    <p className="space-x-1 text-secondary">
      {components.map((component, index) => (
        <Fragment key={index}>{component} </Fragment>
      ))}
    </p>
  );
};

export default OperationValueField;
