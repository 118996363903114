import { Disclosure } from "@headlessui/react";
import classNames from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import type { FC } from "react";

import { ChevronLeftIcon } from "@/components/core/Icon";
import type { BaseRoute } from "@/helpers/routes";

type SidebarItemProps = {
  route: BaseRoute;
  actionTitle?: string;
  shrinkSidebar?: boolean;
};

// eslint-disable-next-line react/display-name
const SidebarRouteItem: FC<SidebarItemProps> = ({ route, actionTitle, shrinkSidebar }) => {
  const router = useRouter();
  const isCurrent = !shrinkSidebar
    ? route?.isCurrent?.(router.pathname)
    : route.isCurrent?.(router.pathname) &&
      route.subRoutes?.every((sub) => sub.isCurrent?.(router.pathname) === false);
  const isActive = route.isActive?.(router.pathname);

  const href = typeof route.href === "string" ? route.href : route.href();
  const as = route.as && route.as();

  return route.subRoutes ? (
    <Disclosure
      as="div"
      key={route.title}
      className="space-y-1 transition-all"
      defaultOpen={isActive}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              isCurrent
                ? "text-zinc-900 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-800"
                : "text-zinc-700 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800",
              "group w-full flex items-center p-2 text-left text-sm font-medium rounded-md focus:outline-none"
            )}
            onClick={async (e: React.MouseEvent) => {
              if (!isCurrent) {
                e.preventDefault();
              }
              await router.push(href, as, { shallow: true });
            }}
          >
            {route.icon && (
              <div
                className={classNames(
                  "pt-0.5 flex-shrink-0 h-6 w-6 text-center",
                  shrinkSidebar ? "mx-auto" : "mr-3"
                )}
              >
                <route.icon
                  swapOpacity
                  size={shrinkSidebar ? "xl" : "lg"}
                  className={
                    isCurrent || isActive
                      ? "text-zinc-500 dark:text-zinc-400"
                      : "text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500"
                  }
                />
              </div>
            )}
            {!shrinkSidebar && <span className="flex-1">{route.title}</span>}
            {!shrinkSidebar && (
              <ChevronLeftIcon
                className={classNames(
                  isActive ? "text-gray-400 -rotate-90" : "text-gray-300",
                  "ml-3 mr-1 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                )}
              />
            )}
          </Disclosure.Button>

          <Disclosure.Panel className="space-y-1" static>
            {isActive
              ? route.subRoutes?.map((sub) => {
                  const isSubCurrent = sub.isCurrent?.(router.pathname);
                  return (
                    <Link href={sub.href} as={sub.href} passHref key={sub.href} legacyBehavior>
                      <Disclosure.Button
                        key={sub.title}
                        className={classNames(
                          sub.color,
                          isSubCurrent
                            ? "text-zinc-900 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-800"
                            : "text-zinc-700 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800",
                          "group relative w-full flex items-center pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50",
                          !shrinkSidebar ? "pl-6" : "pl-2 justify-center"
                        )}
                      >
                        {sub.icon && (
                          <div
                            className={classNames("pt-0.5 flex-shrink-0 h-6 w-6 text-center", {
                              "mr-3": !shrinkSidebar,
                            })}
                          >
                            <sub.icon
                              swapOpacity
                              size={shrinkSidebar ? "xl" : "lg"}
                              className={
                                isSubCurrent
                                  ? "text-zinc-500 dark:text-zinc-400"
                                  : "text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500"
                              }
                            />
                          </div>
                        )}
                        {!shrinkSidebar && sub.title}
                        {shrinkSidebar ? (
                          <span className="absolute inset-0 object-right-top -mr-1">
                            <div className="flex flex-grow justify-end ">{sub.Right}</div>
                          </span>
                        ) : (
                          <div className="flex flex-grow justify-end ">{sub.Right}</div>
                        )}
                      </Disclosure.Button>
                    </Link>
                  );
                })
              : null}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Link
      href={href}
      as={as}
      passHref
      className={classNames(
        isCurrent || isActive
          ? "text-zinc-900 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-800"
          : "text-zinc-700 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800",
        "group flex items-center w-full p-2 text-sm font-medium rounded-md"
      )}
      aria-current={isCurrent ? "page" : undefined}
    >
      {/* Route icon */}
      {route.icon && (
        <div
          className={classNames(
            "pt-0.5 flex-shrink-0 h-6 w-6 text-center",
            shrinkSidebar ? "mx-auto" : "mr-3"
          )}
        >
          <route.icon
            swapOpacity
            size={shrinkSidebar ? "xl" : "lg"}
            className={
              isCurrent
                ? "text-zinc-500 dark:text-zinc-400"
                : "text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500"
            }
          />
        </div>
      )}
      {/* Route title */}
      {!shrinkSidebar && <div className="flex-1">{route.title}</div>}
      {/* Action title */}
      {!shrinkSidebar && actionTitle && (
        <div className="text-xs font-bold uppercase text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500">
          {actionTitle}
        </div>
      )}
    </Link>
  );
};

export default SidebarRouteItem;
