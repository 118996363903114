import { FC, PropsWithChildren, useRef } from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";

const Portal: FC<
  PropsWithChildren<
    {
      className?: string;
      el?: string;
      getRef?: (ref: Element) => void;
    } & React.HTMLProps<HTMLElement>
  >
> = ({ children, className = "root-portal", el = "div", getRef }) => {
  const ref = useRef<Element | null>(null);

  useEffect(() => {
    ref.current = document.createElement(el);
    getRef?.(ref.current);
    className.split(" ").forEach((c) => {
      ref.current?.classList.add(c);
    });
    document.body.appendChild(ref.current);
    return () => {
      if (ref.current) document.body.removeChild(ref.current);
    };
  }, []);

  return ref.current ? createPortal(children, ref.current) : null;
};

export default Portal;
