import classNames from "clsx";
import { CheckIcon, CircleSolidIcon } from "components/core/Icon";
import useDarkMode from "hooks/useDarkMode";
import { selectSelectedGroups } from "integrations/contact/selectors";
import contactSlice from "integrations/contact/slice";
import { useAppDispatch } from "integrations/redux/store";
import Link from "next/link";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import type { ContactGroupRowForDisplay } from "services/src/shared/models/ContactGroup";

import { getGroupColor } from "@/components/groups/helpers";
import type { BaseRoute } from "@/helpers/routes";

type SidebarGroupsSectionProps = {
  groups?: ContactGroupRowForDisplay[];
  route: BaseRoute;
  actionTitle?: string;
  shrinkSidebar?: boolean;
};

export const SidebarGroupsSection: FC<SidebarGroupsSectionProps> = ({
  groups,
  route,
  actionTitle,
  shrinkSidebar,
}) => {
  const [isDarkMode] = useDarkMode();
  const router = useRouter();
  const currentPathName = router?.pathname;

  const dispatch = useAppDispatch();
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectedGroupIds = selectedGroups.map((o) => o.id);

  const onToggleGroupSelection = useCallback(
    (group: ContactGroupRowForDisplay) => {
      if (selectedGroupIds.includes(group.id)) {
        const groups = selectedGroups.filter((o) => o.id !== group.id);
        dispatch(contactSlice.actions.setSelectedGroups({ groups }));
      } else {
        const groups = [...selectedGroups, group];
        dispatch(contactSlice.actions.setSelectedGroups({ groups }));
      }

      // If we are not currently showing /contacts route, navigate to it
      if (currentPathName !== "/contacts") {
        router.push("/contacts", undefined, { shallow: true });
      }
    },
    [selectedGroupIds, currentPathName, selectedGroups, dispatch, router]
  );

  const isCurrent = route.isCurrent?.(router.pathname);
  const isActive = route.isActive?.(router.pathname);

  const href = typeof route.href === "string" ? route.href : route.href();
  const as = route.as && route.as();

  const groupList = useMemo(() => {
    return (
      <>
        {groups?.map((group) => {
          const isSelected = selectedGroupIds.includes(group.id);
          const hslColor = getGroupColor(group, isDarkMode);
          return (
            <div
              className="w-56 flex flex-row items-center pl-4 rounded-md cursor-pointer hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800"
              key={group.id}
              onClick={() => onToggleGroupSelection(group)}
            >
              {!shrinkSidebar && (
                <CircleSolidIcon style={{ color: hslColor, height: "8px", width: "8px" }} />
              )}
              <span
                className={classNames(
                  isSelected
                    ? "text-zinc-900 dark:text-zinc-100"
                    : "text-zinc-700 dark:text-zinc-500",
                  "group flex items-center ml-5 py-1.5 text-sm rounded-md"
                )}
              >
                {group.name}
              </span>
              {isSelected && <CheckIcon className="ml-1.5 text-green-500" size="sm" />}
            </div>
          );
        })}
      </>
    );
  }, [groups, isDarkMode, onToggleGroupSelection, selectedGroupIds, shrinkSidebar]);

  return (
    <>
      <Link
        href={href}
        as={as}
        className={classNames(
          isCurrent || isActive
            ? "text-zinc-900 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-800"
            : "text-zinc-700 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800",
          "group flex items-center w-full p-2 text-sm font-medium rounded-md text-left"
        )}
        aria-current={isCurrent ? "page" : undefined}
      >
        {/* Route icon */}
        {route.icon && (
          <div
            className={classNames(
              "pt-0.5 flex-shrink-0 h-6 w-6 text-center",
              shrinkSidebar ? "mx-auto" : "mr-3"
            )}
          >
            <route.icon
              size={shrinkSidebar ? "lg" : "sm"}
              className={
                isCurrent
                  ? "text-zinc-500 dark:text-zinc-400"
                  : "text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500"
              }
            />
          </div>
        )}
        {/* Route title */}
        {!shrinkSidebar && <div className="flex-1">{route.title}</div>}
        {/* Action title */}
        {!shrinkSidebar && actionTitle && (
          <div className="text-xs font-bold uppercase text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500">
            {actionTitle}
          </div>
        )}
      </Link>
      {!shrinkSidebar && groupList}
    </>
  );
};
