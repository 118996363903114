import { DdbBoolean } from "@shared/models/types";
import { useLiveQuery } from "dexie-react-hooks";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { getEmailDraftDb, LocallyPersistedEmailDraft } from "@/database/emailDraftDb";

export type EmailDraftContextProps = {
  drafts: LocallyPersistedEmailDraft[] | undefined;
  editDraft: (draftId: string, draft: Partial<LocallyPersistedEmailDraft>) => void;
  upsertDraft: (draft: LocallyPersistedEmailDraft) => void;
  deleteDraft: (draftId: string) => void;
};

export const EmailDraftContext = createContext<EmailDraftContextProps>({
  drafts: [],
  editDraft: () => {},
  upsertDraft: () => {},
  deleteDraft: () => {},
});

export const EmailDraftProvider: FC<
  PropsWithChildren<{
    openedOnly?: boolean;
    queryData?: boolean;
  }>
> = ({ openedOnly = true, queryData = true, children }) => {
  const [lastUpdateTick, setLastUpdateTick] = useState<number>(Date.now());
  const cache = useRef<{ [draftId: string]: LocallyPersistedEmailDraft | null }>({});
  const deletedDrafts = useRef(new Set<string>());

  const persistedDrafts = useLiveQuery(async () => {
    if (!queryData) return;
    const db = getEmailDraftDb();
    let results: LocallyPersistedEmailDraft[] | undefined;
    if (openedOnly) {
      results = await db?.draft.where("_isOpenedAt").above(1).reverse().sortBy("_isOpenedAt");
    } else {
      results = await db?.draft.orderBy("_isOpenedAt").reverse().toArray();
    }
    cache.current = {};
    deletedDrafts.current = new Set();
    return results;
  }, [openedOnly]);

  const editDraft = useCallback((draftId: string, draft: Partial<LocallyPersistedEmailDraft>) => {
    const db = getEmailDraftDb();

    const draftPartial = {
      ...draft,
      updatedAt: Date.now(),
    };
    db?.draft.update(draftId, draftPartial);
    cache.current = {
      ...cache.current,
      [draftId]: {
        ...cache.current[draftId],
        ...draftPartial,
      } as LocallyPersistedEmailDraft,
    };
    setLastUpdateTick((prev) => prev + 1);
  }, []);

  const upsertDraft = useCallback((draft: LocallyPersistedEmailDraft) => {
    const db = getEmailDraftDb();
    db?.draft.put(draft);
    cache.current = {
      ...cache.current,
      [draft.id]: draft,
    };
    setLastUpdateTick((prev) => prev + 1);
  }, []);

  const deleteDraft = useCallback((draftId: string) => {
    const db = getEmailDraftDb();
    db?.draft.delete(draftId);
    deletedDrafts.current.add(draftId);
    setLastUpdateTick((prev) => prev + 1);
  }, []);

  const allDrafts = useMemo(() => {
    const result: LocallyPersistedEmailDraft[] = [];

    const processedDraftIds = new Set<string>();

    for (const draft of persistedDrafts || []) {
      processedDraftIds.add(draft.id);

      if (deletedDrafts.current.has(draft.id)) {
        continue;
      }

      const cached = cache.current[draft.id];
      if (cached && cached.updatedAt > draft.updatedAt) {
        // cache is newer than persisted, merge them to simulate edits
        console.log("cache is newer than persisted", cache.current[draft.id], draft);
        result.push({
          ...draft,
          ...cache.current[draft.id],
        });
      } else {
        // not in cache, so push straight to result
        result.push(draft);
      }
    }

    for (const [draftId, draft] of Object.entries(cache.current)) {
      if (processedDraftIds.has(draftId)) {
        continue;
      }
      if (draft === null) continue;
      if (openedOnly && draft._isOpenedAt === DdbBoolean.NO) continue;
      result.push(draft);
    }

    return result;
  }, [persistedDrafts, openedOnly, lastUpdateTick]);

  return (
    <EmailDraftContext.Provider value={{ drafts: allDrafts, editDraft, deleteDraft, upsertDraft }}>
      {children}
    </EmailDraftContext.Provider>
  );
};

export default EmailDraftProvider;
