import { Contact } from "@shared/models/Contact";
import { ContactInteractionRow } from "@shared/models/ContactInteraction";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect } from "react";

import { getContactDb } from "@/database/contactDb";
import { queryFetch } from "@/helpers/fetch";
export async function fetchContactInteraction({
  contactId,
  maxUpsertedAt,
  minUpsertedAt,
  startKey,
  limit,
}: {
  contactId: Contact["id"];
  maxUpsertedAt?: number;
  minUpsertedAt?: number;
  startKey?: string;
  limit?: number;
}) {
  const endpointRoot = `/contacts/${contactId}/interaction`;

  const urlSearchParams = new URLSearchParams();
  if (maxUpsertedAt) urlSearchParams.append("maxUpsertedAt", String(maxUpsertedAt));
  if (minUpsertedAt) urlSearchParams.append("minUpsertedAt", String(minUpsertedAt));
  if (startKey) urlSearchParams.append("startKey", startKey);
  if (limit) urlSearchParams.append("limit", String(limit));

  const qs = urlSearchParams.toString();

  const result = await queryFetch<ContactInteractionRow[]>(
    qs ? `${endpointRoot}?${qs}` : endpointRoot,
  );

  return {
    data: result.response?.ok ? result.data : undefined,
    startKey: result.response?.headers.get("next-start-key"),
  };
}

async function fetchMoreRecent(contactId: Contact["id"] | undefined) {
  if (!contactId) return;
  const frontendDb = getContactDb();
  const result = await frontendDb?.contactInteraction
    .where("contactId")
    .equals(contactId)
    .reverse()
    .limit(1)
    .sortBy("upsertedAt");

  const [latestInteraction] = result || [];

  const latestUpsertedAt = latestInteraction?.upsertedAt;

  let startKey = undefined;

  do {
    const { data, startKey: nextStartKey } = await fetchContactInteraction({
      contactId,
      minUpsertedAt: latestUpsertedAt,
      startKey,
    });

    if (data) {
      await frontendDb?.contactInteraction.bulkPut(data);
    }

    // only keep fetching if we already have some data, and only fetching for latest
    // otherwise, let user scroll and fetch more as needed
    if (latestUpsertedAt) startKey = nextStartKey;
  } while (startKey);
}

async function fetchMoreHistorical(contactId: Contact["id"]) {
  const frontendDb = getContactDb();
  const result = await frontendDb?.contactInteraction
    .where("contactId")
    .equals(contactId)
    .limit(1)
    .sortBy("upsertedAt");

  const [oldestInteraction] = result || [];

  const oldestUpsertedAt = oldestInteraction?.upsertedAt;

  const { data } = await fetchContactInteraction({
    contactId,
    maxUpsertedAt: oldestUpsertedAt,
    limit: 100,
  });

  if (data) {
    await frontendDb?.contactInteraction.bulkPut(data);
  }
}

export function useLiveContactInteraction(contactId: Contact["id"] | undefined) {
  useEffect(() => {
    if (contactId) fetchMoreRecent(contactId);
  }, [contactId]);

  // local fetch
  const interactions = useLiveQuery(async () => {
    if (!contactId) return;
    const frontendDb = getContactDb();
    return frontendDb?.contactInteraction
      .where("contactId")
      .equals(contactId)
      .reverse()
      .sortBy("createdAt");
  }, [contactId]);

  return {
    interactions,
    fetchMoreRecent,
    fetchMoreHistorical,
  };
}
